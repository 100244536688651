import axios, {AxiosResponse} from "axios";
import {JsonApiObjectDto} from "../../dto/json-api/JsonApiDto";
import {UserSettingsDto} from "../../dto/settings/UserSettingsDto";
import {Response} from "../../../../_metronic/helpers";
import {JsonApiErrorsModel} from "../../../modules/_models";
import {logger, toJson} from "../../../../index";

const API_URL = process.env.REACT_APP_API_URL
const USER_SETTINGS_URL = `${API_URL}/settings`

const getUserSettings = (): Promise<UserSettingsDto> => {
    return axios.get(`${USER_SETTINGS_URL}`)
        .then((response: AxiosResponse<JsonApiObjectDto<UserSettingsDto>>) => {
            if (!response) {
                return {}
            }
            const item: UserSettingsDto = response.data?.data?.attributes || {}
            item.id = response.data?.data?.id

            return item
        })
}

const updateUserSettings = (userSettings?: UserSettingsDto | undefined) => {
    if (!userSettings || !userSettings.id) {
        return
    }
    logger.debug(`Update user settings with data: ${toJson(userSettings)}`)

    return axios.put(`${USER_SETTINGS_URL}/${userSettings.id}`, userSettings)
        .then((response: AxiosResponse<Response<UserSettingsDto>>) => {
            logger.debug("User settings updated successfully")
        }).catch(error => {
            if (!error.data) {
                logger.error(`Could not get user settings! Reason: ${error}`)
                return
            }
            const errors = error.data.errors as Array<JsonApiErrorsModel>

            errors.map(error => {
                if (error.code === 'VALIDATION_ERROR') {
                    const field = error.source?.parameter || ''

                    //errors[field] = error.detail
                } else {
                    // Error detail
                }
            })
        })
}

export {
    getUserSettings,
    updateUserSettings,
}