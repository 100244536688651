/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Dispatch, FC, PropsWithChildren, SetStateAction, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {WidgetTotalResult} from "./widget/WidgetTotalResult";
import {WidgetDeposit} from "./widget/WidgetDeposit";
import {WidgetTradeDays} from "./widget/WidgetTradeDays";
import {WidgetTrades} from "./widget/WidgetTrades";
import {WidgetAvgTradeLoss} from "./widget/WidgetAvgTradeLoss";
import {WidgetAvgTradeProfit} from "./widget/WidgetAvgTradeProfit";
import {WidgetMaxDrawdown} from "./widget/WidgetMaxDrawdown";
import {WidgetBreakevenTrades} from "./widget/WidgetBreakevenTrades";
import axios, {AxiosResponse} from "axios";
import {ID} from "../../../_metronic/helpers";
import {JsonApiObjectDto} from "../../common/dto/json-api/JsonApiDto";
import {AccountDashboardDto} from "../../common/dto/account/AccountDashboardDto";
import {ResultTimelineChartWidget} from "./widget/ResultTimelineChartWidget";
import {WidgetProfitTradesPercent} from "./widget/WidgetProfitTradesPercent";
import {WidgetLongTrades} from "./widget/WidgetLongTrades";
import {WidgetShortTrades} from "./widget/WidgetShortTrades";
import {AccountDataProvider, AccountDataResponseProvider} from "../../common/provider/account/AccountDataProvider";
import {FinanceResultToolbar} from "./component/FinanceResultToolbar";
import {TradesChartWidget} from "./widget/TradesChartWidget";
import {TradesResultChartWidget} from "./widget/TradesResultChartWidget";
import moment from "moment";
import {MetaHeader} from "../../common/component/meta/MetaHeader";
import {CurrentAccountsSelectBox} from "../accounts/accounts-list/components/CurrentAccountsSelectBox";
import {Loader} from "../../common/loader/Loader";
import {
    UserSettingsRequestProvider,
    UserSettingsResponseProvider,
    useUserSettingsContext
} from "../../common/provider/settings/UserSettingsDataProvider";
import {UserSettingsMapper} from "../../common/mapper/settings/UserSettingsMapper";
import {updateUserSettings} from "../../common/request/settings/UserSettingsRequest";
import {JsonApiQueryFilterField} from "../../common/dto/json-api/JsonApiQueryFilter";
import {QueryParam} from "../../common/utils/query/QueryParam";
import {useSearchParams} from "react-router-dom";
import Select from "react-select";
import {getAllTags} from "../../common/request/tag/TagRequest";
import {JsonApiTagDtoArrayDto} from "../../common/dto/tag/TagDto";
import {WidgetTotalCommission} from "./widget/WidgetTotalCommission";
import {getAvailableWidgets, getDisabledByDefaultWidgets} from "../../common/dto/dashboard/WidgetDto";
import {WidgetAvgTradeTime} from "./widget/WidgetAvgTradeTime";
import {getFilterSelectedOptions, getQueryFilters, queryFiltersToString} from "../../common/utils/filter/FilterUtils";
import {MenuComponent} from "../../../_metronic/assets/ts/components";
import {closeTypeFilterOptions} from "../trades/components/header/TradeListHeader";
import {WidgetTotalTradeProfit} from "./widget/WidgetTotalTradeProfit";
import {WidgetTotalTradeLoss} from "./widget/WidgetTotalTradeLoss";
import {WidgetTodayResult} from "./widget/WidgetTodayResult";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {ResultByHourTimelineChartWidget} from "./widget/ResultByHourTimelineChartWidget";
import {ResultByDayOfWeekTimelineChartWidget} from "./widget/ResultByDayOfWeekTimelineChartWidget";
import {useThemeMode} from "../../../_metronic/partials";
import {Top10TradedTickersChartWidget} from "./widget/Top10TradedTickersChartWidget";
import {ExcludeDaysFilter} from "../../common/component/filter/ExcludeDaysFilter";
import {ExcludeHoursFilter, excludeHoursFilterOptions} from "../../common/component/filter/ExcludeHoursFilter";

const API_URL = process.env.REACT_APP_API_URL
const DASHBOARD_URL = (accountId: ID) => `${API_URL}/accounts/${accountId}/dashboard`

type DateRangeDto = {
    from?: string,
    to?: string,
}

type Prop = {
    accountId?: ID
    setAccountId: Dispatch<SetStateAction<ID>>,
    dashboard?: AccountDashboardDto
    setDashboard: Dispatch<SetStateAction<AccountDashboardDto>>,
    dateRange?: DateRangeDto,
    setDateRange: Dispatch<SetStateAction<DateRangeDto>>,
    availableWidgets: {[key: string]: string},
    selectedWidgets: {[key: string]: boolean},
    setSelectedWidgets: Dispatch<SetStateAction<any>>,
    refetchRequired: boolean,
    setRefetchRequired: Dispatch<SetStateAction<boolean>>,
}

const DashboardPage: FC<Prop> = (props) => {
    const {accountId} = props
    const {dashboard, setDashboard} = props
    const {dateRange} = props
    const {selectedWidgets} = props
    const {refetchRequired, setRefetchRequired} = props
    const [searchParams, setSearchParams] = useSearchParams()
    //const queryParam = new QueryParam(searchParams, setSearchParams)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const refetchDashboard = () => {
        if (!accountId) {
            return
        }
        const filterQuery = getQueryFilters(searchParams)
        const filterFrom = (dateRange?.from ? `${encodeURIComponent('filter[from][eq]')}=${dateRange?.from}` : null)
        const filterTo = (dateRange?.to ? `${encodeURIComponent('filter[to][eq]')}=${dateRange?.to}` : null)

        if (filterFrom) {
            filterQuery.push(filterFrom)
        }

        if (filterTo) {
            filterQuery.push(filterTo)
        }
        setIsLoading(true)

        axios.get(`${DASHBOARD_URL(accountId)}${filterQuery.length > 0 ? '?' + queryFiltersToString(filterQuery) : ''}`)
            .then((response: AxiosResponse<JsonApiObjectDto<AccountDashboardDto>>) => {
                if (!response) {
                    return
                }
                const data = response?.data?.data?.attributes

                if (data) {
                    data.id = response?.data?.data?.id

                    setDashboard(data)
                }
            }).finally(() => setIsLoading(false))
    }

    useEffect(() => {
        setRefetchRequired(false)

        refetchDashboard()
    }, [accountId, dateRange, refetchRequired === true])

    return (
        <div className="post d-flex flex-column-fluid" id="kt_post">
            <div id="kt_content_container" className="container-fluid">
                <div className="row gy-5 g-xl-10">
                    <WidgetDeposit selectedWidgets={selectedWidgets}
                                   value={dashboard?.currentDashboard?.account?.currentDeposit || 0}
                                   currency={dashboard?.currentDashboard?.account?.currency || ''}/>
                    <WidgetTotalResult selectedWidgets={selectedWidgets}
                                       value={dashboard?.currentDashboard?.totalResult || 0}
                                       previousValue={dashboard?.previousDashboard?.totalResult || 0}
                                       currency={dashboard?.currentDashboard?.account?.currency || ''}/>
                    <WidgetTodayResult selectedWidgets={selectedWidgets}
                                       value={dashboard?.currentDashboard?.todayResult || 0}
                                       previousValue={dashboard?.previousDashboard?.todayResult || 0}
                                       currency={dashboard?.currentDashboard?.account?.currency || ''}/>
                    <WidgetAvgTradeProfit selectedWidgets={selectedWidgets}
                                          value={dashboard?.currentDashboard?.avgTradeProfit || 0}
                                          previousValue={dashboard?.previousDashboard?.avgTradeProfit || 0}
                                          currency={dashboard?.currentDashboard?.account?.currency || ''}/>
                    <WidgetAvgTradeLoss selectedWidgets={selectedWidgets}
                                        value={dashboard?.currentDashboard?.avgTradeLoss || 0}
                                        previousValue={dashboard?.previousDashboard?.avgTradeLoss || 0}
                                        currency={dashboard?.currentDashboard?.account?.currency || ''}/>
                    <WidgetTradeDays selectedWidgets={selectedWidgets}
                                     value={dashboard?.currentDashboard?.totalTradeDays || 0}
                                     previousValue={dashboard?.previousDashboard?.totalTradeDays || 0}/>
                    <WidgetTrades selectedWidgets={selectedWidgets}
                                  value={dashboard?.currentDashboard?.totalTrades || 0}
                                  previousValue={dashboard?.previousDashboard?.totalTrades || 0}/>
                    <WidgetProfitTradesPercent selectedWidgets={selectedWidgets}
                                               totalProfitTrades={dashboard?.currentDashboard?.totalProfitTrades || 0}
                                               previousTotalProfitTrades={dashboard?.previousDashboard?.totalProfitTrades || 0}
                                               totalTrades={dashboard?.currentDashboard?.totalTrades || 0}
                                               previousTotalTrades={dashboard?.previousDashboard?.totalTrades || 0}/>
                    <WidgetMaxDrawdown selectedWidgets={selectedWidgets}
                                       value={dashboard?.currentDashboard?.maxDrawdown || 0}
                                       previousValue={dashboard?.previousDashboard?.maxDrawdown || 0}
                                       currency={dashboard?.currentDashboard?.account?.currency || ''}/>
                    <WidgetTotalCommission selectedWidgets={selectedWidgets}
                                           value={dashboard?.currentDashboard?.totalCommission || 0}
                                           previousValue={dashboard?.previousDashboard?.totalCommission || 0}
                                           currency={dashboard?.currentDashboard?.account?.currency || ''}/>
                    <WidgetLongTrades selectedWidgets={selectedWidgets}
                                      value={dashboard?.currentDashboard?.totalLongTrades || 0}
                                      previousValue={dashboard?.previousDashboard?.totalLongTrades || 0}/>
                    <WidgetShortTrades selectedWidgets={selectedWidgets}
                                       value={dashboard?.currentDashboard?.totalShortTrades || 0}
                                       previousValue={dashboard?.previousDashboard?.totalShortTrades || 0}/>
                    <WidgetBreakevenTrades selectedWidgets={selectedWidgets}
                                           value={dashboard?.currentDashboard?.totalBreakevenTrades || 0}
                                           previousValue={dashboard?.previousDashboard?.totalBreakevenTrades || 0}/>
                    <WidgetTotalTradeProfit selectedWidgets={selectedWidgets}
                                            value={dashboard?.currentDashboard?.totalTakeProfit || 0}
                                            previousValue={dashboard?.previousDashboard?.totalTakeProfit || 0}
                                            currency={dashboard?.currentDashboard?.account?.currency || ''}/>
                    <WidgetTotalTradeLoss selectedWidgets={selectedWidgets}
                                          value={dashboard?.currentDashboard?.totalStopLoss || 0}
                                          previousValue={dashboard?.previousDashboard?.totalStopLoss || 0}
                                          currency={dashboard?.currentDashboard?.account?.currency || ''}/>
                    <WidgetAvgTradeTime selectedWidgets={selectedWidgets}
                                           value={dashboard?.currentDashboard?.totalBreakevenTrades || 0}
                                           previousValue={dashboard?.previousDashboard?.totalBreakevenTrades || 0}/>
                </div>
                {dashboard?.currentDashboard?.resultTimeline && (
                    <>
                        {/*<ProfitPercentChartWidget className='mb-5 mb-xxl-8'
                                                  totalProfit={dashboard?.currentDashboard?.totalTakeProfit || 0}
                                                  totalStopLoss={dashboard?.currentDashboard?.totalStopLoss || 0}/>*/}
                        <ResultTimelineChartWidget className='mb-5 mt-5 mb-xxl-8'
                                                   resultTimeline={dashboard?.currentDashboard?.resultTimeline}/>
                        <ResultByDayOfWeekTimelineChartWidget className='mb-5 mt-5 mb-xxl-8'
                                                              resultByDayOfWeekTimeline={dashboard?.currentDashboard?.resultByDayOfWeekTimeline}/>
                        <ResultByHourTimelineChartWidget className='mb-5 mt-5 mb-xxl-8'
                                                         resultByHourTimeline={dashboard?.currentDashboard?.resultByHourTimeline}/>
                        <TradesResultChartWidget className='mb-5 mb-xxl-8'
                                                 resultTimeline={dashboard?.currentDashboard?.resultTimeline}/>
                        <TradesChartWidget className='mb-5 mb-xxl-8'
                                           resultTimeline={dashboard?.currentDashboard?.resultTimeline}/>
                        <Top10TradedTickersChartWidget className='mb-5 mb-xxl-8'
                                                       top10TradedTickers={dashboard?.currentDashboard?.top10TradedTickers}/>
                    </>
                )}
            </div>
            {isLoading && <Loader/>}
        </div>
    )
}

const sideFilterOptions = [
    {value: 'ALL', label: 'All'},
    {value: 'LONG', label: 'Long'},
    {value: 'SHORT', label: 'Short'},
]

const DashboardToolbar: FC<PropsWithChildren<Prop>> = (props) => {
    const {response: userSettings} = useUserSettingsContext()
    const {isDark} = useThemeMode()
    const intl = useIntl()
    const {accountId, setAccountId} = props
    const {dateRange, setDateRange} = props
    const {availableWidgets, selectedWidgets, setSelectedWidgets, setRefetchRequired} = props
    const [searchParams, setSearchParams] = useSearchParams()
    const queryParam = useMemo(() => new QueryParam(searchParams, setSearchParams), [searchParams])
    const [tagsFilterOptions, setTagsFilterOptions] = useState<Array<any>>([])
    const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false)
    const [selectedSideFilter, setSelectedSideFilter] = useState<any>(getFilterSelectedOptions(searchParams, sideFilterOptions, JsonApiQueryFilterField.SIDE))
    const [selectedCloseTypeFilter, setSelectedCloseTypeFilter] = useState<any>(getFilterSelectedOptions(searchParams, closeTypeFilterOptions, JsonApiQueryFilterField.CLOSE_TYPE))
    const [selectedTagsFilter, setSelectedTagsFilter] = useState<any>(getFilterSelectedOptions(searchParams, tagsFilterOptions, JsonApiQueryFilterField.TAGS))
    const [selectedExcludeDaysFilter, setSelectedExcludeDaysFilter] = useState<any>(getFilterSelectedOptions(searchParams, excludeHoursFilterOptions, JsonApiQueryFilterField.DAYS))
    const [selectedExcludeHoursFilter, setSelectedExcludeHoursFilter] = useState<any>(getFilterSelectedOptions(searchParams, excludeHoursFilterOptions, JsonApiQueryFilterField.HOURS))
    const [totalFiltersApplied, setTotalFiltersApplied] = useState<number>(queryParam.getTotalAppliedFilters(JsonApiQueryFilterField.SYMBOL))
    const userSettingsMapper = useMemo(() => new UserSettingsMapper(), [])
    const [isExcludeTagsFilter, setExcludeTagsFilter] = useState<boolean>(false)
    const [startDate, setStartDate] = useState(moment(dateRange?.from).format("DD MMMM YYYY") || moment().startOf('month'))
    const [endDate, setEndDate] = useState(moment(dateRange?.to).format("DD MMMM YYYY"))
    const [ranges, setRanges] = useState<{[key: string]: any}>({
        [intl.formatMessage({id: "PERIOD.CALENDAR.TODAY"})]: [moment(), moment()],
        [intl.formatMessage({id: "PERIOD.CALENDAR.YESTERDAY"})]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        [intl.formatMessage({id: "PERIOD.CALENDAR.THIS_MONTH"})]: [moment().startOf('month'), moment().endOf('month')],
        [intl.formatMessage({id: "PERIOD.CALENDAR.LAST_MONTH"})]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        [intl.formatMessage({id: "PERIOD.CALENDAR.LAST_QUARTER"})]: [moment().subtract(1, 'quarter').startOf('quarter'), moment().subtract(1, 'quarter').endOf('quarter')],
        [intl.formatMessage({id: "PERIOD.CALENDAR.YEAR"})]: [moment().startOf('year')],
    })

    useEffect(() => {
        setTotalFiltersApplied(queryParam.getTotalAppliedFilters(JsonApiQueryFilterField.SYMBOL))

        const isExcludeTags = searchParams.get(`exclude_${JsonApiQueryFilterField.TAGS}`) || 'false'

        setExcludeTagsFilter(isExcludeTags === 'true')
    }, [searchParams])

    useEffect(() => {
        getAllTags().then((response: JsonApiTagDtoArrayDto) => {
            const tagsFilter: Array<any> = []

            response.data?.forEach(tagData => {
                const tag = tagData.attributes
                tag.id = tagData.id

                tagsFilter.push({value: tag.id, label: tag.name})
            })
            setTagsFilterOptions(tagsFilter)
        })
    }, [])

    useEffect(() => {
        if (dateRange?.from) {
            setStartDate(dateRange.from)
        }

        if (dateRange?.to) {
            setEndDate(dateRange.to)
        }
    }, [dateRange]);

    useEffect(() => {
        setSelectedTagsFilter(getFilterSelectedOptions(searchParams, tagsFilterOptions, JsonApiQueryFilterField.TAGS))
    }, [tagsFilterOptions])

    useEffect(() => {
        MenuComponent.isDropdownActive = showFiltersModal
    }, [showFiltersModal])

    useEffect(() => {
        setTotalFiltersApplied(queryParam.getTotalAppliedFilters())
    }, [queryParam.getTotalAppliedFilters()])

    const addQueryParam = (key: string, options: any) => {
        setShowFiltersModal(true)

        queryParam.append(key, options)
    }

    const applyQueryFilters = () => {
        setShowFiltersModal(false)

        queryParam.apply()

        setRefetchRequired(true)
    }

    const resetQueryFilters = () => {
        setShowFiltersModal(false)

        queryParam.resetFilters()

        setSelectedSideFilter(null)
        setSelectedCloseTypeFilter(null)
        setSelectedTagsFilter(null)
        setSelectedExcludeHoursFilter([])
        setSelectedExcludeDaysFilter(null)

        setRefetchRequired(true)
    }

    const updateSelectedWidgets = (widgetKey: string, event: any) => {
        const updatedSelectedWidgets: {[key: string]: boolean} = {}

        Object.entries(selectedWidgets)
            .forEach(([key, value]) => {
                updatedSelectedWidgets[key] = (key === widgetKey ? !!event.currentTarget.checked : value)
            })

        setSelectedWidgets(updatedSelectedWidgets)

        updateUserSettings(
            userSettingsMapper.updateSelectedDashboardWidgets(userSettings, updatedSelectedWidgets)
        )
    }

    // @ts-ignore
    return (
        <div id="kt_toolbar_container" className="d-flex flex-stack container-fluid">
            {/*begin::Page title*/}
            <FinanceResultToolbar accountId={accountId}/>
            {/*end::Page title*/}
            {/*begin::Actions*/}
            <div className="d-flex align-items-center gap-2 gap-lg-3">
                <button type="button"
                        className="btn btn-sm btn-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                        onClick={() => setShowFiltersModal(!showFiltersModal)}>
                    {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                    <span className="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="none">
                            <path
                                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                fill="currentColor"></path>
                        </svg>
                    </span>
                    {/*end::Svg Icon*/}
                    {intl.formatMessage({id: "DASHBOARD.HEADER.FILTERS"})}
                    {totalFiltersApplied > 0 && <span>({totalFiltersApplied})</span>}
                </button>
                <div className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
                     data-kt-menu="true"
                     id='kt_menu'>
                    {/*begin::Header*/}
                    <div className="px-7 py-5">
                        <div className="fs-5 text-dark">
                            {intl.formatMessage({id: "DASHBOARD.HEADER.FILTERS.TITLE"})}
                        </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Separator*/}
                    <div className="separator border-gray-200"></div>
                    {/*end::Separator*/}
                    {/*begin::Content*/}
                    <div className="px-7 py-5" data-kt-user-table-filter="form">
                        {/*begin::Input group*/}
                        <div className="mb-10">
                            <Select options={sideFilterOptions}
                                    placeholder='Side'
                                    value={selectedSideFilter}
                                    onChange={(options: any) => queryParam.append(JsonApiQueryFilterField.SIDE, options)} />
                        </div>
                        <div className="mb-7">
                            <Select options={closeTypeFilterOptions}
                                    isMulti
                                    placeholder='Close Types'
                                    value={selectedCloseTypeFilter}
                                    onChange={(options: any) => {
                                        setSelectedCloseTypeFilter(options)

                                        addQueryParam(JsonApiQueryFilterField.CLOSE_TYPE, options)
                                    }}/>
                        </div>
                        <div className="form-check form-switch form-check-custom form-check-solid mb-3">
                            <input className="form-check-input h-20px w-30px"
                                   type="checkbox"
                                   value=""
                                   id="include-tags-checkbox"
                                   checked={!isExcludeTagsFilter}
                                   onChange={() => {
                                       const newValue = !isExcludeTagsFilter

                                       setExcludeTagsFilter(newValue)

                                       addQueryParam(`exclude_${JsonApiQueryFilterField.TAGS}`, {value: newValue + ''})
                                   }}
                            />
                            <label className="form-check-label" htmlFor="include-tags-checkbox">Include tags</label>
                        </div>
                        <div className="mb-10">
                            <Select options={tagsFilterOptions}
                                    isMulti
                                    isClearable
                                    placeholder='Tags'
                                    value={selectedTagsFilter}
                                    onChange={(options: any) => {
                                        setSelectedTagsFilter(options)

                                        addQueryParam(JsonApiQueryFilterField.TAGS, options)
                                    }}/>
                        </div>

                        <ExcludeDaysFilter selectedExcludeDaysFilter={selectedExcludeDaysFilter}
                                           setSelectedExcludeDaysFilter={setSelectedExcludeDaysFilter}
                                           addQueryParam={addQueryParam}/>

                        <ExcludeHoursFilter selectedExcludeHoursFilter={selectedExcludeHoursFilter}
                                            setSelectedExcludeHoursFilter={setSelectedExcludeHoursFilter}
                                            addQueryParam={addQueryParam}/>

                        {/*end::Input group*/}
                        {/*begin::Actions*/}
                        <div className="d-flex justify-content-end">
                            <button type="reset" className="btn btn-light btn-active-light-primary me-2 px-6"
                                    data-kt-menu-dismiss="true"
                                    data-kt-user-table-filter="reset"
                                    onClick={resetQueryFilters}>
                                {intl.formatMessage({id: "BUTTON.RESET"})}
                            </button>
                            <button type="button" className="btn btn-primary px-6" data-kt-menu-dismiss="true"
                                    data-kt-user-table-filter="filter"
                                    onClick={applyQueryFilters}>
                                {intl.formatMessage({id: "BUTTON.APPLY"})}
                            </button>
                        </div>
                        {/*end::Actions*/}
                    </div>
                    {/*end::Content*/}
                </div>
                <span>
                    <a href="#"
                       className="btn btn-sm btn-light-primary btn-active-light-primary"
                       data-kt-menu-trigger="click"
                       data-kt-menu-placement="bottom-end"
                       data-bs-toggle="tooltip"
                       data-bs-placement="top" title=""
                       data-bs-original-title="Settings">
                        {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                        <span className="svg-icon svg-icon-2 m-3">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z" fill="currentColor"/>
                                <path opacity="0.3" d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z" fill="currentColor"/>
                            </svg>
                        </span>
                        {intl.formatMessage({id: "DASHBOARD.HEADER.WIDGETS"})}
                        {/*end::Svg Icon*/}
                    </a>
                    {/*begin::Menu*/}

                    <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fs-7 w-250px py-4"
                        data-kt-menu="true">
                            <div className="px-4 py-5">
                                {selectedWidgets && Object.keys(availableWidgets).map(widgetKey => (
                                    <div className="mb-3" key={widgetKey}>
                                        <div
                                            className="form-check form-switch form-check-custom form-check-solid me-10">
                                            <div className="menu-item px-3">
                                                <input className="form-check-input h-20px w-30px"
                                                       type="checkbox"
                                                       id={widgetKey}
                                                       defaultChecked={selectedWidgets[widgetKey]}
                                                       onChange={(event) => updateSelectedWidgets(widgetKey, event)}/>
                                                <label className="form-check-label" htmlFor={widgetKey}>
                                                    {availableWidgets[widgetKey]}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    {/*end::Menu*/}
                </span>
                <div className="d-flex align-items-center gap-2 gap-lg-3">
                    <div className="d-flex align-items-center flex-wrap gap-3 gap-xl-9">
                        <div className="d-flex align-items-center">
                            <div className="text-muted fs-7">{intl.formatMessage({id: 'ACCOUNT.CURRENT'})}</div>
                            <AccountDataProvider>
                                <AccountDataResponseProvider>
                                    <CurrentAccountsSelectBox callback={(account) => setAccountId(account.id)}/>
                                </AccountDataResponseProvider>
                            </AccountDataProvider>
                        </div>
                    </div>
                </div>
                {/*begin::Daterangepicker(defined in src/js/layout/app.js)*/}
                <div className="d-flex align-items-center gap-2 gap-lg-3">
                    <div
                        className="d-flex align-items-center px-4">
                        {/*begin::Display range*/}
                        {/*@ts-ignore*/}
                        <DateRangePicker
                            initialSettings={{
                                autoUpdateInput: true,
                                timePicker: false,
                                startDate: startDate,
                                endDate: endDate,
                                ranges: ranges,
                                locale: {
                                    customRangeLabel: intl.formatMessage({id: "PERIOD.CALENDAR.CUSTOM_RANGE"}),
                                    applyLabel: intl.formatMessage({id: "PERIOD.CALENDAR.APPLY"}),
                                    cancelLabel: intl.formatMessage({id: "PERIOD.CALENDAR.CANCEL"}),
                                    format: "DD MMMM YYYY"
                                },
                            }}
                            onCallback={(start: any, end: any, label: string | undefined) => {
                                const pattern = 'YYYY-MM-DDTHH:mm:ss'

                                setDateRange({from: start.format(pattern), to: end.format(pattern)})

                                const updatedUserSettings = userSettingsMapper.updateDashboardDateRange(
                                    userSettings,
                                    {
                                        from: start.format(pattern),
                                        to: end.format(pattern)
                                    }
                                )
                                updateUserSettings(updatedUserSettings)
                            }}
                        >
                            <input type='text' className='text-gray-600 border-0 outline-0 cursor-pointer' style={{width: '200px'}}/>
                        </DateRangePicker>
                        {/*end::Display range*/}
                        {/*begin::Svg Icon | path: icons/duotune/general/gen014.svg*/}
                        <span className="svg-icon svg-icon-1 ms-2 me-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                 viewBox="0 0 24 24" fill="none">
                                <path opacity="0.3"
                                      d="M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z"
                                      fill="currentColor"/>
                                <path
                                    d="M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z"
                                    fill="currentColor"/>
                                <path
                                    d="M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z"
                                    fill="currentColor"/>
                            </svg>
                        </span>
                        {/*end::Svg Icon*/}
                    </div>
                </div>
                {/*end::Daterangepicker*/}
            </div>
            {/*end::Actions*/}
        </div>
    )
}

const Dashboard = () => {
    const intl = useIntl()
    const {response: userSettings} = useUserSettingsContext()
    const [accountId, setAccountId] = useState<ID>(userSettings?.settings?.account?.selectedId)
    const [dashboard, setDashboard] = useState<AccountDashboardDto>({})
    const [dateRange, setDateRange] = useState<DateRangeDto>(userSettings?.settings?.dateRange?.dashboard || {})
    const [selectedWidgets, setSelectedWidgets] = useState<{ [key: string]: boolean }>({})
    const [availableWidgets] = useState<{ [key: string]: string }>(getAvailableWidgets(intl))
    const [refetchRequired, setRefetchRequired] = useState<boolean>(false)

    useEffect(() => {
        setAccountId(userSettings?.settings?.account?.selectedId)
        setDateRange(userSettings?.settings?.dateRange?.dashboard || {})
    }, [userSettings])

    const setDefaultSelectedWidgets = () => {
        const disabledByDefaultWidgets: any = getDisabledByDefaultWidgets()
        const items: {[key: string]: boolean} = {}

        Object.entries(availableWidgets)
            .forEach(([key, value]) => {
                if (!disabledByDefaultWidgets[key]) {
                    items[key] = true
                } else {
                    items[key] = false
                }
            })

        setSelectedWidgets(items)
    }

    useEffect(() => {
        const totalSelectedWidgets = Object.entries(userSettings?.settings?.dashboard?.selectedWidgets || {}).length
        const isUpdateRequired = Object.entries(availableWidgets).length !== totalSelectedWidgets

        if (!isUpdateRequired && userSettings?.settings?.dashboard?.selectedWidgets) {
            setSelectedWidgets(userSettings?.settings?.dashboard?.selectedWidgets)
        } else {
            setDefaultSelectedWidgets()
        }
    }, [userSettings?.settings?.dashboard?.selectedWidgets])

    useEffect(() => {
        document.getElementById('kt_content_container')?.classList.remove('container')
        document.getElementById('kt_content_container')?.classList.add('container-fluid')

        return () => {
            document.getElementById('kt_content_container')?.classList.remove('container-fluid')
            document.getElementById('kt_content_container')?.classList.add('container')
        }
    }, [])

    return (
        <>
            <MetaHeader pageName='HTML.HEAD.TITLE.DASHBOARD'/>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                <DashboardToolbar
                    dashboard={dashboard}
                    setDashboard={setDashboard}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    accountId={accountId}
                    setAccountId={setAccountId}
                    availableWidgets={availableWidgets}
                    selectedWidgets={selectedWidgets}
                    setSelectedWidgets={setSelectedWidgets}
                    refetchRequired={refetchRequired}
                    setRefetchRequired={setRefetchRequired}
                />
            </div>
            <DashboardPage
                dashboard={dashboard}
                setDashboard={setDashboard}
                dateRange={dateRange}
                setDateRange={setDateRange}
                accountId={accountId}
                setAccountId={setAccountId}
                availableWidgets={availableWidgets}
                selectedWidgets={selectedWidgets}
                setSelectedWidgets={setSelectedWidgets}
                refetchRequired={refetchRequired}
                setRefetchRequired={setRefetchRequired}
            />
        </>
    )
}

const DashboardWrapper: FC = () => {
    return (
        <UserSettingsRequestProvider>
            <UserSettingsResponseProvider>
                <Dashboard />
            </UserSettingsResponseProvider>
        </UserSettingsRequestProvider>
    )
}

export {DashboardWrapper}
