const getCurrencySymbol = (currency?: string) => {
    switch (currency) {
        case 'USD':
            return '\u0024'
        case 'EUR':
            return '\u20ac'
        case 'RUB':
            return '\u20bd'
        default:
            return '';
    }
}

export {getCurrencySymbol}
