const ConfirmRegistration = () => {
    return (
        <div className="mb-10 text-center">
            <div className="fs-6 fw-semibold text-gray-500 mb-10">
                <div className="text-gray-300 fs-2">Confirmation link sent to your email.</div>
                <div className="mt-5">If you didn't receive it check please in spam.</div>
            </div>
            <div className="mb-0">
                <img src="/media/auth/ok.png" className="mw-100 mh-300px" alt=""/>
            </div>
        </div>
    )
}

export {
    ConfirmRegistration
}
