import axios, {AxiosResponse} from "axios";
import {JsonApiTagDtoArrayDto} from "../../dto/tag/TagDto";

const API_URL = process.env.REACT_APP_API_URL
const TAG_URL = `${API_URL}/tags`

const getAllTags = (): Promise<JsonApiTagDtoArrayDto> => {
    return axios.get(`${TAG_URL}`)
        .then((response: AxiosResponse<JsonApiTagDtoArrayDto>) => response.data)
}

export {getAllTags}