import {Column} from 'react-table'
import moment from 'moment'
import {AccountModel} from "../../core/_models";
import {AccountCustomHeader} from "./AccountCustomHeader";
import {AccountSelectionCell} from "./AccountSelectionCell";
import {AccountSelectionHeader} from "./AccountSelectionHeader";
import {AccountActionsCell} from "./AccountActionsCell";
import {AccountDepositHistoryCell} from "./AccountDepositHistoryCell";

const accountColumns: ReadonlyArray<Column<AccountModel>> = [
    {
        Header: (props) => <AccountSelectionHeader tableProps={props}/>,
        id: 'selection',
        Cell: ({...props}) => <AccountSelectionCell id={props.data[props.row.index].id}/>,
    },
    {
        Header: (props) => (
            <AccountCustomHeader tableProps={props} title='ACCOUNTS.NAME' className='min-w-125px'/>
        ),
        id: 'name',
        Cell: ({...props}) => (
            <div>{props.data[props.row.index].name}</div>
        ),
    },
    {
        Header: (props) => (
            <AccountCustomHeader tableProps={props} title='ACCOUNTS.BROKER_ACCOUNT_ID' className='min-w-125px'/>
        ),
        id: 'brokerAccountId',
        Cell: ({...props}) => (
            <div>{props.data[props.row.index].brokerAccountId}</div>
        ),
    },
    {
        Header: (props) => (
            <AccountCustomHeader tableProps={props} title='ACCOUNTS.DEPOSIT' className='min-w-125px'/>
        ),
        id: 'currentDeposit',
        Cell: ({...props}) => (
            <div
                className='d-flex flex-column'>{props.data[props.row.index].currentDeposit.toLocaleString(undefined, {maximumFractionDigits: 12})}</div>
        ),
    },
    {
        Header: (props) => (
            <AccountCustomHeader tableProps={props} title='ACCOUNTS.CURRENCY' className='min-w-125px'/>
        ),
        id: 'currency',
        Cell: ({...props}) => (
            <div className='badge badge-light text-gray-800'>{props.data[props.row.index].currency}</div>
        ),
    },
    {
        Header: (props) => (
            <AccountCustomHeader tableProps={props} title='ACCOUNTS.CHANGE_HISTORY' className='min-w-125px'/>
        ),
        id: 'changeHistory',
        Cell: ({...props}) => <AccountDepositHistoryCell account={props.data[props.row.index]}/>,
    },
    {
        Header: (props) => (
            <AccountCustomHeader tableProps={props} title='ACCOUNTS.DATE_ADD' className='min-w-125px'/>
        ),
        id: 'dateAdd',
        Cell: ({...props}) => (
            <div>
                {moment(props.data[props.row.index].dateAdd).format('YYYY-MM-DD')}
            </div>
        ),
    },
    {
        Header: (props) => (
            <AccountCustomHeader tableProps={props} title='ACCOUNTS.ACTIONS' className='text-end min-w-100px'/>
        ),
        id: 'actions',
        Cell: ({...props}) => <AccountActionsCell account={props.data[props.row.index]}/>,
    },
]

export {accountColumns}
