import {toAbsoluteUrl} from "../../../_metronic/helpers";

const Loader = () => {
    const styles = {
        borderRadius: '0.475rem',
        boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
        backgroundColor: '#fff',
        opacity: 0.6,
        color: '#7e8299',
        fontWeight: '500',
        margin: '0',
        width: '100%',
        height: '100%',
        padding: '1rem 2rem',
        top: '0',
        left: '0',
    }

    return <div style={{...styles, position: 'absolute', justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
        <img src={toAbsoluteUrl('/media/loaders/loader.gif')} width='48px' height='48px' />
    </div>
}

export {Loader}
