import React, {createContext, FC, useContext} from "react";
import {QUERIES, WithChildren} from "../../../../_metronic/helpers";
import {UserSettingsDto} from "../../dto/settings/UserSettingsDto";
import {useQuery} from "react-query";
import {getUserSettings} from "../../request/settings/UserSettingsRequest";

export type AccountContext = {
    userSettings: UserSettingsDto
}

const UserSettingsRequestContext = createContext<AccountContext>({userSettings: {}})

const UserSettingsRequestProvider: FC<WithChildren> = ({children}) => {
    const userSettings: UserSettingsDto = {}

    return (
        <UserSettingsRequestContext.Provider value={{userSettings}}>
            {children}
        </UserSettingsRequestContext.Provider>
    )
}

type JsonApiResponseContextProps<T> = {
    response?: T
}

const UserSettingsQueryResponseContext = createContext<JsonApiResponseContextProps<UserSettingsDto>>({})

const UserSettingsResponseProvider: FC<WithChildren> = ({children}) => {
    const {
        //isFetching,
        //refetch: refetchUserSettings,
        data: response,
    } = useQuery(
        `${QUERIES.USER_SETTINGS}`,
        () => {
            return getUserSettings()
        },
        {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
    )
    return (
        <UserSettingsQueryResponseContext.Provider value={{response}}>
            {children}
        </UserSettingsQueryResponseContext.Provider>
    )
}

const useUserSettingsContext = () => {
    return useContext(UserSettingsQueryResponseContext)
}

export {
    UserSettingsRequestProvider,
    UserSettingsResponseProvider,
    useUserSettingsContext,
}
