import {getCurrencySymbol} from "../../../common/utils/currency/CurrencyUtils";
import {FC} from "react";
import {useIntl} from "react-intl";
import CountUp from "react-countup";
import {WidgetKey} from "../../../common/dto/dashboard/WidgetDto";
import {useThemeMode} from "../../../../_metronic/partials";

type Props = {
    selectedWidgets: {[key: string]: boolean},
    value: number
    currency: string
}

const WidgetDeposit: FC<Props> = ({selectedWidgets, value, currency}) => {
    const intl = useIntl()
    const {isDark} = useThemeMode()
    const darkStyle = (isDark ? "widget-dark" : "")

    if (!selectedWidgets[WidgetKey.DEPOSIT]) {
        return null
    }
    return (
        <div className="col-sm-4 col-xl-2 mb-xl-10">
            <div className="card h-lg-100">
                <div className="card-body d-flex justify-content-between align-items-start flex-column">
                    {/*begin::Icon*/}
                    <div className="m-0">
                        {/*begin::Svg Icon | path: icons/50-finance-icons-red/deposit.svg*/}
                        <span className="svg-icon svg-icon-2hx svg-icon-gray-600">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 206.41 239.54">
                                <g><g id="Icon_Set" data-name="Icon Set"><path className={"deposit-1 " + darkStyle} d="M90,165.61a13.22,13.22,0,0,0,26.44,0c0-7.3-5.42-10.67-13.22-13.22C96.27,150.13,90,146.47,90,139.17a13.22,13.22,0,0,1,26.44,0"/><line
                                className={"deposit-1 " + darkStyle} x1="103.21" y1="118.59" x2="103.21" y2="126.05"/>
                                    <line className="deposit-1" x1="103.21" y1="178.83" x2="103.21" y2="186.29"/><circle
                                className={"deposit-2 " + darkStyle} cx="103.2" cy="152.58" r="50.22"/>
                                    <path className="deposit-1" d="M133.23,56.84,149.79,31.2c3.5-5.42,7.16-11.33,3.22-18.59a17.84,17.84,0,0,0-8.71-7.75C138.86,2.54,133,2.48,127.13,3.07A44,44,0,0,0,116.41,5.6l-4.17,1.54a27.63,27.63,0,0,1-9,1.71,27.63,27.63,0,0,1-9-1.71L90,5.6A43.93,43.93,0,0,0,79.27,3.07c-5.84-.59-11.73-.54-17.17,1.79a17.79,17.79,0,0,0-8.71,7.75c-3.94,7.27-.27,13.18,3.22,18.59L73.17,56.84"/><path
                                className={"deposit-1 " + darkStyle}
                                d="M96.8,54.57c-16.12,0-31.9,5.71-43.65,16.74C29,94,26.38,132.39,3.58,211.31l-.05.18a19.83,19.83,0,0,0,19,25.3H183.82a19.82,19.82,0,0,0,19.05-25.3l-.05-.18C180,132.4,177.43,94,153.25,71.31c-11.75-11-27.53-16.74-43.65-16.74Z"/><path
                                className="deposit-3"
                                d="M140.54,25.24c3.69-5.72,3.37-6.31,2.79-7.38A6.87,6.87,0,0,0,140,15a17.94,17.94,0,0,0-7.15-1.22c-1.38,0-2.89.09-4.58.26a33.27,33.27,0,0,0-8,1.91L116,17.48a38.78,38.78,0,0,1-12.61,2.39H103a38.78,38.78,0,0,1-12.61-2.39l-4.17-1.54a33.09,33.09,0,0,0-8-1.91c-1.69-.17-3.21-.26-4.58-.26A17.94,17.94,0,0,0,66.44,15a6.87,6.87,0,0,0-3.36,2.87c-.58,1.07-.9,1.66,2.79,7.38l8.28,12.82,2.38,3.69a106.68,106.68,0,0,1,53.36,0l2.38-3.69Z"/></g></g>
                            </svg>
                        </span>
                        {/*end::Svg Icon*/}
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Section*/}
                    <div className="d-flex flex-column my-7">
                        {/*begin::Number*/}
                        <span className="fs-2x text-gray-800 lh-1 ls-n2">
                            {getCurrencySymbol(currency)}
                            <CountUp end={value} decimals={2} duration={1.0} />
                        </span>
                        {/*end::Number*/}
                        {/*begin::Follower*/}
                        <div className="m-0">
                            <span className="fs-6 text-gray-400">
                                {intl.formatMessage({id: "DASHBOARD.DEPOSIT"})}
                            </span>
                        </div>
                        {/*end::Follower*/}
                    </div>
                    {/*end::Section*/}
                    {/*begin::Badge*/}
                    <span className="badge badge-light fs-base">
                        &infin;
                    </span>
                    {/*end::Badge*/}
                </div>
            </div>
        </div>
    )
}

export {WidgetDeposit}
