import React, {useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {KTCard} from "../../../_metronic/helpers";
import {TradeTable} from "./table/TradeTable";
import {TradeListHeader} from "./components/header/TradeListHeader";
import {
    TradeQueryRequestProvider,
    TradeQueryResponseProvider,
    useTradeQueryPagination,
    useTradeQueryResponse,
} from "../../common/provider/trade/TradeDataProvider";
import {TradeListViewProvider, useTradeModelListViewContext} from "../../common/provider/trade/TradeListViewProvider";
import {NewTradeModal} from "./modal/NewTradeModal";
import {AccountDto} from "../../common/dto/account/AccountDto";
import {CommonError} from "../../common/component/error/CommonError";
import {TradeMediaModal} from "./modal/TradeMediaModal";
import {MetaHeader} from "../../common/component/meta/MetaHeader";
import {useIntl} from "react-intl";
import {Pagination} from "../../common/component/pagination/Pagination";
import {
    UserSettingsRequestProvider,
    UserSettingsResponseProvider,
    useUserSettingsContext
} from "../../common/provider/settings/UserSettingsDataProvider";
import {SettingsPaginationKey} from "../../common/mapper/settings/UserSettingsMapper";

const TradeListWrapper = () => {
    return (
        <UserSettingsRequestProvider>
            <UserSettingsResponseProvider>
                <TradeQueryRequestProvider>
                    <TradeQueryResponseProvider>
                        <TradeListViewProvider>
                            <TradesList/>
                        </TradeListViewProvider>
                    </TradeQueryResponseProvider>
                </TradeQueryRequestProvider>
            </UserSettingsResponseProvider>
        </UserSettingsRequestProvider>
    )
}

const TradesList = () => {
    const {refetch} = useTradeQueryResponse()
    const {itemForCreate, itemIdForUpdate, imagesView, commonError} = useTradeModelListViewContext()
    const [account, setAccount] = useState<AccountDto>({})
    const pagination = useTradeQueryPagination()
    const {response: userSettings} = useUserSettingsContext()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [allColumns, setAllColumns] = useState<Array<string>>([])
    const [hiddenColumns, setHiddenColumns] = useState<Array<string>>(userSettings?.settings?.table?.trades?.hiddenColumns || [])
    const [isUpdateDeposit, setIsUpdateDeposit] = useState<boolean>(false)

    useEffect(() => {
        console.log('Common error: ' + commonError)
    }, [commonError])

    return (
        <>
            <CommonError errorMessage={commonError}/>
            <KTCard>
                <TradeListHeader account={account} setAccount={setAccount} isUpdateDeposit={isUpdateDeposit} setIsUpdateDeposit={setIsUpdateDeposit} allColumns={allColumns} hiddenColumns={hiddenColumns} setHiddenColumns={setHiddenColumns} />
                <TradeTable account={account} setAccount={setAccount} setIsUpdateDeposit={setIsUpdateDeposit} setIsLoading={setIsLoading} hiddenColumns={hiddenColumns} setAllColumns={setAllColumns} />
            </KTCard>
            <Pagination storageKey={SettingsPaginationKey.TRADES_PAGINATION_KEY} pagination={pagination} refetch={refetch} isLoading={isLoading}/>
            {itemIdForUpdate !== undefined && <NewTradeModal account={account} setAccount={setAccount} setIsUpdateDeposit={setIsUpdateDeposit}/>}
            {itemForCreate !== undefined && <NewTradeModal account={account} setAccount={setAccount} setIsUpdateDeposit={setIsUpdateDeposit}/>}
            {imagesView !== undefined && <TradeMediaModal trade={imagesView}/>}
        </>
    )
}

const volumeData = [
    { time: '2018-10-19', value: 19103293.0 },
    { time: '2018-10-22', value: 21737523.0 },
]

type TradeChartDataDto = {
    id: string,
    interval: string,
    quotes: Array<QuoteDto>,
    settings: any,
}

type QuoteDto = {
    time: number
    open: number
    close: number
    high: number
    low: number
}

const TradeList: React.FC = () => {
    const intl = useIntl()
    const tradeBreadCrumbs: Array<PageLink> = [
        {
            title: intl.formatMessage({id: 'BREADCRUMB.TRADES.MANAGEMENT'}),
            path: '/trades',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]

    useEffect(() => {
        document.getElementById('kt_content_container')?.classList.remove('container')
        document.getElementById('kt_content_container')?.classList.add('container-fluid')

        return () => {
            document.getElementById('kt_content_container')?.classList.remove('container-fluid')
            document.getElementById('kt_content_container')?.classList.add('container')
        }
    }, [])

    /*const chart = anychart.stock()
    const msftDataTable = anychart.data.table();
    msftDataTable.addData([]);
    const firstPlot = chart.plot(0);
    firstPlot.area(msftDataTable.mapAs({'value': 4})).name('MSFT');

    /*const volumeData = [
        { time: '2018-10-19', value: 19103293.0 },
        { time: '2018-10-22', value: 21737523.0 },
    ]

    const chartContainerRef = useRef<any>();
    const chart = useRef<any>();

    useEffect(() => {
        if (!chartContainerRef) {
            return
        }
        const API_URL = process.env.REACT_APP_API_URL
        const JOURNAL_URL = `${API_URL}/accounts/9fbd8d3b-678e-42e1-a56d-53734e041e93/trades/7d108ee0-3520-4c60-a1fb-a3fcd07760aa/charts`
        axios.get(JOURNAL_URL)
            .then((response: AxiosResponse<JsonApiArrayDto<TradeChartDataDto>>) => {
                const quotes = response.data?.data?.map(function (value) {
                    const item = value.attributes.quotes

                    return item
                }) || []

                chart.current = createChart(chartContainerRef.current, {
                    width: 1024,
                    height: 500,
                    /*layout: {
                        textColor: 'black',
                        background: {
                            type: ColorType.Solid,
                            color: 'white'
                        }
                    },
                    grid: {
                        vertLines: {
                            color: '#334158',
                        },
                        horzLines: {
                            color: '#334158',
                        },
                    },
                    crosshair: {
                        mode: CrosshairMode.Normal,
                    },
                    timeScale: {
                        borderColor: '#485c7b',
                    },
                });

                const candleSeries = chart.current.addBarSeries({
                    upColor: '#26a69a', downColor: '#ef5350',
                });
                candleSeries.setData(quotes[1]);

                chart.current.timeScale().fitContent()

                // const areaSeries = chart.current.addAreaSeries({
                //   topColor: 'rgba(38,198,218, 0.56)',
                //   bottomColor: 'rgba(38,198,218, 0.04)',
                //   lineColor: 'rgba(38,198,218, 1)',
                //   lineWidth: 2
                // });

                // areaSeries.setData(areaData);

                /*const volumeSeries = chart.current.addHistogramSeries({
                    color: '#182233',
                    lineWidth: 2,
                    priceFormat: {
                        type: 'volume',
                    },
                    overlay: true,
                    scaleMargins: {
                        top: 0.8,
                        bottom: 0,
                    },
                });

                volumeSeries.setData(volumeData);
            }).catch(error => {
                logger.error("Could not get quotes! Reason: " + error.message)
            })
    }, []);*/

    return (
        <>
            <MetaHeader pageName='HTML.HEAD.TITLE.TRADES'/>
            <PageTitle breadcrumbs={tradeBreadCrumbs}>
                {intl.formatMessage({id: 'BREADCRUMB.TRADES.LIST'})}
            </PageTitle>
            <TradeListWrapper/>
        </>
    )
}

export default TradeList
