/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteUser} from '../../core/_requests'
import {UserModel} from "../../core/_models";
import {MenuComponent} from "../../../../../../_metronic/assets/ts/components";
import {KTSVG, QUERIES} from "../../../../../../_metronic/helpers";

type Props = {
    user: UserModel
}

const UserActionsCell: FC<Props> = ({user}) => {
    const {setItemIdForUpdate} = useListView()
    const {query} = useQueryResponse()
    const queryClient = useQueryClient()

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const openEditModal = () => {
        setItemIdForUpdate(user.id)
    }

    const deleteItem = useMutation(() => deleteUser(user.id), {
        // 💡 response of the mutation is passed to onSuccess
        onSuccess: () => {
            // ✅ update detail view directly
            queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
        },
    })

    const disableUser = () => {
        console.log("Disable user: " + user.id)
    }

    const enableUser = () => {
        console.log("Enable user: " + user.id)
    }

    return (
        <>
            {user.status === 'ACTIVE' ? (
                <>
                    <a
                        href='#'
                        className='btn btn-light btn-active-light-primary btn-sm'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                    >
                        Actions
                        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0'/>
                    </a>
                    {/* begin::Menu */}
                    <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                        data-kt-menu='true'
                    >
                        {/* begin::Menu item */}
                        <div className='menu-item px-3'>
                            <a className='menu-link px-3' onClick={openEditModal}>
                                Edit
                            </a>
                        </div>
                        {/* end::Menu item */}

                        {/* begin::Menu item */}
                        <div className='menu-item px-3'>
                            <a
                                className='menu-link px-3'
                                data-kt-users-table-filter='delete_row'
                                onClick={async () => user.enabled ? await disableUser() : await enableUser()}
                            >
                                {user.enabled ? 'Disable' : 'Enable'}
                            </a>
                        </div>
                        {/* end::Menu item */}

                        {/* begin::Menu item */}
                        <div className='menu-item px-3'>
                            <a
                                className='menu-link px-3'
                                data-kt-users-table-filter='delete_row'
                                onClick={async () => await deleteItem.mutateAsync()}
                            >
                                Delete
                            </a>
                        </div>
                        {/* end::Menu item */}
                    </div>
                </>
            ) : (
                <>
                    <div></div>
                </>
            )}
            {/* end::Menu */}
        </>
    )
}

export {UserActionsCell}
