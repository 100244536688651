import {useIntl} from "react-intl";
import {PageLink, PageTitle} from "../../../_metronic/layout/core";
import {MetaHeader} from "../../common/component/meta/MetaHeader";
import './component/quill.css';
import {Link} from "react-router-dom";
import axios, {AxiosResponse} from "axios";
import React, {useEffect, useState} from "react";
import {JsonApiArrayDto} from "../../common/dto/json-api/JsonApiDto";
import moment from "moment";
import {Pagination} from "../../common/component/pagination/Pagination";
import {PaginationDto} from "../../common/dto/pagination/PaginationDto";
import qs from "qs";
import {ID} from "../../../_metronic/helpers";
import {NewJournalCategoryModal} from "./modal/NewJournalCategoryModal";
import {Loader} from "../../common/loader/Loader";
import {SettingsPaginationKey, UserSettingsMapper} from "../../common/mapper/settings/UserSettingsMapper";
import {logger, toJson} from "../../../index";
import {
    UserSettingsRequestProvider,
    UserSettingsResponseProvider,
    useUserSettingsContext
} from "../../common/provider/settings/UserSettingsDataProvider";
import {updateUserSettings} from "../../common/request/settings/UserSettingsRequest";
import Swal from "sweetalert2";
import {deleteJournalCategoryById, deleteJournalPostById} from "../../common/request/journal/JournalRequest";

export type JournalCategoryDto = {
    id: ID,
    name: string,
    totalPosts: number,
    dateUpdate: Date,
    dateAdd: Date,
}

export type JournalPostDto = {
    id: ID,
    journalCategory: JournalCategoryDto,
    title: string,
    post: string,
    dateUpdate: Date,
    dateAdd: Date,
}

(window as any).showImage = (imageId: string) => {
    window.open(process.env.REACT_APP_API_URL + '/static/' + imageId)
}

const JournalPageContent = () => {
    const intl = useIntl()
    const [categories, setCategories] = useState<Array<JournalCategoryDto>>([])
    const [posts, setPosts] = useState<Array<JournalPostDto>>([])
    const [pagination, setPagination] = useState<PaginationDto | undefined>()
    const [openCategoryModal, setOpenCategoryModal] = useState<boolean>(false)
    const [openCategoryEditModal, setCategoryEditModal] = useState<JournalCategoryDto | undefined>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [showEditCategoriesToolbar, setShowEditCategoriesToolbar] = useState<boolean>(false)
    const {response: userSettings} = useUserSettingsContext()
    const userSettingsMapper = new UserSettingsMapper()
    const [categoryId, setCategoryId] = useState<ID>(userSettings?.settings?.journal?.selectedCategoryId)

    useEffect(() => {
        setCategoryId(userSettings?.settings?.journal?.selectedCategoryId)
    }, [userSettings?.settings?.journal?.selectedCategoryId])

    const refetchJournalCategories = () => {
        const API_URL = process.env.REACT_APP_API_URL
        const JOURNAL_CATEGORY_URL = `${API_URL}/journals/categories`

        axios.get(JOURNAL_CATEGORY_URL)
            .then((response: AxiosResponse<JsonApiArrayDto<JournalCategoryDto>>) => {
                const data = response.data?.data?.map(function (value) {
                    const item = value.attributes
                    item.id = value.id as any

                    return item
                }) || []

                setCategories(data)
            }).catch(error => {
                console.log("Could not get journals categories! Reason: " + error.message)
            })
    }

    const refetchJournalPosts = () => {
        if (!categoryId) {
            logger.error("Select journal category at first!")
            return
        }
        setIsLoading(true)

        const queryParams = qs.parse(window.location.search.replace('?', ''))
        const pageSize = userSettings?.settings?.pagination?.journal?.pageSize || 5
        const API_URL = process.env.REACT_APP_API_URL
        const JOURNAL_URL = `${API_URL}/journals/${categoryId}?page%5Bnumber%5D=${queryParams?.page || 1}&page%5Bsize%5D=${pageSize}`

        axios.get(JOURNAL_URL)
            .then((response: AxiosResponse<JsonApiArrayDto<JournalPostDto>>) => {
                const data = response.data?.data?.map(function (value) {
                    const item = value.attributes
                    item.id = value.id as any

                    return item
                }) || []

                setPosts(data)
                setPagination({
                    total: response.data?.meta?.page.total || 0,
                    maxSize: response.data?.meta?.page.maxSize || 25,
                })
            }).catch(error => {
                logger.error("Could not get journal posts! Reason: " + error.message)
            }).finally(() => setIsLoading(false))
    }

    useEffect(() => refetchJournalCategories(), [])
    useEffect(() => refetchJournalPosts(), [categoryId])

    const selectCategory = (id: ID) => {
        const updatedUserSettings = userSettingsMapper.updateJournalCategoryId(userSettings, id)

        updateUserSettings(updatedUserSettings)

        setCategoryId(id)
    }

    const editCategoriesToolbar = () => {
        setShowEditCategoriesToolbar(!showEditCategoriesToolbar)
    }

    const openModalForCategory = () => {
        setOpenCategoryModal(true)
    }

    const openEditModalForCategory = (category: JournalCategoryDto) => {
        setCategoryEditModal(category)
    }

    const openDeleteModalForCategory = (category: JournalCategoryDto) => {
        return Swal.fire({
            html: intl.formatMessage({id: 'JOURNAL.DELETE.CATEGORY.MODAL.QUESTION.BODY'}) + ' <span class="text-dark">' + category.name + '</span>?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: intl.formatMessage({id: 'JOURNAL.DELETE.CATEGORY.MODAL.BUTTON.YES'}),
            cancelButtonText: intl.formatMessage({id: 'JOURNAL.DELETE.CATEGORY.MODAL.BUTTON.NO'}),
        }).then((result) => {
            if (result.isConfirmed) {
                deleteJournalCategoryById(category.id)
                    .then(() => {
                        Swal.fire(
                            intl.formatMessage({id: 'JOURNAL.DELETE.CATEGORY.MODAL.SUCCESS.TITLE'}),
                            intl.formatMessage({id: 'JOURNAL.DELETE.CATEGORY.MODAL.SUCCESS.BODY'}).replace('%VALUE%', category.name || ''),
                            'success'
                        )
                        refetchJournalCategories()
                        refetchJournalPosts()
                    }).catch(error => {
                        Swal.fire(
                            intl.formatMessage({id: 'JOURNAL.DELETE.CATEGORY.MODAL.ERROR.TITLE'}),
                            intl.formatMessage({id: 'JOURNAL.DELETE.CATEGORY.MODAL.ERROR.BODY'}) + ": " + category.name + "!",
                            'error'
                        )
                        logger.error("Could not delete journal category: " + category.name + "! Reason: " + toJson(error?.response?.data || '{}'))
                    })
            }
        })
    }

    const openDeleteModalForPost = (post: JournalPostDto) => {
        return Swal.fire({
            html: intl.formatMessage({id: 'JOURNAL.DELETE.POST.MODAL.QUESTION.BODY'}) + ' <span class="text-dark">' + post.title + '</span>?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: intl.formatMessage({id: 'JOURNAL.DELETE.POST.MODAL.BUTTON.YES'}),
            cancelButtonText: intl.formatMessage({id: 'JOURNAL.DELETE.POST.MODAL.BUTTON.NO'}),
        }).then((result) => {
            if (result.isConfirmed) {
                deleteJournalPostById(post.id)
                    .then(() => {
                        Swal.fire(
                            intl.formatMessage({id: 'JOURNAL.DELETE.POST.MODAL.SUCCESS.TITLE'}),
                            intl.formatMessage({id: 'JOURNAL.DELETE.POST.MODAL.SUCCESS.BODY'}).replace('%VALUE%', post.title || ''),
                            'success'
                        )
                        refetchJournalCategories()
                        refetchJournalPosts()
                    }).catch(error => {
                        Swal.fire(
                            intl.formatMessage({id: 'JOURNAL.DELETE.POST.MODAL.ERROR.TITLE'}),
                            intl.formatMessage({id: 'JOURNAL.DELETE.POST.MODAL.ERROR.BODY'}) + ": " + post.title + "!",
                            'error'
                        )
                        logger.error("Could not delete post: " + post.title + "! Reason: " + toJson(error?.response?.data || '{}'))
                    })
            }
        })
    }

    return (
        <>
            <div className="container-xxl">
                <div className="d-flex flex-column flex-lg-row">
                    {/*begin::Sticky aside*/}
                    <div className="flex-column flex-lg-row-auto w-100 w-lg-275px mb-10 mb-lg-0">
                        <div className="card card-flush mb-0" data-kt-sticky="true" data-kt-sticky-name="inbox-aside-sticky"
                             data-kt-sticky-offset="{default: false, xl: '0px'}" data-kt-sticky-width="{lg: '275px'}"
                             data-kt-sticky-left="auto" data-kt-sticky-top="150px" data-kt-sticky-animation="false"
                             data-kt-sticky-zindex="95">
                            {/*begin::Aside content*/}
                            <div className="card-body">
                                {/*begin::Menu*/}
                                <div className="menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary mb-10">
                                    {/*begin::Menu item*/}
                                    <h4 className="text-black mb-7">
                                            <span className={'me-lg-5 text-muted'}>
                                                {intl.formatMessage({id: "JOURNAL.CATEGORIES.TITLE"})}
                                            </span>
                                        <label title=""
                                               className="btn btn-icon btn-active-color-primary w-10px h-10px bg-body shadow"
                                               onClick={() => editCategoriesToolbar()}>
                                            <i className="bi bi-pencil-fill fs-8"></i>
                                        </label>
                                    </h4>
                                    {categories.map(category => (
                                        <div className="menu-item mb-3">
                                            <span className="d-flex flex-stack fs-5 text-muted">
                                                {/*begin::Item*/}
                                                <span className={"menu-link " + (userSettings?.settings?.journal?.selectedCategoryId === category.id ? 'active' : '')}
                                                      onClick={() => selectCategory(category.id)}>
                                                        {showEditCategoriesToolbar &&
                                                            <label title=""
                                                                   className="btn btn-icon btn-active-color-primary w-10px h-10px bg-body shadow me-lg-5"
                                                                   onClick={() => openEditModalForCategory(category)}>
                                                                <i className="bi bi-pencil-fill fs-8"></i>
                                                            </label>
                                                        }
                                                    {showEditCategoriesToolbar &&
                                                        <label title=""
                                                               className="btn btn-icon btn-active-color-primary w-10px h-10px bg-body shadow me-lg-5"
                                                               onClick={() => openDeleteModalForCategory(category)}>
                                                            <i className="bi bi-trash3-fill fs-8"></i>
                                                        </label>
                                                    }
                                                    <span className="menu-title">{category.name}</span>
                                                    {/*begin::Number*/}
                                                    <span className="badge badge-light-info">{category.totalPosts}</span>
                                                    {/*end::Number*/}
                                                    </span>
                                                {/*end::Item*/}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                                <button type='button'
                                        className="btn btn-light-primary btn-sm mb-10"
                                        onClick={openModalForCategory}>
                                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr087.svg*/}
                                    <span className="svg-icon svg-icon-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none">
                                                <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1"
                                                      transform="rotate(-90 11 18)" fill="currentColor"></rect>
                                                <rect x="6" y="11" width="12" height="2" rx="1"
                                                      fill="currentColor"></rect>
                                            </svg>
                                        </span>
                                    {/*end::Svg Icon*/}
                                    {intl.formatMessage({id: 'JOURNAL.CREATE.CATEGORY.TITLE'})}
                                </button>
                                <Link to='/journals/posts/new'>
                                    <button type='button' className="btn btn-light-primary btn-sm mb-10">
                                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr087.svg*/}
                                        <span className="svg-icon svg-icon-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none">
                                                    <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1"
                                                          transform="rotate(-90 11 18)" fill="currentColor"></rect>
                                                    <rect x="6" y="11" width="12" height="2" rx="1"
                                                          fill="currentColor"></rect>
                                                </svg>
                                            </span>
                                        {/*end::Svg Icon*/}
                                        {intl.formatMessage({id: 'JOURNAL.CREATE.POST.TITLE'})}
                                    </button>
                                </Link>
                                {/*end::Menu*/}
                            </div>
                            {/*end::Aside content*/}
                        </div>
                    </div>
                    <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
                            {/*begin::Container*/}
                            <div className="container-fluid d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework">
                                    {/*begin::Main column*/}
                                    <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                                        {/*begin::General options*/}
                                        <div className="card card-flush py-4">
                                            <div className="card-body">
                                                {/*begin::Tickets*/}
                                                <div className="mb-0">
                                                    {/*begin::Tickets List*/}
                                                    <div className="mb-10">
                                                        {posts.length === 0 && (
                                                            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                                {intl.formatMessage({id: "TABLE.NO_RECORDS"})}
                                                            </div>
                                                        )}
                                                        {posts.map(post => (
                                                            <div className="d-flex mb-10">
                                                                {/*begin::Symbol*/}
                                                                {/*begin::Svg Icon | path: icons/duotune/files/fil008.svg*/}
                                                                <span className="svg-icon svg-icon-2x me-5 ms-n1 mt-2 svg-icon-success">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path opacity="0.3"
                                                                    d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM11.7 17.7L16 14C16.4 13.6 16.4 12.9 16 12.5C15.6 12.1 15.4 12.6 15 13L11 16L9 15C8.6 14.6 8.4 14.1 8 14.5C7.6 14.9 8.1 15.6 8.5 16L10.3 17.7C10.5 17.9 10.8 18 11 18C11.2 18 11.5 17.9 11.7 17.7Z"
                                                                    fill="currentColor"></path>
                                                                    <path
                                                                    d="M10.4343 15.4343L9.25 14.25C8.83579 13.8358 8.16421 13.8358 7.75 14.25C7.33579 14.6642 7.33579 15.3358 7.75 15.75L10.2929 18.2929C10.6834 18.6834 11.3166 18.6834 11.7071 18.2929L16.25 13.75C16.6642 13.3358 16.6642 12.6642 16.25 12.25C15.8358 11.8358 15.1642 11.8358 14.75 12.25L11.5657 15.4343C11.2533 15.7467 10.7467 15.7467 10.4343 15.4343Z"
                                                                    fill="currentColor"></path>
                                                                    <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z"
                                                                    fill="currentColor"></path>
                                                                    </svg>
                                                                </span>
                                                                {/*end::Svg Icon*/}
                                                                {/*end::Symbol*/}
                                                                {/*begin::Section*/}
                                                                <div className="d-flex flex-column">
                                                                {/*begin::Content*/}
                                                                <div className="d-flex align-items-center mb-2">
                                                                    {/*begin::Label*/}
                                                                    <span className="badge badge-light my-1 me-2">
                                                                        {moment(post.dateAdd).format('DD-MM-YYYY HH:mm')}
                                                                    </span>
                                                                    {/*end::Label*/}
                                                                    {/*begin::Title*/}
                                                                    <Link to={`/journals/posts/edit/${post.id}`}
                                                                          className='text-dark text-hover-primary fs-4 me-8'>
                                                                        {post.title}
                                                                    </Link>
                                                                    {/*end::Title*/}
                                                                    <label title=""
                                                                           className="btn btn-icon btn-active-color-primary w-10px h-10px bg-body shadow me-lg-15"
                                                                           onClick={() => openDeleteModalForPost(post)}>
                                                                        <i className="bi bi-trash3-fill fs-8"></i>
                                                                    </label>
                                                                </div>
                                                                {/*end::Content*/}
                                                                {/*begin::Text*/}
                                                                    <span className="text-muted fs-6">
                                                                        <div className='journal-post' dangerouslySetInnerHTML={{ __html: post.post || ''}}></div>
                                                                    </span>
                                                                {/*end::Text*/}
                                                                    </div>
                                                                {/*end::Section*/}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {/*end::Tickets List*/}
                                                </div>
                                                {/*end::Tickets*/}
                                                {posts.length > 0 && (
                                                    <div className="row px-9 pt-3 pb-5">
                                                        <Pagination storageKey={SettingsPaginationKey.JOURNAL_PAGINATION_KEY} pagination={pagination} refetch={refetchJournalPosts} isLoading={isLoading} defaultPageSize={5}/>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {/*end::General options*/}
                                    </div>
                                    {/*end::Main column*/}
                                    <div></div>
                            </div>
                            {/*end::Container*/}
                        {/*end::Post*/}
                        {isLoading && <Loader/>}
                    </div>
                </div>
            </div>
            {openCategoryModal && <NewJournalCategoryModal category={undefined} setOpenCategoryModal={setOpenCategoryModal} setCategoryEditModal={setCategoryEditModal} refetchJournalCategories={refetchJournalCategories} />}
            {openCategoryEditModal && <NewJournalCategoryModal category={openCategoryEditModal} setOpenCategoryModal={setOpenCategoryModal} setCategoryEditModal={setCategoryEditModal} refetchJournalCategories={refetchJournalCategories} />}
        </>
    )
}

const JournalPage: React.FC = () => {
    const intl = useIntl()
    const tradeBreadCrumbs: Array<PageLink> = [
        {
            title: intl.formatMessage({id: 'BREADCRUMB.JOURNAL'}),
            path: '/journals',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]
    return (
        <UserSettingsRequestProvider>
            <UserSettingsResponseProvider>
                <MetaHeader pageName='HTML.HEAD.TITLE.JOURNAL'/>
                <PageTitle breadcrumbs={tradeBreadCrumbs}>
                    {intl.formatMessage({id: 'BREADCRUMB.JOURNAL.MANAGEMENT'})}
                </PageTitle>
                <JournalPageContent/>
            </UserSettingsResponseProvider>
        </UserSettingsRequestProvider>
    )
}

export {JournalPage}
