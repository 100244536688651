/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {useAuth} from '../core/Auth'
import {login} from "../core/_requests";
import {JwtTokenModel} from "../core/_models";
import {MetaHeader} from "../../../common/component/meta/MetaHeader";
import {useIntl} from "react-intl";
import {GoogleOauth2Button} from "../../../common/component/auth/GoogleOauth2Button";
import {jwtDecode} from "jwt-decode";

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
    password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
})

const initialValues = {
    email: 'user@gmail.com',
    password: 'useruseruser',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const {saveAuth, setCurrentUser} = useAuth()

    const formik= useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)

            try {
                const {data: auth} = await login(values.email, values.password)

                saveAuth(auth?.data?.attributes)

                const jwtToken = auth?.data?.attributes.accessToken
                const decodedJwtToken = jwtDecode<JwtTokenModel>(jwtToken!);
                const user = decodedJwtToken.user

                setCurrentUser(user)
            } catch (error) {
                console.error(error)
                saveAuth(undefined)
                setStatus('The login detail is incorrect')
                setSubmitting(false)
                setLoading(false)
            }
        },
    })
    return (
        <>
            <MetaHeader pageName='HTML.HEAD.TITLE.LOGIN'/>

            <form
                className='form w-100'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_login_form'
            >
                {/* begin::Heading */}
                <div className='text-center mb-10'>
                    <h1 className='text-dark mb-3'>
                        {intl.formatMessage({id: 'AUTH.SIGN_IN.TITLE'})}
                    </h1>
                    <div className='text-gray-400 fs-4'>
                        {intl.formatMessage({id: 'AUTH.SIGN_IN.NEW_HERE'})}
                        {' '}
                        <Link to='/auth/registration' className='link-primary'>
                            {intl.formatMessage({id: 'AUTH.SIGN_IN.CREATE_ACCOUNT'})}
                        </Link>
                    </div>
                </div>
                {/* begin::Heading */}

                {formik.status && (
                    <div className='mb-lg-15 alert alert-danger'>
                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                    </div>
                )}

                {/* begin::Form group */}
                <div className='fv-row mb-10'>
                    <label className='form-label fs-6 text-dark'>
                        {intl.formatMessage({id: 'AUTH.SIGN_IN.EMAIL'})}
                    </label>
                    <input
                        placeholder='Email'
                        {...formik.getFieldProps('email')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {'is-invalid': formik.touched.email && formik.errors.email},
                            {
                                'is-valid': formik.touched.email && !formik.errors.email,
                            }
                        )}
                        type='email'
                        name='email'
                        autoComplete='off'
                    />
                    {formik.touched.email && formik.errors.email && (
                        <div className='fv-plugins-message-container'>
                            <span role='alert'>{formik.errors.email}</span>
                        </div>
                    )}
                </div>
                {/* end::Form group */}

                {/* begin::Form group */}
                <div className='fv-row mb-10'>
                    <div className='d-flex justify-content-between mt-n5'>
                        <div className='d-flex flex-stack mb-2'>
                            {/* begin::Label */}
                            <label className='form-label text-dark fs-6 mb-0'>
                                {intl.formatMessage({id: 'AUTH.SIGN_IN.PASSWORD'})}
                            </label>
                            {/* end::Label */}
                            {/* begin::Link */}
                            <Link
                                to='/auth/password/forgot'
                                className='link-primary fs-6'
                                style={{marginLeft: '5px'}}
                            >
                                {intl.formatMessage({id: 'AUTH.SIGN_IN.QUESTION.FORGOT_PASSWORD'})}
                            </Link>
                            {/* end::Link */}
                        </div>
                    </div>
                    <input
                        type='password'
                        autoComplete='off'
                        {...formik.getFieldProps('password')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                                'is-invalid': formik.touched.password && formik.errors.password,
                            },
                            {
                                'is-valid': formik.touched.password && !formik.errors.password,
                            }
                        )}
                    />
                    {formik.touched.password && formik.errors.password && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.password}</span>
                            </div>
                        </div>
                    )}
                </div>
                {/* end::Form group */}

                {/* begin::Action */}
                <div className='text-center'>
                    <button
                        type='submit'
                        id='kt_sign_in_submit'
                        className='btn btn-lg btn-primary w-100 mb-5'
                        disabled={formik.isSubmitting || !formik.isValid}
                    >
                        {!loading && <span className='indicator-label'>
                            {intl.formatMessage({id: 'AUTH.SIGN_IN.BUTTON.CONTINUE'})}
                        </span>}
                        {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                                Please wait...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>

                    {/* begin::Separator */}
                    <div className='text-center text-muted text-uppercase mb-5'>
                        {intl.formatMessage({id: 'AUTH.SIGN_IN.BUTTON.OR'})}
                    </div>
                    {/* end::Separator */}

                    {/* begin::Google link */}
                    <GoogleOauth2Button
                        buttonText='AUTH.SIGN_IN.BUTTON.CONTINUE_WITH_GOOGLE'
                        setLoading={setLoading}
                        onError={(error) => formik.setStatus(error.message)}
                    />
                    {/* end::Google link */}
                </div>
                {/* end::Action */}
            </form>
        </>
    )
}
