import {Column} from 'react-table'
import moment from 'moment'
import {TradeCustomHeader} from "./TradeCustomHeader";
import {TradeSelectionCell} from "./TradeSelectionCell";
import {TradeSelectionHeader} from "./TradeSelectionHeader";
import {TradeModel} from "../core/_models";
import {TradeActionsCell} from "./TradeActionsCell";
import {isNotEmpty, KTSVG} from "../../../../../_metronic/helpers";
import React from "react";
import clsx from "clsx";
import {getCurrencySymbol} from "../../../../common/utils/currency/CurrencyUtils";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {TradeMediaCell} from "./TradeMediaCell";
import {TradeOpenTypeCell} from "./TradeOpenTypeCell";
import {TradeCloseTypeCell} from "./TradeCloseTypeCell";
import {TradeStateCell} from "./TradeStateCell";
import {TradeSymbolCell} from "./TradeSymbolCell";
import {TradeTagCell} from "./TradeTagCell";
import he from 'he';

const showComment = (comment: string) => (
    <Popover id="popover-basic">
        <Popover.Header as="h3">Trade Comment</Popover.Header>
        <Popover.Body>
            <div className='mw-600px'>{he.unescape(comment)}</div>
        </Popover.Body>
    </Popover>
);

const tradeColumns: ReadonlyArray<Column<TradeModel>> = [
    {
        Header: (props) => <TradeSelectionHeader tableProps={props}/>,
        id: 'selection',
        Cell: ({...props}) => <TradeSelectionCell id={props.data[props.row.index].id}/>,
    },
    {
        Header: (props) => (
            <TradeCustomHeader tableProps={props} title='TRADES.SYMBOL' className='min-w-80px'/>
        ),
        id: 'ticker',
        Cell: ({...props}) => <TradeSymbolCell id={props.data[props.row.index].id} ticker={props.data[props.row.index].ticker} icon={props.data[props.row.index].icon}/>
    },
    {
        Header: (props) => (
            <TradeCustomHeader tableProps={props} title='TRADES.OPEN_DATE' className='min-w-100px'/>
        ),
        id: 'openDate',
        Cell: ({...props}) => (
            <div className='fs-7'>{moment(props.data[props.row.index].openDate).format('DD-MM-YYYY HH:mm')}</div>
        ),
    },
    {
        Header: (props) => (
            <TradeCustomHeader tableProps={props} title='TRADES.CLOSE_DATE' className='min-w-100px'/>
        ),
        id: 'closeDate',
        Cell: ({...props}) => (
            <div className='fs-7'>{moment(props.data[props.row.index].closeDate).format('DD-MM-YYYY HH:mm')}</div>
        ),
    },
    {
        Header: (props) => (
            <TradeCustomHeader tableProps={props} title='TRADES.SIDE' className='min-w-60px'/>
        ),
        id: 'direction',
        Cell: ({...props}) => (
            <>
                {props.data[props.row.index].direction === 'LONG'
                    ? <KTSVG path='media/icons/duotune/arrows/arr066.svg' className='svg-icon-1 svg-icon-success'/>
                    : <KTSVG path='media/icons/duotune/arrows/arr065.svg' className='svg-icon-1 svg-icon-danger'/>
                }
            </>
        ),
    },
    {
        Header: (props) => (
            <TradeCustomHeader tableProps={props} title='TRADES.OPEN_TYPE' className='min-w-80px'/>
        ),
        id: 'openType',
        Cell: ({...props}) => <TradeOpenTypeCell openType={props.data[props.row.index].openType} />
    },
    {
        Header: (props) => (
            <TradeCustomHeader tableProps={props} title='TRADES.CLOSE_TYPE' className='min-w-80px'/>
        ),
        id: 'closeType',
        Cell: ({...props}) => <TradeCloseTypeCell closeType={props.data[props.row.index].closeType} />
    },
    {
        Header: (props) => (
            <TradeCustomHeader tableProps={props} title='TRADES.VOLUME' className='min-w-70px'/>
        ),
        id: 'volume',
        Cell: ({...props}) => (
            <div className='badge badge-light-info fw-normal'>{props.data[props.row.index].volume.toLocaleString(undefined, {maximumFractionDigits: 12})}</div>
        ),
    },
    {
        Header: (props) => (
            <TradeCustomHeader tableProps={props} title='TRADES.OPEN_PRICE' className='min-w-80px'/>
        ),
        id: 'openPrice',
        Cell: ({...props}) => (
            <div className='fs-7'>
                {props.data[props.row.index].openPrice.toLocaleString(undefined, {maximumFractionDigits: 12})}
                {getCurrencySymbol(props.data[props.row.index].account.currency)}
            </div>
        ),
    },
    {
        Header: (props) => (
            <TradeCustomHeader tableProps={props} title='TRADES.CLOSE_PRICE' className='min-w-80px'/>
        ),
        id: 'closePrice',
        Cell: ({...props}) => (
            <div className='fs-7'>
                {props.data[props.row.index].closePrice.toLocaleString(undefined, {maximumFractionDigits: 12})}
                {getCurrencySymbol(props.data[props.row.index].account.currency)}
            </div>
        ),
    },
    {
        Header: (props) => (
            <TradeCustomHeader tableProps={props} title='TRADES.COMMISSION' className='min-w-80px'/>
        ),
        id: 'commission',
        Cell: ({...props}) => (
            <div className='fs-7'>
                {props.data[props.row.index].commission.toLocaleString(undefined, {maximumFractionDigits: 12})}
                {getCurrencySymbol(props.data[props.row.index].account.currency)}
            </div>
        ),
    },
    {
        Header: (props) => (
            <TradeCustomHeader tableProps={props} title='TRADES.RESULT' className='min-w-70px'/>
        ),
        id: 'result',
        Cell: ({...props}) => (
            <div
                className={clsx('badge', props.data[props.row.index].result >= 0.0 ? 'badge-light-success fw-normal' : 'badge-light-danger fw-normal')}>
                {props.data[props.row.index].result.toLocaleString(undefined, {maximumFractionDigits: 12})}
                {getCurrencySymbol(props.data[props.row.index].account.currency)}
            </div>
        ),
    },
    {
        Header: (props) => (
            <TradeCustomHeader tableProps={props} title='TRADES.STATE' className='min-w-70px'/>
        ),
        id: 'state',
        Cell: ({...props}) => <TradeStateCell state={props.data[props.row.index].state} />
    },
    {
        Header: (props) => (
            <TradeCustomHeader tableProps={props} title='TRADES.MEDIA' className='min-w-60px'/>
        ),
        id: 'media',
        Cell: ({...props}) => <TradeMediaCell trade={props.data[props.row.index]}/>,
    },
    {
        Header: (props) => (
            <TradeCustomHeader tableProps={props} title='TRADES.COMMENT' className='min-w-60px'/>
        ),
        id: 'comment',
        Cell: ({...props}) => (
            <div className='cursor-pointer'>
                {isNotEmpty(props.data[props.row.index].comment)
                    ? <OverlayTrigger trigger="click" placement="left"
                                      overlay={showComment(props.data[props.row.index].comment)}>
                        <div>
                            <KTSVG path='media/icons/duotune/communication/com003.svg' className='svg-icon-1'/>
                        </div>
                    </OverlayTrigger>
                    : ''
                }
            </div>
        ),
    },
    {
        Header: (props) => (
            <TradeCustomHeader tableProps={props} title='TRADES.TAGS' className='min-w-125px'/>
        ),
        id: 'tags',
        Cell: ({...props}) => <TradeTagCell tags={props.data[props.row.index].tags}/>,
    },
    {
        Header: (props) => (
            <TradeCustomHeader tableProps={props} title='TRADES.DATE_ADD' className='min-w-80px'/>
        ),
        id: 'dateAdd',
        Cell: ({...props}) => (
            <div className='fs-7'>
                {moment(props.data[props.row.index].dateAdd).format('DD-MM-YYYY HH:mm')}
            </div>
        ),
    },
    {
        Header: (props) => (
            <TradeCustomHeader tableProps={props} title='TRADES.ACTIONS' className='text-end min-w-100px'/>
        ),
        id: 'actions',
        Cell: ({...props}) => <TradeActionsCell trade={props.data[props.row.index]} account={props.account}
                                                setAccount={props.setAccount}
                                                setIsUpdateDeposit={props.setIsUpdateDeposit}/>,
    },
]

export {tradeColumns}
