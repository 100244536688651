import {JsonApiArrayDto, JsonApiObjectDto} from "../json-api/JsonApiDto";
import {TickerIconDto} from "../media/icon/TickerIconDto";
import {IntlShape} from "react-intl";
import {ID} from "../../../../_metronic/helpers";
import {AccountModel} from "../../../modules/accounts/accounts-list/core/_models";
import {TradeMediaModel, TradeTagDto} from "../../../modules/trades/table/core/_models";


export interface JsonApiTradeArrayDto extends JsonApiArrayDto<TradeDto> {
}

export interface JsonApiTradeObjectDto extends JsonApiObjectDto<TradeDto> {
}

export type TradeDto = {
    id?: ID,
    ticker?: string,
    account?: AccountModel
    volume?: number,
    direction?: 'SHORT' | 'LONG',
    openType?: string,
    openDate?: Date,
    openPrice?: number,
    closeType?: string,
    closeDate?: Date,
    closePrice?: number,
    priceLevel?: number,
    stopLossPrice?: number,
    takeProfitsPrices: TradeTakeProfitPriceDto[],
    exchange: 'IBKR' | 'BINANCE' | 'NOT_DEFINED',
    commission?: number,
    result?: number,
    comment?: string,
    medias?: Array<TradeMediaModel>,
    state?: 'COMPLETE' | 'OPEN',
    tags?: Array<TradeTagDto>,
    icon?: TickerIconDto,
    dateUpdate?: Date,
    dateAdd?: Date,
}

export type TradeTakeProfitPriceDto = {
    price?: number
    volume?: number
}

export const getAvailableColumns = (intl: IntlShape) => {
    return {
        closeDate: intl.formatMessage({id: "TRADES.CLOSE_DATE"}),
        closePrice: intl.formatMessage({id: "TRADES.CLOSE_PRICE"}),
        closeType: intl.formatMessage({id: "TRADES.CLOSE_TYPE"}),
        comment: intl.formatMessage({id: "TRADES.COMMENT"}),
        commission: intl.formatMessage({id: "TRADES.COMMISSION"}),
        dateAdd: intl.formatMessage({id: "TRADES.DATE_ADD"}),
        direction: intl.formatMessage({id: "TRADES.SIDE"}),
        media: intl.formatMessage({id: "TRADES.MEDIA"}),
        openDate: intl.formatMessage({id: "TRADES.OPEN_DATE"}),
        openPrice: intl.formatMessage({id: "TRADES.OPEN_PRICE"}),
        openType: intl.formatMessage({id: "TRADES.OPEN_TYPE"}),
        result: intl.formatMessage({id: "TRADES.RESULT"}),
        state: intl.formatMessage({id: "TRADES.STATE"}),
        tags: intl.formatMessage({id: "TRADES.TAGS"}),
        ticker: intl.formatMessage({id: "TRADES.SYMBOL"}),
        volume: intl.formatMessage({id: "TRADES.VOLUME"}),
    }
}

export const getDisabledByDefaultColumns = () => {
    return {
        dateAdd: true,
        openDate: true,
    }
}

export enum TradeReportBroker {
    TRADE_THE_POOL = "TRADE_THE_POOL",
    IBKR = "IBKR",
    NOT_DEFINED = "NOT_DEFINED",
}
