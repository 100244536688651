import {ID} from "../../../../_metronic/helpers";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL
const JOURNAL_CATEGORY_URL = `${API_URL}/journals/categories`
const JOURNAL_POST_URL = `${API_URL}/journals/posts`

const deleteJournalCategoryById = (categoryId: ID): Promise<void> => {
    return axios.delete(`${JOURNAL_CATEGORY_URL}/${categoryId}`)
}

const deleteJournalPostById = (postId: ID): Promise<void> => {
    return axios.delete(`${JOURNAL_POST_URL}/${postId}`)
}

export {
    deleteJournalCategoryById,
    deleteJournalPostById,
}
