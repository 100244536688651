import React, {FC, useEffect, useState} from 'react'
import clsx from "clsx";
import {FormikErrors, useFormik} from "formik";
import axios, {AxiosResponse} from "axios";
import * as Yup from "yup";
import {AccountModel} from "../core/_models";
import {JsonApiErrorsModel} from "../../../_models";
import {ID, KTSVG, Response} from "../../../../../_metronic/helpers";
import {useAccountQueryResponse} from "../core/QueryResponseProvider";
import {useAccountListView} from "../../../../common/provider/account/AccountListViewProvider";
import {useIntl} from "react-intl";

type NewAccountDto = {
    name: string
    brokerAccountId: ID
    currency: 'USD' | 'EUR' | 'RUB'
}

const newAccountSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Minimum 2 symbols')
        .max(64, 'Maximum 64 symbols')
        .required('Name is required'),
    brokerAccountId: Yup.string()
        .max(32, 'Maximum 32 symbols')
        .notRequired(),
    currency: Yup.string()
        .oneOf(['USD', 'EUR', 'RUB'])
        .required('Currency is required'),
})

const NewAccountModal: FC = () => {
    const intl = useIntl()
    const {itemForCreate, setItemForCreate, itemIdForUpdate: account, setItemIdForUpdate} = useAccountListView()
    const {refetch: refetchAllAccounts} = useAccountQueryResponse()

    const [newAccount] = useState<NewAccountDto>({
        name: account?.name || '',
        brokerAccountId: account?.brokerAccountId,
        currency: account?.currency || 'USD',
    })

    useEffect(() => {
        document.body.classList.add('modal-open')

        return () => {
            document.body.classList.remove('modal-open')
        }
    }, [])

    const formik = useFormik({
        initialValues: newAccount,
        validationSchema: newAccountSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setSubmitting(true)

            const API_URL = process.env.REACT_APP_API_URL
            const ACCOUNT_URL = `${API_URL}/accounts`

            if (!account) {
                return axios.post(ACCOUNT_URL, values)
                    .then((response: AxiosResponse<Response<AccountModel>>) => {
                        closeModal()
                        refetchAllAccounts()
                    }).catch(error => {
                        const {response} = error;
                        const errors: FormikErrors<any> = {};

                        (response.data.errors as Array<JsonApiErrorsModel>).map(error => {
                            if (error.code === 'VALIDATION_ERROR') {
                                const field = error.source?.parameter || ''

                                errors[field] = error.detail
                            } else {
                                setStatus(error.detail)
                            }
                        })
                        formik.setErrors(errors)
                    })
            } else {
                return axios.put(ACCOUNT_URL + '/' + account.id, values)
                    .then((response: AxiosResponse<Response<AccountModel>>) => {
                        closeModal()
                        refetchAllAccounts()
                    }).catch(error => {
                        const {response} = error;
                        const errors: FormikErrors<any> = {};

                        (response.data.errors as Array<JsonApiErrorsModel>).map(error => {
                            if (error.code === 'VALIDATION_ERROR') {
                                const field = error.source?.parameter || ''

                                errors[field] = error.detail
                            } else {
                                setStatus(error.detail)
                            }
                        })
                        formik.setErrors(errors)
                    })
            }
        },
    })

    const closeModal = () => {
        setItemForCreate(undefined)
        setItemIdForUpdate(undefined)
    }

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_trade'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered mw-650px'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>
                        <div className='modal-header'>
                            {/* begin::Modal title */}
                            <h2 className='fw-bolder'>
                                {!account && intl.formatMessage({id: 'ACCOUNTS.MODAL.CREATE.TITLE'})}
                                {account && intl.formatMessage({id: 'ACCOUNTS.MODAL.UPDATE.TITLE'})}
                            </h2>
                            {/* end::Modal title */}

                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary cursor-pointer'
                                data-kt-trade-modal-action='close'
                                onClick={closeModal}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                            </div>
                            {/* end::Close */}
                        </div>
                        {/* begin::Modal body */}
                        <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                            <form id='kt_modal_add_trade_form' className='form' onSubmit={formik.handleSubmit}
                                  noValidate>
                                {/* begin::Scroll */}
                                <div
                                    className='d-flex flex-column scroll-y me-n7 pe-7'
                                    id='kt_modal_add_trade_scroll'
                                    data-kt-scroll='true'
                                    data-kt-scroll-activate='{default: false, lg: true}'
                                    data-kt-scroll-max-height='auto'
                                    data-kt-scroll-dependencies='#kt_modal_add_trade_header'
                                    data-kt-scroll-wrappers='#kt_modal_add_trade_scroll'
                                    data-kt-scroll-offset='300px'
                                >
                                    {formik.status && (
                                        <div className='mb-lg-15 alert alert-danger'>
                                            <div className='alert-text font-weight-bold'>{formik.status}</div>
                                        </div>
                                    )}
                                    {/* begin::Input group */}
                                    <div className='fv-row mb-7'>
                                        {/* begin::Label */}
                                        <label className='required fw-bold fs-6 mb-2'>
                                            {intl.formatMessage({id: 'ACCOUNTS.MODAL.CREATE.NAME'})}
                                        </label>
                                        {/* end::Label */}

                                        {/* begin::Input */}
                                        <input
                                            placeholder={intl.formatMessage({id: 'ACCOUNTS.MODAL.CREATE.NAME'})}
                                            {...formik.getFieldProps('name')}
                                            type='text'
                                            name='name'
                                            className={clsx(
                                                'form-control form-control-solid mb-3 mb-lg-0',
                                                {'is-invalid': formik.touched.name && formik.errors.name},
                                                {
                                                    'is-valid': formik.touched.name && !formik.errors.name,
                                                }
                                            )}
                                            autoComplete='off'
                                            disabled={formik.isSubmitting}
                                        />
                                        {formik.touched.name && formik.errors.name && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.name}</span>
                                                </div>
                                            </div>
                                        )}
                                        {/* end::Input */}
                                    </div>
                                    {/* end::Input group */}

                                    {/* begin::Input group */}
                                    <div className='fv-row mb-7'>
                                        {/* begin::Label */}
                                        <label className='required fw-bold fs-6 mb-2'>
                                            {intl.formatMessage({id: 'ACCOUNTS.MODAL.CREATE.CURRENCY'})}
                                        </label>
                                        {/* end::Label */}

                                        {/* begin::Input */}
                                        <select
                                            className='form-select form-select-solid'
                                            {...formik.getFieldProps('currency')}
                                            name='currency'
                                        >
                                            <option value='USD'>USD</option>
                                            <option value='EUR'>EUR</option>
                                            <option value='RUB'>RUB</option>
                                        </select>
                                        {formik.touched.currency && formik.errors.currency && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.currency}</span>
                                                </div>
                                            </div>
                                        )}
                                        {/* end::Input */}
                                    </div>
                                    {/* end::Input group */}

                                    {/* begin::Input group */}
                                    <div className='fv-row mb-7'>
                                        {/* begin::Label */}
                                        <label className='fw-bold fs-6 mb-2'>
                                            {intl.formatMessage({id: 'ACCOUNTS.MODAL.CREATE.BROKER_ACCOUNT_ID'})}
                                            <div className="text-muted fs-7">
                                                {intl.formatMessage({id: 'ACCOUNTS.MODAL.CREATE.BROKER_ACCOUNT_ID.NOTICE'})}
                                            </div>
                                        </label>
                                        {/* end::Label */}

                                        {/* begin::Input */}
                                        <input
                                            placeholder={intl.formatMessage({id: 'ACCOUNTS.MODAL.CREATE.BROKER_ACCOUNT_ID'})}
                                            {...formik.getFieldProps('brokerAccountId')}
                                            type='text'
                                            name='brokerAccountId'
                                            className={clsx(
                                                'form-control form-control-solid mb-3 mb-lg-0',
                                                {'is-invalid': formik.touched.brokerAccountId && formik.errors.brokerAccountId},
                                                {
                                                    'is-valid': formik.touched.brokerAccountId && !formik.errors.brokerAccountId,
                                                }
                                            )}
                                            autoComplete='off'
                                            disabled={formik.isSubmitting}
                                        />
                                        {formik.touched.brokerAccountId && formik.errors.brokerAccountId && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.brokerAccountId}</span>
                                                </div>
                                            </div>
                                        )}
                                        {/* end::Input */}
                                    </div>
                                    {/* end::Input group */}
                                </div>
                                {/* end::Scroll */}

                                {/* begin::Actions */}
                                <div className='text-center pt-15'>
                                    <button
                                        type='reset'
                                        onClick={closeModal}
                                        className='btn btn-light me-3'
                                        data-kt-trade-modal-action='cancel'
                                        disabled={formik.isSubmitting}
                                    >
                                        {intl.formatMessage({id: 'BUTTON.DISCARD'})}
                                    </button>

                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-trade-modal-action='submit'
                                        disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                                    >
                                        <span className='indicator-label'>
                                            {intl.formatMessage({id: 'BUTTON.SUBMIT'})}
                                        </span>
                                        {(formik.isSubmitting) && (
                                            <span className='indicator-progress'>
                      Please wait...{' '}
                                                <span
                                                    className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                                        )}
                                    </button>
                                </div>
                                {/* end::Actions */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-backdrop fade show'></div>
        </>
    )
}

export {NewAccountModal}
