/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from "../../../../_metronic/assets/ts/_utils";
import {AccountResultTimelineDto} from "../../../common/dto/account/AccountDashboardDto";
import {IntlShape, useIntl} from "react-intl";
import {useThemeMode} from "../../../../_metronic/partials";

type Props = {
    className: string,
    resultTimeline?: Array<AccountResultTimelineDto>
}

const ResultTimelineChartWidget: React.FC<Props> = ({className, resultTimeline}) => {
    const intl = useIntl()
    const {isDark} = useThemeMode()
    const chartRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (!chartRef.current) {
            return
        }
        const height = parseInt(getCSS(chartRef.current, 'height'))
        const chart = new ApexCharts(chartRef.current, getChartOptions(height, intl, isDark, resultTimeline))

        if (chart) {
            chart.render()
        }
        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef, resultTimeline])

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fs-3 mb-1'>
                        {intl.formatMessage({id: "DASHBOARD.CHART.RESULT_TIMELINE.TITLE"})}
                    </span>

                    <span className='text-muted fs-7'>
                        {resultTimeline?.length || 0}&nbsp;
                        {intl.formatMessage({id: "DASHBOARD.CHART.RECORDS"})}
                    </span>
                </h3>
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className='card-body'>
                {/* begin::Chart */}
                <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '350px'}}></div>
                {/* end::Chart */}
            </div>
            {/* end::Body */}
        </div>
    )
}

export {ResultTimelineChartWidget}

function getChartOptions(height: number, intl: IntlShape, isDark: boolean, resultTimeline?: Array<AccountResultTimelineDto>): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')
    const baseColor = getCSSVariableValue('--bs-info')
    const lightColor = (isDark ? '#b899ff' : '#ede6ff')
    const opacity = (isDark ? 0.2 : 1)
    const dataList = resultTimeline?.map(value => value.result || 0) || []
    const datesList = resultTimeline?.map(value => value.date || 0) || []

    return {
        series: [
            {
                name: intl.formatMessage({id: "DASHBOARD.CHART.RESULT_TIMELINE.PROFIT"}),
                data: dataList,
            },
        ],
        chart: {
            fontFamily: 'inherit',
            type: 'area',
            height: 450,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {},
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            type: 'solid',
            opacity: opacity,
        },
        stroke: {
            curve: 'smooth',
            show: true,
            width: 1,
            colors: [baseColor],
        },
        xaxis: {
            categories: datesList,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
            crosshairs: {
                position: 'front',
                stroke: {
                    color: baseColor,
                    width: 1,
                    dashArray: 3,
                },
            },
            tooltip: {
                enabled: true,
                formatter: undefined,
                offsetY: 0,
                style: {
                    fontSize: '12px',
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val) {
                    return '$' + val
                },
            },
        },
        colors: [lightColor],
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
        markers: {
            strokeColors: baseColor,
            strokeWidth: 3,
        },
    }
}
