import {useIntl} from "react-intl";
import React, {FC} from "react";

type Props = {
    state: string
}

const TradeStateCell: FC<Props> = (props) => {
    const intl = useIntl()
    const {state} = props

    return (
        <>
            {state === 'COMPLETE' ? (
                <div className="badge badge-light-success fw-normal">{intl.formatMessage({id: 'TRADES.STATE.COMPLETE'})}</div>
            ) : (
                <div className="badge badge-light-warning fw-normal">{intl.formatMessage({id: 'TRADES.STATE.ACTIVE'})}</div>
            )}
        </>
    )
}

export {TradeStateCell}
