const calculateNumberDifferencePercent = (currentValue: number, previousValue: number, invert: boolean = false) => {
    if (currentValue === 0 && previousValue === 0) {
        return 0
    } else if (previousValue === 0 && currentValue > 0) {
        return 100
    } else if (previousValue === 0 && currentValue < 0) {
        return -100
    }
    const percent = (currentValue - previousValue) / previousValue * 100 * (invert ? -1 : 1)
    const multiplier = 10

    return Math.round(percent * multiplier) / multiplier
}

export {calculateNumberDifferencePercent}
