import {useGoogleLogin} from "@react-oauth/google";
import {registerByGoogle, requestOauth2State} from "../../../modules/auth/core/_requests";
import {JsonApiAuthModel, JwtTokenModel, useAuth} from "../../../modules/auth";
import React, {Dispatch, FC, SetStateAction, useEffect, useState} from "react";
import {toAbsoluteUrl} from "../../../../_metronic/helpers";
import {useIntl} from "react-intl";
import {jwtDecode} from "jwt-decode";

type OnErrorCallback = (error: any) => void;

type Props = {
    buttonText: string,
    setLoading: Dispatch<SetStateAction<boolean>>
    onError: OnErrorCallback
}

const GoogleOauth2Button: FC<Props> = (props) => {
    const intl = useIntl()
    const {saveAuth, setCurrentUser} = useAuth()
    const {buttonText, setLoading, onError} = props
    const [oauth2State, setOauth2State] = useState('')

    useEffect(() => {
        requestOauth2State().then(state => setOauth2State(state || ''))
    }, [])

    const loginViaGoogle = useGoogleLogin({
        flow: 'auth-code',
        scope: 'email profile',
        state: oauth2State,
        onSuccess: tokenResponse => {
            setLoading(true)

            registerByGoogle(tokenResponse.code)
                .then(response => updateCurrentUserByJwt(response.data))
                .catch(error => {
                    onError(error)

                    saveAuth(undefined)
                    setLoading(false)
                })
        },
        onError: (errorResponse) => {
            console.log('Login Failed! Reason: ' + errorResponse.error_description);

            onError("Can't login via GOOGLE! Try again later!")
        }
    })

    const updateCurrentUserByJwt = (response: JsonApiAuthModel) => {
        saveAuth(response.data?.attributes)

        const jwtToken = response.data?.attributes.accessToken
        const decodedJwtToken = jwtDecode<JwtTokenModel>(jwtToken!);
        const user = decodedJwtToken.user

        setCurrentUser(user)
    }

    return (
        <button
            type='button'
            className='btn btn-light-primary w-100 mb-10'
            onClick={() => loginViaGoogle()}
        >
            <img
                alt='Logo'
                src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                className='h-20px me-3'
            />
            {intl.formatMessage({id: buttonText})}
        </button>
    )
}

export {GoogleOauth2Button}
