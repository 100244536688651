/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from "../../../../_metronic/assets/ts/_utils";
import {AccountResultByHourTimelineDto} from "../../../common/dto/account/AccountDashboardDto";
import {IntlShape, useIntl} from "react-intl";
import {useThemeMode} from "../../../../_metronic/partials";

type Props = {
    className: string,
    resultByHourTimeline?: Array<AccountResultByHourTimelineDto>
}

const ResultByHourTimelineChartWidget: React.FC<Props> = ({className, resultByHourTimeline}) => {
    const intl = useIntl()
    const {isDark} = useThemeMode()
    const chartRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (!chartRef.current) {
            return
        }
        const height = parseInt(getCSS(chartRef.current, 'height'))
        const chart = new ApexCharts(chartRef.current, getChartOptions(height, intl, isDark, resultByHourTimeline))

        if (chart) {
            chart.render()
        }
        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef, resultByHourTimeline])

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fs-3 mb-1'>
                        {intl.formatMessage({id: "DASHBOARD.CHART.RESULT_TIMELINE_BY_HOUR.TITLE"})}
                    </span>

                    <span className='text-muted fs-7'>
                        {resultByHourTimeline?.length || 0}&nbsp;
                        {intl.formatMessage({id: "DASHBOARD.CHART.RECORDS"})}
                    </span>
                </h3>
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className='card-body'>
                {/* begin::Chart */}
                <div ref={chartRef} id='kt_charts_widget_4_chart'></div>
                {/* end::Chart */}
            </div>
            {/* end::Body */}
        </div>
    )
}

export {ResultByHourTimelineChartWidget}

function getChartOptions(height: number, intl: IntlShape, isDark: boolean, resultByHourTimeline?: Array<AccountResultByHourTimelineDto>): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')
    const baseColor = getCSSVariableValue('--bs-info')
    const profitColor = (isDark ? '#50cd89' : '#50cd89')
    const breakevenColor = (isDark ? 'rgba(136,122,34,0.78)' : 'rgba(202,155,0,0.78)')
    const lossColor = (isDark ? '#852c2c' : '#d55959')
    const opacity = (isDark ? 0.2 : 1)
    const profitDataList = resultByHourTimeline?.map(value => value.profitTrades || 0) || []
    const breakevenDataList = resultByHourTimeline?.map(value => value.breakevenTrades || 0) || []
    const lossDataList = resultByHourTimeline?.map(value => value.lossTrades || 0) || []
    const datesList = resultByHourTimeline?.map(value => value.hour || 0) || []
    const chartColors = [profitColor, breakevenColor, lossColor]

    return {
        series: [
            {
                name: intl.formatMessage({id: "DASHBOARD.CHART.RESULT_TIMELINE_BY_HOUR.PROFIT_TRADES"}),
                data: profitDataList,
            },
            {
                name: intl.formatMessage({id: "DASHBOARD.CHART.RESULT_TIMELINE_BY_HOUR.BREAKEVEN_TRADES"}),
                data: breakevenDataList,
            },
            {
                name: intl.formatMessage({id: "DASHBOARD.CHART.RESULT_TIMELINE_BY_HOUR.LOSS_TRADES"}),
                data: lossDataList,
            },
        ],
        chart: {
            fontFamily: 'inherit',
            type: 'area',
            height: 350,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {},
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            opacity: opacity,
        },
        stroke: {
            curve: 'smooth',
            show: true,
            width: 1,
            colors: chartColors,
        },
        xaxis: {
            categories: datesList,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
                formatter: (value) => {
                    return value + ':00'
                },
            },
            crosshairs: {
                position: 'front',
                stroke: {
                    color: baseColor,
                    width: 1,
                    dashArray: 3,
                },
            },
            tooltip: {
                enabled: true,
                formatter: undefined,
                offsetY: 0,
                style: {
                    fontSize: '12px',
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
                formatter: (value) => {
                    return Math.floor(value) + ''
                },
            },
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val) {
                    return val + ''
                },
            },
        },
        colors: chartColors,
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
        markers: {
            strokeColors: chartColors,
            strokeWidth: 3,
        },
    }
}
