/* eslint-disable react-hooks/exhaustive-deps */
import React, {createContext, FC, useContext, useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {
    createJsonApiResponseContext,
    ID,
    initialQueryRequest,
    initialQueryResponse,
    QUERIES,
    QueryRequestContextProps,
    QueryState,
    WithChildren,
} from "../../../../_metronic/helpers"
import {TradeModel} from "../../../modules/trades/table/core/_models";
import {getTrades} from "../../../modules/trades/table/core/_requests";
import {useTradeModelListViewContext} from "./TradeListViewProvider";
import {PaginationDto} from "../../dto/pagination/PaginationDto";
import qs from "qs";
import {useSearchParams} from "react-router-dom";
import {useUserSettingsContext} from "../settings/UserSettingsDataProvider";
import {getQueryFilters, queryFiltersToString} from "../../utils/filter/FilterUtils";

const TradeQueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)
const TradeQueryResponseContext = createJsonApiResponseContext<TradeModel>(initialQueryResponse)

const TradeQueryRequestProvider: FC<WithChildren> = ({children}) => {
    const [state, setState] = useState<QueryState>(initialQueryRequest.state)

    const updateState = (updates: Partial<QueryState>) => {
        const updatedState = {...state, ...updates} as QueryState
        setState(updatedState)
    }
    return (
        <TradeQueryRequestContext.Provider value={{state, updateState}}>
            {children}
        </TradeQueryRequestContext.Provider>
    )
}

const TradeQueryResponseProvider: FC<WithChildren> = ({children}) => {
    const {state} = useTradeQueryRequest()
    //const {setCommonError} = useTradeModelListViewContext()
    const {pagination, setPagination} = useTradeModelListViewContext()
    const [searchParams] = useSearchParams()
    const {response: userSettings} = useUserSettingsContext()
    const [query, setQuery] = useState<string>('')
    const [accountId, setAccountId] = useState<ID>( userSettings?.settings?.account?.selectedId)
    const [pageSize, setPageSize] = useState<number>(userSettings?.settings?.pagination?.trades?.pageSize || 15)

    useEffect(() => {
        setAccountId(userSettings?.settings?.account?.selectedId)
    }, [userSettings?.settings?.account?.selectedId])

    useEffect(() => {
        setPageSize(userSettings?.settings?.pagination?.trades?.pageSize || 15)
    }, [userSettings?.settings?.pagination?.trades?.pageSize])

    const queryParams = qs.parse(window.location.search.replace('?', ''))
    const pageQuery = `page%5Bnumber%5D=${queryParams?.page || 1}&page%5Bsize%5D=${pageSize}`
    const filterQuery = queryFiltersToString(
        getQueryFilters(searchParams)
    )
    const queryRequest = [pageQuery, filterQuery].join('&')

    const {
        isFetching,
        refetch,
        //isError,
        //error,
        data: response,
    } = useQuery(
        `${QUERIES.TRADE_LIST}-${accountId}-${queryRequest}`,
        () => {
            return getTrades(accountId, queryRequest)
        },
        {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
    )

    useEffect(() => {
        setPagination({
            total: response?.meta?.page.total || 0,
            maxSize: response?.meta?.page.maxSize || 15,
        })
    }, [response])

    return (
        <TradeQueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query, pagination}}>
            {children}
        </TradeQueryResponseContext.Provider>
    )
}

const useTradeQueryRequest = () => useContext(TradeQueryRequestContext)

const useTradeQueryResponse = () => useContext(TradeQueryResponseContext)

const useTradeQueryResponseData = () => {
    const {response} = useTradeQueryResponse()

    if (!response) {
        return []
    }
    return response?.data?.map(function (value) {
        const trade = value.attributes
        trade.id = value.id as any

        return trade
    }) || []
}

const useTradeQueryPagination = (): PaginationDto => {
    const {response} = useTradeQueryResponse()

    return {
        total: response?.meta?.page.total || 0,
        maxSize: response?.meta?.page.maxSize || 25,
    }
}

const useTradeQueryResponseLoading = (): boolean => {
    const {isLoading} = useTradeQueryResponse()

    return isLoading
}

export {
    TradeQueryRequestProvider,
    TradeQueryResponseProvider,
    useTradeQueryRequest,
    useTradeQueryResponse,
    useTradeQueryResponseData,
    useTradeQueryPagination,
    useTradeQueryResponseLoading,
}
