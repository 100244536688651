import {useIntl} from "react-intl";
import React, {FC} from "react";
import {useThemeMode} from "../../../../../_metronic/partials";

type Props = {
    openType: string
}

const TradeOpenTypeCell: FC<Props> = (props) => {
    const intl = useIntl()
    const {isDark} = useThemeMode()
    const {openType} = props
    const getOpenTypeDescription = (openType: string) => {
        switch (openType) {
            case 'LEVEL_REBOUND':
                return intl.formatMessage({id: 'TRADES.OPEN_TYPE.LEVEL_REBOUND'});
            case 'LEVEL_BREAKDOWN':
                return intl.formatMessage({id: 'TRADES.OPEN_TYPE.LEVEL_BREAKDOWN'});
            case 'LEVEL_RETEST':
                return intl.formatMessage({id: 'TRADES.OPEN_TYPE.LEVEL_RETEST'});
            case 'FALSE_BREAKDOWN_TWO_BARS':
                return intl.formatMessage({id: 'TRADES.OPEN_TYPE.FALSE_BREAKDOWN_TWO_BARS'});
            case 'FALSE_BREAKDOWN_MANY_BARS':
                return intl.formatMessage({id: 'TRADES.OPEN_TYPE.FALSE_BREAKDOWN_MANY_BARS'});
            case 'FALSE_BREAKDOWN':
                return intl.formatMessage({id: 'TRADES.OPEN_TYPE.FALSE_BREAKDOWN'});
            case 'TILT':
                return intl.formatMessage({id: 'TRADES.OPEN_TYPE.TILT'});
            case 'OTHER':
                return intl.formatMessage({id: 'TRADES.OPEN_TYPE.OTHER'});
            default:
                return intl.formatMessage({id: 'TRADES.OPEN_TYPE.UNKNOWN'});
        }
    }
    return (
        <div className={'badge badge-light fw-normal ' + (isDark ? 'text-gray-800' : 'text-gray-600')}>
            {getOpenTypeDescription(openType)}
        </div>
    )
}

export {TradeOpenTypeCell}
