/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useContext, useEffect, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import {
    createJsonApiResponseContext,
    ID,
    initialQueryResponse,
    QUERIES,
    stringifyRequestQuery,
    WithChildren,
} from "../../../../../_metronic/helpers"
import {getAccountDepositHistory, getAccounts} from './_requests'
import {AccountModel} from './_models'
import {useAccountQueryRequest} from './QueryRequestProvider'

const AccountQueryResponseContext = createJsonApiResponseContext<AccountModel>(initialQueryResponse)

const AccountQueryResponseProvider: FC<WithChildren> = ({children}) => {
    const {state} = useAccountQueryRequest()
    const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
    const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

    useEffect(() => {
        if (query !== updatedQuery) {
            setQuery(updatedQuery)
        }
    }, [updatedQuery])

    const {
        isFetching,
        refetch,
        data: response,
    } = useQuery(
        `${QUERIES.ACCOUNT_LIST}-${query}`,
        () => {
            return getAccounts(query)
        },
        {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
    )
    return (
        <AccountQueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
            {children}
        </AccountQueryResponseContext.Provider>
    )
}

const useAccountQueryResponse = () => useContext(AccountQueryResponseContext)

const useAccountQueryResponseData = () => {
    const {response} = useAccountQueryResponse()

    if (!response) {
        return []
    }
    return response?.data?.map(function (value, index) {
        const item = value.attributes
        item.id = value.id as any

        return item
    }) || []
}

const useAccountDepositHistoryResponseData = (accountId: ID) => {
    const {
        //isFetching,
        //refetch,
        data: response,
    } = useQuery(
        `${QUERIES.ACCOUNT_LIST}-deposits-history`,
        () => {
            return getAccountDepositHistory(accountId)
        },
        {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
    )

    if (!response) {
        return []
    }
    return response?.data?.map(function (value, index) {
        const depositHistory = value.attributes
        depositHistory.id = value.id as any

        return depositHistory
    }) || []
}

const useAccountQueryResponseLoading = (): boolean => {
    const {isLoading} = useAccountQueryResponse()
    return isLoading
}

export {
    AccountQueryResponseProvider,
    useAccountQueryResponse,
    useAccountQueryResponseData,
    useAccountDepositHistoryResponseData,
    useAccountQueryResponseLoading,
}
