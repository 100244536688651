import {UsersListWrapper} from './users-list/UsersList'
import {useIntl} from "react-intl";
import React, {useEffect} from "react";
import {useAuth} from "../auth";
import {PageLink, PageTitle} from "../../../_metronic/layout/core";
import {MetaHeader} from "../../common/component/meta/MetaHeader";

const UsersPage = () => {
    const intl = useIntl()
    const {currentUser} = useAuth()
    const usersBreadcrumbs: Array<PageLink> = [
        {
            title: intl.formatMessage({id: 'BREADCRUMB.USER.MANAGEMENT'}),
            path: '/apps/user-management/users',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]

    useEffect(() => {
        document.getElementById('kt_content_container')?.classList.remove('container')
        document.getElementById('kt_content_container')?.classList.add('container-fluid')

        return () => {
            document.getElementById('kt_content_container')?.classList.remove('container-fluid')
            document.getElementById('kt_content_container')?.classList.add('container')
        }
    }, [])

    return (
        <>
            <MetaHeader pageName='HTML.HEAD.TITLE.USERS'/>
            <PageTitle breadcrumbs={usersBreadcrumbs}>
                {intl.formatMessage({id: 'BREADCRUMB.USER.LIST'})}
            </PageTitle>
            {currentUser?.role === 'ADMIN' && <UsersListWrapper/>}
            {currentUser?.role !== 'ADMIN' && <h2>You are not allowed to view this page!</h2>}
        </>
    )
}

export default UsersPage
