import {useIntl} from "react-intl";
import React, {FC} from "react";
import {useThemeMode} from "../../../../../_metronic/partials";

type Props = {
    closeType: string
}

const TradeCloseTypeCell: FC<Props> = (props) => {
    const intl = useIntl()
    const {isDark} = useThemeMode()
    const {closeType} = props
    const getCloseTypeDescription = (closeType: string) => {
        switch (closeType) {
            case 'TAKE_PROFIT':
                return {
                    label: intl.formatMessage({id: 'TRADES.CLOSE_TYPE.TAKE_PROFIT'}),
                    style: 'badge-light-success'
                }
            case 'STOP_LOSS':
                return {
                    label: intl.formatMessage({id: 'TRADES.CLOSE_TYPE.STOP_LOSS'}),
                    style: 'badge-light-danger'
                }
            case 'BREAKEVEN':
                return {
                    label: intl.formatMessage({id: 'TRADES.CLOSE_TYPE.BREAKEVEN'}),
                    style: 'badge-light'
                }
            case 'OTHER':
                return {
                    label: intl.formatMessage({id: 'TRADES.CLOSE_TYPE.OTHER'}),
                    style: 'badge-light'
                }
            default:
                return {
                    label: intl.formatMessage({id: 'TRADES.CLOSE_TYPE.UNKNOWN'}),
                    style: 'badge-light'
                }
        }
    }
    const closeTypeDescription = getCloseTypeDescription(closeType)

    return (
        <div className={'badge fw-normal ' + closeTypeDescription.style + ' ' + (isDark ? 'text-gray-800' : '')}>
            {closeTypeDescription.label}
        </div>
    )
}

export {TradeCloseTypeCell}
