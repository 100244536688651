import {useIntl} from "react-intl";
import {useSearchParams} from "react-router-dom";
import React, {FC, useEffect, useState} from "react";
import {PaginationDto} from "../../dto/pagination/PaginationDto";
import {UserSettingsMapper} from "../../mapper/settings/UserSettingsMapper";
import {useUserSettingsContext} from "../../provider/settings/UserSettingsDataProvider";
import {updateUserSettings} from "../../request/settings/UserSettingsRequest";

type Props = {
    storageKey: string
    pagination: PaginationDto | undefined
    refetch: Function
    isLoading: boolean
    pageSizes?: Array<number> | undefined
    defaultPageSize?: number | undefined
}

const Pagination: FC<Props> = (props) => {
    const MAX_PAGES = 8
    const intl = useIntl()
    const {refetch, pagination, storageKey, isLoading} = props
    const {pageSizes: sizes, defaultPageSize: defaultSize} = props
    const pageSizes = (sizes ? sizes : [5, 15, 25, 50, 100])
    const defaultPageSize = (defaultSize ? defaultSize : 15)
    const [searchParams, setSearchParams] = useSearchParams()
    const [currentPage, setCurrentPage] = useState<number>(parseInt(searchParams.get("page") || '1'))
    const {response: userSettings} = useUserSettingsContext()
    const [currentPageSize, setCurrentPageSize] = useState<number>(userSettings?.settings?.pagination?.trades?.pageSize || defaultPageSize)
    const userSettingsMapper = new UserSettingsMapper()

    let totalPages = Math.ceil((pagination?.total || 0) / (pagination?.maxSize || 1))

    useEffect(() => {
        if (currentPage > totalPages) {
            setCurrentPage(1)
            setSearchParams({page: '1'})
        }
    }, [currentPage, totalPages])

    useEffect(() => {
        setCurrentPageSize(userSettings?.settings?.pagination?.trades?.pageSize || defaultPageSize)
    }, [userSettings])

    if (!pagination) {
        return null
    }

    if (totalPages < 1) {
        totalPages = 1
    }
    const pages = Array<number>()
    let startIndex = (currentPage > MAX_PAGES - 4 ? currentPage - 4 : 1)

    if (startIndex === 0) {
        startIndex = 1
    }

    for (let i = startIndex; i < startIndex + MAX_PAGES; ++i) {
        if (i < totalPages) {
            pages.push(i)
        }
    }
    const onPageChange = (page: number) => {
        if (isLoading) {
            return
        }

        if (page > 0 && page !== currentPage && page <= totalPages) {
            setCurrentPage(page)
            setSearchParams({page: page + '', size: currentPageSize + ''})

            refetch()
        }
    }
    const onPageSizeChange = (pageSize: number) => {
        if (isLoading) {
            return
        }
        setCurrentPageSize(pageSize)
        setSearchParams({page: currentPage + '', size: pageSize + ''})

        const updatedUserSettings = userSettingsMapper.updatePaginationPageSize(userSettings, storageKey, pageSize)

        updateUserSettings(updatedUserSettings)

        refetch()
    }
    let showStartEntry = (currentPage - 1) * pagination.maxSize + (currentPage < 2 ? 1 : 0)
    let showEndEntry = showStartEntry + pagination?.maxSize - (currentPage < 2 ? 1 : 0)

    if (showEndEntry > pagination.total) {
        showEndEntry = pagination.total
    }

    if (showEndEntry < 1){
        showStartEntry = 0
    }
    return (
        <div className='d-flex flex-stack flex-wrap pt-10'>
            <div
                className='fs-6 text-gray-700'>
                {intl.formatMessage({id: 'TABLE.PAGINATION'})
                    .replace("%FROM%", showStartEntry.toString())
                    .replace("%TO%", showEndEntry.toString())
                    .replace('%TOTAL%', pagination.total.toString())
                }
            </div>

            <ul className='pagination'>
                <li>
                    <select className="form-select form-select-sm form-select-solid cursor-pointer"
                            value={currentPageSize}
                            onChange={(event) => onPageSizeChange(parseInt(event.currentTarget.value || defaultPageSize.toString()))}>
                        {pageSizes.map(pageSize => (
                            <option value={pageSize} key={pageSize}>{pageSize}</option>
                        ))}
                    </select>
                </li>
                <li className='page-item'></li>
                <li className='page-item'></li>
                <li className='page-item'></li>
                <li className='page-item'></li>
                <li className={'page-item previous ' + (currentPage > 1 ? 'cursor-pointer' : 'disabled')}>
                    <span className='page-link' onClick={() => onPageChange(currentPage - 1)}>
                        <i className='previous'></i>
                    </span>
                </li>

                {startIndex > 1 && (
                    <>
                        <li key={totalPages}
                            className={"page-item cursor-pointer " + (1 !== currentPage ? "" : "page-item active")}>
                                <span className='page-link' onClick={() => onPageChange(1)}>
                                    {1}
                                </span>
                        </li>
                        <li key={totalPages}
                            className={"page-item cursor-pointer"}>
                                <span className='page-link'>
                                    ...
                                </span>
                        </li>
                    </>
                )}

                {pages.map(page => (
                    <li key={page}
                        className={"page-item cursor-pointer " + (page !== currentPage ? "" : "page-item active")}>
                        <span className='page-link' onClick={() => onPageChange(page)}>
                            {page}
                        </span>
                    </li>
                ))}

                {totalPages > MAX_PAGES && currentPage < (totalPages - 4) && (
                    <>
                        <li key={totalPages}
                            className={"page-item cursor-pointer"}>
                                <span className='page-link'>
                                    ...
                                </span>
                        </li>
                    </>
                )}

                {currentPage <= totalPages && (
                    <li key={totalPages}
                        className={"page-item cursor-pointer " + (totalPages !== currentPage ? "" : "page-item active")}>
                        <span className='page-link' onClick={() => onPageChange(totalPages)}>
                            {totalPages}
                        </span>
                    </li>
                )}

                <li className={'page-item next ' + (currentPage < totalPages ? 'cursor-pointer' : 'disabled')}>
                    <span className='page-link cursor-pointer' onClick={() => onPageChange(currentPage + 1)}>
                        <i className='next'></i>
                    </span>
                </li>
            </ul>
        </div>
    )
}

export {Pagination}
