import clsx from 'clsx'
import {FC, PropsWithChildren, useMemo} from 'react'
import {HeaderProps} from 'react-table'
import {TradeModel} from "../core/_models";
import {initialQueryState} from "../../../../../_metronic/helpers";
import {useTradeQueryRequest} from "../../../../common/provider/trade/TradeDataProvider";
import {useIntl} from "react-intl";
import {useThemeMode} from "../../../../../_metronic/partials";

type Props = {
    className?: string
    title?: string
    tableProps: PropsWithChildren<HeaderProps<TradeModel>>
}
const TradeCustomHeader: FC<Props> = ({className, title, tableProps}) => {
    const intl = useIntl()
    const {isDark} = useThemeMode()
    const id = tableProps.column.id
    const {state, updateState} = useTradeQueryRequest()
    const sortFields = [
        "ticker",
        "direction",
        "openDate",
        "openType",
        "closeDate",
        "closeType",
    ]
    const isSelectedForSorting = useMemo(() => {
        return state.sort && state.sort === id
    }, [state, id])

    const order: 'asc' | 'desc' | undefined = useMemo(() => state.order, [state])

    const isColumnSortable = (column: string) => {
        return sortFields.indexOf(column) > -1
    }

    const sortColumn = () => {
        // avoid sorting for these columns
        if (!isColumnSortable(id)) {
            return
        }

        if (!isSelectedForSorting) {
            // enable sort asc
            updateState({sort: id, order: 'asc', ...initialQueryState})
            return
        }

        if (isSelectedForSorting && order !== undefined) {
            if (order === 'asc') {
                // enable sort desc
                updateState({sort: id, order: 'desc', ...initialQueryState})
                return
            }

            // disable sort
            updateState({sort: undefined, order: undefined, ...initialQueryState})
        }
    }

    return (
        <th
            {...tableProps.column.getHeaderProps()}
            className={clsx(
                (isDark ? 'text-gray-800' : 'text-gray-700'),
                className,
                isSelectedForSorting && order !== undefined && `table-sort-${order}`,
                isColumnSortable(id) && 'cursor-pointer',
            )}
            onClick={sortColumn}
        >
            {intl.formatMessage({id: title})}
        </th>
    )
}

export {TradeCustomHeader}
