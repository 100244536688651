/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteSlmSoftStaticUrl, toAbsoluteStaticUrl} from '../../../_metronic/helpers'
import {Link, useLocation} from 'react-router-dom'
import {useAuth} from "../auth";
import {useAccountQueryResponseData} from "../accounts/accounts-list/core/QueryResponseProvider";
import {useIntl} from "react-intl";
import CountUp from "react-countup";
import {UserStatisticsModel} from "../user/users-list/core/_models";
import {getUserStatisticsById} from "../user/users-list/core/_requests";

const ProfileHeader: React.FC = () => {
    const location = useLocation()
    const intl = useIntl()
    const {currentUser} = useAuth()
    const accounts = useAccountQueryResponseData()
    const [userStatistics, setUserStatistics] = useState<UserStatisticsModel>({
        totalProfitTrades: 0,
        totalLossTrades: 0,
        totalBreakevenTrades: 0,
        profitRate: 0,
    })

    useEffect(() => {
        getUserStatisticsById(currentUser?.id).then(response => {
            if (response?.data?.attributes) {
                const data = response?.data?.attributes
                data.profitRate = (userStatistics?.totalProfitTrades || 0) / (userStatistics?.totalLossTrades || 1)

                setUserStatistics(data)
            }
        })
    }, [userStatistics.profitRate])

    return (
        <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
                <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                    <div className='me-7 mb-4'>
                        <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                            <img src={toAbsoluteSlmSoftStaticUrl(`/static/users/${currentUser?.profileImageId}`)} alt='Metornic'/>
                            <div
                                className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                        </div>
                    </div>

                    <div className='flex-grow-1'>
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                            <div className='d-flex flex-column'>
                                <div className='d-flex align-items-center mb-2'>
                                    <a href='#' className='text-muted text-hover-primary fs-2 me-1'>
                                        {currentUser?.username}
                                    </a>
                                    <a href='#'>
                                        <KTSVG
                                            path='/media/icons/duotune/general/gen026.svg'
                                            className='svg-icon-1 svg-icon-primary'
                                        />
                                    </a>
                                    <a href="#" className="btn btn-sm btn-light-success ms-2 fs-8 py-1 px-3"
                                       data-bs-toggle="modal" data-bs-target="#kt_modal_upgrade_plan">
                                        {currentUser?.subscription?.type === 'PREMIUM' && 'Premium Subscription'}
                                        {currentUser?.subscription?.type === 'PRO' && 'Pro Subscription'}
                                        {currentUser?.subscription?.type === 'BASIC' && 'Basic Subscription'}
                                        {currentUser?.subscription?.type === 'FREE' && 'Free Subscription'}
                                    </a>
                                </div>

                                <div className='d-flex flex-wrap fs-6 mb-4 pe-2'>
                                    <a
                                        href='#'
                                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                                    >
                                        <KTSVG
                                            path='/media/icons/duotune/communication/com006.svg'
                                            className='svg-icon-4 me-1'
                                        />
                                        {currentUser?.role}
                                    </a>
                                    <a
                                        href='#'
                                        className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                                    >
                                        <KTSVG
                                            path='/media/icons/duotune/communication/com011.svg'
                                            className='svg-icon-4 me-1'
                                        />
                                        {currentUser?.email}
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex flex-wrap flex-stack'>
                            <div className='d-flex flex-column flex-grow-1 pe-8'>
                                <div className='d-flex flex-wrap'>
                                    <div
                                        className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                        <div className='d-flex align-items-center'>
                                            <div className='fs-2 text-muted'>
                                                <CountUp end={accounts.length} duration={1.5} />
                                            </div>
                                        </div>

                                        <div className='fs-6 text-gray-400'>
                                            {intl.formatMessage({id: "PROFILE.DASHBOARD.ACCOUNTS"})}
                                        </div>
                                    </div>

                                    <div
                                        className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                        <div className='d-flex align-items-center'>
                                            <div className='fs-2 text-muted'>
                                                <CountUp end={userStatistics.totalProfitTrades || 0} duration={1.5} />
                                            </div>
                                        </div>

                                        <div className='fs-6 text-gray-400'>
                                            {intl.formatMessage({id: "PROFILE.DASHBOARD.PROFIT_TRADES"})}
                                        </div>
                                    </div>

                                    <div
                                        className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                        <div className='d-flex align-items-center'>
                                            <div className='fs-2 text-muted'>
                                                <CountUp end={userStatistics.totalLossTrades || 0} duration={1.5} />
                                            </div>
                                        </div>

                                        <div className='fs-6 text-gray-400'>
                                            {intl.formatMessage({id: "PROFILE.DASHBOARD.LOSS_TRADES"})}
                                        </div>
                                    </div>

                                    <div
                                        className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                        <div className='d-flex align-items-center'>
                                            <div className='fs-2 text-muted'>
                                                <CountUp end={userStatistics.totalBreakevenTrades || 0} duration={1.5} />
                                            </div>
                                        </div>

                                        <div className='fs-6 text-gray-400'>
                                            {intl.formatMessage({id: "PROFILE.DASHBOARD.BREAKEVEN_TRADES"})}
                                        </div>
                                    </div>

                                    <div
                                        className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                        <div className='d-flex align-items-center'>
                                            <div className='fs-2 text-muted'>
                                                <CountUp end={userStatistics?.profitRate || 0.0} decimals={2} duration={1.5} />
                                                %
                                            </div>
                                        </div>

                                        <div className='fs-6 text-gray-400'>
                                            {intl.formatMessage({id: "PROFILE.DASHBOARD.PROFIT_RATE"})}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='d-flex overflow-auto h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 flex-nowrap'>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/profile/overview' && 'active')
                                }
                                to='/profile/overview'
                            >
                                {intl.formatMessage({id: "PROFILE.LINK.OVERVIEW"})}
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/profile/edit' && 'active')
                                }
                                to='/profile/edit'
                            >
                                {intl.formatMessage({id: "PROFILE.LINK.EDIT_PROFILE"})}
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export {ProfileHeader}
