import {useListView} from '../../core/ListViewProvider'
import {UsersListFilter} from './UsersListFilter'
import {useIntl} from "react-intl";
import {KTSVG} from "../../../../../../_metronic/helpers";

const UsersListToolbar = () => {
    const {setItemIdForUpdate} = useListView()
    const openAddUserModal = () => {
        setItemIdForUpdate(null)
    }
    const intl = useIntl()

    return (
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
            <UsersListFilter/>

            {/* begin::Add user */}
            <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2'/>
                {intl.formatMessage({id: 'USER.ADD'})}
            </button>
            {/* end::Add user */}
        </div>
    )
}

export {UsersListToolbar}
