import React, {useEffect, useState} from 'react'
import {ID, KTSVG} from "../../../../../_metronic/helpers";
import {useMutation} from "react-query";
import axios, {AxiosResponse} from "axios";
import {AccountDepositHistoryModel, JsonApiAccountDepositHistoryModel} from "../core/_models";
import moment from "moment";
import {JsonApiErrorsModel} from "../../../_models";
import {useIntl} from "react-intl";
import {useAccountListView} from "../../../../common/provider/account/AccountListViewProvider";
import Swal from "sweetalert2";

const API_URL = process.env.REACT_APP_API_URL
const ACCOUNT_URL = `${API_URL}/accounts`

const AccountDepositHistoryModal = () => {
    const intl = useIntl()
    const {setItemIdForDepositHistory} = useAccountListView()
    const [error, setError] = useState<JsonApiErrorsModel | undefined>(undefined)

    useEffect(() => {
        document.body.classList.add('modal-open')

        return () => {
            document.body.classList.remove('modal-open')
        }
    }, [])

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered mw-950px'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>
                        <div className='modal-header'>
                            {/* begin::Modal title */}
                            <h2>
                                {intl.formatMessage({id: 'ACCOUNTS.MODAL.DEPOSIT.HISTORY.TITLE'})}
                            </h2>
                            {/* end::Modal title */}

                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => setItemIdForDepositHistory(undefined)}
                                style={{cursor: 'pointer'}}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                            </div>
                            {/* end::Close */}
                        </div>
                        {/* begin::Modal body */}
                        <div className='modal-body mx-5 mx-xl-18 pt-8 pb-15'>
                            {/* begin::Body */}
                            {error && (
                                <div className="mb-lg-15 alert alert-danger">
                                    <div className="alert-text font-weight-bold">{error.detail}</div>
                                </div>
                            )}
                            <DepositHistoryItems onError={setError}/>
                            {/* end: Card Body */}
                        </div>
                        {/* end::Modal body */}
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}
        </>
    )
}

const DepositHistoryItems = ({onError}: any) => {
    const intl = useIntl()
    const {itemIdForDepositHistory} = useAccountListView()
    const [data, setData] = useState<AccountDepositHistoryModel[]>([])

    const deleteDepositHistoryItem = (accountId: ID, historyId: ID | undefined): Promise<void> => {
        return axios.delete(`${ACCOUNT_URL}/${accountId}/deposits/history/${historyId}`)
            .catch(error => onError(error.response.data.errors[0]))
    }
    let id: ID

    const getAllDepositHistoriesRequest = () => {
        axios.get(`${ACCOUNT_URL}/${itemIdForDepositHistory}/deposits/history`)
            .then((response: AxiosResponse<JsonApiAccountDepositHistoryModel>) => {
                if (!response) {
                    return
                }
                const depositHistories = response?.data?.data?.map(function (value, index) {
                    const depositHistory = value.attributes
                    depositHistory.id = value.id as any

                    return depositHistory
                }) || []

                setData(depositHistories);
            })
    }
    const deleteDepositHistoryItemMutation = useMutation(() => deleteDepositHistoryItem(itemIdForDepositHistory, id),
        {onSuccess: () => getAllDepositHistoriesRequest()})

    useEffect(() => getAllDepositHistoriesRequest(), [])

    return (
        <div className="mh-450px scroll-y me-n7 pe-7">
            <div className='timeline-label'>
                {data.map(depositHistory => (
                    <div className='timeline-item' key={depositHistory.id}>
                        {/* begin::Label */}
                        <div className='timeline-label text-gray-800 fs-6'>
                            {moment(depositHistory.dateAdd).format('YYYY-MM-DD HH:MM')}
                        </div>
                        {/* end::Label */}
                        {/* begin::Badge */}
                        <div className='timeline-badge'>
                            <i className={depositHistory.changeValue && depositHistory.changeValue >= 0
                                ? 'fa fa-genderless text-success fs-1'
                                : 'fa fa-genderless text-danger fs-1'}
                            >
                            </i>
                        </div>
                        {/* end::Badge */}
                        {/* begin::Text */}
                        <div className='timeline-content d-flex'>
                            <span className='text-gray-800 ps-3'>
                                {depositHistory.changeValue ? (
                                    <span>
                                        {depositHistory.changeValue >= 0.0 ? intl.formatMessage({id: 'ACCOUNTS.MODAL.DEPOSIT.HISTORY.INCOME_VALUE'}) : intl.formatMessage({id: 'ACCOUNTS.MODAL.DEPOSIT.HISTORY.OUTCOME_VALUE'})}
                                        : {depositHistory.changeValue}
                                        <span className='text-muted fs-7 p-2'>
                                            {depositHistory.comment && depositHistory.comment.length > 0 && `(${depositHistory.comment})`}
                                        </span>
                                    </span>
                                ) : ''}
                            </span>
                        </div>
                        <div>
                            <a href="#"
                               className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                               onClick={async (e) => {
                                   e.preventDefault()

                                   Swal.fire({
                                       html: intl.formatMessage({id: 'ACCOUNTS.MODAL.DEPOSIT.HISTORY.MODAL.QUESTION.DELETE.BODY'}) + '?',
                                       icon: 'warning',
                                       showCancelButton: true,
                                       confirmButtonColor: '#d33',
                                       cancelButtonColor: '#3085d6',
                                       confirmButtonText: intl.formatMessage({id: 'ACCOUNTS.MODAL.DEPOSIT.HISTORY.MODAL.QUESTION.DELETE.BUTTON.YES'}),
                                       cancelButtonText: intl.formatMessage({id: 'ACCOUNTS.MODAL.DEPOSIT.HISTORY.MODAL.QUESTION.DELETE.BUTTON.NO'}),
                                   }).then((result) => {
                                       if (result.isConfirmed && depositHistory.id) {
                                           id = depositHistory.id

                                           deleteDepositHistoryItemMutation.mutateAsync()
                                       }
                                   })
                               }}
                            >
                                <span className="svg-icon svg-icon-3">
                                    <svg width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" xmlns="http://www.w3.org/2000/svg"
                                         className="mh-50px"><path
                                        d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                        fill="currentColor"></path><path opacity="0.5"
                                                                         d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                                                         fill="currentColor"></path><path opacity="0.5"
                                                                                                          d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                                                                                          fill="currentColor"></path>
                                    </svg>
                                </span>
                            </a>
                        </div>
                        {/* end::Text */}
                    </div>
                ))}
            </div>
            {data.length === 0 && (
                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {intl.formatMessage({id: 'ACCOUNTS.MODAL.DEPOSIT.HISTORY.HISTORY_EMPTY'})}
                </div>
            )}
        </div>
    )
}

export {AccountDepositHistoryModal}
