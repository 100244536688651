const

enum JsonApiQueryFilterField {
    SYMBOL = 'symbol',
    SIDE = 'side',
    OPEN_TYPE = 'openType',
    CLOSE_TYPE = 'closeType',
    OPEN_DATE = 'openDate',
    CLOSE_DATE = 'closeDate',
    STATE = 'state',
    TAGS = 'tags',
    HOURS = 'hours',
    DAYS = 'days',
}

enum JsonApiQueryFilterOperator {
    CONTAIN = 'CONTAIN',
    NOT_CONTAIN = 'NOT_CONTAIN',
    EQ = 'EQ',
    NE = 'NE',
    IN = 'IN',
    NOT_IN = 'NOT_IN',
}

export {JsonApiQueryFilterField, JsonApiQueryFilterOperator}
