import {ID} from "../../../../_metronic/helpers";
import axios, {AxiosResponse} from "axios";
import {EmptyJsonApiResponseDto, JsonApiObjectDto} from "../../dto/json-api/JsonApiDto";
import {AccountDto} from "../../dto/account/AccountDto";

const API_URL = process.env.REACT_APP_API_URL
const ACCOUNT_URL = `${API_URL}/accounts`

const getAccountById = (accountId: ID): Promise<JsonApiObjectDto<AccountDto>> => {
    if (!accountId) {
        return Promise.resolve(EmptyJsonApiResponseDto)
    }
    return axios.get(`${ACCOUNT_URL}/${accountId}`)
        .then((response: AxiosResponse<JsonApiObjectDto<AccountDto>>) => response?.data)
}

export {getAccountById}