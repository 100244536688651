import axios, {AxiosResponse} from 'axios'
import {JsonApiTradeArrayDto} from "../../dto/trade/TradeDto";
import {ID} from "../../../../_metronic/helpers";

const API_URL = process.env.REACT_APP_API_URL
const TRADE_URL = `${API_URL}/accounts`

const getAllTrades = (accountId: ID, query: string): Promise<JsonApiTradeArrayDto> => {
    return axios.get(`${TRADE_URL}/${accountId}/trades?${query}`)
        .then((response: AxiosResponse<JsonApiTradeArrayDto>) => response.data)
}

const deleteTradeMediaById = (accountId: ID, tradeId: ID, mediaId: ID): Promise<void> => {
    return axios.delete(`${TRADE_URL}/${accountId}/trades/${tradeId}/medias/${mediaId}`)
}

export {getAllTrades, deleteTradeMediaById}