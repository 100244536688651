import React, {FC} from "react";
import {TradeTagDto} from "../core/_models";
import {useThemeMode} from "../../../../../_metronic/partials";

type Props = {
    tags: Array<TradeTagDto>
}

const TradeTagCell: FC<Props> = (props) => {
    const {isDark} = useThemeMode()
    const {tags} = props

    tags.sort((a, b) => {
        if (a.name && b.name) {
            return a.name.localeCompare(b.name)
        }
        return 0
    })

    return (
        <>
            {tags.map((tag: TradeTagDto) => (
                <span key={tag.id}>
                    <div className={"badge badge-light me-lg-1 fw-normal " + (isDark ? 'text-gray-800' : 'text-gray-700')}>{tag.name}</div>
                </span>
            ))}
        </>
    )
}

export {TradeTagCell}
