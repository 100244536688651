const PricingFree = () => {
    return (
        <div className="mt-sm-n20">
            { /*begin::Curve top*/ }
            <div className="landing-curve landing-dark-color">
                <svg viewBox="15 -1 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                        fill="currentColor"></path>
                </svg>
            </div>
            { /*end::Curve top*/ }
            { /*begin::Wrapper*/ }
            <div className="py-20 landing-dark-bg">
                { /*begin::Container*/ }
                <div className="container">
                    { /*begin::Plans*/ }
                    <div className="d-flex flex-column container pt-lg-20">
                        { /*begin::Heading*/ }
                        <div className="mb-13 text-center">
                            <h1 className="fs-2hx fw-bolder text-white mb-5" id="pricing"
                                data-kt-scroll-offset="{default: 100, lg: 150}">Free-forever features for users</h1>
                        </div>
                        { /*end::Heading*/ }
                        { /*begin::Pricing*/ }
                        <div className="text-center" id="kt_pricing">
                            { /*begin::Row*/ }
                            <div className="row g-10">

                            </div>
                            { /*end::Row*/ }
                        </div>
                        { /*end::Pricing*/ }
                    </div>
                    { /*end::Plans*/ }
                </div>
                { /*end::Container*/ }
            </div>
            { /*end::Wrapper*/ }
            { /*begin::Curve bottom*/ }
            <div className="landing-curve landing-dark-color">
                <svg viewBox="15 12 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                        fill="currentColor"></path>
                </svg>
            </div>
            { /*end::Curve bottom*/ }
        </div>
    )
}

export {PricingFree}
