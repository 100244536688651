import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {ProfileHeader} from './ProfileHeader'
import React from "react";
import {Overview} from "./components/Overview";
import {Settings} from "./components/settings/Settings";
import {AccountQueryRequestProvider} from "../accounts/accounts-list/core/QueryRequestProvider";
import {AccountQueryResponseProvider} from "../accounts/accounts-list/core/QueryResponseProvider";
import {MetaHeader} from "../../common/component/meta/MetaHeader";
import {useIntl} from "react-intl";
import {AccountListViewProvider} from "../../common/provider/account/AccountListViewProvider";

const ProfilePage = () => {
    const intl = useIntl()
    const profileBreadCrumbs: Array<PageLink> = [
        {
            title: intl.formatMessage({id: "PROFILE.LINK.OVERVIEW"}),
            path: '/profile',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]
    return (
        <>
            <MetaHeader pageName='HTML.HEAD.TITLE.PROFILE'/>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Overview</PageTitle>
                    <div className="post d-flex flex-column-fluid" id="kt_post">
                        <div id="kt_content_container" className="container-xxl">
                            <Routes>
                                <Route
                                    element={
                                        <>
                                            <AccountQueryRequestProvider>
                                                <AccountQueryResponseProvider>
                                                    <AccountListViewProvider>
                                                        <ProfileHeader />
                                                        <Outlet />
                                                    </AccountListViewProvider>
                                                </AccountQueryResponseProvider>
                                            </AccountQueryRequestProvider>
                                        </>
                                    }
                                >
                                    <Route
                                        path='overview'
                                        element={
                                            <>
                                                <PageTitle breadcrumbs={profileBreadCrumbs}>
                                                    {intl.formatMessage({id: "PROFILE.LINK.OVERVIEW"})}
                                                </PageTitle>
                                                <Overview />
                                            </>
                                        }
                                    />
                                    <Route
                                        path='edit'
                                        element={
                                            <>
                                                <PageTitle breadcrumbs={profileBreadCrumbs}>
                                                    {intl.formatMessage({id: "PROFILE.LINK.EDIT_PROFILE"})}
                                                </PageTitle>
                                                <Settings />
                                            </>
                                        }
                                    />
                                    <Route index element={<Navigate to='/profile/overview' />} />
                                </Route>
                            </Routes>
                        </div>
                    </div>
        </>
    )
}

export default ProfilePage
