import {FC} from "react";
import {getCurrencySymbol} from "../../../common/utils/currency/CurrencyUtils";
import {useIntl} from "react-intl";
import {PercentChangeWidget} from "./PercentChangeWidget";
import CountUp from "react-countup";
import {WidgetKey} from "../../../common/dto/dashboard/WidgetDto";
import {useThemeMode} from "../../../../_metronic/partials";

type Props = {
    selectedWidgets: {[key: string]: boolean},
    value: number
    previousValue: number
    currency: string
}

const WidgetMaxDrawdown: FC<Props> = ({selectedWidgets, value, previousValue, currency}) => {
    const intl = useIntl()
    const {isDark} = useThemeMode()
    const darkStyle = (isDark ? "widget-dark" : "")

    if (!selectedWidgets[WidgetKey.MAX_DRAWDOWN]) {
        return null
    }
    return (
        <div className="col-sm-4 col-xl-2 mb-xl-10">
            <div className="card h-lg-100">
                <div className="card-body d-flex justify-content-between align-items-start flex-column">
                    {/*begin::Icon*/}
                    <div className="m-0">
                        {/*begin::Svg Icon | path: icons/50-finance-icons-red/max-drawdown.svg*/}
                        <span className="svg-icon svg-icon-2hx svg-icon-gray-600">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 201 241.69"><defs><style>{`.max-drawdown-1,.max-drawdown-2{fill:none;stroke:#344154;stroke-linecap:round;stroke-width:5.5px;}.max-drawdown-1{stroke - miterlimit:10;}.max-drawdown-2{stroke - linejoin:round;}.max-drawdown-3{fill:#009ef7;}`}</style></defs><g><g id="Icon_Set" data-name="Icon Set">
                                <line className={"max-drawdown-1 " + darkStyle} x1="27.82" y1="38.97" x2="132.33" y2="38.97"/><polyline
                                className={"max-drawdown-1 " + darkStyle} points="178.7 2.75 178.7 124.5 59.7 124.5"/><path className={"max-drawdown-1 " + darkStyle}
                                                                                                    d="M81.81,104a31.49,31.49,0,0,1,0-44.52"/><path
                                className={"max-drawdown-1 " + darkStyle} d="M126.33,59.47a31.47,31.47,0,0,1,0,44.52"/><path className={"max-drawdown-1 " + darkStyle}
                                                                                                     d="M153.07,83.86a1.79,1.79,0,1,1,1.79,1.79A1.79,1.79,0,0,1,153.07,83.86Z"/><path
                                className={"max-drawdown-1 " + darkStyle} d="M51.49,83.86a1.79,1.79,0,1,1,1.79,1.79A1.79,1.79,0,0,1,51.49,83.86Z"/><path className={"max-drawdown-1 " + darkStyle}
                                                                                                                                 d="M95.37,90.4a8.69,8.69,0,0,0,17.38,0c0-4.8-3.56-7-8.69-8.69-4.56-1.49-8.69-3.89-8.69-8.69a8.69,8.69,0,1,1,17.38,0"/><line
                                className={"max-drawdown-1 " + darkStyle} x1="104.07" y1="59.47" x2="104.07" y2="64.38"/><line className={"max-drawdown-1 " + darkStyle} x1="104.07" y1="99.09"
                                                                                                       x2="104.07" y2="104"/><polyline
                                className={"max-drawdown-1 " + darkStyle} points="178.7 58.56 198.25 58.56 198.25 144.09 59.7 144.09"/><line className={"max-drawdown-1 " + darkStyle} x1="162.14"
                                                                                                                     y1="13.63" x2="162.14"
                                                                                                                     y2="38.63"/><line
                                className={"max-drawdown-1 " + darkStyle} x1="145.57" y1="38.63" x2="145.57" y2="24.67"/><polyline className={"max-drawdown-2 " + darkStyle}
                                                                                                           points="27.82 38.97 27.82 190.54 2.75 190.54 43.76 238.94 84.76 190.54 59.7 190.54 59.7 144.09"/><polygon
                                className="max-drawdown-3"
                                points="38.82 144.09 38.82 190.54 38.82 201.54 27.82 201.54 26.49 201.54 43.76 221.92 61.03 201.54 59.69 201.54 48.69 201.54 48.69 190.54 48.69 144.08 38.82 144.09"/></g></g>
                            </svg>
                        </span>
                        {/*end::Svg Icon*/}
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Section*/}
                    <div className="d-flex flex-column my-7">
                        {/*begin::Number*/}
                        <span className="fs-2x text-gray-800 lh-1 ls-n2">
                            {getCurrencySymbol(currency)}
                            <CountUp end={value} decimals={2} duration={1.0} />
                        </span>
                        {/*end::Number*/}
                        {/*begin::Follower*/}
                        <div className="m-0">
                            <span className="fs-6 text-gray-400">
                                {intl.formatMessage({id: "DASHBOARD.MAX_DRAWDOWN"})}
                            </span>
                        </div>
                        {/*end::Follower*/}
                    </div>
                    {/*end::Section*/}
                    <PercentChangeWidget currentValue={value} previousValue={previousValue} />
                </div>
            </div>
        </div>
    )
}

export {WidgetMaxDrawdown}
