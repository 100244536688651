import {FC} from "react";
import {useIntl} from "react-intl";
import {PercentChangeWidget} from "./PercentChangeWidget";
import CountUp from "react-countup";
import {WidgetKey} from "../../../common/dto/dashboard/WidgetDto";
import {useThemeMode} from "../../../../_metronic/partials";

type Props = {
    selectedWidgets: {[key: string]: boolean},
    value: number
    previousValue: number
}

const WidgetLongTrades: FC<Props> = ({selectedWidgets, value, previousValue}) => {
    const intl = useIntl()
    const {isDark} = useThemeMode()
    const darkStyle = (isDark ? "widget-dark" : "")

    if (!selectedWidgets[WidgetKey.LONG_TRADES]) {
        return null
    }
    return (
        <div className="col-sm-4 col-xl-2 mb-xl-10">
            <div className="card h-lg-100">
                <div className="card-body d-flex justify-content-between align-items-start flex-column">
                    {/*begin::Icon*/}
                    <div className="m-0">
                        {/*begin::Svg Icon | path: icons/50-finance-icons-red/long-trade.svg*/}
                        <span className="svg-icon svg-icon-2hx svg-icon-gray-600">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 263.55 207.19"><defs><style>{`.long-trade-1{fill:none;stroke:#344154;stroke-linecap:round;stroke-miterlimit:10;stroke-width:5.5px;}.long-trade-2{fill:#009ef7;}`}</style></defs><g><g id="Icon_Set" data-name="Icon Set">
                                <rect className={"long-trade-1 " + darkStyle} x="2.75" y="2.75" width="144.59" height="82.84"/><path
                                className={"long-trade-1 " + darkStyle} d="M53.48,65.73a30.5,30.5,0,0,1,0-43.13"/><path className={"long-trade-1 " + darkStyle}
                                                                                                  d="M96.61,22.6a30.5,30.5,0,0,1,0,43.13"/><path
                                className={"long-trade-1 " + darkStyle} d="M122.51,46.22A1.73,1.73,0,1,1,124.24,48,1.73,1.73,0,0,1,122.51,46.22Z"/><path className={"long-trade-1 " + darkStyle}
                                                                                                                                   d="M24.12,46.22A1.73,1.73,0,1,1,25.85,48,1.73,1.73,0,0,1,24.12,46.22Z"/><path
                                className={"long-trade-1 " + darkStyle}
                                d="M66.63,52.56a8.42,8.42,0,0,0,16.84,0c0-4.65-3.45-6.8-8.42-8.42-4.42-1.44-8.42-3.77-8.42-8.42a8.42,8.42,0,1,1,16.84,0"/><line
                                className={"long-trade-1 " + darkStyle} x1="75.05" y1="22.6" x2="75.05" y2="27.35"/><line className={"long-trade-1 " + darkStyle} x1="75.05" y1="60.98"
                                                                                                    x2="75.05" y2="65.73"/><polyline
                                className={"long-trade-1 " + darkStyle} points="166.28 104.56 166.28 21.72 147.34 21.72"/><polyline className={"long-trade-1 " + darkStyle}
                                                                                                              points="21.69 85.59 21.69 104.56 128.91 104.56"/><polyline
                                className={"long-trade-1 " + darkStyle} points="185.21 104.56 185.21 40.9 166.28 40.9"/><polyline className={"long-trade-1 " + darkStyle}
                                                                                                            points="40.63 104.56 40.63 123.74 109.89 123.74"/><polyline
                                className={"long-trade-1 " + darkStyle}
                                points="128.2 122.84 68.26 182.78 88.78 203.3 146.54 145.54 185.71 184.72 218.94 151.49 247.52 122.91 260.8 136.09 260.8 88.06 212.67 88.06 227 102.39 185.71 143.68 146.54 104.5 128.2 122.84"/><path
                                className="long-trade-2"
                                d="M83.82,182.78l5,5,50-50,7.78-7.78,7.78,7.78,31.4,31.4,25.45-25.45,28.58-28.58,7.75-7.75,2.32,2.3V99.07H239.23l3.33,3.33-7.78,7.78-41.29,41.29-7.78,7.78-7.78-7.78-31.4-31.4L136,130.63Z"/></g></g>
                            </svg>
                        </span>
                        {/*end::Svg Icon*/}
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Section*/}
                    <div className="d-flex flex-column my-7">
                        {/*begin::Number*/}
                        <span className="fs-2x text-gray-800 lh-1 ls-n2">
                            <CountUp end={value} duration={1.0} />
                        </span>
                        {/*end::Number*/}
                        {/*begin::Follower*/}
                        <div className="m-0">
                            <span className="fs-6 text-gray-400">
                                {intl.formatMessage({id: "DASHBOARD.LONG_TRADES"})}
                            </span>
                        </div>
                        {/*end::Follower*/}
                    </div>
                    {/*end::Section*/}
                    <PercentChangeWidget currentValue={value} previousValue={previousValue} />
                </div>
            </div>
        </div>
    )
}

export {WidgetLongTrades}
