/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {UserModel} from '../../core/_models'
import {toAbsoluteStaticUrl} from "../../../../../../_metronic/helpers";

type Props = {
    user: UserModel
}

const UserInfoCell: FC<Props> = ({user}) => (
    <div className='d-flex align-items-center'>
        {/* begin:: Avatar */}
        <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
            <a href='#'>
                <div className='symbol-label'>
                    <img src={toAbsoluteStaticUrl(`/static/${user?.profileImage?.id}`)} alt={user.username}
                         className='w-100'/>
                </div>
            </a>
        </div>
        <div className='d-flex flex-column'>
            <a href='#' className='text-gray-800 text-hover-primary mb-1'>
                {user.username}
            </a>
        </div>
    </div>
)

export {UserInfoCell}
