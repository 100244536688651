import {createContext, FC, useContext, useMemo, useState} from 'react'
import {
    calculatedGroupingIsDisabled,
    calculateIsAllDataSelected,
    groupingOnSelect,
    groupingOnSelectAll,
    ID,
    initialListView,
    ListViewContextProps,
    WithChildren,
} from "../../../../../_metronic/helpers"
import {useQueryResponse, useQueryResponseData} from './QueryResponseProvider'

const ListViewContext = createContext<ListViewContextProps>(initialListView)

const ListViewProvider: FC<WithChildren> = ({children}) => {
    const [selected, setSelected] = useState<Array<ID>>(initialListView.selected)
    const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(initialListView.itemIdForUpdate)
    const [itemIdForDepositHistory, setItemIdForDepositHistory] = useState<ID>(initialListView.itemIdForDepositHistory)
    const [itemForCreate, setItemForCreate] = useState<any>(initialListView.itemForCreate)
    const [itemForDelete, setItemForDelete] = useState<any>(initialListView.itemForCreate)
    const [globalError, setGlobalError] = useState<any>(initialListView.globalError)
    const [imagesView, setImagesView] = useState<any>(initialListView.imagesView)
    const {isLoading} = useQueryResponse()
    const data = useQueryResponseData()
    const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
    const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])

    return (
        <ListViewContext.Provider
            value={{
                selected,
                itemIdForUpdate,
                setItemIdForUpdate,
                itemIdForDepositHistory,
                setItemIdForDepositHistory,
                itemForCreate,
                setItemForCreate,
                itemForDelete,
                setItemForDelete,
                imagesView,
                setImagesView,
                globalError,
                setGlobalError,
                disabled,
                isAllSelected,
                onSelect: (id: ID) => {
                    groupingOnSelect(id, selected, setSelected)
                },
                onSelectAll: () => {
                    groupingOnSelectAll(isAllSelected, setSelected, data)
                },
                clearSelected: () => {
                    setSelected([])
                },
            }}
        >
            {children}
        </ListViewContext.Provider>
    )
}

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}
