/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts from 'apexcharts'
import {getCSS, getCSSVariableValue} from "../../../../_metronic/assets/ts/_utils";
import {AccountTop10TradedTickerDto,} from "../../../common/dto/account/AccountDashboardDto";
import {IntlShape, useIntl} from "react-intl";
import {useThemeMode} from "../../../../_metronic/partials";

type Props = {
    className: string,
    top10TradedTickers?: AccountTop10TradedTickerDto[]
}

const Top10TradedTickersChartWidget: React.FC<Props> = ({className, top10TradedTickers}) => {
    const intl = useIntl()
    const {isDark} = useThemeMode()
    const chartRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (!chartRef.current) {
            return
        }
        const height = parseInt(getCSS(chartRef.current, 'height'))
        const chart = new ApexCharts(chartRef.current, getChartOptions(height, intl, isDark, top10TradedTickers))

        if (chart) {
            chart.render()
        }
        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef, top10TradedTickers])

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fs-3 mb-1'>
                        {intl.formatMessage({id: "DASHBOARD.CHART.TOP_10_TRADED_TRADES.TITLE"})}
                    </span>

                    <span className='text-muted fs-7'>
                        {top10TradedTickers?.length || 0}&nbsp;
                        {intl.formatMessage({id: "DASHBOARD.CHART.RECORDS"})}
                    </span>
                </h3>
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className='card-body'>
                {/* begin::Chart */}
                <div ref={chartRef} id='kt_charts_widget_4_chart'></div>
                {/* end::Chart */}
            </div>
            {/* end::Body */}
        </div>
    )
}

export {Top10TradedTickersChartWidget}

function getChartOptions(height: number, intl: IntlShape, isDark: boolean, top10TradedTickers?: AccountTop10TradedTickerDto[]): any {
    const baseColor = getCSSVariableValue('--bs-info')
    const opacity = (isDark ? 0.2 : 1)
    const series = top10TradedTickers?.map(ticker => {
        return {
            x: ticker.ticker,
            y: ticker.totalTrades,
        }
    }) || []

    return {
        series: [
            {
                data: series
            }
        ],
        fill: {
            type: 'none',
            opacity: opacity,
        },
        stroke: {
            width: 1,
            colors: ['rgb(131,82,232)']
        },
        colors: [baseColor],
        legend: {
            show: false
        },
        chart: {
            height: 350,
            type: 'treemap',
            toolbar: {
                show: false,
            },
        },
    }
}
