import {FC} from "react";
import prettyMilliseconds from "pretty-ms";
import {useIntl} from "react-intl";
import {PercentChangeWidget} from "./PercentChangeWidget";
import {WidgetKey} from "../../../common/dto/dashboard/WidgetDto";
import {useThemeMode} from "../../../../_metronic/partials";

type Props = {
    selectedWidgets: {[key: string]: boolean},
    value: number
    previousValue: number
}

const WidgetAvgTradeTime: FC<Props> = ({selectedWidgets, value, previousValue}) => {
    const intl = useIntl()
    const {isDark} = useThemeMode()
    const darkStyle = (isDark ? "widget-dark" : "")

    if (!selectedWidgets[WidgetKey.AVG_TRADE_TIME]) {
        return null
    }
    let prettyTime = prettyMilliseconds(value * 1000, {verbose: true, compact: true})

    if (prettyTime.includes('second') || prettyTime.includes('seconds')) {
        prettyTime = prettyTime.replace('seconds', intl.formatMessage({id: "DURATION.SHORT.SECONDS"}))
        prettyTime = prettyTime.replace('second', intl.formatMessage({id: "DURATION.SHORT.SECOND"}))
    } else if (prettyTime.includes('minute') || prettyTime.includes('minutes')) {
        prettyTime = prettyTime.replace('minutes', intl.formatMessage({id: "DURATION.SHORT.MINUTES"}))
        prettyTime = prettyTime.replace('minute', intl.formatMessage({id: "DURATION.SHORT.MINUTE"}))
    } else if (prettyTime.includes('hour') || prettyTime.includes('hours')) {
        prettyTime = prettyTime.replace('hours', intl.formatMessage({id: "DURATION.SHORT.HOURS"}))
        prettyTime = prettyTime.replace('hour', intl.formatMessage({id: "DURATION.SHORT.HOUR"}))
    } else if (prettyTime.includes('day') || prettyTime.includes('days')) {
        prettyTime = prettyTime.replace('days', intl.formatMessage({id: "DURATION.SHORT.DAYS"}))
        prettyTime = prettyTime.replace('day', intl.formatMessage({id: "DURATION.SHORT.DAY"}))
    } else if (prettyTime.includes('year') || prettyTime.includes('years')) {
        prettyTime = prettyTime.replace('years', intl.formatMessage({id: "DURATION.SHORT.YEARS"}))
        prettyTime = prettyTime.replace('year', intl.formatMessage({id: "DURATION.SHORT.YEAR"}))
    }

    if (value == 0) {
        prettyTime = '0'
    }
    return (
        <div className="col-sm-4 col-xl-2 mb-xl-10">
            <div className="card h-lg-100">
                <div className="card-body d-flex justify-content-between align-items-start flex-column">
                    {/*begin::Icon*/}
                    <div className="m-0">
                        {/*begin::Svg Icon | path: icons/50-finance-icons-red/avg-trade-time.svg*/}
                        <span className="svg-icon svg-icon-2x svg-icon-gray-600">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.9 115.84"><defs><style>{`.avg-trade-time-1{fill:none;stroke:#344154;stroke-miterlimit:10;stroke-width:3px;}.avg-trade-time-2{fill:#ff6d3b;}`}</style></defs>
                                <g><g id="Icon_Set" data-name="Icon Set"><path className={"avg-trade-time-1 " + darkStyle}
                                                                                                           d="M6.79,105.73S-4.29,82.23,25,63.75c0,0,4.53-2.74,4.53-5.83S25,52.1,25,52.1c-29.25-18.48-18.17-42-18.17-42"/><path
                                className={"avg-trade-time-1 " + darkStyle} d="M4.54,1.5H60.36a3,3,0,0,1,3,3v5.57a0,0,0,0,1,0,0H1.5a0,0,0,0,1,0,0V4.54A3,3,0,0,1,4.54,1.5Z"/><path
                                className={"avg-trade-time-1 " + darkStyle}
                                d="M1.5,105.73H63.4a0,0,0,0,1,0,0v5.57a3,3,0,0,1-3,3H4.54a3,3,0,0,1-3-3v-5.57A0,0,0,0,1,1.5,105.73Z"/><path
                                className={"avg-trade-time-1 " + darkStyle}
                                d="M58.11,105.73s11.08-23.5-18.17-42c0,0-4.53-2.74-4.53-5.83s4.53-5.82,4.53-5.82c29.25-18.48,18.17-42,18.17-42"/><path
                                className={"avg-trade-time-1 " + darkStyle}
                                d="M58.11,105.73s11.08-23.5-18.17-42c0,0-4.53-2.74-4.53-5.83s4.53-5.82,4.53-5.82c29.25-18.48,18.17-42,18.17-42H6.79S-4.29,33.62,25,52.1c0,0,4.53,2.73,4.53,5.82S25,63.75,25,63.75c-29.25,18.48-18.17,42-18.17,42Z"/><path
                                className="avg-trade-time-2"
                                d="M10.69,100.35H54.26a23.65,23.65,0,0,0,.32-9.27,97.53,97.53,0,0,0-44.14,0A24.48,24.48,0,0,0,10.69,100.35Z"/><path
                                className="avg-trade-time-2"
                                d="M27.8,47.52a18.87,18.87,0,0,1,4.65,4.09,18.82,18.82,0,0,1,4.66-4.09c11.28-7.14,15.68-15,17.1-21.42a97.81,97.81,0,0,0-43.51,0C12.12,32.57,16.52,40.39,27.8,47.52Z"/></g></g>
                            </svg>
                        </span>
                        {/*end::Svg Icon*/}
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Section*/}
                    <div className="d-flex flex-column my-7">
                        {/*begin::Number*/}
                        <span className="fs-2x text-gray-800 lh-1 ls-n2">
                            {prettyTime !== '0' && '~' + prettyTime}
                            {prettyTime === '0' && 0}
                        </span>
                        {/*end::Number*/}
                        {/*begin::Follower*/}
                        <div className="m-0">
                            <span className="fs-6 text-gray-400">
                                {intl.formatMessage({id: "DASHBOARD.AVG_TRADE_TIME"})}
                            </span>
                        </div>
                        {/*end::Follower*/}
                    </div>
                    {/*end::Section*/}
                    <PercentChangeWidget currentValue={value} previousValue={previousValue} />
                </div>
            </div>
        </div>
    )
}

export {WidgetAvgTradeTime}
