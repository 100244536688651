import axios, {AxiosResponse} from 'axios'
import {JsonApiAccountDepositHistoryModel, JsonApiAccountModel} from './_models'
import {ID} from "../../../../../_metronic/helpers";

const API_URL = process.env.REACT_APP_API_URL
const ACCOUNT_URL = `${API_URL}/accounts`

const getAccounts = (query: string): Promise<JsonApiAccountModel> => {
    return axios.get(`${ACCOUNT_URL}?${query}`)
        .then((response: AxiosResponse<JsonApiAccountModel>) => response.data)
}

const getAccountDepositHistory = (accountId: ID): Promise<JsonApiAccountDepositHistoryModel> => {
    return axios.get(`${ACCOUNT_URL}/${accountId}/deposits/history`)
        .then((response: AxiosResponse<JsonApiAccountDepositHistoryModel>) => response.data)
}

const deleteAccountById = (accountId: ID): Promise<void> => {
    return axios.delete(`${ACCOUNT_URL}/${accountId}`).then(() => {
    })
}

export {getAccounts, getAccountDepositHistory, deleteAccountById}
