import {KTSVG} from "../../../../../../_metronic/helpers";
import React from "react";
import {useIntl} from "react-intl";
import {useAccountListView} from "../../../../../common/provider/account/AccountListViewProvider";

const AccountsListHeader = () => {
    const intl = useIntl()
    const {setItemForCreate} = useAccountListView()

    return (
        <div className='card-header border-0 pt-6'>
            {/* begin::Card toolbar */}
            <div className='d-flex align-items-center position-relative my-1'></div>
            <div className='card-toolbar'>
                <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                    <button type='button' className='btn btn-primary' onClick={() => setItemForCreate('' as any)}>
                        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2'/>
                        {intl.formatMessage({id: 'ACCOUNTS.ADD_ACCOUNT'})}
                    </button>
                </div>
            </div>
            {/* end::Card toolbar */}
        </div>
    )
}

export {AccountsListHeader}
