import React from "react";
import {useIntl} from "react-intl";
import {PageLink, PageTitle} from "../../../_metronic/layout/core";
import {MetaHeader} from "../../common/component/meta/MetaHeader";
import {JournalEditPostPageContent} from "./component/JournalEditPostPageContent";

const JournalNewPostPage: React.FC = () => {
    const intl = useIntl()
    const tradeBreadCrumbs: Array<PageLink> = [
        {
            title: intl.formatMessage({id: 'BREADCRUMB.JOURNAL'}),
            path: '/journals',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]
    return (
        <>
            <MetaHeader pageName='HTML.HEAD.TITLE.JOURNAL.POSTS.NEW'/>
            <PageTitle breadcrumbs={tradeBreadCrumbs}>
                {intl.formatMessage({id: 'BREADCRUMB.JOURNAL.POSTS.NEW'})}
            </PageTitle>
            <JournalEditPostPageContent/>
        </>
    )
}

export {JournalNewPostPage}
