import {FC} from "react";
import {getCurrencySymbol} from "../../../common/utils/currency/CurrencyUtils";
import {PercentChangeWidget} from "./PercentChangeWidget";
import {useIntl} from "react-intl";
import CountUp from "react-countup";
import {WidgetKey} from "../../../common/dto/dashboard/WidgetDto";
import {useThemeMode} from "../../../../_metronic/partials";

type Props = {
    selectedWidgets: {[key: string]: boolean},
    value: number
    previousValue: number
    currency: string
}

const WidgetTotalResult: FC<Props> = ({selectedWidgets, value, previousValue, currency}) => {
    const intl = useIntl()
    const {isDark} = useThemeMode()
    const darkStyle = (isDark ? "widget-dark" : "")

    if (!selectedWidgets[WidgetKey.TRADES_TOTAL_RESULT]) {
        return null
    }
    return (
        <div className="col-sm-6 col-xl-2 mb-xl-10">
            <div className="card h-lg-100">
                <div className="card-body d-flex justify-content-between align-items-start flex-column">
                    {/*begin::Icon*/}
                    <div className="m-0">
                        {/*begin::Svg Icon | path: icons/50-finance-icons-red/result.svg*/}
                        <span className="svg-icon svg-icon-2hx svg-icon-gray-600">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 235.57 229.16"><defs><style>{`.result-1{fill:none;stroke:#344154;stroke-linecap:round;stroke-linejoin:round;stroke-width:5.5px;}.result-2{fill:#009ef7;}`}</style></defs>
                                <g><g id="Icon_Set" data-name="Icon Set"><path
                                className={"result-1 " + darkStyle}
                                d="M160.1,226.41V205a6.74,6.74,0,0,1,3.64-6L223,168.16a6.75,6.75,0,0,1,9.87,6v52.26"/><path
                                className={"result-1 " + darkStyle}
                                d="M140.16,48.68s37.58,4.08,53.65,19.26c9.81,9.26,11.26,32.44,14.48,46.59,4,17.54,6.75,28.9,6.75,54.35"/><line
                                className={"result-1 " + darkStyle} x1="170.24" y1="170.57" x2="170.24" y2="194.01"/><path
                                className={"result-1 " + darkStyle}
                                d="M86.19,56.57a12.55,12.55,0,1,0,0,25.1c6.93,0,10.13-5.14,12.55-12.55,2.15-6.59,5.62-12.55,12.55-12.55a12.53,12.53,0,0,1,9.77,4.68"/><line
                                className={"result-1 " + darkStyle} x1="73.64" y1="69.11" x2="66.56" y2="69.11"/>
                                <path className={"result-1 " + darkStyle} d="M143.75,79.48a46.16,46.16,0,1,0-31.92,34"/><path
                                className={"result-1 " + darkStyle}
                                d="M170.24,170.59v0A15,15,0,0,0,161,156.62c-8.14-3.3-19.79-11.05-27.06-29.13a98.3,98.3,0,0,0-8.16-16.4c-3.23-5.1-8.42-11.74-12.39-17.76-4.43-6.73-4.94-10.91,0-15.22a2.65,2.65,0,0,0,.3-.25A9.51,9.51,0,0,1,127.3,79.5c5.37,7.06,12.61,17,25.46,26.43a6.47,6.47,0,0,0,10.29-5.31,22.54,22.54,0,0,0-8.32-17.79"/><path
                                className={"result-1 " + darkStyle}
                                d="M55.61,27V15A12.28,12.28,0,0,0,43.33,2.75H15A12.28,12.28,0,0,0,2.75,15V133.59A12.28,12.28,0,0,0,15,145.87h28.3a12.28,12.28,0,0,0,12.28-12.28V115.35"/><line
                                className={"result-1 " + darkStyle} x1="29.18" y1="33.45" x2="29.18" y2="119.39"/><polygon
                                className="result-2"
                                points="171.1 207.53 171.1 222.21 221.82 197.5 221.82 181.15 171.1 207.53"/></g></g></svg>
                        </span>
                        {/*end::Svg Icon*/}
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Section*/}
                    <div className="d-flex flex-column my-7">
                        {/*begin::Number*/}
                        <span className="fs-2x text-gray-800 lh-1 ls-n2">
                            {getCurrencySymbol(currency)}
                            <CountUp end={value} decimals={2} duration={1.0} />
                        </span>
                        {/*end::Number*/}
                        {/*begin::Follower*/}
                        <div className="m-0">
                            <span className="fs-6 text-gray-400">
                                {intl.formatMessage({id: "DASHBOARD.TOTAL_RESULT"})}
                            </span>
                        </div>
                        {/*end::Follower*/}
                    </div>
                    {/*end::Section*/}
                    <PercentChangeWidget currentValue={value} previousValue={previousValue} invert={true}/>
                </div>
            </div>
        </div>
    )
}

export {WidgetTotalResult}
