/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Link, Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useIntl} from "react-intl";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {ConfirmRegistration} from "./components/ConfirmRegistration";

const AuthLayout = () => {
    const intl = useIntl()
    useEffect(() => {
        document.body.classList.add('bg-white')
        return () => {
            document.body.classList.remove('bg-white')
        }
    }, [])

    return (
        <div
            className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
            style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/banner.png')})`,
            }}
        >
            {/* begin::Content */}
            <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                {/* begin::Logo */}
                <a href='/' className='mb-12 pe-lg-16'>
                    <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.svg')} className='h-45px'/>
                </a>
                {/* end::Logo */}
                {/* begin::Wrapper */}
                <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
                    <Outlet/>
                </div>
                {/* end::Wrapper */}
            </div>
            {/* end::Content */}
            {/* begin::Footer */}
            <div className='d-flex flex-center flex-column-auto p-10'>
                <div className='d-flex align-items-center fs-6'>
                    <Link className='text-muted text-hover-primary px-2' to="/">
                        {intl.formatMessage({id: 'LINK.HOME'})}
                    </Link>
                    <Link className='text-muted text-hover-primary px-2' to="/about">
                        {intl.formatMessage({id: 'LINK.ABOUT'})}
                    </Link>
                    <Link className='text-muted text-hover-primary px-2' to="/contacts">
                        {intl.formatMessage({id: 'LINK.CONTACT'})}
                    </Link>
                </div>
            </div>
            {/* end::Footer */}
        </div>
    )
}

const AuthPage = () => {
    const clientId = process.env.REACT_APP_GOOGLE_OAUTH2_CLIENT_ID || 'empty'

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <Routes>
                <Route element={<AuthLayout/>}>
                    <Route path='login' element={<Login/>}/>
                    <Route path='registration' element={<Registration/>}/>
                    <Route path='registration/confirm' element={<ConfirmRegistration/>}/>
                    <Route path='password/forgot' element={<ForgotPassword/>}/>
                    <Route index element={<Login/>}/>
                </Route>
            </Routes>
        </GoogleOAuthProvider>
    )
}

export {AuthPage}
