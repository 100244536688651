import {createContext, FC, useContext, useState} from 'react'
import {
    initialQueryRequest,
    QueryRequestContextProps,
    QueryState,
    WithChildren
} from "../../../../../_metronic/helpers";

const AccountQueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)

const AccountQueryRequestProvider: FC<WithChildren> = ({children}) => {
    const [state, setState] = useState<QueryState>(initialQueryRequest.state)

    const updateState = (updates: Partial<QueryState>) => {
        const updatedState = {...state, ...updates} as QueryState
        setState(updatedState)
    }

    return (
        <AccountQueryRequestContext.Provider value={{state, updateState}}>
            {children}
        </AccountQueryRequestContext.Provider>
    )
}

const useAccountQueryRequest = () => useContext(AccountQueryRequestContext)

export {AccountQueryRequestProvider, useAccountQueryRequest}
