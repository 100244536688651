import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react'
import clsx from "clsx";
import {FormikErrors, useFormik} from "formik";
import {ID, KTSVG, Response} from "../../../../_metronic/helpers";
import axios, {AxiosResponse} from "axios";
import {JsonApiErrorsModel} from "../../_models";
import {useTradeModelListViewContext} from "../../../common/provider/trade/TradeListViewProvider";
import * as Yup from "yup";
//import '../../../../_metronic/assets/sass/core/vendors/plugins/_daterangepicker.scss';
//import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from "moment";
import {TradeMediaModel, TradeModel, TradeTagDto} from "../table/core/_models";
import {useTradeQueryResponse} from "../../../common/provider/trade/TradeDataProvider";
import {AccountDto} from "../../../common/dto/account/AccountDto";
import {getAccountById} from "../../../common/request/account/AccountRequest";
import {useDropzone} from "react-dropzone";
import prettyBytes from "pretty-bytes";
import Swal from "sweetalert2";
import {deleteTradeMediaById} from "../../../common/request/trade/TradeRequest";
import {Tag, WithContext as ReactTags} from 'react-tag-input';
import {getAllTags} from "../../../common/request/tag/TagRequest";
import {validate as uuidValidate} from 'uuid';
import {useIntl} from "react-intl";
import {logger} from "../../../../index";
import DateRangePicker from 'react-bootstrap-daterangepicker';

type NewTradeDto = {
    ticker: string
    direction: 'LONG' | 'SHORT'
    state: 'OPEN' | 'COMPLETE'
    volume: number
    result: number
    commission: number
    openPrice: number
    openType: string
    openDate: string
    closeType: string
    closePrice: number
    closeDate: string
    exchange: 'IBKR' | 'BINANCE' | 'NOT_DEFINED',
    comment: string
    files: Array<File>
    fileNames: {[key: string]: string}
    tags: Array<TradeTagDto>
}

type Prop = {
    account?: AccountDto
    setAccount: Dispatch<SetStateAction<AccountDto>>
    setIsUpdateDeposit: Dispatch<SetStateAction<boolean>>
}

const NewTradeModal: FC<Prop> = (props) => {
    const intl = useIntl()
    const {itemForCreate: accountId, setItemForCreate, itemIdForUpdate: trade, setItemIdForUpdate} = useTradeModelListViewContext()
    const [tradeLongDirection, setTradeLongDirection] = useState(!trade || trade?.direction === 'LONG');
    const [existsFilesList, setExistsFilesList] = useState<any>([])
    const [mediaFiles, setMediaFiles] = useState<Array<TradeMediaModel>>(trade?.medias || [])
    const [tags, setTags] = useState<Array<any>>(
        trade?.tags?.map((tag) => {
            return {id: tag.id, text: tag.name}
        }) || []
    )
    const {refetch: refetchAllTrades} = useTradeQueryResponse()
    const {setAccount, setIsUpdateDeposit} = props
    const [suggestions, setSuggestions] = useState<Array<any>>()
    const [formTicker, setFormTicker] = useState<string>("")

    useEffect(() => {
        setFormTicker(trade?.ticker || "")
    }, [trade]);

    const newTradeSchema = Yup.object().shape({
        ticker: Yup.string()
            .min(1, intl.formatMessage({id: 'TRADES.MODAL.CREATE.VALIDATION.MINIMUM.SYMBOLS'}).replace('%VALUE%', '1'))
            .max(16, intl.formatMessage({id: 'TRADES.MODAL.CREATE.VALIDATION.MINIMUM.SYMBOLS'}).replace('%VALUE%', '16'))
            .required(intl.formatMessage({id: 'TRADES.MODAL.CREATE.VALIDATION.REQUIRED'})),
        volume: Yup.number()
            .min(0.00000000001, intl.formatMessage({id: 'TRADES.MODAL.CREATE.VALIDATION.MINIMUM'}) + ' > 0')
            .required(intl.formatMessage({id: 'TRADES.MODAL.CREATE.VALIDATION.REQUIRED'})),
        result: Yup.number()
            .required(intl.formatMessage({id: 'TRADES.MODAL.CREATE.VALIDATION.REQUIRED'})),
        commission: Yup.number()
            .min(0, intl.formatMessage({id: 'TRADES.MODAL.CREATE.VALIDATION.MINIMUM'}) + ' 0')
            .required(intl.formatMessage({id: 'TRADES.MODAL.CREATE.VALIDATION.REQUIRED'})),
        openType: Yup.string()
            .oneOf(['LEVEL_BREAKDOWN', 'LEVEL_REBOUND', 'LEVEL_RETEST', 'FALSE_BREAKDOWN_TWO_BARS', 'FALSE_BREAKDOWN_MANY_BARS', 'FALSE_BREAKDOWN', 'TILT', 'OTHER', 'UNKNOWN', 'NOT_DEFINED'])
            .required(intl.formatMessage({id: 'TRADES.MODAL.CREATE.VALIDATION.REQUIRED'})),
        closeType: Yup.string()
            .oneOf(['TAKE_PROFIT', 'STOP_LOSS', 'BREAKEVEN'])
            .required(intl.formatMessage({id: 'TRADES.MODAL.CREATE.VALIDATION.REQUIRED'})),
        openPrice: Yup.number()
            .required(intl.formatMessage({id: 'TRADES.MODAL.CREATE.VALIDATION.REQUIRED'})),
        closePrice: Yup.number()
            .required(intl.formatMessage({id: 'TRADES.MODAL.CREATE.VALIDATION.REQUIRED'})),
        comment: Yup.string()
            .max(4096, intl.formatMessage({id: 'TRADES.MODAL.CREATE.VALIDATION.MAXIMUM'}) + ' <= 4096')
            .notRequired(),
    })

    const handleDelete = (i: number) => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = (tag: any) => {
        setTags([...tags, tag]);
    };

    const handleDrag = (tag: any, currPos: number, newPos: number) => {
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        setTags(newTags);
    };

    const handleTagClick = (index: number) => {};

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        accept: {
            'image/*': ['.png', '.gif', '.jpeg', '.jpg', '.pdf'],
        },
        multiple: true,
        onDrop: acceptedFiles => {
            formik.setFieldValue("files", acceptedFiles)
        }
    })

    useEffect(() => {
        getAllTags().then(response => {
            setSuggestions(
                response.data?.map((data) => {
                    return {
                        id: data.id,
                        text: data.attributes.name
                    }
                })
            )
        })
    }, [])

    useEffect(() => {
        const files = mediaFiles.map(file => (
            <li key={file.id}>
                <div className='d-flex align-items-center mb-1'>
                    <div className='mt-1 pe-3 flex-grow-3 min-w-300px'>
                        {file.name} - {prettyBytes(file?.size || 0)}
                    </div>
                    <div className="svg-icon svg-icon-2 cursor-pointer"
                         onClick={() => deleteTradeMedias(file?.id)}
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24"
                             fill="none" xmlns="http://www.w3.org/2000/svg"
                             className="mh-50px"><path
                            d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                            fill="#f1416c"></path><path opacity="0.5"
                            d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                            fill="#f1416c"></path><path opacity="0.5"
                            d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                            fill="#f1416c"></path>
                        </svg>
                    </div>
                </div>
            </li>
        )) || []

        setExistsFilesList(files)
    }, [mediaFiles])

    const [initialTradeValues] = useState<NewTradeDto>({
        ticker: trade?.ticker || '',
        direction: trade?.direction || 'LONG',
        state: trade?.state || 'COMPLETE',
        volume: trade?.volume || 0,
        result: trade?.result || 0,
        commission: trade?.commission || 0,
        openType: trade?.openType || 'NOT_DEFINED',
        openPrice: trade?.openPrice || 0,
        openDate: moment(trade?.openDate || moment()).format("YYYY-MM-DDTHH:mm:ss"),
        closeType: trade?.closeType || 'TAKE_PROFIT',
        closePrice: trade?.closePrice || 0,
        closeDate: moment(trade?.closeDate || moment()).format("YYYY-MM-DDTHH:mm:ss"),
        exchange: trade?.exchange || 'NOT_DEFINED',
        comment: trade?.comment || '',
        files: [],
        fileNames: {},
        tags: []
    })

    useEffect(() => {
        document.body.classList.add('modal-open')

        return () => {
            document.body.classList.remove('modal-open')
        }
    }, [])

    const updateFormikDate = (start: any, end: any, setFieldValue: any) => {
        const pattern = 'YYYY-MM-DDTHH:mm:ss'

        setFieldValue('openDate', start.format(pattern))
        setFieldValue('closeDate', end.format(pattern))
    }

    const formik = useFormik({
        initialValues: initialTradeValues,
        validationSchema: newTradeSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setSubmitting(true)
            setStatus(undefined)

            values.direction = (tradeLongDirection ? 'LONG' : 'SHORT')
            values.state = (values.closeDate ? 'COMPLETE' : 'OPEN')
            values.tags = tags.map((tag: Tag) => {
                return {
                    id: (uuidValidate(tag.id as any) ? tag.id as any : undefined),
                    name: tag.text
                }
            })
            const formData = new FormData();

            values.files.forEach((file) => {
                formData.append('files', file);
            });
            const json = JSON.stringify(values);

            const blob = new Blob([json], {
                type: 'application/json'
            })
            const API_URL = process.env.REACT_APP_API_URL

            if (!trade) {
                const TRADE_URL = `${API_URL}/accounts/${accountId}/trades`

                formData.append("newTrade", blob)

                return axios.post(TRADE_URL, formData, {headers: {'Content-Type': 'multipart/form-data'}})
                    .then((response: AxiosResponse<Response<TradeModel>>) => {
                        setItemForCreate(undefined)
                        refetchAllTrades()

                        getAccountById(accountId)
                            .then(response => {
                                if (!response) {
                                    return
                                }
                                const data = response?.data?.attributes

                                if (data) {
                                    data.id = response?.data?.id as any

                                    setAccount(data)
                                }
                            })

                        setIsUpdateDeposit(true)
                    }).catch(error => {
                        const {response} = error;
                        const errors: FormikErrors<any> = {};

                        (response.data.errors as Array<JsonApiErrorsModel>).map(error => {
                            if (error.code === 'VALIDATION_ERROR') {
                                const field = error.source?.parameter || ''

                                errors[field] = error.detail
                            } else {
                                setStatus(error.detail)
                            }
                        })
                        formik.setErrors(errors)
                    })
            } else {
                const accountId = trade.account?.id
                const TRADE_URL = `${API_URL}/accounts/${accountId}/trades/${trade.id}`

                formData.append("updateTrade", blob)

                return axios.put(TRADE_URL, formData, {headers: {'Content-Type': 'multipart/form-data'}})
                    .then((response) => {
                        setItemIdForUpdate(undefined)
                        refetchAllTrades()

                        getAccountById(accountId)
                            .then(response => {
                                if (!response) {
                                    return
                                }
                                const data = response?.data?.attributes

                                if (data) {
                                    data.id = response?.data?.id as any

                                    setAccount(data)
                                }
                            })

                        setIsUpdateDeposit(true)

                        Swal.fire(
                            'Updated!',
                            'Your trade ' + response?.data?.data?.attributes.ticker + ' has been updated.',
                            'success'
                        )
                    }).catch(error => {
                        const {response} = error;
                        const errors: FormikErrors<any> = {};

                        if (!response.data || !response.data.errors) {
                            setStatus(error.detail)
                        }
                        (response.data.errors as Array<JsonApiErrorsModel>).map(error => {
                            if (error.code === 'VALIDATION_ERROR') {
                                const field = error.source?.parameter || ''

                                errors[field] = error.detail
                            } else {
                                setStatus(error.detail)
                            }
                        })
                        formik.setErrors(errors)
                    })
            }
        },
    })

    const calculateResult = () => {
        try {
            const openPrice = formik.values.openPrice
            const closePrice = formik.values.closePrice
            const volume = formik.values.volume

            if (openPrice === 0 || closePrice === 0 || volume === 0) {
                return
            }
            const commission = formik.values.commission
            const priceDiff = (tradeLongDirection ? closePrice - openPrice : openPrice - closePrice)
            const result = (priceDiff * volume) - commission

            logger.debug(`Open: ${openPrice}, close: ${closePrice}, volume: ${volume}, diff: ${priceDiff}`)

            formik.setFieldValue('result', result)
        } catch (exception) {
            return 0
        }
    }

    const updateFileNameField = (originalFileName: string, event: any) => {
        formik.values.fileNames[originalFileName] = event.target.value
    }

    const filesList = acceptedFiles.map(file => (
        <li key={file.name}>
            <input type="text" className="form-control" placeholder={file.name} style={{padding: '.05rem .35rem'}} onChange={(event) => {updateFileNameField(file.name, event)}}/>
            {prettyBytes(file?.size || 0)}
        </li>
    ))

    const deleteTradeMedias = (mediaId: ID) => {
        console.log(`Delete media: ${mediaId} from trade: ${trade?.id}`)

        setMediaFiles(mediaFiles.filter((item) => item.id !== mediaId))

        deleteTradeMediaById(trade?.account?.id, trade?.id, mediaId)
            .catch(error => {
                formik.setStatus(error?.response?.data?.errors[0].detail)
            })
    }

    const closeModal = () => {
        setItemForCreate(undefined)
        setItemIdForUpdate(undefined)
    }

    const toUpperCase = (event: any) => {
        const tickerUpperCase = event.target.value.toUpperCase()

        setFormTicker(tickerUpperCase)

        formik.values.ticker = tickerUpperCase
    }

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_trade'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered mw-1000px'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>
                        <div className='modal-header'>
                            {/* begin::Modal title */}
                            <h2 className='fw-bolder'>
                                {intl.formatMessage({id: 'TRADES.MODAL.CREATE.TITLE'})}
                            </h2>
                            {/* end::Modal title */}

                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary cursor-pointer'
                                data-kt-trade-modal-action='close'
                                onClick={closeModal}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                            </div>
                            {/* end::Close */}
                        </div>
                        {/* begin::Modal body */}
                        <div className='modal-body scroll-y mx-5 mx-xl-15'>
                            <form id='kt_modal_add_trade_form'
                                  className='form'
                                  onSubmit={formik.handleSubmit}
                                  noValidate>
                                {/* begin::Scroll */}
                                <div className='row g-5'>
                                    <div className='col-xl-12'>
                                        {formik.status && (
                                            <div className='alert alert-danger'>
                                                <div className='alert-text font-weight-bold'>{formik.status}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='col-xl-5'>
                                        {/* begin::Input group */}
                                        <div className='fv-row mb-5'>
                                            {/* begin::Label */}
                                            <label className='required fw-bold fs-6'>
                                                {intl.formatMessage({id: 'TRADES.MODAL.CREATE.SYMBOL'})}
                                            </label>
                                            {/* end::Label */}

                                            {/* begin::Input */}
                                            <input
                                                placeholder={intl.formatMessage({id: 'TRADES.MODAL.CREATE.SYMBOL'})}
                                                {...formik.getFieldProps('ticker')}
                                                type='text'
                                                name='ticker'
                                                value={formTicker}
                                                onChange={toUpperCase}
                                                className={clsx(
                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                    {'is-invalid': formik.touched.ticker && formik.errors.ticker},
                                                    {
                                                        'is-valid': formik.touched.ticker && !formik.errors.ticker,
                                                    }
                                                )}
                                                autoComplete='off'
                                                disabled={formik.isSubmitting}
                                            />
                                            {formik.touched.ticker && formik.errors.ticker && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>{formik.errors.ticker}</span>
                                                    </div>
                                                </div>
                                            )}
                                            {/* end::Input */}
                                        </div>
                                        {/* end::Input group */}

                                        {/* begin::Input group */}
                                        <div className='fv-row mb-5'>
                                            <div className="mb-1">
                                                <label className="d-flex align-items-center fs-5 fw-bold">
                                                    <span>{intl.formatMessage({id: 'TRADES.MODAL.CREATE.SIDE'})}</span>
                                                </label>
                                            </div>
                                            <div className="btn-group w-200 w-lg-100" data-kt-buttons="true"
                                                 data-kt-buttons-target="[data-kt-button]">
                                                <label
                                                    className={"btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success " + (tradeLongDirection ? "active" : "")}
                                                    onClick={() => {
                                                        setTradeLongDirection(true)
                                                        calculateResult()
                                                    }}
                                                    data-kt-button="true">
                                                    <input className="btn-check" type="radio" radioGroup='direction'
                                                           defaultChecked={tradeLongDirection}
                                                           name="direction"
                                                           value="SHORT"/>
                                                    &nbsp;&nbsp;&nbsp;LONG&nbsp;&nbsp;&nbsp;
                                                </label>
                                                <label
                                                    className={"btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-danger " + (!tradeLongDirection ? "active" : "")}
                                                    onClick={() => {
                                                        setTradeLongDirection(false)
                                                        calculateResult()
                                                    }}
                                                    data-kt-button="true">
                                                    <input className="btn-check" type="radio" radioGroup='direction'
                                                           defaultChecked={!tradeLongDirection}
                                                           name="direction"
                                                           value="SHORT"/>
                                                    SHORT
                                                </label>
                                            </div>
                                        </div>
                                        {/* end::Input group */}

                                        {/* begin::Input group */}
                                        <div className='fv-row mb-5'>
                                            {/* begin::Label */}
                                            <label className='required fw-bold fs-6 mb-2 mt-1'>
                                                {intl.formatMessage({id: 'TRADES.MODAL.CREATE.VOLUME'})}
                                            </label>
                                            {/* end::Label */}

                                            {/* begin::Input */}
                                            <input
                                                placeholder={intl.formatMessage({id: 'TRADES.MODAL.CREATE.VOLUME'})}
                                                {...formik.getFieldProps('volume')}
                                                type='text'
                                                name='volume'
                                                className={clsx(
                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                    {'is-invalid': formik.touched.volume && formik.errors.volume},
                                                    {
                                                        'is-valid': formik.touched.volume && !formik.errors.volume,
                                                    }
                                                )}
                                                onKeyUp={calculateResult}
                                                autoComplete='off'
                                                disabled={formik.isSubmitting}
                                            />
                                            {formik.touched.volume && formik.errors.volume && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>{formik.errors.volume}</span>
                                                    </div>
                                                </div>
                                            )}
                                            {/* end::Input */}
                                        </div>
                                        {/* end::Input group */}

                                        {/* begin::Input group */}
                                        <div className='fv-row mb-5'>
                                            {/* begin::Label */}
                                            <label className='required fw-bold fs-6 mb-2'>
                                                {intl.formatMessage({id: 'TRADES.MODAL.CREATE.RESULT'})}
                                            </label>
                                            {/* end::Label */}

                                            {/* begin::Input */}
                                            <input
                                                placeholder={intl.formatMessage({id: 'TRADES.MODAL.CREATE.RESULT'})}
                                                {...formik.getFieldProps('result')}
                                                type='text'
                                                name='result'
                                                className={clsx(
                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                    {'is-invalid': formik.touched.result && formik.errors.result},
                                                    {
                                                        'is-valid': formik.touched.result && !formik.errors.result,
                                                    }
                                                )}
                                                autoComplete='off'
                                                disabled={formik.isSubmitting}
                                            />
                                            {formik.touched.result && formik.errors.result && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>{formik.errors.result}</span>
                                                    </div>
                                                </div>
                                            )}
                                            {/* end::Input */}
                                        </div>
                                        {/* end::Input group */}

                                        {/* begin::Input group */}
                                        <div className='fv-row mb-4'>
                                            {/* begin::Label */}
                                            <label className='required fw-bold fs-6 mb-2'>
                                                {intl.formatMessage({id: 'TRADES.MODAL.CREATE.COMMISSION'})}
                                            </label>
                                            {/* end::Label */}

                                            {/* begin::Input */}
                                            <input
                                                placeholder={intl.formatMessage({id: 'TRADES.MODAL.CREATE.COMMISSION'})}
                                                {...formik.getFieldProps('commission')}
                                                type='text'
                                                name='commission'
                                                className={clsx(
                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                    {'is-invalid': formik.touched.commission && formik.errors.commission},
                                                    {'is-valid': formik.touched.commission && !formik.errors.commission}
                                                )}
                                                onKeyUp={calculateResult}
                                                autoComplete='off'
                                                disabled={formik.isSubmitting}
                                            />
                                            {formik.touched.commission && formik.errors.commission && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>{formik.errors.commission}</span>
                                                    </div>
                                                </div>
                                            )}
                                            {/* end::Input */}
                                        </div>
                                        {/* end::Input group */}

                                        {/* begin::Input group */}
                                        <div className='fv-row mb-0'>
                                            <label className='fw-bold fs-6 mb-2'>
                                                {intl.formatMessage({id: 'TRADES.MODAL.CREATE.MEDIA_FILES'})}
                                            </label>
                                            <section className="">
                                                <div {...getRootProps({className: 'dropzone file-container'})}>
                                                    <input {...getInputProps()} name='files'/>
                                                    <p>
                                                        {intl.formatMessage({id: 'TRADES.MODAL.CREATE.MEDIA_FILES.TOOLTIP'})}
                                                    </p>
                                                </div>
                                                {existsFilesList.length > 0 && (
                                                    <aside>
                                                        <h4 className='mt-2'>Exists Files</h4>
                                                        <ul>{existsFilesList}</ul>
                                                    </aside>
                                                )}
                                                {filesList.length > 0 && (
                                                    <aside>
                                                        <h4 className='mt-2'>New Files</h4>
                                                        <div className="text-gray-400 mb-2">
                                                            {intl.formatMessage({id: 'TRADES.MODAL.CREATE.MEDIA_FILES.UPDATE_FILE_NAME'})}
                                                        </div>
                                                        <ul>{filesList}</ul>
                                                    </aside>
                                                )}
                                            </section>
                                        </div>
                                        {/* end::Input group */}
                                    </div>
                                    <div className='col-xl-7'>
                                        {/* begin::Input group */}
                                        <div className='row mb-5'>
                                            <label className='fw-bold fs-6 mb-2'>
                                                {intl.formatMessage({id: 'TRADES.MODAL.CREATE.OPEN_TYPE'})}
                                            </label>
                                            <div>
                                                <select
                                                    {...formik.getFieldProps('openType')}
                                                    className='form-select form-select-solid'
                                                    name='openType'
                                                >
                                                    <option value='LEVEL_BREAKDOWN'>
                                                        {intl.formatMessage({id: 'TRADES.OPEN_TYPE.LEVEL_BREAKDOWN'})}
                                                    </option>
                                                    <option value='LEVEL_REBOUND'>
                                                        {intl.formatMessage({id: 'TRADES.OPEN_TYPE.LEVEL_REBOUND'})}
                                                    </option>
                                                    <option value='LEVEL_RETEST'>
                                                        {intl.formatMessage({id: 'TRADES.OPEN_TYPE.LEVEL_RETEST'})}
                                                    </option>
                                                    <option value='FALSE_BREAKDOWN_TWO_BARS'>
                                                        {intl.formatMessage({id: 'TRADES.OPEN_TYPE.FALSE_BREAKDOWN_TWO_BARS'})}
                                                    </option>
                                                    <option value='FALSE_BREAKDOWN_MANY_BARS'>
                                                        {intl.formatMessage({id: 'TRADES.OPEN_TYPE.FALSE_BREAKDOWN_MANY_BARS'})}
                                                    </option>
                                                    <option value='FALSE_BREAKDOWN'>
                                                        {intl.formatMessage({id: 'TRADES.OPEN_TYPE.FALSE_BREAKDOWN'})}
                                                    </option>
                                                    <option value='TILT'>
                                                        {intl.formatMessage({id: 'TRADES.OPEN_TYPE.TILT'})}
                                                    </option>
                                                    <option value='OTHER'>
                                                        {intl.formatMessage({id: 'TRADES.OPEN_TYPE.OTHER'})}
                                                    </option>
                                                    <option value='NOT_DEFINED'>
                                                        {intl.formatMessage({id: 'TRADES.OPEN_TYPE.NOT_DEFINED'})}
                                                    </option>
                                                </select>
                                                {formik.touched.openType && formik.errors.openType && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert'>{formik.errors.openType}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {/* end::Input group */}

                                        {/* begin::Input group */}
                                        <div className='row mb-5'>
                                            <label className='fw-bold'>
                                                {intl.formatMessage({id: 'TRADES.MODAL.CREATE.CLOSE_TYPE'})}
                                            </label>
                                            <div>
                                                <select
                                                    {...formik.getFieldProps('closeType')}
                                                    className='form-select form-select-solid'
                                                    name='closeType'
                                                >
                                                    <option value='TAKE_PROFIT'>
                                                        {intl.formatMessage({id: 'TRADES.CLOSE_TYPE.TAKE_PROFIT'})}
                                                    </option>
                                                    <option value='STOP_LOSS'>
                                                        {intl.formatMessage({id: 'TRADES.CLOSE_TYPE.STOP_LOSS'})}
                                                    </option>
                                                    <option value='BREAKEVEN'>
                                                        {intl.formatMessage({id: 'TRADES.CLOSE_TYPE.BREAKEVEN'})}
                                                    </option>
                                                    <option value='OTHER'>
                                                        {intl.formatMessage({id: 'TRADES.CLOSE_TYPE.OTHER'})}
                                                    </option>
                                                </select>
                                                {formik.touched.closeType && formik.errors.closeType && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert'>{formik.errors.closeType}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {/* end::Input group */}
                                        {/* begin::Input group */}
                                        <div className='fv-row mb-5'>
                                            {/* begin::Label */}
                                            <label className='required fw-bold fs-6 mb-2'>
                                                {intl.formatMessage({id: 'TRADES.MODAL.CREATE.OPEN_PRICE'})}
                                            </label>
                                            {/* end::Label */}

                                            {/* begin::Input */}
                                            <input
                                                placeholder={intl.formatMessage({id: 'TRADES.MODAL.CREATE.OPEN_PRICE'})}
                                                {...formik.getFieldProps('openPrice')}
                                                type='text'
                                                name='openPrice'
                                                className={clsx(
                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                    {'is-invalid': formik.touched.openPrice && formik.errors.openPrice},
                                                    {'is-valid': formik.touched.openPrice && !formik.errors.openPrice}
                                                )}
                                                onKeyUp={calculateResult}
                                                autoComplete='off'
                                                disabled={formik.isSubmitting}
                                            />
                                            {formik.touched.openPrice && formik.errors.openPrice && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>{formik.errors.openPrice}</span>
                                                    </div>
                                                </div>
                                            )}
                                            {/* end::Input */}
                                        </div>
                                        {/* end::Input group */}

                                        {/* begin::Input group */}
                                        <div className='fv-row mb-5'>
                                            {/* begin::Label */}
                                            <label className='required fw-bold fs-6 mb-2'>
                                                {intl.formatMessage({id: 'TRADES.MODAL.CREATE.CLOSE_PRICE'})}
                                            </label>
                                            {/* end::Label */}

                                            {/* begin::Input */}
                                            <input
                                                placeholder={intl.formatMessage({id: 'TRADES.MODAL.CREATE.CLOSE_PRICE'})}
                                                {...formik.getFieldProps('closePrice')}
                                                type='text'
                                                name='closePrice'
                                                className={clsx(
                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                    {'is-invalid': formik.touched.closePrice && formik.errors.closePrice},
                                                    {'is-valid': formik.touched.closePrice && !formik.errors.closePrice}
                                                )}
                                                onKeyUp={calculateResult}
                                                autoComplete='off'
                                                disabled={formik.isSubmitting}
                                            />
                                            {formik.touched.closePrice && formik.errors.closePrice && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>{formik.errors.closePrice}</span>
                                                    </div>
                                                </div>
                                            )}
                                            {/* end::Input */}
                                        </div>
                                        {/* end::Input group */}

                                        {/* begin::Input group */}

                                        <div className='fv-row mb-5'>
                                            <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'TRADES.MODAL.CREATE.OPEN_DATE'})}</label>
                                            <label className='fw-bold fs-6 mb-2'>&nbsp; - {intl.formatMessage({id: 'TRADES.MODAL.CREATE.CLOSE_DATE'})}</label>
                                            {/*@ts-ignore*/}
                                            <DateRangePicker
                                                initialSettings={{
                                                    timePicker: true,
                                                    timePicker24Hour: true,
                                                    startDate: (trade?.openDate ? moment(trade.openDate).format("DD/MM/YYYY HH:mm") : moment().format("DD/MM/YYYY")),
                                                    endDate: (trade?.closeDate ? moment(trade.closeDate).format("DD/MM/YYYY HH:mm") : moment().format("DD/MM/YYYY")),
                                                    locale: {format: "DD-MM-YYYY HH:mm"}
                                                }}
                                                onEvent={(event, picker) => updateFormikDate(picker.startDate, picker.endDate, formik.setFieldValue)}
                                            >
                                                <input type='text' className='form-control form-control-solid'/>

                                            </DateRangePicker>
                                        </div>
                                        {/* end::Input group */}

                                        {/* begin::Input group */}
                                        <div className='fv-row mb-0'>
                                            {/* begin::Label */}
                                            <label className='fw-bold fs-6 mb-2'>
                                                {intl.formatMessage({id: 'TRADES.MODAL.CREATE.COMMENT'})}
                                            </label>
                                            {/* end::Label */}

                                            {/* begin::Input */}
                                            <textarea
                                                placeholder={intl.formatMessage({id: 'TRADES.MODAL.CREATE.COMMENT'})}
                                                {...formik.getFieldProps('comment')}
                                                className={clsx(
                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                    {'is-invalid': formik.touched.comment && formik.errors.comment},
                                                    {'is-valid': formik.touched.comment && !formik.errors.comment}
                                                )}
                                                autoComplete='off'
                                                disabled={formik.isSubmitting}
                                                rows={4}
                                            />
                                            {formik.touched.comment && formik.errors.comment && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>{formik.errors.comment}</span>
                                                    </div>
                                                </div>
                                            )}
                                            {/* end::Input */}
                                        </div>
                                        {/* end::Input group */}
                                        {/* end::Scroll */}
                                    </div>
                                    <div className='col-xl-12'>
                                        {/* begin::Input group */}
                                        <div className='fv-row'>
                                            <label className='fw-bold fs-6 mb-2'>
                                                {intl.formatMessage({id: 'TRADES.MODAL.CREATE.TAGS'})}
                                            </label>
                                            <ReactTags
                                                tags={tags}
                                                suggestions={suggestions}
                                                handleDelete={handleDelete}
                                                handleAddition={handleAddition}
                                                handleDrag={handleDrag}
                                                handleTagClick={handleTagClick}
                                                autofocus={false}
                                                inputFieldPosition="inline"
                                                allowUnique={true}
                                                autocomplete={true}
                                                placeholder={intl.formatMessage({id: 'TRADES.MODAL.CREATE.TAGS.PLACEHOLDER'})}
                                                classNames={{
                                                    tagInputField: 'form-control form-control-solid mt-2',
                                                    tag: 'badge badge-info me-lg-3',
                                                    remove: 'ReactTags__remove',
                                                }}
                                            />
                                        </div>
                                        {/* end::Input group */}
                                    </div>
                                </div>
                                {/* begin::Actions */}
                                <div className='text-center pt-15'>
                                    <button
                                        type='reset'
                                        onClick={closeModal}
                                        className='btn btn-light me-3'
                                        data-kt-trade-modal-action='cancel'
                                        disabled={formik.isSubmitting}
                                    >
                                        {intl.formatMessage({id: 'BUTTON.DISCARD'})}
                                    </button>

                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-trade-modal-action='submit'
                                        disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                                    >
                                        <span className='indicator-label'>
                                            {intl.formatMessage({id: 'BUTTON.SUBMIT'})}
                                        </span>
                                        {(formik.isSubmitting) && (
                                            <span className='indicator-progress'>
                      Please wait...{' '}
                                                <span
                                                    className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                                        )}
                                    </button>
                                </div>
                                {/* end::Actions */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-backdrop fade show'></div>
        </>
    )
}

export {NewTradeModal}
