/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteSlmSoftStaticUrl} from '../../../helpers'
import {useIntl} from "react-intl";

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  const intl = useIntl()

  return (
      <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary py-4 fs-6 w-275px'
          data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <div className='menu-content d-flex align-items-center px-3'>
            <div className='symbol symbol-50px me-5'>
              <img alt='Logo' src={toAbsoluteSlmSoftStaticUrl(`/static/users/${currentUser?.profileImageId}`)} />
            </div>

            <div className='d-flex flex-column overflow-hidden'>
              <div className='d-flex align-items-center fs-5'>
                {currentUser?.username}
                <span className='badge badge-light-success fs-8 px-2 py-1 ms-2'>
                {currentUser?.role}
              </span>
              </div>
              <a href='#' className='text-muted text-hover-primary fs-7 text-gray-800'>
                {currentUser?.email}
              </a>
            </div>
          </div>
        </div>

        <div className='separator my-2'></div>

        <div className='menu-item px-5'>
          <Link to={'/profile'} className='menu-link px-5'>
            {intl.formatMessage({id: "MENU.HEADER.PROFILE"})}
          </Link>
        </div>

        <div className='separator my-2'></div>

        <Languages />

        <div className='menu-item px-5'>
          <a onClick={logout} className='menu-link px-5'>
            {intl.formatMessage({id: "MENU.HEADER.SIGN_OUT"})}
          </a>
        </div>
      </div>
  )
}

export {HeaderUserMenu}
