import {getCurrencySymbol} from "../../../common/utils/currency/CurrencyUtils";
import React, {Dispatch, FC, SetStateAction, useEffect, useState} from "react";
import {getAccountById} from "../../../common/request/account/AccountRequest";
import {AccountDto} from "../../../common/dto/account/AccountDto";
import {useIntl} from "react-intl";
import {ID} from "../../../../_metronic/helpers";
import {useThemeMode} from "../../../../_metronic/partials";

type Prop = {
    header?: string
    accountId?: ID
    isUpdateDeposit?: boolean
    setIsUpdateDeposit?: Dispatch<SetStateAction<boolean>>
}

const FinanceResultToolbar: FC<Prop> = (props) => {
    const intl = useIntl()
    const {isDark} = useThemeMode()
    const {accountId, header, isUpdateDeposit, setIsUpdateDeposit} = props
    const [account, setAccount] = useState<AccountDto | undefined>()

    const refetchAccount = () => {
        getAccountById(accountId)
            .then(response => {
                if (!response) {
                    return
                }
                const data = response?.data?.attributes

                if (data) {
                    data.id = response?.data?.id as any

                    setAccount(data)
                }
            })
    }

    useEffect(() => {
        refetchAccount()
    }, [accountId])

    useEffect(() => {
        if (isUpdateDeposit) {
            if (setIsUpdateDeposit) {
                setIsUpdateDeposit(false)
            }
            refetchAccount()
        }
    }, [isUpdateDeposit])

    return (
        <div data-kt-swapper="true"
             data-kt-swapper-mode="prepend"
             data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
             className="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
            {/*begin::Title*/}
            <h1 className={"d-flex text-dark text-muted fs-3 flex-column mb-0 fw-normal " + (isDark ? 'text-gray-800' : 'text-gray-700')}>{header ? header : intl.formatMessage({id: 'DASHBOARD.FINANCE_RESULT'})}
                {/*begin::Description*/}
                <span className={"text-muted fs-7 mt-2 " + (isDark ? 'text-gray-800' : 'text-gray-600')}>{intl.formatMessage({id: 'DASHBOARD.YOUR_DEPOSIT_IS'})}&nbsp;
                    <span className="text-primary">
                        {getCurrencySymbol(account?.currency)}
                        {account?.currentDeposit?.toLocaleString(undefined, {maximumFractionDigits: 2})}
                    </span>
                </span>
                {/*end::Description*/}</h1>
            {/*end::Title*/}
        </div>
    )
}

export {FinanceResultToolbar}
