/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {AccountResultTimelineDto} from "../../../common/dto/account/AccountDashboardDto";
import {getCSS, getCSSVariableValue} from "../../../../_metronic/assets/ts/_utils";
import {IntlShape, useIntl} from "react-intl";
import {NavigateFunction, useNavigate, useSearchParams} from "react-router-dom";
import {logger} from "../../../../index";
import {JsonApiQueryFilterField} from "../../../common/dto/json-api/JsonApiQueryFilter";
import {useThemeMode} from "../../../../_metronic/partials";

type Props = {
    className: string,
    resultTimeline?: Array<AccountResultTimelineDto>
}

const TradesResultChartWidget: React.FC<Props> = ({className, resultTimeline}) => {
    const intl = useIntl()
    const navigate = useNavigate()
    const {isDark} = useThemeMode()
    const [searchParams, setSearchParams] = useSearchParams()
    const chartRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (!chartRef.current) {
            return
        }
        const height = parseInt(getCSS(chartRef.current, 'height'))
        const chart = new ApexCharts(chartRef.current, getChartOptions(height, intl, isDark, navigate, searchParams, setSearchParams, resultTimeline))

        if (chart) {
            chart.render()
        }
        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef, resultTimeline])

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fs-3 mb-1'>
                        {intl.formatMessage({id: "DASHBOARD.CHART.TRADES_RESULT_STATISTICS.TITLE"})}
                    </span>

                    <span className='text-muted fs-7'>
                        {resultTimeline?.length || 0}&nbsp;
                        {intl.formatMessage({id: "DASHBOARD.CHART.RECORDS"})}
                    </span>
                </h3>
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className='card-body'>
                {/* begin::Chart */}
                <div ref={chartRef} id='kt_charts_widget_5_chart' style={{height: '350px'}}></div>
                {/* end::Chart */}
            </div>
            {/* end::Body */}
        </div>
    )
}

function getChartOptions(height: number, intl: IntlShape, isDark: boolean, navigate: NavigateFunction, searchParams: URLSearchParams, setSearchParams: any, resultTimeline?: Array<AccountResultTimelineDto>): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')
    const profitColor = getCSSVariableValue('--bs-success')
    const breakevenColor = getCSSVariableValue('--bs-gray-400')
    const lossColor = getCSSVariableValue('--bs-danger')
    const opacity = (isDark ? 0.7 : 1.0)
    let maxValueY = 5

    const profitTrades = resultTimeline?.map(value => {
        const totalValue = (value.profitTrades || 0)

        if (totalValue > maxValueY) {
            maxValueY = totalValue
        }
        return totalValue || 0
    }) || []

    const breakevenTrades = resultTimeline?.map(value => {
        const totalValue = (value.breakevenTrades || 0)

        if (totalValue > maxValueY) {
            maxValueY = totalValue
        }
        return totalValue || 0
    }) || []

    const lossTrades = resultTimeline?.map(value => {
        if (value.lossTrades) {
            if (value.lossTrades
                    && value.lossTrades > maxValueY) {
                maxValueY = value.lossTrades
            }
            return value.lossTrades * (-1)
        }
        return 0
    }) || []

    const datesList = resultTimeline?.map(value => value.date || 0) || []
    const colors = [profitColor, breakevenColor, lossColor]

    return {
        series: [
            {
                name: intl.formatMessage({id: "DASHBOARD.CHART.TRADES_RESULT_STATISTICS.PROFIT_TRADES"}),
                data: profitTrades,
            },
            {
                name: intl.formatMessage({id: "DASHBOARD.CHART.TRADES_RESULT_STATISTICS.BREAKEVEN_TRADES"}),
                data: breakevenTrades,
            },
            {
                name: intl.formatMessage({id: "DASHBOARD.CHART.TRADES_RESULT_STATISTICS.LOSS_TRADES"}),
                data: lossTrades,
            },
        ],
        chart: {
            fontFamily: 'inherit',
            type: 'bar',
            stacked: true,
            height: 350,
            toolbar: {
                show: false,
            },
            events: {
                dataPointMouseEnter: function (event) {
                    event.path[0].style.cursor = "pointer";
                },
                dataPointSelection: function (event, chartContext, config) {
                    logger.debug("Point index: " + config.dataPointIndex + " -> " + datesList[config.dataPointIndex])

                    navigate(`/trades?${JsonApiQueryFilterField.CLOSE_DATE}=${datesList[config.dataPointIndex]}`, { replace: true });
                }
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '14%',
                borderRadius: 5,
            },
        },
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: datesList,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        yaxis: {
            min: (-1) * parseInt(maxValueY + (maxValueY * 0.2) + ''),
            max: parseInt(maxValueY + (maxValueY * 0.2) + ''),
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        fill: {
            opacity: opacity,
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val) {
                    const value = (val >= 0 ? val : val * (-1))
                    return value + ' (' + intl.formatMessage({id: "DASHBOARD.CHART.TRADES_RESULT_STATISTICS.CLICK_TO_VIEW_TRADES"}) + ')'
                },
            },
        },
        colors: colors,
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
    }
}

export {TradesResultChartWidget}
