import {createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useRef, useState,} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, ProfileModel, UserSubscriptionDto} from './_models'
import * as authHelper from './AuthHelpers'
import {authenticateUser} from "./_requests";
import {WithChildren} from "../../../../_metronic/helpers";
import {UserModel} from "../../user/users-list/core/_models";

type AuthContextProps = {
    auth: AuthModel | undefined
    saveAuth: (auth: AuthModel | undefined) => void
    currentUser: ProfileModel | undefined
    setCurrentUser: Dispatch<SetStateAction<ProfileModel | undefined>>
    logout: () => void
}

const initAuthContextPropsState = {
    auth: authHelper.getAuth(),
    saveAuth: () => {
    },
    currentUser: undefined,
    setCurrentUser: () => {
    },
    logout: () => {
    },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
    return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
    const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
    const [currentUser, setCurrentUser] = useState<ProfileModel | undefined>()
    const saveAuth = (auth: AuthModel | undefined) => {
        setAuth(auth)

        if (auth) {
            authHelper.setAuth(auth)
        } else {
            authHelper.removeAuth()
        }
    }

    const logout = () => {
        saveAuth(undefined)
        setCurrentUser(undefined)
    }

    return (
        <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
            {children}
        </AuthContext.Provider>
    )
}

const AuthInit: FC<WithChildren> = ({children}) => {
    const {auth, logout, setCurrentUser} = useAuth()
    const didRequest = useRef(false)
    const [showSplashScreen, setShowSplashScreen] = useState(true)
    
    // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
    useEffect(() => {
        const requestUser = async (apiToken: string) => {
            try {
                if (!didRequest.current) {
                    const {data} = await authenticateUser()

                    if (data.data) {
                        const response: UserModel = data?.data?.attributes
                        const subscription: UserSubscriptionDto = {
                            id: response.subscription?.id,
                            type: response.subscription?.type || 'FREE',
                            amount: response.subscription?.amount || 0.0,
                            dateStart: response.subscription?.dateStart,
                            dateEnd: response.subscription?.dateEnd,
                            dateUpdate: response.subscription?.dateUpdate,
                            dateAdd: response.subscription?.dateAdd,
                        }
                        const profile: ProfileModel = {
                            id: data?.data?.id as any,
                            username: response.username || 'UNKNOWN',
                            countryCode: response.countryCode || '',
                            email: response.email || '',
                            role: response.role || 'USER',
                            profileImageId: response.profileImage?.id,
                            subscription: subscription,
                            timezone: response.timezone || 'UTC',
                            status: response.status || 'ACTIVE',
                            enabled: response.enabled || false,
                        }
                        setCurrentUser(profile)
                    }
                }
            } catch (error) {
                if (!didRequest.current) {
                    logout()
                }
            } finally {
                setShowSplashScreen(false)
            }
            return () => (didRequest.current = true)
        }

        if (auth && auth.accessToken) {
            requestUser(auth.accessToken)
        } else {
            logout()
            setShowSplashScreen(false)
        }
        // eslint-disable-next-line
    }, [])

    return showSplashScreen ? <LayoutSplashScreen/> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
