/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation} from 'react-query'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {ID, KTSVG} from '../../../../../../_metronic/helpers'
import {useAccountQueryResponse} from '../../core/QueryResponseProvider'
import {deleteAccountById} from "../../core/_requests";
import Swal from "sweetalert2";
import {useIntl} from "react-intl";
import {useAccountListView} from "../../../../../common/provider/account/AccountListViewProvider";
import {AccountDto} from "../../../../../common/dto/account/AccountDto";

type Props = {
    account: AccountDto
}

const AccountActionsCell: FC<Props> = ({account}) => {
    const {setItemIdForUpdate, setItemIdForUpdateDepositHistory, setItemForDelete} = useAccountListView()
    const {query, refetch: refetchAccounts} = useAccountQueryResponse()
    const intl = useIntl()

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const openEditModal = () => {
        setItemIdForUpdate(account)
    }

    const updateDeposit = () => {
        setItemIdForUpdateDepositHistory(account.id)
    }

    const showDeleteModal = (accountId: ID, name?: string) => {
        return Swal.fire({
            html: intl.formatMessage({id: 'ACCOUNTS.DELETE.MODAL.QUESTION.BODY'}) + ' <span class="text-dark">' + name + '</span>?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: intl.formatMessage({id: 'ACCOUNTS.DELETE.MODAL.QUESTION.BUTTON.YES'}),
            cancelButtonText: intl.formatMessage({id: 'ACCOUNTS.DELETE.MODAL.QUESTION.BUTTON.NO'}),
        }).then((result: any) => {
            if (result.isConfirmed) {
                deleteAccountById(account.id)
                    .then(() => {
                        Swal.fire(
                            intl.formatMessage({id: 'ACCOUNTS.DELETE.MODAL.SUCCESS.TITLE'}),
                            intl.formatMessage({id: 'ACCOUNTS.DELETE.MODAL.SUCCESS.BODY'}).replace('%NAME%', name || ''),
                            'success'
                        )
                        refetchAccounts()
                    }).catch(error => console.log("Could not delete account: " + account.name + "! Reason: " + error))
            }
        })
    }

    const deleteItemMutation = useMutation(() => showDeleteModal(account.id, account.name))

    return (
        <>
            <a
                href='#'
                className='btn btn-light btn-active-light-primary btn-sm text-gray-800'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
            >
                {intl.formatMessage({id: "ACCOUNTS.ACTIONS"})}
                <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0'/>
            </a>
            {/* begin::Menu */}
            <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fs-7 w-150px py-4'
                data-kt-menu='true'
            >
                {/* begin::Menu item */}
                <div className='menu-item px-3'>
                    <a className='menu-link px-3' onClick={openEditModal}>
                        {intl.formatMessage({id: "ACCOUNTS.EDIT"})}
                    </a>
                </div>
                {/* end::Menu item */}

                {/* begin::Menu item */}
                <div className='menu-item px-3'>
                    <a
                        className='menu-link px-3'
                        onClick={updateDeposit}
                    >
                        {intl.formatMessage({id: "ACCOUNTS.UPDATE_DEPOSIT"})}
                    </a>
                </div>
                {/* end::Menu item */}

                {/* begin::Menu item */}
                <div className='menu-item px-3'>
                    <a
                        className='menu-link px-3'
                        onClick={async () => await deleteItemMutation.mutateAsync()}
                    >
                        {intl.formatMessage({id: "ACCOUNTS.DELETE"})}
                    </a>
                </div>
                {/* end::Menu item */}
            </div>
        </>
    )
}

export {AccountActionsCell}
