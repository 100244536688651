import React, {FC, useContext, useMemo} from "react";
import {AccountDto} from "../../../../common/dto/account/AccountDto";
import {AccountQueryResponseContext} from "../../../../common/provider/account/AccountDataProvider";
import {ID} from "../../../../../_metronic/helpers";
import {useUserSettingsContext} from "../../../../common/provider/settings/UserSettingsDataProvider";
import {UserSettingsMapper} from "../../../../common/mapper/settings/UserSettingsMapper";
import {updateUserSettings} from "../../../../common/request/settings/UserSettingsRequest";
import {logger} from "../../../../../index";

type AccountChangeCallback = (value: AccountDto) => void;

type Prop = {
    callback: AccountChangeCallback
}

const CurrentAccountsSelectBox: FC<Prop> = (props) => {
    const {callback} = props
    const {response: accounts} = useContext(AccountQueryResponseContext)
    const {response: userSettings} = useUserSettingsContext()
    const accountId = userSettings?.settings?.account?.selectedId || ''
    const userSettingsMapper = useMemo(() => new UserSettingsMapper(), [])

    if (accounts && userSettings) {
        const isSavedAccountExists = accounts?.find((element, index, array) => element.id === accountId)

        if (!isSavedAccountExists
                && accounts?.length > 0) {
            logger.info("Update account in user settings")

            const updatedSettings = userSettingsMapper.updateSelectedAccountId(userSettings, accounts[0].id)

            updateUserSettings(updatedSettings)
        } else if (!isSavedAccountExists) {
            logger.info("Remove non exists account from user settings")

            const updatedSettings = userSettingsMapper.updateSelectedAccountId(userSettings, undefined)

            updateUserSettings(updatedSettings)
        }
    }
    const onChange = (accountId: ID) => {
        const updatedSettings = userSettingsMapper.updateSelectedAccountId(userSettings, accountId)

        updateUserSettings(updatedSettings)

        callback(
            accounts?.find(account => account.id === accountId) || {}
        )
    }
    const getBrokerAccountId = (account: AccountDto) => {
        if (account.brokerAccountId) {
            return `(${account.brokerAccountId})`
        }
        return ''
    }
    return (
        <select
            className="form-select form-select-transparent text-dark fs-7 lh-1 py-0 ps-3 w-auto cursor-pointer"
            data-hide-search="true" data-control="select2" data-dropdown-css-class="w-150px"
            data-placeholder="Select an option"
            value={accountId}
            onChange={(event) => onChange(event.target.value as any)}
        >
            {accounts?.map(account => <option key={account.id} value={account.id as any}>{account.name} {getBrokerAccountId(account)}</option>)}
        </select>
    )
}

export {CurrentAccountsSelectBox}
