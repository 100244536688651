import axios, {AxiosResponse} from 'axios'
import {ID} from "../../../../../_metronic/helpers";
import {JsonApiTradeModel} from "./_models";
import {EmptyJsonApiArrayResponseDto} from "../../../../common/dto/json-api/JsonApiDto";

const API_URL = process.env.REACT_APP_API_URL
const TRADE_URL = (accountId: ID) => `${API_URL}/accounts/${accountId}/trades`

const getTrades = (accountId: ID, query: string): Promise<JsonApiTradeModel> => {
    if (!accountId) {
        return Promise.resolve(EmptyJsonApiArrayResponseDto)
    }
    return axios.get(`${TRADE_URL(accountId)}?${query}`)
        .then((response: AxiosResponse<JsonApiTradeModel>) => response.data)
}

const deleteSelectedTrades = (accountId: ID, tradeIds: Array<ID>): Promise<void> => {
    const requests = tradeIds.map((tradeId) => deleteTradeById(accountId, tradeId))

    return axios.all(requests)
        .then(() => {

        })
}

const deleteTradeById = (accountId: ID, tradeId: ID): Promise<void> => {
    return axios.delete(`${TRADE_URL(accountId)}/${tradeId}`)
}

export {getTrades, deleteTradeById, deleteSelectedTrades}
