import {ID, KTSVG} from "../../../../../_metronic/helpers";
import React, {Dispatch, FC, SetStateAction, useEffect, useMemo, useState} from "react";
import {
    AccountDataProvider,
    AccountDataResponseProvider
} from "../../../../common/provider/account/AccountDataProvider";
import {useTradeQueryResponse} from "../../../../common/provider/trade/TradeDataProvider";
import {useTradeModelListViewContext} from "../../../../common/provider/trade/TradeListViewProvider";
import {TradeListGrouping} from "./TradeListGrouping";
import {FinanceResultToolbar} from "../../../dashboard/component/FinanceResultToolbar";
import {AccountDto} from "../../../../common/dto/account/AccountDto";
import {useIntl} from "react-intl";
import {CurrentAccountsSelectBox} from "../../../accounts/accounts-list/components/CurrentAccountsSelectBox";
import Select from "react-select";
import {getAllTags} from "../../../../common/request/tag/TagRequest";
import {JsonApiTagDtoArrayDto} from "../../../../common/dto/tag/TagDto";
import {useSearchParams} from "react-router-dom";
import {QueryParam} from "../../../../common/utils/query/QueryParam";
import {JsonApiQueryFilterField} from "../../../../common/dto/json-api/JsonApiQueryFilter";
import {logger} from "../../../../../index";
import {useUserSettingsContext} from "../../../../common/provider/settings/UserSettingsDataProvider";
import {updateUserSettings} from "../../../../common/request/settings/UserSettingsRequest";
import {UserSettingsMapper} from "../../../../common/mapper/settings/UserSettingsMapper";
import {getFilterSelectedOptions} from "../../../../common/utils/filter/FilterUtils";
import {MenuComponent} from "../../../../../_metronic/assets/ts/components";
import {getAvailableColumns, getDisabledByDefaultColumns} from "../../../../common/dto/trade/TradeDto";
import moment from "moment/moment";
import {useThemeMode} from "../../../../../_metronic/partials";
import {ImportTradeReportModal} from "../../modal/ImportTradeReportModal";
import {ExcludeDaysFilter} from "../../../../common/component/filter/ExcludeDaysFilter";
import {ExcludeHoursFilter, excludeHoursFilterOptions} from "../../../../common/component/filter/ExcludeHoursFilter";

type Prop = {
    allColumns: Array<string>
    hiddenColumns: Array<string>
    account?: AccountDto
    setAccount: Dispatch<SetStateAction<AccountDto>>
    setHiddenColumns: Dispatch<SetStateAction<Array<string>>>
    isUpdateDeposit?: boolean
    setIsUpdateDeposit?: Dispatch<SetStateAction<boolean>>
}

const sideFilterOptions = [
    {value: 'ALL', label: 'All'},
    {value: 'LONG', label: 'Long'},
    {value: 'SHORT', label: 'Short'},
]

const openTypeFilterOptions = [
    {value: 'LEVEL_BREAKDOWN', label: 'Level Breakdown'},
    {value: 'LEVEL_REBOUND', label: 'Level Rebound'},
    {value: 'LEVEL_RETEST', label: 'Level Retest'},
    {value: 'FALSE_BREAKDOWN_TWO_BARS', label: 'False Breakdown With Two Bars'},
    {value: 'FALSE_BREAKDOWN_MANY_BARS', label: 'False Breakdown With Many Bars'},
    {value: 'FALSE_BREAKDOWN', label: 'False Breakdown'},
    {value: 'TILT', label: 'Tilt'},
    {value: 'OTHER', label: 'Other'},
    {value: 'NOT_DEFINED', label: 'Not Defined'},
]

const closeTypeFilterOptions = [
    {value: 'TAKE_PROFIT', label: 'Take Profit'},
    {value: 'STOP_LOSS', label: 'Stop Loss'},
    {value: 'BREAKEVEN', label: 'Breakeven'},
    {value: 'OTHER', label: 'Other'},
]

const stateFilterOptions = [
    {value: 'COMPLETE', label: 'Complete'},
    {value: 'OPEN', label: 'Open'},
]

type DateRangeDto = {
    from?: string,
    to?: string,
}

const TradeListHeader: FC<Prop> = (props) => {
    const intl = useIntl()
    const {isDark} = useThemeMode()
    const [dateRange, setDateRange] = useState<DateRangeDto>({})
    const {response: userSettings} = useUserSettingsContext()
    const {account, setAccount, allColumns, hiddenColumns, setHiddenColumns, isUpdateDeposit, setIsUpdateDeposit} = props
    const {setItemForCreate, selected} = useTradeModelListViewContext()
    const [accountId, setAccountId] = useState<ID>(userSettings?.settings?.account?.selectedId)
    const [searchParams, setSearchParams] = useSearchParams()
    const queryParam = useMemo(() => new QueryParam(searchParams, setSearchParams), [])
    const {refetch: refetchAllTrades} = useTradeQueryResponse()
    const [tagsFilterOptions, setTagsFilterOptions] = useState<Array<any>>([])
    const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false)
    const [selectedSymbolFilter, setSelectedSymbolFilter] = useState<string>(searchParams.get(JsonApiQueryFilterField.SYMBOL) || '')
    const [selectedSideFilter, setSelectedSideFilter] = useState<any>(getFilterSelectedOptions(searchParams, sideFilterOptions, JsonApiQueryFilterField.SIDE))
    const [selectedOpenTypeFilter, setSelectedOpenTypeFilter] = useState<any>(getFilterSelectedOptions(searchParams, openTypeFilterOptions, JsonApiQueryFilterField.OPEN_TYPE))
    const [selectedCloseTypeFilter, setSelectedCloseTypeFilter] = useState<any>(getFilterSelectedOptions(searchParams, closeTypeFilterOptions, JsonApiQueryFilterField.CLOSE_TYPE))
    const [selectedStateFilter, setSelectedStateFilter] = useState<any>(getFilterSelectedOptions(searchParams, stateFilterOptions, JsonApiQueryFilterField.STATE))
    const [selectedTagsFilter, setSelectedTagsFilter] = useState<any>(getFilterSelectedOptions(searchParams, tagsFilterOptions, JsonApiQueryFilterField.TAGS))
    const [totalFiltersApplied, setTotalFiltersApplied] = useState<number>(queryParam.getTotalAppliedFilters(JsonApiQueryFilterField.SYMBOL))
    const [openImportTradesModal, setOpenImportTradesModal] = useState(false)
    const userSettingsMapper = new UserSettingsMapper()
    const availableColumns: {[key: string]: string} = useMemo(() => getAvailableColumns(intl), [])
    const disabledByDefaultColumns: {[key: string]: boolean} = useMemo(() => getDisabledByDefaultColumns(), [])
    const [selectedExcludeDaysFilter, setSelectedExcludeDaysFilter] = useState<any>(getFilterSelectedOptions(searchParams, excludeHoursFilterOptions, JsonApiQueryFilterField.DAYS))
    const [selectedExcludeHoursFilter, setSelectedExcludeHoursFilter] = useState<any>(getFilterSelectedOptions(searchParams, excludeHoursFilterOptions, JsonApiQueryFilterField.HOURS))
    const ranges: {[key: string]: any} = {}

    ranges[intl.formatMessage({id: "PERIOD.CALENDAR.TODAY"})] = [moment(), moment()]
    ranges[intl.formatMessage({id: "PERIOD.CALENDAR.YESTERDAY"})] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')]
    ranges[intl.formatMessage({id: "PERIOD.CALENDAR.THIS_MONTH"})] = [moment().startOf('month'), moment().endOf('month')]
    ranges[intl.formatMessage({id: "PERIOD.CALENDAR.LAST_MONTH"})] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    ranges[intl.formatMessage({id: "PERIOD.CALENDAR.LAST_QUARTER"})] = [moment().subtract(1, 'quarter').startOf('quarter'), moment().subtract(1, 'quarter').endOf('quarter')]
    ranges[intl.formatMessage({id: "PERIOD.CALENDAR.YEAR"})] = [moment().startOf('year')]

    const updateTrades = (account: AccountDto) => {
        const updatedSettings = userSettingsMapper.updateSelectedAccountId(userSettings, account?.id)

        updateUserSettings(updatedSettings)

        setAccountId(account?.id)
        setAccount(account)

        refetchAllTrades()
    }

    useEffect(() => {
        getAllTags().then((response: JsonApiTagDtoArrayDto) => {
            const tagsFilter: Array<any> = []

            response.data?.forEach(tagData => {
                const tag = tagData.attributes
                tag.id = tagData.id

                tagsFilter.push({value: tag.id, label: tag.name})
            })
            setTagsFilterOptions(tagsFilter)
        })
    }, [])

    useEffect(() => {
        setSelectedTagsFilter(getFilterSelectedOptions(searchParams, tagsFilterOptions, JsonApiQueryFilterField.TAGS))
    }, [tagsFilterOptions])

    useEffect(() => {
        setTotalFiltersApplied(queryParam.getTotalAppliedFilters(JsonApiQueryFilterField.SYMBOL))
    }, [queryParam.getTotalAppliedFilters(JsonApiQueryFilterField.SYMBOL)])

    useEffect(() => {
        MenuComponent.isDropdownActive = showFiltersModal
    }, [showFiltersModal])

    useEffect(() => {
        setHiddenColumns(userSettings?.settings?.table?.trades?.hiddenColumns || Object.keys(disabledByDefaultColumns))
    }, [userSettings?.settings?.table?.trades?.hiddenColumns])

    useEffect(() => {
        setAccountId(userSettings?.settings?.account?.selectedId)
    }, [userSettings?.settings?.account?.selectedId])

    const addQueryParam = (key: string, options: any) => {
        setShowFiltersModal(true)

        queryParam.append(key, options)
    }

    const applyQueryFilters = () => {
        setShowFiltersModal(false)

        queryParam.apply()

        setSelectedSymbolFilter(queryParam.getByKey(JsonApiQueryFilterField.SYMBOL) || '')

        refetchAllTrades()
    }

    const resetQueryFilters = () => {
        setShowFiltersModal(false)

        queryParam.resetFilters()

        setSelectedSymbolFilter('')
        setSelectedSideFilter(null)
        setSelectedOpenTypeFilter(null)
        setSelectedCloseTypeFilter(null)
        setSelectedStateFilter(null)
        setSelectedTagsFilter(null)

        refetchAllTrades()
    }

    const updateHiddenColumns = (updatedColumn: string, event: any) => {
        logger.debug(`Column: ${updatedColumn} ${event.currentTarget.checked ? 'checked' : 'unchecked'}`)

        let updatedHiddenColumns = hiddenColumns.map(column => column)

        if (!event.currentTarget.checked) {
            updatedHiddenColumns.push(updatedColumn)
        } else {
            updatedHiddenColumns = hiddenColumns.filter(column => column !== updatedColumn)
        }
        setHiddenColumns(updatedHiddenColumns)

        const updatedSettings = userSettingsMapper.updateTradesHiddenColumns(userSettings, updatedHiddenColumns)

        updateUserSettings(updatedSettings)
    }

    return (
        <div className='card-header border-0 pt-6'>
            {/* begin::Card toolbar */}
            <div className='d-flex align-items-center position-relative my-1'>
                <FinanceResultToolbar accountId={accountId} isUpdateDeposit={isUpdateDeposit} setIsUpdateDeposit={setIsUpdateDeposit} header={intl.formatMessage({id: 'BREADCRUMB.TRADES.LIST'})}/>
            </div>
            <div className="d-flex align-items-center flex-wrap gap-3 gap-xl-9">
                <div className="d-flex align-items-center position-relative my-1">
                    {/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}
                    <span className="svg-icon svg-icon-3 position-absolute ms-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546"
                                  height="2" rx="1" transform="rotate(45 17.0365 15.1223)"
                                  fill="currentColor"></rect>
                            <path
                                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                fill="currentColor"></path>
                        </svg>
                    </span>
                    {/*end::Svg Icon*/}
                    <input type="text"
                           className="form-control form-control-sm bg-body w-150px ps-10 "
                           placeholder={intl.formatMessage({id: 'TRADES.SEARCH'})}
                           value={selectedSymbolFilter}
                           onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                               queryParam.append(JsonApiQueryFilterField.SYMBOL, {value: event.currentTarget.value})

                               applyQueryFilters()
                           }}/>
                </div>
                <button type="button"
                        className="btn btn-sm btn-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                        onClick={() => setShowFiltersModal(!showFiltersModal)}>
                    {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
                    <span className="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="none">
                            <path
                                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                fill="currentColor"></path>
                        </svg>
                    </span>
                    {/*end::Svg Icon*/}
                    {intl.formatMessage({id: 'TRADES.FILTER'})}
                    {totalFiltersApplied > 0 && <span>({totalFiltersApplied})</span>}
                </button>
                <div className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
                     data-kt-menu="true"
                     id='kt_menu'>
                    {/*begin::Header*/}
                    <div className="px-7 py-5">
                        <div className="fs-5 text-dark">
                            {intl.formatMessage({id: 'TRADES.FILTER.OPTIONS'})}
                        </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Separator*/}
                    <div className="separator border-gray-200"></div>
                    {/*end::Separator*/}
                    {/*begin::Content*/}
                    <div className="px-7 py-5" data-kt-user-table-filter="form">
                        {/*begin::Input group*/}
                        <div className="mb-10">
                            <Select options={sideFilterOptions}
                                    placeholder={intl.formatMessage({id: 'TRADES.SIDE'})}
                                    value={selectedSideFilter}
                                    onChange={(options: any) => addQueryParam(JsonApiQueryFilterField.SIDE, options)}/>
                        </div>
                        <div className="mb-10">
                            <Select options={openTypeFilterOptions}
                                    placeholder={intl.formatMessage({id: 'TRADES.OPEN_TYPE'})}
                                    isMulti
                                    value={selectedOpenTypeFilter}
                                    onChange={(options: any) => {
                                        setSelectedOpenTypeFilter(options)

                                        addQueryParam(JsonApiQueryFilterField.OPEN_TYPE, options)
                                    }}/>
                        </div>
                        <div className="mb-10">
                            <Select options={closeTypeFilterOptions}
                                    isMulti
                                    placeholder={intl.formatMessage({id: 'TRADES.CLOSE_TYPE'})}
                                    value={selectedCloseTypeFilter}
                                    onChange={(options: any) => {
                                        setSelectedCloseTypeFilter(options)

                                        addQueryParam(JsonApiQueryFilterField.CLOSE_TYPE, options)
                                    }}/>
                        </div>
                        <div className="mb-10">
                            <Select options={stateFilterOptions}
                                    placeholder={intl.formatMessage({id: 'TRADES.STATE'})}
                                    value={selectedStateFilter}
                                    onChange={(options: any) => addQueryParam(JsonApiQueryFilterField.STATE, options)}/>
                        </div>
                        <div className="mb-10">
                            <Select options={tagsFilterOptions}
                                    isMulti
                                    isClearable
                                    placeholder={intl.formatMessage({id: 'TRADES.TAGS'})}
                                    value={selectedTagsFilter}
                                    onChange={(options: any) => {
                                        setSelectedTagsFilter(options)

                                        addQueryParam(JsonApiQueryFilterField.TAGS, options)
                                    }}/>
                        </div>

                        <ExcludeDaysFilter selectedExcludeDaysFilter={selectedExcludeDaysFilter}
                                           setSelectedExcludeDaysFilter={setSelectedExcludeDaysFilter}
                                           addQueryParam={addQueryParam}/>

                        <ExcludeHoursFilter selectedExcludeHoursFilter={selectedExcludeHoursFilter}
                                           setSelectedExcludeHoursFilter={setSelectedExcludeHoursFilter}
                                           addQueryParam={addQueryParam}/>

                        {/*end::Input group*/}
                        {/*begin::Actions*/}
                        <div className="d-flex justify-content-end">
                            <button type="reset" className="btn btn-light btn-active-light-primary me-2 px-6"
                                    data-kt-menu-dismiss="true"
                                    data-kt-user-table-filter="reset"
                                    onClick={resetQueryFilters}>
                                {intl.formatMessage({id: 'BUTTON.RESET'})}
                            </button>
                            <button type="button" className="btn btn-primary px-6" data-kt-menu-dismiss="true"
                                    data-kt-user-table-filter="filter"
                                    onClick={applyQueryFilters}>
                                {intl.formatMessage({id: 'BUTTON.APPLY'})}
                            </button>
                        </div>
                        {/*end::Actions*/}
                    </div>
                    {/*end::Content*/}
                </div>
                <button type="button"
                        className="btn btn-sm btn-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                        onClick={() => setOpenImportTradesModal(true)}>
                    <span className="svg-icon svg-icon-2">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect opacity="0.3" width="12" height="2" rx="1" transform="matrix(0 -1 -1 0 12.75 19.75)"
                                  fill="currentColor"/>
                            <path
                                d="M12.0573 17.8813L13.5203 16.1256C13.9121 15.6554 14.6232 15.6232 15.056 16.056C15.4457 16.4457 15.4641 17.0716 15.0979 17.4836L12.4974 20.4092C12.0996 20.8567 11.4004 20.8567 11.0026 20.4092L8.40206 17.4836C8.0359 17.0716 8.0543 16.4457 8.44401 16.056C8.87683 15.6232 9.58785 15.6554 9.9797 16.1256L11.4427 17.8813C11.6026 18.0732 11.8974 18.0732 12.0573 17.8813Z"
                                fill="currentColor"/>
                            <path
                                d="M18.75 15.75H17.75C17.1977 15.75 16.75 15.3023 16.75 14.75C16.75 14.1977 17.1977 13.75 17.75 13.75C18.3023 13.75 18.75 13.3023 18.75 12.75V5.75C18.75 5.19771 18.3023 4.75 17.75 4.75L5.75 4.75C5.19772 4.75 4.75 5.19771 4.75 5.75V12.75C4.75 13.3023 5.19771 13.75 5.75 13.75C6.30229 13.75 6.75 14.1977 6.75 14.75C6.75 15.3023 6.30229 15.75 5.75 15.75H4.75C3.64543 15.75 2.75 14.8546 2.75 13.75V4.75C2.75 3.64543 3.64543 2.75 4.75 2.75L18.75 2.75C19.8546 2.75 20.75 3.64543 20.75 4.75V13.75C20.75 14.8546 19.8546 15.75 18.75 15.75Z"
                                fill="#C4C4C4"/>
                        </svg>
                    </span>
                    {intl.formatMessage({id: 'TRADES.IMPORT'})}
                </button>
                <span>
                    <a href="#"
                       className="btn btn-sm btn-light-primary btn-active-light-primary"
                       data-kt-menu-trigger="click"
                       data-kt-menu-placement="bottom-end"
                       data-bs-toggle="tooltip"
                       data-bs-placement="top" title=""
                       data-bs-original-title="Settings">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen019.svg*/}
                        <span className="svg-icon svg-icon-2 m-3">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z"
                                    fill="currentColor"/>
                                <path opacity="0.3"
                                      d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z"
                                      fill="currentColor"/>
                            </svg>
                        </span>
                        {intl.formatMessage({id: 'TRADES.COLUMNS'})}
                        {/*end::Svg Icon*/}
                    </a>
                    {/*begin::Menu*/}

                    <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fs-7 w-250px py-4"
                        data-kt-menu="true">
                        <div className="px-4 py-5">
                            {hiddenColumns !== undefined && allColumns.map(column => (
                                <div className="mb-3" key={column}>
                                    <div className="form-check form-switch form-check-custom form-check-solid me-10">
                                        <div className="menu-item px-3">
                                            <input className="form-check-input h-20px w-30px"
                                                   type="checkbox"
                                                   id={column}
                                                   defaultChecked={hiddenColumns.indexOf(column) === -1}
                                                   onChange={(event) => updateHiddenColumns(column, event)}/>
                                            <label className="form-check-label" htmlFor={column}>
                                                {availableColumns[column]}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/*end::Menu*/}
                </span>

                <div className="d-flex align-items-center fw-bolder">
                    <div className={"text-muted fs-7 fw-normal " + (isDark ? 'text-gray-800' : 'text-gray-700')}>
                        {intl.formatMessage({id: 'ACCOUNT.CURRENT'})}
                    </div>
                    <AccountDataProvider>
                        <AccountDataResponseProvider>
                            <CurrentAccountsSelectBox callback={updateTrades}/>
                        </AccountDataResponseProvider>
                    </AccountDataProvider>
                </div>
                {selected.length > 0 ? (
                    <TradeListGrouping account={account} setAccount={setAccount}/>
                ) : (
                    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                        <button type='button' className='btn btn-primary' onClick={() => setItemForCreate(accountId)}>
                            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2'/>
                            {intl.formatMessage({id: 'TRADES.ADD_TRADE'})}
                        </button>
                    </div>
                )}
            </div>
            {/* end::Card toolbar */}
            {openImportTradesModal && <ImportTradeReportModal accountId={accountId} setOpenImportTradesModal={setOpenImportTradesModal}/> }
        </div>
    )
}

export {TradeListHeader, closeTypeFilterOptions}
