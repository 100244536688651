import {IntlShape} from "react-intl";

export type WidgetDto = {

}

export enum WidgetKey {
    TRADES_TOTAL_RESULT = "totalResult",
    TRADES_TODAY_RESULT = "todayResult",
    DEPOSIT = "deposit",
    TOTAL_TRADE_PROFIT = "totalTradeProfit",
    TOTAL_TRADE_LOSS = "totalTradeLoss",
    AVG_TRADE_PROFIT = "avgTradeProfit",
    AVG_TRADE_LOSS = "avgTradeLoss",
    TRADE_DAYS = "tradeDays",
    TOTAL_TRADES = "totalTrades",
    PROFIT_TRADES_PERCENT = "profitTradesPercent",
    MAX_DRAWDOWN = "maxDrawdown",
    AVG_TRADE_TIME = "avgTradeTime",
    LONG_TRADES = "longTrades",
    SHORT_TRADES = "shortTrades",
    BREAKEVEN_TRADES = "breakevenTrades",
    COMMISSION = "commission",
}

export const getAvailableWidgets = (intl: IntlShape) => {
    return {
        totalResult: intl.formatMessage({id: "DASHBOARD.TOTAL_RESULT"}),
        todayResult: intl.formatMessage({id: "DASHBOARD.TODAY_RESULT"}),
        deposit: intl.formatMessage({id: "DASHBOARD.DEPOSIT"}),
        totalTradeProfit: intl.formatMessage({id: "DASHBOARD.TOTAL_TRADE_PROFIT"}),
        totalTradeLoss: intl.formatMessage({id: "DASHBOARD.TOTAL_TRADE_LOSS"}),
        avgTradeProfit: intl.formatMessage({id: "DASHBOARD.AVG_TRADE_PROFIT"}),
        avgTradeLoss: intl.formatMessage({id: "DASHBOARD.AVG_TRADE_LOSS"}),
        tradeDays: intl.formatMessage({id: "DASHBOARD.TRADE_DAYS"}),
        totalTrades: intl.formatMessage({id: "DASHBOARD.TOTAL_TRADES"}),
        profitTradesPercent: intl.formatMessage({id: "DASHBOARD.PROFIT_TRADES_PERCENT"}),
        maxDrawdown: intl.formatMessage({id: "DASHBOARD.MAX_DRAWDOWN"}),
        longTrades: intl.formatMessage({id: "DASHBOARD.LONG_TRADES"}),
        shortTrades: intl.formatMessage({id: "DASHBOARD.SHORT_TRADES"}),
        breakevenTrades: intl.formatMessage({id: "DASHBOARD.BREAKEVEN_TRADES"}),
        commission: intl.formatMessage({id: "DASHBOARD.COMMISSION"}),
        avgTradeTime: intl.formatMessage({id: "DASHBOARD.AVG_TRADE_TIME"}),
    }
}

export const getDisabledByDefaultWidgets = () => {
    return {
        todayResult: true,
        totalTradeProfit: true,
        totalTradeLoss: true,
        avgTradeTime: true,
    }
}
