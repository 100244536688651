import {FC, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
//import TradePage from "../modules/trades/TradePage";
//import {DashboardWrapper} from "../modules/dashboard/DashboardWrapper";
import {useAuth} from "../modules/auth";
import {WithChildren} from "../../_metronic/helpers";
import {DashboardWrapper} from "../modules/dashboard/DashboardWrapper";
import {JournalPage} from "../modules/journal/JournalPage";
import {JournalNewPostPage} from "../modules/journal/JournalNewPostPage";
import {JournalEditPostPage} from "../modules/journal/JournalEditPostPage";
import AccountPage from "../modules/accounts/AccountPage";
import TradePage from "../modules/trades/TradePage";
import ProfilePage from "../modules/profile/ProfilePage";
import UsersPage from "../modules/user/UsersPage";
import {TradeChart} from "../modules/trades/components/chart/TradeChart";
//import {JournalPage} from "../modules/journal/JournalPage";
//import {JournalNewPostPage} from '../modules/journal/JournalNewPostPage'
//import {JournalEditPostPage} from "../modules/journal/JournalEditPostPage";

const PrivateRoutes = () => {
    const {currentUser} = useAuth()
    //const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
    //const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
    //const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

    return (
        <Routes>
            <Route element={<MasterLayout/>}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path='auth/*' element={<Navigate to='/dashboard'/>}/>
                <Route path=':lang/auth/*' element={<Navigate to={`/dashboard`}/>}/>
                {/* Pages */}
                <Route path='dashboard' element={<DashboardWrapper />} />
                <Route
                    path='profile/*'
                    element={
                        <SuspensedView>
                            <ProfilePage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='accounts/*'
                    element={
                        <SuspensedView>
                            <AccountPage/>
                        </SuspensedView>
                    }
                />
                {currentUser?.role === 'ADMIN' &&
                    <Route
                        path='users/*'
                        element={
                            <SuspensedView>
                                <UsersPage/>
                            </SuspensedView>
                        }
                    />
                }
                <Route
                    path='trades'
                    element={
                        <SuspensedView>
                            <TradePage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='trades/:id'
                    element={
                        <SuspensedView>
                            <TradeChart/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='journals'
                    element={
                        <SuspensedView>
                            <JournalPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='journals/posts/new'
                    element={
                        <SuspensedView>
                            <JournalNewPostPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='journals/posts/edit/:id'
                    element={
                        <SuspensedView>
                            <JournalEditPostPage/>
                        </SuspensedView>
                    }
                />
                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404'/>}/>
            </Route>
        </Routes>
    )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>
}

export {PrivateRoutes}
