import {createContext, Dispatch, FC, SetStateAction, useContext, useMemo, useState} from 'react'
import {
    calculatedGroupingIsDisabled,
    calculateIsAllDataSelected,
    groupingOnSelect,
    groupingOnSelectAll,
    ID,
    WithChildren,
} from '../../../../_metronic/helpers'
import {useTradeQueryResponse, useTradeQueryResponseData} from "./TradeDataProvider";
import {TradeModel} from "../../../modules/trades/table/core/_models";
import {PaginationDto} from "../../dto/pagination/PaginationDto";

export type TradeListViewContextProps = {
    selected: Array<ID>
    onSelect: (selectedId: ID) => void
    onSelectAll: () => void
    clearSelected: () => void
    // NULL => (CREATION MODE) | MODAL IS OPENED
    // NUMBER => (EDIT MODE) | MODAL IS OPENED
    // UNDEFINED => MODAL IS CLOSED
    itemIdForUpdate?: TradeModel
    setItemIdForUpdate: Dispatch<SetStateAction<TradeModel | undefined>>
    itemForCreate?: ID
    setItemForCreate: Dispatch<SetStateAction<ID | undefined>>
    itemForDelete?: ID
    setItemForDelete: Dispatch<SetStateAction<ID | undefined>>
    isAllSelected: boolean
    disabled: boolean
    commonError?: string | undefined
    setCommonError: Dispatch<SetStateAction<string | undefined>>
    imagesView?: TradeModel | undefined
    setImagesView: Dispatch<SetStateAction<TradeModel | undefined>>
    pagination?: PaginationDto | undefined
    setPagination: Dispatch<SetStateAction<PaginationDto | undefined>>
}

export const initialTradeListView: TradeListViewContextProps = {
    selected: [],
    onSelect: () => {},
    onSelectAll: () => {},
    clearSelected: () => {},
    setItemIdForUpdate: () => {},
    setItemForCreate: () => {},
    setItemForDelete: () => {},
    setCommonError: () => {},
    setImagesView: () => {},
    setPagination: () => {},
    isAllSelected: false,
    disabled: false,
}

const TradeListViewContext = createContext<TradeListViewContextProps>(initialTradeListView)

const TradeListViewProvider: FC<WithChildren> = ({children}) => {
    const [selected, setSelected] = useState<Array<ID>>(initialTradeListView.selected)
    const [itemIdForUpdate, setItemIdForUpdate] = useState<TradeModel | undefined>(initialTradeListView.itemIdForUpdate)
    const [itemForCreate, setItemForCreate] = useState<any>(initialTradeListView.itemForCreate)
    const [itemForDelete, setItemForDelete] = useState<any>(initialTradeListView.itemForCreate)
    const [commonError, setCommonError] = useState<any>(initialTradeListView.commonError)
    const [imagesView, setImagesView] = useState<any>(initialTradeListView.imagesView)
    const [pagination, setPagination] = useState<PaginationDto | undefined>(initialTradeListView.pagination)
    const {isLoading} = useTradeQueryResponse()
    const data = useTradeQueryResponseData()
    const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
    const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])

    return (
        <TradeListViewContext.Provider
            value={{
                selected,
                itemIdForUpdate,
                setItemIdForUpdate,
                itemForCreate,
                setItemForCreate,
                itemForDelete,
                setItemForDelete,
                imagesView,
                setImagesView,
                commonError,
                setCommonError,
                pagination,
                setPagination,
                disabled,
                isAllSelected,
                onSelect: (id: ID) => {
                    groupingOnSelect(id, selected, setSelected)
                },
                onSelectAll: () => {
                    groupingOnSelectAll(isAllSelected, setSelected, data)
                },
                clearSelected: () => {
                    setSelected([])
                },
            }}
        >
            {children}
        </TradeListViewContext.Provider>
    )
}

const useTradeModelListViewContext = () => useContext(TradeListViewContext)

export {TradeListViewProvider, useTradeModelListViewContext}
