import React, {Dispatch, FC, SetStateAction, useState} from "react";
import Select from "react-select";
import {JsonApiQueryFilterField} from "../../dto/json-api/JsonApiQueryFilter";
import {useIntl} from "react-intl";

type Props = {
    selectedExcludeDaysFilter: any
    setSelectedExcludeDaysFilter: Dispatch<SetStateAction<any>>
    addQueryParam: (key: string, options: any) => any
}

export const ExcludeDaysFilter: FC<Props> = (props) => {
    const intl = useIntl()
    const {selectedExcludeDaysFilter, setSelectedExcludeDaysFilter, addQueryParam} = props
    const [isExcludeFilter, setExcludeFilter] = useState<boolean>(false)
    const [excludeDaysFilterOptions] = useState([
        {value: "1", label: intl.formatMessage({id: "MONDAY"})},
        {value: "2", label: intl.formatMessage({id: "TUESDAY"})},
        {value: "3", label: intl.formatMessage({id: "WEDNESDAY"})},
        {value: "4", label: intl.formatMessage({id: "THURSDAY"})},
        {value: "5", label: intl.formatMessage({id: "FRIDAY"})},
        {value: "6", label: intl.formatMessage({id: "SATURDAY"})},
        {value: "7", label: intl.formatMessage({id: "SUNDAY"})},
    ])
    return (
        <>
            <div className="form-check form-switch form-check-custom form-check-solid mb-3">
                <input className="form-check-input h-20px w-30px"
                       type="checkbox"
                       value=""
                       id="include-days-checkbox"
                       checked={!isExcludeFilter}
                       onChange={() => {
                           const newValue = !isExcludeFilter

                           setExcludeFilter(newValue)

                           addQueryParam(`exclude_${JsonApiQueryFilterField.DAYS}`, {value: newValue + ''})
                       }}
                />
                <label className="form-check-label" htmlFor="include-days-checkbox">Include days</label>
            </div>
            <div className="mb-10">
                <Select options={excludeDaysFilterOptions}
                        isMulti
                        isClearable
                        placeholder='Days'
                        value={selectedExcludeDaysFilter}
                        onChange={(options: any) => {
                            setSelectedExcludeDaysFilter(options)

                            addQueryParam(JsonApiQueryFilterField.DAYS, options)
                        }}/>
            </div>
        </>
    )
}
