import React from 'react'
import {useAuth} from "../../auth";
import {useIntl} from "react-intl";

export function Overview() {
    const {currentUser} = useAuth()
    const intl = useIntl()

    return (
        <>
            <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
                <div className="card-header cursor-pointer">
                    {/*begin::Card title*/}
                    <div className="card-title m-0">
                        <h3 className="fw-bold m-0 text-muted">
                            {intl.formatMessage({id: "PROFILE.DETAILS"})}
                        </h3>
                    </div>
                    {/*end::Card title*/}
                </div>
                {/*begin::Card header*/}
                {/*begin::Card body*/}
                <div className="card-body p-9">
                    {/*begin::Input group*/}
                    <div className="row mb-7">
                        {/*begin::Label*/}
                        <label className="col-lg-4 fw-bold text-muted">
                            {intl.formatMessage({id: "PROFILE.COUNTRY"})}
                        </label>
                        {/*end::Label*/}
                        {/*begin::Col*/}
                        <div className="col-lg-8">
                            <span className="fw-bolder fs-6 text-gray-800">
                                {intl.formatMessage({id: `COUNTRY.CODE.${currentUser?.countryCode || 'US'}`})}
                            </span>
                        </div>
                        {/*end::Col*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="row mb-7">
                        {/*begin::Label*/}
                        <label className="col-lg-4 fw-bold text-muted">
                            {intl.formatMessage({id: "PROFILE.TIMEZONE"})}
                        </label>
                        {/*end::Label*/}
                        {/*begin::Col*/}
                        <div className="col-lg-8">
                            <span className="fw-bolder fs-6 text-gray-800">
                                {0 == 0 && '(GMT) UTC'}
                                {0 > 0 && `GMT+${currentUser?.timezone}`}
                                {0 < 0 && `GMT-${currentUser?.timezone}`}
                            </span>
                        </div>
                        {/*end::Col*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="row mb-7">
                        {/*begin::Label*/}
                        <label className="col-lg-4 fw-bold text-muted">
                            {intl.formatMessage({id: "PROFILE.STATUS"})}
                        </label>
                        {/*end::Label*/}
                        {/*begin::Col*/}
                        <div className="col-lg-8">
                            <span className="fw-bolder fs-6 text-gray-800">
                                <span className={"badge badge-light-" + (currentUser?.status === 'ACTIVE' ? 'success' : 'danger')}>
                                    {currentUser?.status}
                                </span>
                            </span>
                        </div>
                        {/*end::Col*/}
                    </div>
                    {/*end::Input group*/}
                </div>
                {/*end::Card body*/}
            </div>
        </>
    )
}
