import {useIntl} from "react-intl";
import React, {FC} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {toAbsoluteStaticUrl, toAbsoluteUrl} from "../../../../../_metronic/helpers";
import {TickerIconDto} from "../../../../common/dto/media/icon/TickerIconDto";

type Props = {
    id: string
    ticker: string
    icon?: TickerIconDto
}

const TradeSymbolCell: FC<Props> = (props) => {
    const intl = useIntl()
    const {id, ticker, icon} = props

    return (
        <div className='d-flex align-items-center'>
            <div className="symbol symbol-circle symbol-20px overflow-hidden me-3">
                <a href="#">
                    <div className="symbol-label">
                        {icon && <img src={toAbsoluteStaticUrl(`/static/icon/${icon.id}`)} style={{width: '14px', height: '14px', borderRadius: '50%'}} />}
                        {/*Icon /media/svg/no-icon.svg*/}
                        {!icon &&
                            <svg width='14px' height='14px' fill='#dddddd' version="1.1" id="Capa_1" viewBox="0 0 206.559 206.559">
                                <path d="M0,103.279c0,56.948,46.331,103.279,103.279,103.279s103.279-46.331,103.279-103.279S160.228,0,103.279,0
                                            S0,46.331,0,103.279z M170,103.279c0,36.79-29.931,66.721-66.721,66.721c-11.765,0-22.821-3.072-32.429-8.439L161.56,70.85
                                            C166.927,80.458,170,91.514,170,103.279z M103.279,36.559c11.765,0,22.821,3.072,32.429,8.439l-90.709,90.711
                                            c-5.368-9.608-8.44-20.664-8.44-32.43C36.559,66.489,66.489,36.559,103.279,36.559z"/>
                            </svg>
                        }
                    </div>
                </a>
            </div>
            <div className="d-flex flex-column">
                <OverlayTrigger
                    key='right'
                    placement='right'
                    overlay={
                        <Tooltip id={`tooltip-right`}>
                            {intl.formatMessage({id: 'TRADES.TOOLTIP_CLICK_VIEW_DETAILS'})}
                        </Tooltip>
                    }
                >
                    <a href={toAbsoluteUrl(`/trades/${id}`)} className='link-primary'>
                        {ticker}
                    </a>
                </OverlayTrigger>
            </div>
        </div>
    )
}

export {TradeSymbolCell}
