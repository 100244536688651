import {JsonApiQueryFilterField, JsonApiQueryFilterOperator} from "../../dto/json-api/JsonApiQueryFilter";

const getFilterSelectedOptions = (searchParams: URLSearchParams, options: any, key: string) => {
    if (!searchParams.get(key)) {
        return
    }
    const keySet: any = {}

    searchParams.get(key)?.split(',').forEach(key => keySet[key] = '')

    return options.filter((option: any) => option.value in keySet)
}

const getQueryFilters = (searchParams: URLSearchParams) => {
    const filters: Array<string> = []

    appendArrayFilterIfExists(searchParams, JsonApiQueryFilterField.SYMBOL, JsonApiQueryFilterOperator.CONTAIN, filters)
    appendArrayFilterIfExists(searchParams, JsonApiQueryFilterField.SIDE, JsonApiQueryFilterOperator.EQ, filters)
    appendArrayFilterIfExists(searchParams, JsonApiQueryFilterField.OPEN_TYPE, JsonApiQueryFilterOperator.IN, filters)
    appendArrayFilterIfExists(searchParams, JsonApiQueryFilterField.CLOSE_TYPE, JsonApiQueryFilterOperator.IN, filters)
    appendArrayFilterIfExists(searchParams, JsonApiQueryFilterField.OPEN_DATE, JsonApiQueryFilterOperator.EQ, filters)
    appendArrayFilterIfExists(searchParams, JsonApiQueryFilterField.CLOSE_DATE, JsonApiQueryFilterOperator.EQ, filters)
    appendArrayFilterIfExists(searchParams, JsonApiQueryFilterField.STATE, JsonApiQueryFilterOperator.IN, filters)
    appendArrayFilterIfExists(searchParams, JsonApiQueryFilterField.TAGS, JsonApiQueryFilterOperator.IN, filters)
    appendArrayFilterIfExists(searchParams, JsonApiQueryFilterField.HOURS, JsonApiQueryFilterOperator.IN, filters)
    appendArrayFilterIfExists(searchParams, JsonApiQueryFilterField.DAYS, JsonApiQueryFilterOperator.IN, filters)

    return filters
}

const appendArrayFilterIfExists = (searchParams: URLSearchParams, key: string, operator: string, filters: Array<any>) => {
    const excludeSearchParam = searchParams.get(`exclude_${key}`)
    const isExcludeOperator = (excludeSearchParam && excludeSearchParam === 'true' ? true : false)

    if (searchParams.get(key)) {
        let requiredOperator = operator

        if (isExcludeOperator) {
            if (operator === JsonApiQueryFilterOperator.CONTAIN) {
                requiredOperator = JsonApiQueryFilterOperator.NOT_CONTAIN
            } else if (operator === JsonApiQueryFilterOperator.IN) {
                requiredOperator = JsonApiQueryFilterOperator.NOT_IN
            } else if (operator === JsonApiQueryFilterOperator.EQ) {
                requiredOperator = JsonApiQueryFilterOperator.NE
            }
        }
        filters.push(`filter[${key}][${requiredOperator}]=${searchParams.get(key)}`)
    }
}

const queryFiltersToString = (filters: Array<string>) => {
    if (filters.length > 0) {
        return filters.join('&').replaceAll('[', '%5B').replaceAll(']', '%5D').replaceAll('%2C', ',')
    }
    return ''
}

export {
    getFilterSelectedOptions,
    getQueryFilters,
    queryFiltersToString,
}
