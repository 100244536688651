/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {register} from '../core/_requests'
import {Link, useNavigate} from 'react-router-dom'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import {MetaHeader} from "../../../common/component/meta/MetaHeader";
import {useIntl} from "react-intl";
import {GoogleOauth2Button} from "../../../common/component/auth/GoogleOauth2Button";
import {logger} from "../../../../index";

const initialValues = {
    username: '',
    email: '',
    password: '',
    changepassword: '',
    acceptTerms: true,
}

export function Registration() {
    const intl = useIntl()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const {saveAuth, setCurrentUser} = useAuth()
    const registrationSchema = Yup.object().shape({
        username: Yup.string()
            .min(3, intl.formatMessage({id: 'AUTH.REGISTER.VALIDATION.USERNAME.MINIMUM'}).replace('%VALUE%', '3'))
            .max(64, intl.formatMessage({id: 'AUTH.REGISTER.VALIDATION.USERNAME.MAXIMUM'}).replace('%VALUE%', '64'))
            .required(intl.formatMessage({id: 'AUTH.REGISTER.VALIDATION.USERNAME.REQUIRED'})),
        email: Yup.string()
            .email(intl.formatMessage({id: 'AUTH.REGISTER.VALIDATION.EMAIL.WRONG_FORMAT'}))
            .min(3, intl.formatMessage({id: 'AUTH.REGISTER.VALIDATION.EMAIL.MINIMUM'}).replace('%VALUE%', '3'))
            .max(50, intl.formatMessage({id: 'AUTH.REGISTER.VALIDATION.EMAIL.MAXIMUM'}).replace('%VALUE%', '50'))
            .required(intl.formatMessage({id: 'AUTH.REGISTER.VALIDATION.EMAIL.REQUIRED'})),
        password: Yup.string()
            .min(8, intl.formatMessage({id: 'AUTH.REGISTER.VALIDATION.PASSWORD.MINIMUM'}).replace('%VALUE%', '8'))
            .max(50, intl.formatMessage({id: 'AUTH.REGISTER.VALIDATION.PASSWORD.MAXIMUM'}).replace('%VALUE%', '50'))
            .required(intl.formatMessage({id: 'AUTH.REGISTER.VALIDATION.PASSWORD.REQUIRED'})),
        changepassword: Yup.string()
            .required(intl.formatMessage({id: 'AUTH.REGISTER.VALIDATION.PASSWORD_CONFIRMATION.REQUIRED'}))
            .when('password', {
                is: (val: string) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf([Yup.ref('password')], intl.formatMessage({id: 'AUTH.REGISTER.VALIDATION.PASSWORD_CONFIRMATION.INVALID'})),
            }),
        acceptTerms: Yup.bool().required(intl.formatMessage({id: 'AUTH.REGISTER.VALIDATION.ACCEPT_TERMS'})),
    })
    const formik = useFormik({
        initialValues,
        validationSchema: registrationSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)

            register(
                values.username,
                values.email,
                values.password,
                values.changepassword
            ).then(response => {
                navigate(`/auth/registration/confirm`)
            }).catch(errorResponse => {
                setSubmitting(false)
                setLoading(false)
                saveAuth(undefined)

                const error = errorResponse?.response?.data?.errors[0]

                logger.error(`Could not register user! Reason: ${error.detail}`)

                setStatus(error.detail || 'The registration details is incorrect')
            })
        },
    })

    useEffect(() => {
        PasswordMeterComponent.bootstrap()
    }, [])

    return (
        <>
            <MetaHeader pageName='HTML.HEAD.TITLE.REGISTER'/>
            <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_signup_form'
                onSubmit={formik.handleSubmit}
            >
                {/* begin::Heading */}
                <div className='mb-10 text-center'>
                    {/* begin::Title */}
                    <h1 className='text-dark mb-3'>
                        {intl.formatMessage({id: 'AUTH.REGISTER.CREATE_ACCOUNT'})}
                    </h1>
                    {/* end::Title */}

                    {/* begin::Link */}
                    <div className='text-gray-400 fs-4'>
                        {intl.formatMessage({id: 'AUTH.REGISTER.QUESTION.HAVE_ACCOUNT'})}
                        <Link to='/auth/password/forgot' className='link-primary' style={{marginLeft: '5px'}}>
                            {intl.formatMessage({id: 'AUTH.REGISTER.QUESTION.FORGOT_PASSWORD'})}
                        </Link>
                    </div>
                    {/* end::Link */}
                </div>
                {/* end::Heading */}

                {/* begin::Action */}
                <GoogleOauth2Button
                    buttonText='AUTH.REGISTER.BUTTON.SIGN_IN_WITH_GOOGLE'
                    setLoading={setLoading}
                    onError={(error) => formik.setStatus(error.message)}
                />
                {/* end::Action */}

                <div className='d-flex align-items-center mb-10'>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    <span className='text-gray-400 fs-7 mx-2'>
                        {intl.formatMessage({id: 'AUTH.REGISTER.BUTTON.OR'})}
                    </span>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                </div>

                {formik.status && (
                    <div className='mb-lg-15 alert alert-danger'>
                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                    </div>
                )}

                {/* begin::Form group Username */}
                <div className='fv-row mb-7'>
                    <label className='form-label text-dark fs-6'>
                        {intl.formatMessage({id: 'AUTH.REGISTER.USERNAME'})}
                    </label>
                    <input
                        placeholder={intl.formatMessage({id: 'AUTH.REGISTER.USERNAME'})}
                        type='username'
                        autoComplete='off'
                        {...formik.getFieldProps('username')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {'is-invalid': formik.touched.username && formik.errors.username},
                            {
                                'is-valid': formik.touched.username && !formik.errors.username,
                            }
                        )}
                    />
                    {formik.touched.username && formik.errors.username && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.username}</span>
                            </div>
                        </div>
                    )}
                </div>
                {/* end::Form group */}

                {/* begin::Form group Email */}
                <div className='fv-row mb-7'>
                    <label className='form-label text-dark fs-6'>
                        {intl.formatMessage({id: 'AUTH.REGISTER.EMAIL'})}
                    </label>
                    <input
                        placeholder={intl.formatMessage({id: 'AUTH.REGISTER.EMAIL'})}
                        type='email'
                        autoComplete='off'
                        {...formik.getFieldProps('email')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {'is-invalid': formik.touched.email && formik.errors.email},
                            {
                                'is-valid': formik.touched.email && !formik.errors.email,
                            }
                        )}
                    />
                    {formik.touched.email && formik.errors.email && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.email}</span>
                            </div>
                        </div>
                    )}
                </div>
                {/* end::Form group */}

                {/* begin::Form group Password */}
                <div className='mb-10 fv-row' data-kt-password-meter='true'>
                    <div className='mb-1'>
                        <label className='form-label text-dark fs-6'>
                            {intl.formatMessage({id: 'AUTH.REGISTER.PASSWORD'})}
                        </label>
                        <div className='position-relative mb-3'>
                            <input
                                type='password'
                                placeholder={intl.formatMessage({id: 'AUTH.REGISTER.PASSWORD'})}
                                autoComplete='off'
                                {...formik.getFieldProps('password')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    {
                                        'is-invalid': formik.touched.password && formik.errors.password,
                                    },
                                    {
                                        'is-valid': formik.touched.password && !formik.errors.password,
                                    }
                                )}
                            />
                            {formik.touched.password && formik.errors.password && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.password}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* begin::Meter */}
                        <div
                            className='d-flex align-items-center mb-3'
                            data-kt-password-meter-control='highlight'
                        >
                            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                        </div>
                        {/* end::Meter */}
                    </div>
                    <div className='text-muted'>
                        {intl.formatMessage({id: 'AUTH.REGISTER.PASSWORD_TOOLTIP'})}
                    </div>
                </div>
                {/* end::Form group */}

                {/* begin::Form group Confirm password */}
                <div className='fv-row mb-5'>
                    <label className='form-label text-dark fs-6'>
                        {intl.formatMessage({id: 'AUTH.REGISTER.CONFIRM_PASSWORD'})}
                    </label>
                    <input
                        type='password'
                        placeholder={intl.formatMessage({id: 'AUTH.REGISTER.CONFIRM_PASSWORD'})}
                        autoComplete='off'
                        {...formik.getFieldProps('changepassword')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                                'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                            },
                            {
                                'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                            }
                        )}
                    />
                    {formik.touched.changepassword && formik.errors.changepassword && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.changepassword}</span>
                            </div>
                        </div>
                    )}
                </div>
                {/* end::Form group */}

                {/* begin::Form group
                <div className='fv-row mb-10'>
                    <div className='form-check form-check-custom form-check-solid'>
                        <input
                            className='form-check-input'
                            type='checkbox'
                            id='kt_login_toc_agree'
                            {...formik.getFieldProps('acceptTerms')}
                        />
                        <label
                            className='form-check-label text-gray-700 fs-6'
                            htmlFor='kt_login_toc_agree'
                        >
                            {intl.formatMessage({id: 'AUTH.REGISTER.AGREE_TERM_OF_USE_PREFIX'})}
                            {' '}
                            <Link to='/auth/terms' className='ms-1 link-primary'>
                                {intl.formatMessage({id: 'AUTH.REGISTER.AGREE_TERM_OF_USE_SUFFIX'})}
                            </Link>
                        </label>
                        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.acceptTerms}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div> */}
                {/* end::Form group */}

                {/* begin::Form group */}
                <div className='text-center'>
                    <button
                        type='submit'
                        id='kt_sign_up_submit'
                        className='btn btn-lg btn-primary w-100 mb-5'
                        disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
                    >
                        {!loading && <span className='indicator-label'>
                        {intl.formatMessage({id: 'AUTH.REGISTER.BUTTON.CREATE'})}
                        </span>}
                        {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                        )}
                    </button>
                    <Link to='/auth/login'>
                        <button
                            type='button'
                            id='kt_login_signup_form_cancel_button'
                            className='btn btn-lg btn-light-primary w-100 mb-5'
                        >
                            {intl.formatMessage({id: 'BUTTON.CANCEL'})}
                        </button>
                    </Link>
                </div>
                {/* end::Form group */}
            </form>
        </>
    )
}
