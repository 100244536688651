import {UserSettingsDto} from "../../dto/settings/UserSettingsDto";
import {logger} from "../../../../index";
import {ID} from "../../../../_metronic/helpers";
import {ThemeModeType} from "../../../../_metronic/partials";

export enum SettingsPaginationKey {
    TRADES_PAGINATION_KEY = "trades",
    JOURNAL_PAGINATION_KEY = "journal",
}

export class UserSettingsMapper {
    updateTheme(userSettings: UserSettingsDto | undefined, theme: ThemeModeType) {
        const updatedSettings: UserSettingsDto = userSettings || {}

        if (!updatedSettings.settings) {
            updatedSettings.settings = {}
        }
        updatedSettings.settings.theme = theme

        return updatedSettings
    }

    updateLanguage(userSettings: UserSettingsDto | undefined, language: string) {
        const updatedSettings: UserSettingsDto = userSettings || {}

        if (!updatedSettings.settings) {
            updatedSettings.settings = {}
        }
        updatedSettings.settings.language = language

        return updatedSettings
    }

    updateSelectedAccountId(userSettings: UserSettingsDto | undefined, selectedAccountId: ID) {
        const updatedSettings: UserSettingsDto = userSettings || {}

        if (!updatedSettings.settings) {
            updatedSettings.settings = {}
        }

        if (!updatedSettings.settings.account) {
            updatedSettings.settings.account = {}
        }
        updatedSettings.settings.account.selectedId = selectedAccountId

        return updatedSettings
    }

    updateTradesHiddenColumns(userSettings: UserSettingsDto | undefined, hiddenColumns: Array<string>) {
        const updatedSettings: UserSettingsDto = userSettings || {}

        if (!updatedSettings.settings) {
            updatedSettings.settings = {}
        }

        if (!updatedSettings.settings.table) {
            updatedSettings.settings.table = {}
        }

        if (!updatedSettings.settings.table.trades) {
            updatedSettings.settings.table.trades = {}
        }
        updatedSettings.settings.table.trades.hiddenColumns = hiddenColumns

        return updatedSettings
    }

    updateDashboardDateRange(userSettings: UserSettingsDto | undefined, dateRange: any) {
        const updatedSettings: UserSettingsDto = userSettings || {}

        if (!updatedSettings.settings) {
            updatedSettings.settings = {}
        }

        if (!updatedSettings.settings.dateRange) {
            updatedSettings.settings.dateRange = {}
        }
        updatedSettings.settings.dateRange.dashboard = {
            from: dateRange.from,
            to: dateRange.to,
        }
        return updatedSettings
    }

    updateJournalCategoryId(userSettings: UserSettingsDto | undefined, selectedCategoryId: ID) {
        const updatedSettings: UserSettingsDto = userSettings || {}

        if (!updatedSettings.settings) {
            updatedSettings.settings = {}
        }

        if (!updatedSettings.settings.journal) {
            updatedSettings.settings.journal = {}
        }
        updatedSettings.settings.journal.selectedCategoryId = selectedCategoryId

        return updatedSettings
    }

    updatePaginationPageSize(userSettings: UserSettingsDto | undefined, key: string, pageSize: number) {
        const updatedSettings: UserSettingsDto = userSettings || {}

        if (!updatedSettings.settings) {
            updatedSettings.settings = {}
        }

        if (!updatedSettings.settings.pagination) {
            updatedSettings.settings.pagination = {}
        }
        switch (key) {
            case SettingsPaginationKey.TRADES_PAGINATION_KEY:
                if (!updatedSettings.settings.pagination.trades) {
                    updatedSettings.settings.pagination.trades = {}
                }
                updatedSettings.settings.pagination.trades.pageSize = pageSize
                break;
            case SettingsPaginationKey.JOURNAL_PAGINATION_KEY:
                if (!updatedSettings.settings.pagination.journal) {
                    updatedSettings.settings.pagination.journal = {}
                }
                updatedSettings.settings.pagination.journal.pageSize = pageSize
                break;
            default:
                logger.error(`Could not update pagination page size in user settings because key: ${key} not implemented!`)
        }
        return updatedSettings
    }

    updateSelectedDashboardWidgets(userSettings: UserSettingsDto | undefined, selectedWidgets: {[key: string]: boolean}) {
        const updatedSettings: UserSettingsDto = userSettings || {}

        if (!updatedSettings.settings) {
            updatedSettings.settings = {}
        }

        if (!updatedSettings.settings.dashboard) {
            updatedSettings.settings.dashboard = {}
        }
        updatedSettings.settings.dashboard.selectedWidgets = selectedWidgets

        return updatedSettings
    }
}
