import {FC} from "react";
import {PercentChangeWidget} from "./PercentChangeWidget";
import {useIntl} from "react-intl";
import CountUp from "react-countup";
import {WidgetKey} from "../../../common/dto/dashboard/WidgetDto";
import {useThemeMode} from "../../../../_metronic/partials";

type Props = {
    selectedWidgets: {[key: string]: boolean},
    value: number
    previousValue: number
}

const WidgetTradeDays: FC<Props> = ({selectedWidgets, value, previousValue}) => {
    const intl = useIntl()
    const {isDark} = useThemeMode()
    const darkStyle = (isDark ? "widget-dark" : "")

    if (!selectedWidgets[WidgetKey.TRADE_DAYS]) {
        return null
    }
    return (
        <div className="col-sm-4 col-xl-2 mb-xl-10">
            <div className="card h-lg-100">
                <div className="card-body d-flex justify-content-between align-items-start flex-column">
                    {/*begin::Icon*/}
                    <div className="m-0">
                        {/*begin::Svg Icon | path: icons/50-finance-icons-red/trade-days.svg*/}
                        <span className="svg-icon svg-icon-2x svg-icon-gray-600">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.64 100.47"><defs><style>{`.trade-days-1,.trade-days-2{fill:none;stroke:#344154;stroke-miterlimit:10;stroke-width:3px;}.trade-days-2{stroke - linecap:round;}.trade-days-3{fill:#009ef7;}`}</style></defs><g><g id="Icon_Set" data-name="Icon Set">
                                <path className={"trade-days-1 " + darkStyle} d="M121.14,31.17V93.11A5.86,5.86,0,0,1,115.28,99H7.36A5.86,5.86,0,0,1,1.5,93.11V31.17"/><path
                                className={"trade-days-2 " + darkStyle} d="M22,12.46a5.36,5.36,0,0,0-.44,2.12,5.55,5.55,0,1,0,11.09,0,5.36,5.36,0,0,0-.44-2.12"/><line
                                className={"trade-days-2 " + darkStyle} x1="27.1" y1="14.49" x2="27.1" y2="1.5"/><path className={"trade-days-2 " + darkStyle}
                                                                                                 d="M100.65,12.46a5.54,5.54,0,1,1-10.22,0"/><line
                                className={"trade-days-2 " + darkStyle} x1="95.54" y1="14.49" x2="95.54" y2="1.5"/><line className="trade-days-2" x1="89.41" y1="5.1" x2="33.22"
                                                                                                   y2="5.1"/><path className={"trade-days-2 " + darkStyle}
                                                                                                                   d="M21,5.1H7.36A5.85,5.85,0,0,0,1.5,11V31.17H121.14V11a5.86,5.86,0,0,0-5.86-5.85H101.67"/><line
                                className={"trade-days-2 " + darkStyle} x1="35.4" y1="44.01" x2="35.4" y2="56.16"/><line className={"trade-days-2 " + darkStyle} x1="52.68" y1="44.01"
                                                                                                   x2="52.68" y2="56.16"/><line className={"trade-days-2 " + darkStyle}
                                                                                                                                x1="69.96"
                                                                                                                                y1="44.01"
                                                                                                                                x2="69.96"
                                                                                                                                y2="56.16"/><line
                                className={"trade-days-2 " + darkStyle} x1="87.24" y1="44.01" x2="87.24" y2="56.16"/><line className={"trade-days-2 " + darkStyle} x1="35.4" y1="73.77"
                                                                                                     x2="35.4" y2="85.93"/><line className={"trade-days-2 " + darkStyle}
                                                                                                                                 x1="52.68"
                                                                                                                                 y1="73.77"
                                                                                                                                 x2="52.68"
                                                                                                                                 y2="85.93"/><line
                                className={"trade-days-2 " + darkStyle} x1="69.96" y1="73.77" x2="69.96" y2="85.93"/><line className={"trade-days-2 " + darkStyle} x1="87.24" y1="73.77"
                                                                                                     x2="87.24" y2="85.93"/><line className={"trade-days-2 " + darkStyle}
                                                                                                                                  x1="25.65"
                                                                                                                                  y1="65.07"
                                                                                                                                  x2="96.98"
                                                                                                                                  y2="65.07"/><path
                                className="trade-days-3"
                                d="M104.59,15a9.07,9.07,0,0,1-18.1,0H36.15A9.07,9.07,0,0,1,18,15H6.5V26.17H116.14V15Z"/></g></g>
                            </svg>
                        </span>
                        {/*end::Svg Icon*/}
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Section*/}
                    <div className="d-flex flex-column my-7">
                        {/*begin::Number*/}
                        <span className="fs-2x text-gray-800 lh-1 ls-n2">
                            <CountUp end={value} duration={1.0} />
                        </span>
                        {/*end::Number*/}
                        {/*begin::Follower*/}
                        <div className="m-0">
                            <span className="fs-6 text-gray-400">
                                {intl.formatMessage({id: "DASHBOARD.TRADE_DAYS"})}
                            </span>
                        </div>
                        {/*end::Follower*/}
                    </div>
                    {/*end::Section*/}
                    <PercentChangeWidget currentValue={value} previousValue={previousValue} />
                </div>
            </div>
        </div>
    )
}

export {WidgetTradeDays}
