import {FC} from "react";
import {PercentChangeWidget} from "./PercentChangeWidget";
import {useIntl} from "react-intl";
import CountUp from "react-countup";
import {WidgetKey} from "../../../common/dto/dashboard/WidgetDto";
import {useThemeMode} from "../../../../_metronic/partials";

type Props = {
    selectedWidgets: {[key: string]: boolean},
    value: number
    previousValue: number
}

const WidgetTrades: FC<Props> = ({selectedWidgets, value, previousValue}) => {
    const intl = useIntl()
    const {isDark} = useThemeMode()
    const darkStyle = (isDark ? "widget-dark" : "")

    if (!selectedWidgets[WidgetKey.TOTAL_TRADES]) {
        return null
    }
    return (
        <div className="col-sm-4 col-xl-2 mb-xl-10">
            <div className="card h-lg-100">
                <div className="card-body d-flex justify-content-between align-items-start flex-column">
                    {/*begin::Icon*/}
                    <div className="m-0">
                        {/*begin::Svg Icon | path: icons/50-finance-icons-red/total-trades.svg*/}
                        <span className="svg-icon svg-icon-2x svg-icon-gray-600">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75.47 103.17"><defs><style>{`.total-trades-1{fill:#009ef7;}.total-trades-2{fill:none;stroke:#344154;stroke-miterlimit:10;stroke-width:3px;}`}</style></defs><g><g id="Icon_Set" data-name="Icon Set"><rect className="total-trades-1" x="21.94" y="37.28"
                                                                                                           width="5.53" height="19.45"
                                                                                                           transform="translate(71.7 22.3) rotate(90)"/><rect
                                className={"total-trades-1"} x="35.3" y="-5.35" width="4.86" height="45.51" transform="translate(55.14 -20.32) rotate(90)"/><rect
                                className={"total-trades-2 " + darkStyle} x="1.5" y="1.5" width="72.47" height="100.17" rx="4.42"/><rect className={"total-trades-2 " + darkStyle} x="11.67"
                                                                                                                 y="11.67" width="52.13"
                                                                                                                 height="11.47"/><rect
                                className={"total-trades-2 " + darkStyle} x="11.67" y="40.93" width="52.13" height="49.91"/><line className={"total-trades-2 " + darkStyle} x1="24.75" y1="53.24"
                                                                                                          x2="24.75" y2="90.84"/><line
                                className={"total-trades-2 " + darkStyle} x1="37.73" y1="40.93" x2="37.73" y2="91.18"/><line className={"total-trades-2 " + darkStyle} x1="50.72" y1="41.27"
                                                                                                     x2="50.72" y2="91.53"/><line className={"total-trades-2 " + darkStyle}
                                                                                                                                  x1="63.8"
                                                                                                                                  y1="53.07"
                                                                                                                                  x2="11.67"
                                                                                                                                  y2="53.07"/><line
                                className={"total-trades-2 " + darkStyle} x1="63.8" y1="66.06" x2="11.67" y2="66.06"/><line className={"total-trades-2 " + darkStyle} x1="63.8" y1="79.04"
                                                                                                    x2="11.67" y2="79.04"/></g></g>
                            </svg>
                        </span>
                        {/*end::Svg Icon*/}
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Section*/}
                    <div className="d-flex flex-column my-7">
                        {/*begin::Number*/}
                        <span className="fs-2x text-gray-800 lh-1 ls-n2">
                            <CountUp end={value} duration={1.0} />
                        </span>
                        {/*end::Number*/}
                        {/*begin::Follower*/}
                        <div className="m-0">
                            <span className="fs-6 text-gray-400">
                                {intl.formatMessage({id: "DASHBOARD.TOTAL_TRADES"})}
                            </span>
                        </div>
                        {/*end::Follower*/}
                    </div>
                    {/*end::Section*/}
                    <PercentChangeWidget currentValue={value} previousValue={previousValue} />
                </div>
            </div>
        </div>
    )
}

export {WidgetTrades}
