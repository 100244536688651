import React, {Dispatch, FC, SetStateAction, useState} from 'react'
import {FormikErrors, useFormik} from "formik";
import {ID, KTSVG} from "../../../../_metronic/helpers";
import axios, {AxiosResponse} from "axios";
import {JsonApiErrorsModel} from "../../_models";
import {useTradeQueryResponse} from "../../../common/provider/trade/TradeDataProvider";
import {useDropzone} from "react-dropzone";
import {useIntl} from "react-intl";
import prettyBytes from "pretty-bytes";
import {ImportTradeResultDto, JsonApiObjectImportTradeResulDto} from "../../../common/dto/import/ImportTradeResultDto";
import {TradeReportBroker} from "../../../common/dto/trade/TradeDto";
import * as Yup from "yup";

type Prop = {
    accountId: ID
    setOpenImportTradesModal: Dispatch<SetStateAction<boolean>>,
}

const style = {}

const ImportTradeReportModal: FC<Prop> = (props) => {
    const intl = useIntl()
    const {refetch: refetchAllTrades} = useTradeQueryResponse()
    const {accountId, setOpenImportTradesModal} = props
    const [tradeImportResult, setTradeImportResult] = useState<ImportTradeResultDto | undefined>()
    const [showOkButton, setShowOkButton] = useState(false)
    const [broker, setBroker] = useState(TradeReportBroker.TRADE_THE_POOL)

    const validationSchema = Yup.object().shape({
        broker: Yup.string()
            .required(intl.formatMessage({id: 'TRADES.MODAL.IMPORT.VALIDATION.REQUIRED'})),
    })
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        accept: {
            'text/*': ['.csv'],
        },
        multiple: false,
        onDrop: acceptedFiles => {
            formik.setFieldValue("files", acceptedFiles)
        }
    })
    const formik = useFormik({
        initialValues: {
            broker: broker,
            files: [],
        },
        validationSchema: validationSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            if (values.files.length === 0) {
                return
            }
            setSubmitting(true)
            setStatus(undefined)

            const formData = new FormData();

            values.broker = broker
            values.files.forEach((file) => {
                formData.append('files', file);
            });
            const json = JSON.stringify(values);

            const blob = new Blob([json], {
                type: 'application/json'
            })
            const API_URL = process.env.REACT_APP_API_URL
            const TRADE_URL = `${API_URL}/accounts/${accountId}/trades/import`

            formData.append("importReport", blob)

            return axios.post(TRADE_URL, formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then((response: AxiosResponse<JsonApiObjectImportTradeResulDto>) => {
                    const importResult = response.data.data?.attributes

                    setTradeImportResult(importResult)

                    if ((importResult?.skippedTradeTickers?.length || 0) === 0) {
                        closeModal()
                    } else {
                        setShowOkButton(true)
                    }
                    refetchAllTrades()
                }).catch(error => {
                    const {response} = error;
                    const errors: FormikErrors<any> = {};

                    (response.data.errors as Array<JsonApiErrorsModel>).forEach(error => {
                        if (error.code === 'VALIDATION_ERROR') {
                            const field = error.source?.parameter || ''

                            errors[field] = error.detail
                        } else {
                            setStatus(error.detail)
                        }
                    })
                    formik.setErrors(errors)
                })
        },
    })

    const filesList = acceptedFiles.map(file => (
        <li key={file.name}>
            <span style={{padding: '.05rem .35rem'}}>
                {file.name}
            </span>
            {prettyBytes(file?.size || 0)}
        </li>
    ))
    const changeBroker = (newBroker: TradeReportBroker) => {
        setBroker(newBroker)
    }
    const closeModal = () => {
        setOpenImportTradesModal(false)
    }
    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_trade'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered mw-600px'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>
                        <div className='modal-header'>
                            {/* begin::Modal title */}
                            <h2 className='fw-bolder'>
                                {intl.formatMessage({id: 'TRADES.MODAL.IMPORT.TITLE'})}
                            </h2>
                            {/* end::Modal title */}

                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary cursor-pointer'
                                data-kt-trade-modal-action='close'
                                onClick={closeModal}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                            </div>
                            {/* end::Close */}
                        </div>
                        {/* begin::Modal body */}
                        <div className='modal-body scroll-y mx-5 mx-xl-15'>
                            <form id='kt_modal_add_trade_form'
                                  className='form'
                                  onSubmit={formik.handleSubmit}
                                  noValidate>
                                {/* begin::Scroll */}
                                <div className='row g-5'>
                                    <div className='col-xl-12'>
                                        {formik.status && (
                                            <div className='alert alert-danger'>
                                                <div className='alert-text font-weight-bold'>{formik.status}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='col-xl-12'>
                                        {/* begin::Input group */}
                                        <div className='fv-row mb-15'>
                                            <label className='fw-bold fs-6 mb-2'>
                                                {intl.formatMessage({id: 'TRADES.MODAL.IMPORT.BROKER.TITLE'})}
                                            </label>
                                            <section className="">
                                                <div className="btn-group" data-kt-buttons="true"
                                                     data-kt-buttons-target="[data-kt-button]">
                                                    <label
                                                        className={"btn btn-thin btn-outline btn-color-muted btn-active-light-primary disabled" + (broker === TradeReportBroker.IBKR ? " active" : "")}
                                                        style={style}
                                                        data-kt-button="true">
                                                        <input className="btn-check" type="radio"
                                                               defaultChecked={broker === TradeReportBroker.IBKR}
                                                               onClick={() => changeBroker(TradeReportBroker.IBKR)}/>
                                                        IBKR
                                                    </label>
                                                    <label
                                                        className={"btn btn-thin btn-outline btn-color-muted btn-active-light-primary" + (broker === TradeReportBroker.TRADE_THE_POOL ? " active" : "")}
                                                        style={style}
                                                        data-kt-button="true">
                                                        <input className="btn-check" type="radio"
                                                               defaultChecked={broker === TradeReportBroker.TRADE_THE_POOL}
                                                               onClick={() => changeBroker(TradeReportBroker.TRADE_THE_POOL)}/>
                                                        TradeThePool
                                                    </label>
                                                </div>
                                            </section>
                                        </div>
                                        <div className='fv-row mb-0'>
                                            <label className='fw-bold fs-6 mb-2'>
                                                {intl.formatMessage({id: 'TRADES.MODAL.IMPORT.REPORT_FILES'})}
                                            </label>
                                            <section className="">
                                                <div {...getRootProps({className: 'dropzone file-container'})}>
                                                    <input {...getInputProps()} name='files'/>
                                                    <p>
                                                        {intl.formatMessage({id: 'TRADES.MODAL.IMPORT.REPORT_FILES.TOOLTIP'})}
                                                    </p>
                                                </div>
                                                {filesList.length > 0 && (
                                                    <div className="mt-10">
                                                        <aside>
                                                            <h4 className='mt-2'>New Files</h4>
                                                            <ul>{filesList}</ul>
                                                        </aside>
                                                    </div>
                                                )}
                                            </section>
                                        </div>
                                        {/* end::Input group */}
                                    </div>
                                    {(tradeImportResult && tradeImportResult.skippedTradeTickers && tradeImportResult.skippedTradeTickers.length > 0) && (
                                        <div>
                                            <label className='fw-bold fs-6 mb-2 text-danger'>
                                            {intl.formatMessage({id: 'TRADES.MODAL.IMPORT.REPORT_FILES.EXCLUDED'})}
                                            </label>
                                            <section className="">
                                                {tradeImportResult?.skippedTradeTickers?.join(", ")}
                                            </section>
                                        </div>
                                    )}
                                </div>
                                {/* begin::Actions */}
                                <div className='text-center pt-15'>
                                    {!showOkButton && (
                                        <>
                                            <button
                                                type='reset'
                                                onClick={closeModal}
                                                className='btn btn-light me-3'
                                                data-kt-trade-modal-action='cancel'
                                                disabled={formik.isSubmitting}
                                            >
                                                {intl.formatMessage({id: 'BUTTON.DISCARD'})}
                                            </button>

                                            <button
                                                type='submit'
                                                className='btn btn-primary'
                                                data-kt-trade-modal-action='submit'
                                                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                                            >
                                                <span className='indicator-label'>
                                                    {intl.formatMessage({id: 'BUTTON.SUBMIT'})}
                                                </span>
                                                {(formik.isSubmitting) && (
                                                    <span className='indicator-progress'>
                                                        Please wait...{' '}
                                                    <span
                                                        className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                        </>
                                    )}
                                    {showOkButton && (
                                        <button
                                            type='reset'
                                            onClick={closeModal}
                                            className='btn btn-primary'
                                            data-kt-trade-modal-action='cancel'
                                            disabled={formik.isSubmitting}
                                        >
                                            {intl.formatMessage({id: 'BUTTON.OK'})}
                                        </button>
                                    )}
                                </div>
                                {/* end::Actions */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-backdrop fade show'></div>
        </>
    )
}

export {ImportTradeReportModal}
