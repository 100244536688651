import {useMutation} from 'react-query'
import {useTradeModelListViewContext} from "../../../../common/provider/trade/TradeListViewProvider";
import {deleteSelectedTrades} from "../../table/core/_requests";
import {useTradeQueryResponse} from "../../../../common/provider/trade/TradeDataProvider";
import {AccountDto} from "../../../../common/dto/account/AccountDto";
import {Dispatch, FC, SetStateAction} from "react";
import {getAccountById} from "../../../../common/request/account/AccountRequest";
import {useUserSettingsContext} from "../../../../common/provider/settings/UserSettingsDataProvider";

type Prop = {
    account?: AccountDto
    setAccount: Dispatch<SetStateAction<AccountDto>>
}

const TradeListGrouping: FC<Prop> = (props) => {
    const {account, setAccount} = props
    const {selected, clearSelected} = useTradeModelListViewContext()
    const {refetch: refetchAllTrades} = useTradeQueryResponse()
    const {response: userSettings} = useUserSettingsContext()
    const accountId = userSettings?.settings?.account?.selectedId

    const deleteSelectedItems = useMutation(() => deleteSelectedTrades(accountId, selected), {
        // 💡 response of the mutation is passed to onSuccess
        onSuccess: () => {
            getAccountById(accountId).then(response => response.data?.attributes)
                .then(account => {
                    if (account) {
                        setAccount(account)
                    }
                })

            refetchAllTrades()

            clearSelected()
        },
    })

    return (
        <div className='d-flex justify-content-end align-items-center'>
            <div className='fw-bolder me-5'>
                <span className='me-2'>{selected.length}</span> Selected
            </div>

            <button
                type='button'
                className='btn btn-danger'
                onClick={async () => await deleteSelectedItems.mutateAsync()}
            >
                Delete Selected
            </button>
        </div>
    )
}

export {TradeListGrouping}
