import React, {createContext, FC, useContext} from 'react'
import {WithChildren} from '../helpers'
import {useUserSettingsContext} from "../../app/common/provider/settings/UserSettingsDataProvider";

type Props = {
  selectedLang: 'en' | 'ru'
}
const initialState: Props = {
  selectedLang: 'en',
}

const I18nContext = createContext<Props>(initialState)

const useLang = () => {
  return useContext(I18nContext).selectedLang
}

const MetronicI18nProvider: FC<WithChildren> = ({children}) => {
  const {response: userSettings} = useUserSettingsContext()
  const lang = {selectedLang: userSettings?.settings?.language || initialState.selectedLang }

  return <I18nContext.Provider value={lang as Props}>{children}</I18nContext.Provider>
}

export {MetronicI18nProvider, useLang}
