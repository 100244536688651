import {createContext, Dispatch, FC, SetStateAction, useContext, useMemo, useState} from 'react'
import {
    calculatedGroupingIsDisabled,
    calculateIsAllDataSelected,
    groupingOnSelect,
    groupingOnSelectAll,
    ID,
    WithChildren,
} from '../../../../_metronic/helpers'
import {
    useAccountQueryResponse,
    useAccountQueryResponseData
} from "../../../modules/accounts/accounts-list/core/QueryResponseProvider";
import {AccountDto} from "../../dto/account/AccountDto";

export type ListViewContextProps = {
    selected: Array<ID>
    onSelect: (selectedId: ID) => void
    onSelectAll: () => void
    clearSelected: () => void
    // NULL => (CREATION MODE) | MODAL IS OPENED
    // NUMBER => (EDIT MODE) | MODAL IS OPENED
    // UNDEFINED => MODAL IS CLOSED
    itemIdForUpdate?: AccountDto
    setItemIdForUpdate: Dispatch<SetStateAction<AccountDto | undefined>>
    itemIdForDepositHistory?: ID
    setItemIdForDepositHistory: Dispatch<SetStateAction<ID>>
    itemIdForUpdateDepositHistory?: ID
    setItemIdForUpdateDepositHistory: Dispatch<SetStateAction<ID | undefined>>
    itemForCreate?: ID
    setItemForCreate: Dispatch<SetStateAction<ID | undefined>>
    itemForDelete?: ID
    setItemForDelete: Dispatch<SetStateAction<ID | undefined>>
    isAllSelected: boolean
    disabled: boolean
    globalError?: string
    setGlobalError: Dispatch<SetStateAction<string | undefined>>
}

export const initialListView: ListViewContextProps = {
    selected: [],
    onSelect: () => {},
    onSelectAll: () => {},
    clearSelected: () => {},
    setItemIdForUpdate: () => {},
    setItemIdForDepositHistory: () => {},
    setItemIdForUpdateDepositHistory: () => {},
    setItemForCreate: () => {},
    setItemForDelete: () => {},
    setGlobalError: () => {},
    isAllSelected: false,
    disabled: false,
}

const AccountListViewContext = createContext<ListViewContextProps>(initialListView)

const AccountListViewProvider: FC<WithChildren> = ({children}) => {
    const [selected, setSelected] = useState<Array<ID>>(initialListView.selected)
    const [itemIdForUpdate, setItemIdForUpdate] = useState<AccountDto | undefined>(initialListView.itemIdForUpdate)
    const [itemIdForDepositHistory, setItemIdForDepositHistory] = useState<ID>(initialListView.itemIdForDepositHistory)
    const [itemIdForUpdateDepositHistory, setItemIdForUpdateDepositHistory] = useState<ID>(initialListView.itemIdForDepositHistory)
    const [itemForCreate, setItemForCreate] = useState<any>(initialListView.itemForCreate)
    const [itemForDelete, setItemForDelete] = useState<any>(initialListView.itemForCreate)
    const [globalError, setGlobalError] = useState<any>(initialListView.globalError)
    const {isLoading} = useAccountQueryResponse()
    const data = useAccountQueryResponseData()
    const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
    const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])

    return (
        <AccountListViewContext.Provider
            value={{
                selected,
                itemIdForUpdate,
                setItemIdForUpdate,
                itemIdForDepositHistory,
                setItemIdForDepositHistory,
                itemIdForUpdateDepositHistory,
                setItemIdForUpdateDepositHistory,
                itemForCreate,
                setItemForCreate,
                itemForDelete,
                setItemForDelete,
                globalError,
                setGlobalError,
                disabled,
                isAllSelected,
                onSelect: (id: ID) => {
                    groupingOnSelect(id, selected, setSelected)
                },
                onSelectAll: () => {
                    groupingOnSelectAll(isAllSelected, setSelected, data)
                },
                clearSelected: () => {
                    setSelected([])
                },
            }}
        >
            {children}
        </AccountListViewContext.Provider>
    )
}

const useAccountListView = () => useContext(AccountListViewContext)

export {AccountListViewProvider, useAccountListView}
