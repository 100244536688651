import {KTSVG, Response} from "../../../../_metronic/helpers";
import clsx from "clsx";
import React, {Dispatch, FC, SetStateAction} from "react";
import {FormikErrors, useFormik} from "formik";
import axios, {AxiosResponse} from "axios";
import {JsonApiErrorsModel} from "../../_models";
import {useIntl} from "react-intl";
import * as Yup from "yup";
import {JournalCategoryDto} from "../JournalPage";

const newJournalCategorySchema = Yup.object().shape({
    name: Yup.string()
        .min(1, 'Minimum 1 symbol')
        .max(128, 'Maximum 128 symbols')
        .required('Name is required'),
})

type Props = {
    category: JournalCategoryDto | undefined,
    setOpenCategoryModal: Dispatch<SetStateAction<boolean>>,
    setCategoryEditModal: Dispatch<SetStateAction<JournalCategoryDto | undefined>>,
    refetchJournalCategories: Function
}

const NewJournalCategoryModal: FC<Props> = (props) => {
    const intl = useIntl()
    const {category, setOpenCategoryModal, setCategoryEditModal, refetchJournalCategories} = props
    const closeModal = () => {
        setOpenCategoryModal(false)
        setCategoryEditModal(undefined)
    }

    const formik = useFormik({
        initialValues: {name: category?.name || ''},
        validationSchema: newJournalCategorySchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setSubmitting(true)

            const API_URL = process.env.REACT_APP_API_URL
            const JOURNAL_CATEGORY_URL = `${API_URL}/journals/categories`

            const handleSuccess = (response: AxiosResponse<Response<JournalCategoryDto>>) => {
                closeModal()
                refetchJournalCategories()
            }

            const handleError = (error: any) => {
                const {response} = error
                const errors: FormikErrors<any> = {};

                (response.data.errors as Array<JsonApiErrorsModel>).map(error => {
                    if (error.code === 'VALIDATION_ERROR') {
                        const field = error.source?.parameter || ''

                        errors[field] = error.detail
                    } else {
                        setStatus(error.detail)
                    }
                })
                formik.setErrors(errors)
            }

            if (!category) {
                return axios.post(JOURNAL_CATEGORY_URL, values)
                    .then((response: AxiosResponse<Response<JournalCategoryDto>>) => handleSuccess(response))
                    .catch(error => handleError(error))
            } else {
                return axios.put(`${JOURNAL_CATEGORY_URL}/${category.id}`, values)
                    .then((response: AxiosResponse<Response<JournalCategoryDto>>) => handleSuccess(response))
                    .catch(error => handleError(error))
            }
        },
    })

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_trade'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered mw-650px'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>
                        <div className='modal-header'>
                            {/* begin::Modal title */}
                            <h2 className='text-muted'>
                                { category?.name ?
                                    intl.formatMessage({id: 'JOURNAL.EDIT.CATEGORY.MODAL.TITLE'}) :
                                    intl.formatMessage({id: 'JOURNAL.CREATE.CATEGORY.TITLE'}) }
                            </h2>
                            {/* end::Modal title */}

                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary cursor-pointer'
                                data-kt-trade-modal-action='close'
                                onClick={closeModal}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                            </div>
                            {/* end::Close */}
                        </div>
                        {/* begin::Modal body */}
                        <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                            <form id='kt_modal_add_trade_form' className='form' onSubmit={formik.handleSubmit}
                                  noValidate>
                                {/* begin::Scroll */}
                                <div
                                    className='d-flex flex-column scroll-y me-n7 pe-7'
                                    id='kt_modal_add_trade_scroll'
                                    data-kt-scroll='true'
                                    data-kt-scroll-activate='{default: false, lg: true}'
                                    data-kt-scroll-max-height='auto'
                                    data-kt-scroll-dependencies='#kt_modal_add_trade_header'
                                    data-kt-scroll-wrappers='#kt_modal_add_trade_scroll'
                                    data-kt-scroll-offset='300px'
                                >
                                    {formik.status && (
                                        <div className='mb-lg-15 alert alert-danger'>
                                            <div className='alert-text font-weight-bold'>{formik.status}</div>
                                        </div>
                                    )}
                                    {/* begin::Input group */}
                                    <div className='fv-row mb-7'>
                                        {/* begin::Label */}
                                        <label className='required text-muted fs-6 mb-2'>
                                            {intl.formatMessage({id: 'ACCOUNTS.MODAL.CREATE.NAME'})}
                                        </label>
                                        {/* end::Label */}

                                        {/* begin::Input */}
                                        <input
                                            placeholder={intl.formatMessage({id: 'ACCOUNTS.MODAL.CREATE.NAME'})}
                                            {...formik.getFieldProps('name')}
                                            type='text'
                                            name='name'
                                            className={clsx(
                                                'form-control form-control-solid mb-3 mb-lg-0',
                                                {'is-invalid': formik.touched.name && formik.errors.name},
                                                {
                                                    'is-valid': formik.touched.name && !formik.errors.name,
                                                }
                                            )}
                                            autoComplete='off'
                                            disabled={formik.isSubmitting}
                                        />
                                        {formik.touched.name && formik.errors.name && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.name}</span>
                                                </div>
                                            </div>
                                        )}
                                        {/* end::Input */}
                                    </div>
                                    {/* end::Input group */}
                                </div>
                                {/* end::Scroll */}

                                {/* begin::Actions */}
                                <div className='text-center pt-15'>
                                    <button
                                        type='reset'
                                        onClick={closeModal}
                                        className='btn btn-light me-3'
                                        data-kt-trade-modal-action='cancel'
                                        disabled={formik.isSubmitting}
                                    >
                                        {intl.formatMessage({id: 'BUTTON.DISCARD'})}
                                    </button>

                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-trade-modal-action='submit'
                                        disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                                    >
                                        <span className='indicator-label'>
                                            {intl.formatMessage({id: 'BUTTON.SUBMIT'})}
                                        </span>
                                        {(formik.isSubmitting) && (
                                            <span className='indicator-progress'>
                      Please wait...{' '}
                                                <span
                                                    className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                                        )}
                                    </button>
                                </div>
                                {/* end::Actions */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-backdrop fade show'></div>
        </>
    )
}

export {NewJournalCategoryModal}
