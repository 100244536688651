import {logger} from "../../../../index";

class QueryParam {
    private static readonly PAGE_KEY = 'page'
    private static readonly SIZE_KEY = 'size'

    private readonly params: any
    private readonly searchParams: URLSearchParams
    private readonly setSearchParams: any

    constructor(searchParams: URLSearchParams, setSearchParams: any) {
        this.params = {}
        this.searchParams = searchParams
        this.setSearchParams = setSearchParams

        this.addUriQueryParams()

        QueryParam.toLog('Init: ', this.params)
    }

    getAll() {
        return this.params
    }

    getByKey(key: string) {
        return this.params[key]
    }

    getTotalAppliedFilters(...exceptFilters: string[]) {
        const exceptKeys: any = {}

        exceptFilters?.forEach(key => exceptKeys[key] = key)

        const keys = Object.keys(this.params)
            .filter(key => key !== QueryParam.PAGE_KEY)
            .filter(key => key !== QueryParam.SIZE_KEY)
            .filter(key => !key.includes('exclude_'))
            .filter(key => !exceptKeys[key])
            .filter(key => key.length > 0)
            .map(key => key)

        return keys.length
    }

    append(key: string, value: any) {
        if (!value
                || (Array.isArray(value) && value.length === 0)
                || (!Array.isArray(value) && (value.value === '' || value.value.toUpperCase() === 'ALL'))) {
            this.searchParams.delete(key)

            this.delete(key)

            return this
        }

        if (Array.isArray(value)) {
            this.params[key] = value.map((option: any) => option.value).join()
        } else {
            this.params[key] = value.value
        }
        QueryParam.toLog('After append: ', this.params)

        return this
    }

    delete(key: string) {
        this.searchParams.delete(key)

        delete this.params[key]

        QueryParam.toLog('After delete: ', this.params)

        return this
    }

    appendPage(value: number) {
        this.params[QueryParam.PAGE_KEY] = value

        return this
    }

    getAsString() {
        return JSON.stringify(this.params)
    }

    apply() {
        this.resetPage()

        QueryParam.toLog('After apply: ', this.params)

        this.setSearchParams(this.params)
    }

    resetPage() {
        this.appendPage(1)

        return this
    }

    resetFilters() {
        Object.keys(this.params)
            .filter(key => key !== QueryParam.PAGE_KEY)
            .filter(key => key !== QueryParam.SIZE_KEY)
            .forEach(key => this.delete(key))

        this.apply()
    }

    private addUriQueryParams() {
        const queryParamItems = window.location.search.replace('?', '').split('&')

        queryParamItems.filter(param => param && param !== '')
            .forEach(param => {
                const [key, value] = param.split('=')

                this.params[key] = value
            })
    }

    private static toLog(message: string, value: any) {
        logger.debug(message + JSON.stringify(value))
    }
}

export {QueryParam}