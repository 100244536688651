import {getCurrencySymbol} from "../../../common/utils/currency/CurrencyUtils";
import {FC} from "react";
import {useIntl} from "react-intl";
import CountUp from "react-countup";
import {PercentChangeWidget} from "./PercentChangeWidget";
import {WidgetKey} from "../../../common/dto/dashboard/WidgetDto";
import {useThemeMode} from "../../../../_metronic/partials";

type Props = {
    selectedWidgets: {[key: string]: boolean},
    value: number
    previousValue: number
    currency: string
}

const WidgetTotalCommission: FC<Props> = ({selectedWidgets, value, previousValue, currency}) => {
    const intl = useIntl()
    const {isDark} = useThemeMode()
    const darkStyle = (isDark ? "widget-dark" : "")

    if (!selectedWidgets[WidgetKey.COMMISSION]) {
        return null
    }
    return (
        <div className="col-sm-4 col-xl-2 mb-xl-10">
            <div className="card h-lg-100">
                <div className="card-body d-flex justify-content-between align-items-start flex-column">
                    {/*begin::Icon*/}
                    <div className="m-0">
                        {/*begin::Svg Icon | path: icons/50-finance-icons-red/deposit.svg*/}
                        <span className="svg-icon svg-icon-2hx svg-icon-gray-600">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 236.97 186.12"><defs><style>{`.commission-1,.commission-2{fill:none;stroke:#344154;stroke-linecap:round;stroke-width:5.5px;}.commission-1{stroke - miterlimit:10;}.commission-2{stroke - linejoin:round;}.commission-3{fill:#ff6d3b;}`}</style></defs><g><g id="Icon_Set" data-name="Icon Set">
                                <path className={"commission-1 " + darkStyle} d="M151,162.9a158.64,158.64,0,0,0-50-5.12c-11.09.64-22.15,2.44-33.25,2S44,155.17,35.93,147.55l-31.53-30A5.38,5.38,0,0,1,4.26,110h0a17.58,17.58,0,0,1,24.85-.46L41.59,121.3"/><path
                                className={"commission-1 " + darkStyle}
                                d="M112.87,129.43l-20,4.29a91.71,91.71,0,0,1-19.19,2H62.83a10,10,0,0,1-10-10h0a10,10,0,0,1,10-10h9.52a64.41,64.41,0,0,0,25.53-5.27c5.66-2.44,11.28-5,17.33-6.48,16.53-4.13,39.92.39,56.07,20.07"/><path
                                className={"commission-2 " + darkStyle} d="M234.22,110.65H184.28a6.74,6.74,0,0,0-6,3.64l-30.8,59.21a6.75,6.75,0,0,0,6,9.87h80.74"/><polyline
                                className={"commission-1 " + darkStyle} points="172.06 75.53 5.38 75.53 5.38 2.75 132.42 2.75 132.42 26.17"/><path className={"commission-1 " + darkStyle}
                                                                                                                             d="M50,58.09A26.8,26.8,0,0,1,50,20.2"/><path
                                className={"commission-1 " + darkStyle} d="M87.85,20.19a26.8,26.8,0,0,1,0,37.89"/><circle className={"commission-1 " + darkStyle} cx="112.13" cy="40.95"
                                                                                                    r="1.52"/><path className={"commission-1 " + darkStyle}
                                                                                                                    d="M24.15,41a1.52,1.52,0,1,1,1.52,1.52A1.52,1.52,0,0,1,24.15,41Z"/><path
                                className={"commission-1 " + darkStyle}
                                d="M61.5,46.51a7.4,7.4,0,1,0,14.8,0c0-4.09-3-6-7.4-7.4-3.88-1.27-7.4-3.31-7.4-7.4a7.4,7.4,0,1,1,14.8,0"/><line
                                className={"commission-1 " + darkStyle} x1="68.9" y1="20.19" x2="68.9" y2="24.37"/><line className={"commission-1 " + darkStyle} x1="68.9" y1="53.91" x2="68.9"
                                                                                                   y2="58.09"/><line className={"commission-1 " + darkStyle} x1="132.42"
                                                                                                                     y1="58.09" x2="162.2"
                                                                                                                     y2="58.09"/><line
                                className={"commission-1 " + darkStyle} x1="132.42" y1="39.43" x2="150.92" y2="39.43"/><polygon className="commission-3"
                                                                                                          points="160.47 172.37 191.71 172.37 218.03 121.65 186.86 121.65 160.47 172.37"/></g></g>
                            </svg>
                        </span>
                        {/*end::Svg Icon*/}
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Section*/}
                    <div className="d-flex flex-column my-7">
                        {/*begin::Number*/}
                        <span className="fs-2x text-gray-800 lh-1 ls-n2">
                            {getCurrencySymbol(currency)}
                            <CountUp end={value} decimals={2} duration={1.0} />
                        </span>
                        {/*end::Number*/}
                        {/*begin::Follower*/}
                        <div className="m-0">
                            <span className="fs-6 text-gray-400">
                                {intl.formatMessage({id: "DASHBOARD.COMMISSION"})}
                            </span>
                        </div>
                        {/*end::Follower*/}
                    </div>
                    {/*end::Section*/}
                    <PercentChangeWidget currentValue={value} previousValue={previousValue} invertColor={true} />
                </div>
            </div>
        </div>
    )
}

export {WidgetTotalCommission}
