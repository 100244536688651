import React, {useMemo} from 'react'
import {ColumnInstance, Row, useTable} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {KTCardBody} from "../../../../../_metronic/helpers";
import {AccountModel} from "../core/_models";
import {accountColumns} from "./columns/_columns";
import {useAccountQueryResponseData, useAccountQueryResponseLoading} from "../core/QueryResponseProvider";
import {useIntl} from "react-intl";
import {Loader} from "../../../../common/loader/Loader";

const AccountsTable = () => {
    const intl = useIntl()
    const users = useAccountQueryResponseData()
    const isLoading = useAccountQueryResponseLoading()
    const data = useMemo(() => users, [users])
    const columns = useMemo(() => accountColumns, [])
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data,
    })

    return (
        <KTCardBody className='py-4'>
            <div className='table-responsive'>
                <table
                    id='kt_table_users'
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    {...getTableProps()}
                >
                    <thead>
                    <tr className='text-start text-muted fs-7 text-uppercase gs-0 text-gray-800'>
                        {headers.map((column: ColumnInstance<AccountModel>) => (
                            <CustomHeaderColumn key={column.id} column={column}/>
                        ))}
                    </tr>
                    </thead>
                    <tbody className='text-gray-800' {...getTableBodyProps()}>
                    {rows.length > 0 ? (
                        rows.map((row: Row<AccountModel>, i) => {
                            prepareRow(row)
                            return <CustomRow row={row} key={`row-${i}-${row.id}`}/>
                        })
                    ) : (
                        <tr>
                            <td colSpan={12}>
                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                    {intl.formatMessage({id: "TABLE.NO_RECORDS"})}
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            {isLoading && <Loader/>}
        </KTCardBody>
    )
}

export {AccountsTable}
