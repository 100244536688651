import {FC} from "react";
import {useIntl} from "react-intl";
import {PercentChangeWidget} from "./PercentChangeWidget";
import CountUp from "react-countup";
import {WidgetKey} from "../../../common/dto/dashboard/WidgetDto";
import {useThemeMode} from "../../../../_metronic/partials";

type Props = {
    selectedWidgets: {[key: string]: boolean},
    totalProfitTrades: number
    previousTotalProfitTrades: number
    totalTrades: number
    previousTotalTrades: number
}

const WidgetProfitTradesPercent: FC<Props> = ({selectedWidgets, totalProfitTrades, previousTotalProfitTrades, totalTrades, previousTotalTrades}) => {
    const intl = useIntl()
    const {isDark} = useThemeMode()
    const darkStyle = (isDark ? "widget-dark" : "")

    if (!selectedWidgets[WidgetKey.PROFIT_TRADES_PERCENT]) {
        return null
    }
    let profitPercent = 0
    let previousProfitPercent = 0

    if (totalProfitTrades !== 0
            && totalTrades !== 0) {
        profitPercent = Math.floor(totalProfitTrades / totalTrades * 100)
    }

    if (previousTotalProfitTrades !== 0
            && previousTotalTrades !== 0) {
        previousProfitPercent = Math.floor(previousTotalProfitTrades / previousTotalTrades * 100)
    }
    return (
        <div className="col-sm-4 col-xl-2 mb-xl-10">
            <div className="card h-lg-100">
                <div className="card-body d-flex justify-content-between align-items-start flex-column">
                    {/*begin::Icon*/}
                    <div className="m-0">
                        {/*begin::Svg Icon | path: icons/50-finance-icons-red/success-rate.svg*/}
                        <span className="svg-icon svg-icon-2hx svg-icon-gray-600">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 273.12 246.63"><defs><style>{`.success-rate-1,.success-rate-2{fill:none;stroke:#344154;stroke-linecap:round;stroke-width:5.5px;}.success-rate-1{stroke - miterlimit:10;}.success-rate-2{stroke - linejoin:round;}.success-rate-3{fill:#ff6d3b;}.success-rate-4{fill:#009ef7;}`}</style></defs>
                                <g><g id="Icon_Set" data-name="Icon Set">
                                    <rect className={"success-rate-1 " + darkStyle} x="2.75" y="161.04" width="144.59" height="82.84"/><path
                                className={"success-rate-1 " + darkStyle} d="M53.47,224a30.5,30.5,0,0,1,0-43.13"/><path className={"success-rate-1 " + darkStyle}
                                                                                                d="M96.6,180.89a30.5,30.5,0,0,1,0,43.13"/><circle
                                className={"success-rate-1 " + darkStyle} cx="124.24" cy="204.51" r="1.73"/><path className={"success-rate-1 " + darkStyle}
                                                                                          d="M24.11,204.51a1.73,1.73,0,1,1,1.73,1.73A1.73,1.73,0,0,1,24.11,204.51Z"/><path
                                className={"success-rate-1 " + darkStyle}
                                d="M66.62,210.85a8.42,8.42,0,1,0,16.84,0c0-4.65-3.45-6.8-8.42-8.42-4.42-1.44-8.42-3.77-8.42-8.42a8.42,8.42,0,1,1,16.84,0"/><line
                                className={"success-rate-1 " + darkStyle} x1="75.04" y1="180.89" x2="75.04" y2="185.64"/><line className={"success-rate-1 " + darkStyle} x1="75.04" y1="219.27"
                                                                                                       x2="75.04" y2="224.02"/><line
                                className={"success-rate-2 " + darkStyle} x1="221.21" y1="76.08" x2="253.66" y2="17.01"/><path className={"success-rate-2 " + darkStyle}
                                                                                                       d="M259.87,76.08h0a10.5,10.5,0,0,1-10.5-10.5V58.31a10.5,10.5,0,0,1,10.5-10.5h0a10.5,10.5,0,0,1,10.5,10.5v7.27A10.5,10.5,0,0,1,259.87,76.08Z"/><path
                                className={"success-rate-2 " + darkStyle}
                                d="M215.73,45.29h0a10.5,10.5,0,0,1-10.5-10.5V27.52A10.5,10.5,0,0,1,215.73,17h0a10.5,10.5,0,0,1,10.5,10.5v7.27A10.5,10.5,0,0,1,215.73,45.29Z"/><polyline
                                className={"success-rate-1 " + darkStyle} points="123.61 159.58 123.61 2.75 172.14 2.75 172.14 243.88"/><polyline className={"success-rate-1 " + darkStyle}
                                                                                                                          points="75.07 161.04 75.07 56.56 123.61 56.56"/><polyline
                                className={"success-rate-1 " + darkStyle} points="172.14 98.92 220.68 98.92 220.68 243.26"/><rect className="success-rate-3" x="134.61" y="13.75"
                                                                                                          width="26.54" height="128.56"/><rect
                                className="success-rate-4" x="183.14" y="108.8" width="26.54" height="74.47"/></g></g>
                            </svg>
                        </span>
                        {/*end::Svg Icon*/}
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Section*/}
                    <div className="d-flex flex-column my-7">
                        {/*begin::Number*/}
                        <span className="fs-2x text-gray-800 lh-1 ls-n2">
                            <CountUp end={profitPercent} duration={1.0} />%
                        </span>
                        {/*end::Number*/}
                        {/*begin::Follower*/}
                        <div className="m-0">
                            <span className="fs-6 text-gray-400">
                                {intl.formatMessage({id: "DASHBOARD.PROFIT_TRADES_PERCENT"})}
                            </span>
                        </div>
                        {/*end::Follower*/}
                    </div>
                    {/*end::Section*/}
                    <PercentChangeWidget currentValue={profitPercent} previousValue={previousProfitPercent} />
                </div>
            </div>
        </div>
    )
}

export {WidgetProfitTradesPercent}
