export interface JsonApiObjectDto<T> {
    data?: JsonApiAttributesDto<T>
    errors?: Array<JsonApiErrorsDto>
    meta: JsonApiMetaDto
}

export interface JsonApiArrayDto<T> {
    data?: Array<JsonApiAttributesDto<T>>
    errors?: Array<JsonApiErrorsDto>
    meta: JsonApiMetaDto
}

export const EmptyJsonApiArrayResponseDto: JsonApiArrayDto<any> = {
    meta: {
        api: {
            version: '1.0'
        },
        page: {
            maxSize: 0,
            total: 0
        }
    }
}

export const EmptyJsonApiResponseDto: JsonApiObjectDto<any> = {
    meta: {
        api: {
            version: '1.0'
        },
        page: {
            maxSize: 0,
            total: 0
        }
    }
}

export interface JsonApiAttributesDto<T> {
    id: string
    type: string
    links: {
        self: string
    }
    attributes: T
}

export interface JsonApiErrorsDto {
    status: number
    code?: string
    detail: string
    source?: JsonApiErrorSourceDto
}

export interface JsonApiErrorSourceDto {
    parameter: string
}

export interface JsonApiMetaDto {
    api: {
        version: string
    }
    page: {
        maxSize: number
        total: number
    }
}