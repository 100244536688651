import React, {useEffect, useRef, useState} from "react";
import {MetaHeader} from "../../../../common/component/meta/MetaHeader";
import {PageLink, PageTitle} from "../../../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import {
    UserSettingsRequestProvider,
    UserSettingsResponseProvider,
    useUserSettingsContext
} from "../../../../common/provider/settings/UserSettingsDataProvider";
import {
    BarData,
    ColorType,
    createChart,
    CreatePriceLineOptions,
    CrosshairMode,
    LineStyle,
    UTCTimestamp
} from "@felipecsl/lightweight-charts";
import axios, {AxiosResponse} from "axios";
import {JsonApiArrayDto} from "../../../../common/dto/json-api/JsonApiDto";
import {TradeChartDto} from "../../../../common/dto/trade/TradeChartDto";
import {useParams} from "react-router-dom";
import {TradeDto} from "../../../../common/dto/trade/TradeDto";
import {KTCard, KTCardBody} from "../../../../../_metronic/helpers";
import {logger} from "../../../../../index";
import {useAuth} from "../../../auth";
import moment from "moment-timezone";
import {useThemeMode} from "../../../../../_metronic/partials";

enum ChartPeriod {
    INTERVAL_1_WEEK = "INTERVAL_1_WEEK",
    INTERVAL_1_DAY = "INTERVAL_1_DAY",
    INTERVAL_1_HOUR = "INTERVAL_1_HOUR",
    INTERVAL_4_HOURS = "INTERVAL_4_HOURS",
    INTERVAL_30_MINUTES = "INTERVAL_30_MINUTES",
    INTERVAL_15_MINUTES = "INTERVAL_15_MINUTES",
    INTERVAL_5_MINUTE = "INTERVAL_5_MINUTE",
    INTERVAL_1_MINUTE = "INTERVAL_1_MINUTE",
}

enum ChartLayout {
    LAYOUT_1 = "LAYOUT_1",
    LAYOUT_2_VERTICAL = "LAYOUT_2_VERTICAL",
    LAYOUT_2_HORIZONTAL = "LAYOUT_2_HORIZONTAL",
    LAYOUT_3 = "LAYOUT_3",
}

const LEVEL_PRICE_LINE_COLOR = '#2196F3'
const TAKE_PROFIT_PRICE_LINE_COLOR = '#26a69a'
const STOP_LOSS_PRICE_LINE_COLOR = '#ef5350'

const TvTradeChartWrapper = () => {
    const {id: tradeId} = useParams()
    const {isDark} = useThemeMode()
    const {currentUser} = useAuth()
    const {response: userSettings} = useUserSettingsContext()
    const chartContainerRef= useRef<HTMLDivElement | null>(null)
    const chartContainerRef2= useRef<HTMLDivElement | null>(null)
    const chartContainerRef3= useRef<HTMLDivElement | null>(null)
    const [quotes, setQuotes] = useState<{[key: string]: Array<any>}>({})
    const [trade, setTrade] = useState<TradeDto>({} as TradeDto)
    const [availableIntervals, setAvailableIntervals] = useState<any[]>([])
    const [topChartInterval, setTopChartInterval] = useState(ChartPeriod.INTERVAL_1_DAY)
    const [topChartHidePriceLines, setTopChartHidePriceLines] = useState(false)
    const [bottomChartInterval, setBottomChartInterval] = useState(ChartPeriod.INTERVAL_5_MINUTE)
    const [bottomChartHidePriceLines, setBottomChartHidePriceLines] = useState(false)
    const [middleChartInterval, setMiddleChartInterval] = useState(ChartPeriod.INTERVAL_1_MINUTE)
    const [chartLayout, setChartLayout] = useState(ChartLayout.LAYOUT_3)

    const drawChart = (trade: TradeDto, quotes: BarData[], period: ChartPeriod, containerRef: any) => {
        const chart = createChart(containerRef.current!!, {
            layout: {
                textColor: (isDark ? 'white': 'black'),
                background: {
                    type: ColorType.Solid,
                    color: (isDark ? '#1e1e2d' : 'white')
                }
            },
            grid: {
                vertLines: {
                    visible: false,
                    color: (isDark ? 'rgba(232,232,232,0.1)' : 'rgba(232,232,232,0.4)'),
                },
                horzLines: {
                    visible: false,
                    color: (isDark ? 'rgba(232,232,232,0.1)' : 'rgba(232,232,232,0.4)'),
                },
            },
            rightPriceScale: {
                borderColor: 'rgba(182,182,182,0.5)'
            },
            timeScale: {
                borderColor: 'rgba(182,182,182,0.5)',
            }
        })
        const barSeries = chart.addBarSeries({ upColor: '#1475cf', downColor: '#787878' })
        barSeries.setData(quotes)

        chart.applyOptions({
            crosshair: {
                // Change mode from default 'magnet' to 'normal'.
                // Allows the crosshair to move freely without snapping to datapoints
                mode: CrosshairMode.Normal,

                // Vertical crosshair line (showing Date in Label)
                vertLine: {
                    width: 1,
                    color: '#999999',
                    style: LineStyle.LargeDashed,
                    labelBackgroundColor: '#2d2d2d',
                },
                // Horizontal crosshair line (showing Price in Label)
                horzLine: {
                    width: 1,
                    color: '#999999',
                    style: LineStyle.LargeDashed,
                    labelBackgroundColor: '#2d2d2d',
                },
            },
        })
        chart.timeScale().applyOptions({
            timeVisible: true,
            rightOffset: 7,
        })
        const getMarkerByTradeType = (type: 'OPEN' | 'CLOSE', direction: 'LONG' | 'SHORT', price: number, utcDate: Date) => {
            const timezone = currentUser?.timezone || 'UTC'
            let offset = 0

            switch (period) {
                case ChartPeriod.INTERVAL_1_MINUTE:
                   offset = 60
                   break
                case ChartPeriod.INTERVAL_5_MINUTE:
                   offset = 5 * 60
                   break
                case ChartPeriod.INTERVAL_15_MINUTES:
                   offset = 15 * 60
                   break
                case ChartPeriod.INTERVAL_30_MINUTES:
                   offset = 30 * 60
                   break
                case ChartPeriod.INTERVAL_1_HOUR:
                   offset = 60 * 60
                   break
                case ChartPeriod.INTERVAL_4_HOURS:
                   offset = 4 * 60 * 60
                   break
                case ChartPeriod.INTERVAL_1_DAY:
                   offset = 24 * 60 * 60
                   break
                case ChartPeriod.INTERVAL_1_WEEK:
                   offset = 7 * 24 * 60 * 60
                   break
                default:
                    break
            }
            const date = Math.ceil(moment(utcDate).tz(timezone).valueOf() / 1000) + (moment(utcDate).utcOffset() * 60) - offset

            if (type === 'OPEN') {
                if (direction === 'LONG') {
                    return {
                        time: date,
                        position: 'belowBar',
                        color: '#2196F3',
                        shape: 'arrowUp',
                        text: '' + price,
                    }
                }
                return {
                    time: date,
                    position: 'aboveBar',
                    color: '#e91e63',
                    shape: 'arrowDown',
                    text: '' + price,
                }
            }
            if (direction === 'LONG') {
                return {
                    time: date,
                    position: 'aboveBar',
                    color: '#e91e63',
                    shape: 'arrowDown',
                    text: '' + price,
                }
            }
            return {
                time: date,
                position: 'belowBar',
                color: '#2196F3',
                shape: 'arrowUp',
                text: '' + price,
            }
        }
        const drawPriceLine = (price: number, lineStyle: LineStyle, color: string, title: string, draggable: boolean = false): CreatePriceLineOptions => {
            const priceLine: CreatePriceLineOptions = {
                price: price,
                color: color,
                lineWidth: 1,
                lineStyle: lineStyle,
                axisLabelVisible: true,
                title: title,
                draggable: draggable,
            }
            barSeries.createPriceLine(priceLine)

            return priceLine
        }
        const markers: any = [
            getMarkerByTradeType('OPEN', trade.direction!!, trade.openPrice!!, trade.openDate!!),
            getMarkerByTradeType('CLOSE', trade.direction!!, trade.closePrice!!, trade.closeDate!!),
        ]
        barSeries.setMarkers(markers)

        switch (period) {
            case ChartPeriod.INTERVAL_1_MINUTE:
            case ChartPeriod.INTERVAL_5_MINUTE:
            case ChartPeriod.INTERVAL_15_MINUTES:
            case ChartPeriod.INTERVAL_30_MINUTES:
            case ChartPeriod.INTERVAL_1_HOUR:
            case ChartPeriod.INTERVAL_4_HOURS:
                if (trade.priceLevel !== null) {
                    drawPriceLine(trade.priceLevel!!, LineStyle.Solid, LEVEL_PRICE_LINE_COLOR, 'Уровень')
                }
                trade?.takeProfitsPrices?.forEach(takeProfitPrice => {
                    drawPriceLine(takeProfitPrice.price!!, LineStyle.LargeDashed, TAKE_PROFIT_PRICE_LINE_COLOR, 'TP1')
                })

                if (trade.stopLossPrice !== null) {
                    drawPriceLine(trade.stopLossPrice!!, LineStyle.LargeDashed, STOP_LOSS_PRICE_LINE_COLOR, 'SL')
                }
                break
            default:
                break
        }
        chart.subscribeClick((event: any) => {
            //console.log(event)
        })
        chart.subscribeCrosshairMove((event: any) => {
            //console.log(event)

            //line?.applyOptions( {
            //    price: barSeries.coordinateToPrice(event.point!!.y) || 0,
            //})
        })
        chart.subscribeCustomPriceLineDragged((params) => {
            const line = params.customPriceLine;

            //alert(`${line.options().title} dragged from ${params.fromPriceString} to ${chart.priceScale('right').formatPrice(line.options().price, 2)}`);
        });

        chart.timeScale().fitContent()

        //console.log(chart.takeScreenshot().toDataURL('image/png', 1.0))

        return chart
    }

    useEffect(() => {
        if (!userSettings?.settings?.account?.selectedId) {
            return
        }
        const API_URL = process.env.REACT_APP_API_URL
        const TRADE_CHART_URL = `${API_URL}/accounts/${userSettings?.settings?.account?.selectedId}/trades/${tradeId}/charts`

        axios.get(TRADE_CHART_URL)
            .then((response: AxiosResponse<JsonApiArrayDto<TradeChartDto>>) => {
                const newQuotes: {[key: string]: Array<any>} = {}
                const intervals: any[] = []

                response.data?.data?.forEach(function (value) {
                    const item = value.attributes
                    const interval = item.interval || ''
                    let intervalLabel = ''
                    let intervalOrder = 0

                    switch(interval) {
                        case ChartPeriod.INTERVAL_1_WEEK:
                            intervalLabel = 'W'
                            intervalOrder = 0
                            break
                        case ChartPeriod.INTERVAL_1_DAY:
                            intervalLabel = 'D'
                            intervalOrder = 1
                            break
                        case ChartPeriod.INTERVAL_4_HOURS:
                            intervalLabel = 'H4'
                            intervalOrder = 2
                            break
                        case ChartPeriod.INTERVAL_1_HOUR:
                            intervalLabel = 'H1'
                            intervalOrder = 3
                            break
                        case ChartPeriod.INTERVAL_30_MINUTES:
                            intervalLabel = 'M30'
                            intervalOrder = 4
                            break
                        case ChartPeriod.INTERVAL_15_MINUTES:
                            intervalLabel = 'M15'
                            intervalOrder = 5
                            break
                        case ChartPeriod.INTERVAL_5_MINUTE:
                            intervalLabel = 'M5'
                            intervalOrder = 6
                            break
                        case ChartPeriod.INTERVAL_1_MINUTE:
                            intervalLabel = 'M1'
                            intervalOrder = 7
                            break
                        default:
                            logger.error("Could not prepare required chart period constant: " + interval + "!")
                    }
                    intervals.push({
                        label: intervalLabel,
                        interval: interval,
                        order: intervalOrder
                    })
                    setTrade(item.trade || {} as TradeDto)

                    item.quotes?.forEach(quote => {
                        quote.time = quote.time as UTCTimestamp

                        if (!newQuotes[interval]) {
                            newQuotes[interval] = []
                        }
                        newQuotes[interval].push(quote)
                    })
                })
                setQuotes(newQuotes)
                setAvailableIntervals(
                    intervals.sort((interval1, interval2) => interval1.order - interval2.order)
                )
            })
    }, [userSettings?.settings?.account?.selectedId])

    useEffect(() => {
        let chart: any | null = null

        if (topChartInterval in quotes) {
            chart = drawChart(trade, quotes[topChartInterval], topChartInterval, chartContainerRef)
        }
        return () => {
            chart?.remove();
        }
    }, [quotes, topChartInterval, chartLayout])

    useEffect(() => {
        let chart: any | null = null

        if ((chartLayout === ChartLayout.LAYOUT_2_VERTICAL || chartLayout === ChartLayout.LAYOUT_2_HORIZONTAL || chartLayout === ChartLayout.LAYOUT_3)
                && bottomChartInterval in quotes) {
            chart = drawChart(trade, quotes[bottomChartInterval], bottomChartInterval, chartContainerRef2)
        }
        return () => {
            if ((chartLayout === ChartLayout.LAYOUT_2_VERTICAL || chartLayout === ChartLayout.LAYOUT_2_HORIZONTAL || chartLayout === ChartLayout.LAYOUT_3)
                    && bottomChartInterval in quotes) {
                chart?.remove();
            }
        }
    }, [quotes, bottomChartInterval, chartLayout])

    useEffect(() => {
        let chart: any | null = null

        if (chartLayout === ChartLayout.LAYOUT_3 && middleChartInterval in quotes) {
            chart = drawChart(trade, quotes[middleChartInterval], middleChartInterval, chartContainerRef3)
        }
        return () => {
            if (chartLayout === ChartLayout.LAYOUT_3) {
                chart?.remove();
            }
        }
    }, [quotes, middleChartInterval, chartLayout])

    useEffect(() => {
        if (topChartHidePriceLines) {

        } else {

        }
    }, [topChartHidePriceLines])

    useEffect(() => {
        if (bottomChartHidePriceLines) {

        } else {

        }
    }, [bottomChartHidePriceLines])

    useEffect(() => {
        document.getElementById('kt_content_container')?.classList.remove('container')
        document.getElementById('kt_content_container')?.classList.add('container-fluid')

        return () => {
            document.getElementById('kt_content_container')?.classList.remove('container-fluid')
            document.getElementById('kt_content_container')?.classList.add('container')
        }
    }, [])

    const changeChartInterval = (range: any, position: string) => {
        if (position === 'top') {
            setTopChartInterval(range.interval)
        } else if (position === 'middle') {
            setMiddleChartInterval(range.interval)
        } else {
            setBottomChartInterval(range.interval)
        }
    }

    return (
        <>
            <div className="card-header border-0 ">
                <div className="d-flex align-items-center position-relative mb-3">
                    <a href="#"
                       className={'btn btn-icon btn-light-primary mb-3 me-2' + (chartLayout === ChartLayout.LAYOUT_1 ? ' active' : '')}
                       key={`chart-layout-1`}
                       title='1 график'
                       onClick={() => setChartLayout(ChartLayout.LAYOUT_1)}
                    >
                        <img src='/media/icons/layouts/layout-1.png' width='16'/>
                    </a>
                    <a href="#"
                       className={'btn btn-icon btn-light-primary mb-3 me-2' + (chartLayout === ChartLayout.LAYOUT_2_HORIZONTAL ? ' active' : '')}
                       key={`chart-layout-2-horizontal`}
                       title='2 графика по горизонтали'
                       onClick={() => setChartLayout(ChartLayout.LAYOUT_2_HORIZONTAL)}
                    >
                        <img src='/media/icons/layouts/layout-2-horizontal.png' width='16'/>
                    </a>
                    <a href="#"
                       className={'btn btn-icon btn-light-primary mb-3 me-2' + (chartLayout === ChartLayout.LAYOUT_2_VERTICAL ? ' active' : '')}
                       key={`chart-layout-2-vertical`}
                       title='2 графика по вертикали'
                       onClick={() => setChartLayout(ChartLayout.LAYOUT_2_VERTICAL)}
                    >
                        <img src='/media/icons/layouts/layout-2-vertical.png' width='16'/>
                    </a>
                    <a href="#"
                       className={'btn btn-icon btn-light-primary mb-3 me-2' + (chartLayout === ChartLayout.LAYOUT_3 ? ' active' : '')}
                       key={`chart-layout-3`}
                       title='3 графика'
                       onClick={() => setChartLayout(ChartLayout.LAYOUT_3)}
                    >
                        <img src='/media/icons/layouts/layout-3-horizontal-2-vertical.png' width='16'/>
                    </a>
                </div>
            </div>
            {chartLayout === ChartLayout.LAYOUT_1 && (
                <>
                    <KTCard>
                        <KTCardBody className='py-4'>
                            <div className="mb-3">
                                <span className='me-10'>{trade.ticker}</span>
                                {availableIntervals.map(availableInterval => (
                                    <a href="#"
                                       className={"btn btn-icon btn-light-primary me-2" + (availableInterval.interval === topChartInterval ? ' active' : '')}
                                       key={`up-interval-${availableInterval.label}`}
                                       title='Скрыть уровни'
                                       onClick={() => changeChartInterval(availableInterval, 'top')}
                                    >
                                        {availableInterval.label}
                                    </a>
                                ))}
                                {/*<a href="#"
                           className={'btn btn-light-primary me-6'}
                           key={`up-hide-price-lines`}
                           onClick={() => setTopChartHidePriceLines(!topChartHidePriceLines)}
                        >
                            <i className='bi bi-eye fs-1'/>
                        </a>*/}
                            </div>
                            <div className='table-responsive'>
                                <div style={{height: '1024px'}} ref={chartContainerRef}/>
                            </div>
                        </KTCardBody>
                    </KTCard>
                </>
            )}
            {chartLayout === ChartLayout.LAYOUT_2_HORIZONTAL && (
                <>
                    <KTCard>
                        <KTCardBody className='py-4'>
                            <div className="mb-3">
                                <span className='me-10'>{trade.ticker}</span>
                                {availableIntervals.map(availableInterval => (
                                    <a href="#"
                                       className={"btn btn-icon btn-light-primary me-2" + (availableInterval.interval === topChartInterval ? ' active' : '')}
                                       key={`up-interval-${availableInterval.label}`}
                                       title='Скрыть уровни'
                                       onClick={() => changeChartInterval(availableInterval, 'top')}
                                    >
                                        {availableInterval.label}
                                    </a>
                                ))}
                                {/*<a href="#"
                           className={'btn btn-light-primary me-6'}
                           key={`up-hide-price-lines`}
                           onClick={() => setTopChartHidePriceLines(!topChartHidePriceLines)}
                        >
                            <i className='bi bi-eye fs-1'/>
                        </a>*/}
                            </div>
                            <div className='table-responsive'>
                                <div style={{height: '600px'}} ref={chartContainerRef}/>
                            </div>
                        </KTCardBody>
                    </KTCard>
                    <div style={{height: '20px'}}></div>
                    <KTCard>
                        <KTCardBody className='py-4'>
                            <div className="mb-3">
                                <span className='me-10'>{trade.ticker}</span>
                                {availableIntervals.map(availableInterval => (
                                    <a href="#"
                                       className={"btn btn-icon btn-light-primary me-2" + (availableInterval.interval === bottomChartInterval ? ' active' : '')}
                                       key={`bottom-interval-${availableInterval.label}`}
                                       onClick={() => changeChartInterval(availableInterval, 'bottom')}
                                    >
                                        {availableInterval.label}
                                    </a>
                                ))}
                                {/*<a href="#"
                           className={'btn btn-light-primary me-6'}
                           key={`bottom-hide-price-lines`}
                           title='Скрыть уровни'
                           onClick={() => setBottomChartHidePriceLines(!bottomChartHidePriceLines)}
                        >
                            <i className='bi bi-eye fs-1'/>
                        </a>*/}
                            </div>
                            <div className='table-responsive'>
                                <div style={{height: '430px'}} ref={chartContainerRef2}/>
                            </div>
                        </KTCardBody>
                    </KTCard>
                </>
            )}
            {chartLayout === ChartLayout.LAYOUT_2_VERTICAL && (
                <div>
                    <div className='row g-6 g-xl-9'>
                        <div className='col-lg-6'>
                            <div className='card card-flush h-lg-100'>
                                <KTCard>
                                    <KTCardBody>
                                        <div className="mb-3">
                                            <span className='me-10'>{trade.ticker}</span>
                                            {availableIntervals.map(availableInterval => (
                                                <a href="#"
                                                   className={"btn btn-icon btn-light-primary me-2" + (availableInterval.interval === topChartInterval ? ' active' : '')}
                                                   key={`up-interval-${availableInterval.label}`}
                                                   title='Скрыть уровни'
                                                   onClick={() => changeChartInterval(availableInterval, 'top')}
                                                >
                                                    {availableInterval.label}
                                                </a>
                                            ))}
                                            {/*<a href="#"
                                               className={'btn btn-light-primary me-6'}
                                               key={`up-hide-price-lines`}
                                               onClick={() => setTopChartHidePriceLines(!topChartHidePriceLines)}
                                            >
                                                <i className='bi bi-eye fs-1'/>
                                            </a>*/}
                                        </div>
                                        <div className='table-responsive'>
                                            <div style={{height: '1024px'}} ref={chartContainerRef}/>
                                        </div>
                                    </KTCardBody>
                                </KTCard>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='card card-flush h-lg-100'>
                                <KTCard>
                                    <KTCardBody>
                                        <div className="mb-3">
                                            <span className='me-10'>{trade.ticker}</span>
                                            {availableIntervals.map(availableInterval => (
                                                <a href="#"
                                                   className={"btn btn-icon btn-light-primary me-2" + (availableInterval.interval === bottomChartInterval ? ' active' : '')}
                                                   key={`bottom-interval-${availableInterval.label}`}
                                                   onClick={() => changeChartInterval(availableInterval, 'bottom')}
                                                >
                                                    {availableInterval.label}
                                                </a>
                                            ))}
                                            {/*<a href="#"
                                               className={'btn btn-light-primary me-6'}
                                               key={`bottom-hide-price-lines`}
                                               title='Скрыть уровни'
                                               onClick={() => setBottomChartHidePriceLines(!bottomChartHidePriceLines)}
                                            >
                                                <i className='bi bi-eye fs-1'/>
                                            </a>*/}
                                        </div>
                                        <div className='table-responsive'>
                                            <div style={{height: '1024px'}} ref={chartContainerRef2}/>
                                        </div>
                                    </KTCardBody>
                                </KTCard>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {chartLayout === ChartLayout.LAYOUT_3 && (
                <div>
                    <div className='row g-6 g-xl-9 mb-4'>
                        <div className='col-lg-12'>
                            <div className='card card-flush h-lg-100'>
                                <KTCard>
                                    <KTCardBody>
                                        <div className="mb-3">
                                            <span className='me-10'>{trade.ticker}</span>
                                            {availableIntervals.map(availableInterval => (
                                                <a href="#"
                                                   className={"btn btn-icon btn-light-primary me-2" + (availableInterval.interval === middleChartInterval ? ' active' : '')}
                                                   key={`middle-interval-${availableInterval.label}`}
                                                   title='Скрыть уровни'
                                                   onClick={() => changeChartInterval(availableInterval, 'middle')}
                                                >
                                                    {availableInterval.label}
                                                </a>
                                            ))}
                                            {/*<a href="#"
                                               className={'btn btn-light-primary me-6'}
                                               key={`up-hide-price-lines`}
                                               onClick={() => setTopChartHidePriceLines(!topChartHidePriceLines)}
                                            >
                                                <i className='bi bi-eye fs-1'/>
                                            </a>*/}
                                        </div>
                                        <div className='table-responsive'>
                                            <div style={{height: '440px'}} ref={chartContainerRef3}/>
                                        </div>
                                    </KTCardBody>
                                </KTCard>
                            </div>
                        </div>
                    </div>
                    <div className='row g-6 g-xl-9'>
                        <div className='col-lg-6'>
                            <div className='card card-flush h-lg-100'>
                                <KTCard>
                                    <KTCardBody>
                                        <div className="mb-3">
                                            <span className='me-10'>{trade.ticker}</span>
                                            {availableIntervals.map(availableInterval => (
                                                <a href="#"
                                                   className={"btn btn-icon btn-light-primary me-2" + (availableInterval.interval === topChartInterval ? ' active' : '')}
                                                   key={`up-interval-${availableInterval.label}`}
                                                   title='Скрыть уровни'
                                                   onClick={() => changeChartInterval(availableInterval, 'top')}
                                                >
                                                    {availableInterval.label}
                                                </a>
                                            ))}
                                            {/*<a href="#"
                                               className={'btn btn-light-primary me-6'}
                                               key={`up-hide-price-lines`}
                                               onClick={() => setTopChartHidePriceLines(!topChartHidePriceLines)}
                                            >
                                                <i className='bi bi-eye fs-1'/>
                                            </a>*/}
                                        </div>
                                        <div className='table-responsive'>
                                            <div style={{height: '560px'}} ref={chartContainerRef}/>
                                        </div>
                                    </KTCardBody>
                                </KTCard>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='card card-flush h-lg-100'>
                                <KTCard>
                                    <KTCardBody>
                                        <div className="mb-3">
                                            <span className='me-10'>{trade.ticker}</span>
                                            {availableIntervals.map(availableInterval => (
                                                <a href="#"
                                                   className={"btn btn-icon btn-light-primary me-2" + (availableInterval.interval === bottomChartInterval ? ' active' : '')}
                                                   key={`bottom-interval-${availableInterval.label}`}
                                                   onClick={() => changeChartInterval(availableInterval, 'bottom')}
                                                >
                                                    {availableInterval.label}
                                                </a>
                                            ))}
                                            {/*<a href="#"
                                               className={'btn btn-light-primary me-6'}
                                               key={`bottom-hide-price-lines`}
                                               title='Скрыть уровни'
                                               onClick={() => setBottomChartHidePriceLines(!bottomChartHidePriceLines)}
                                            >
                                                <i className='bi bi-eye fs-1'/>
                                            </a>*/}
                                        </div>
                                        <div className='table-responsive'>
                                            <div style={{height: '560px'}} ref={chartContainerRef2}/>
                                        </div>
                                    </KTCardBody>
                                </KTCard>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

const TradeChart = () => {
    const intl = useIntl()
    const tradeBreadCrumbs: Array<PageLink> = [
        {
            title: intl.formatMessage({id: 'BREADCRUMB.TRADES.MANAGEMENT'}),
            path: '/trades',
            isSeparator: false,
            isActive: false,
        },
        {
            title: 'chart',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]
    return (
        <>
            <MetaHeader pageName='HTML.HEAD.TITLE.TRADES.CHART'/>
            <PageTitle breadcrumbs={tradeBreadCrumbs}>
                {intl.formatMessage({id: 'BREADCRUMB.TRADES.CHART'})}
            </PageTitle>
            <UserSettingsRequestProvider>
                <UserSettingsResponseProvider>
                    <TvTradeChartWrapper/>
                </UserSettingsResponseProvider>
            </UserSettingsRequestProvider>
        </>
    )
}

export {TradeChart}
