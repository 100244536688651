import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import {MetaHeader} from "../../../common/component/meta/MetaHeader";
import {useIntl} from "react-intl";

const initialValues = {
    email: 'admin@demo.com',
}

export function ForgotPassword() {
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
    const forgotPasswordSchema = Yup.object().shape({
        email: Yup.string()
            .email(intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD.VALIDATION.EMAIL.WRONG_FORMAT'}))
            .min(3, intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD.VALIDATION.EMAIL.MINIMUM'}).replace('%VALUE%', '3'))
            .max(50, intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD.VALIDATION.EMAIL.MAXIMUM'}).replace('%VALUE%', '50'))
            .required(intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD.VALIDATION.EMAIL.REQUIRED'})),
    })
    const formik = useFormik({
        initialValues,
        validationSchema: forgotPasswordSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            setHasErrors(undefined)
            setTimeout(() => {
                requestPassword(values.email)
                    .then(({data: {result}}) => {
                        setHasErrors(false)
                        setLoading(false)
                    })
                    .catch(() => {
                        setHasErrors(true)
                        setLoading(false)
                        setSubmitting(false)
                        setStatus('The login detail is incorrect')
                    })
            }, 1000)
        },
    })

    return (
        <>
            <MetaHeader pageName='HTML.HEAD.TITLE.FORGOT_PASSWORD'/>
            <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_password_reset_form'
                onSubmit={formik.handleSubmit}
            >
                <div className='text-center mb-10'>
                    {/* begin::Title */}
                    <h1 className='text-dark mb-3'>
                        {intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD.TITLE'})}
                    </h1>
                    {/* end::Title */}

                    {/* begin::Link */}
                    <div className='text-gray-400 fs-4'>
                        {intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD.TOOLTIP'})}
                    </div>
                    {/* end::Link */}
                </div>

                {/* begin::Title */}
                {hasErrors === true && (
                    <div className='mb-lg-15 alert alert-danger'>
                        <div className='alert-text font-weight-bold'>
                            Sorry, looks like there are some errors detected, please try again.
                        </div>
                    </div>
                )}

                {hasErrors === false && (
                    <div className='mb-10 bg-light-info p-8 rounded'>
                        <div className='text-info'>
                            {intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD.SENT_TOOLTIP'})}
                        </div>
                    </div>
                )}
                {/* end::Title */}

                {/* begin::Form group */}
                <div className='fv-row mb-10'>
                    <label className='form-label text-gray-900 fs-6'>
                        {intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD.EMAIL'})}
                    </label>
                    <input
                        type='email'
                        placeholder=''
                        autoComplete='off'
                        {...formik.getFieldProps('email')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {'is-invalid': formik.touched.email && formik.errors.email},
                            {
                                'is-valid': formik.touched.email && !formik.errors.email,
                            }
                        )}
                    />
                    {formik.touched.email && formik.errors.email && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.email}</span>
                            </div>
                        </div>
                    )}
                </div>
                {/* end::Form group */}

                {/* begin::Form group */}
                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                    <button
                        type='submit'
                        id='kt_password_reset_submit'
                        className='btn btn-lg btn-primary me-4'
                    >
                        <span className='indicator-label'>
                            {intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD.BUTTON.RESET'})}
                        </span>
                        {loading && (
                            <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
                        )}
                    </button>
                    <Link to='/auth/login'>
                        <button
                            type='button'
                            id='kt_login_password_reset_form_cancel_button'
                            className='btn btn-lg btn-light-primary'
                            disabled={formik.isSubmitting || !formik.isValid}
                        >
                            {intl.formatMessage({id: 'BUTTON.CANCEL'})}
                        </button>
                    </Link>{' '}
                </div>
                {/* end::Form group */}
            </form>
        </>
    )
}
