import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {UserModel} from '../../core/_models'
import moment from 'moment'

const usersColumns: ReadonlyArray<Column<UserModel>> = [
    {
        Header: (props) => <UserSelectionHeader tableProps={props}/>,
        id: 'selection',
        Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id}/>,
    },
    {
        Header: (props) => <UserCustomHeader tableProps={props} title='USER.USERNAME' className='min-w-125px'/>,
        id: 'username',
        Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]}/>,
    },
    {
        Header: (props) => (
            <UserCustomHeader tableProps={props} title='USER.EMAIL' className='min-w-125px'/>
        ),
        id: 'email',
        Cell: ({...props}) => (
            <div className='badge badge-light fw-bolder'>{props.data[props.row.index].email}</div>
        ),
    },
    {
        Header: (props) => (
            <UserCustomHeader tableProps={props} title='USER.ROLE' className='min-w-125px'/>
        ),
        id: 'role',
        Cell: ({...props}) => (
            <div className='d-flex flex-column'>{props.data[props.row.index].role}</div>
        ),
    },
    {
        Header: (props) => (
            <UserCustomHeader tableProps={props} title='USER.TIMEZONE' className='min-w-125px'/>
        ),
        id: 'timezone',
        Cell: ({...props}) => (
            <div className='fw-bolder'>
                {props.data[props.row.index].timezone > 0 ? '+' : ''}
                {props.data[props.row.index].timezone}
            </div>
        ),
    },
    {
        Header: (props) => (
            <UserCustomHeader tableProps={props} title='USER.COUNTRY' className='min-w-125px'/>
        ),
        id: 'country',
        Cell: ({...props}) => props.data[props.row.index].countryCode,
    },
    {
        Header: (props) => (
            <UserCustomHeader tableProps={props} title='USER.STATUS' className='min-w-125px'/>
        ),
        id: 'status',
        Cell: ({...props}) => (
            <a href='#'>
                {props.data[props.row.index].status === 'ACTIVE' ? (
                    <div className="badge badge-light-success fw-bolder">{props.data[props.row.index].status}</div>
                ) : (
                    <div className="badge badge-light-danger fw-bolder">{props.data[props.row.index].status}</div>
                )}
            </a>
        ),
    },
    {
        Header: (props) => (
            <UserCustomHeader tableProps={props} title='USER.ENABLED' className='min-w-125px'/>
        ),
        id: 'enabled',
        Cell: ({...props}) => (
            <a href='#'>
                {props.data[props.row.index].enabled ? (
                    <div className="badge badge-light-success fw-bolder">Enabled</div>
                ) : (
                    <div className="badge badge-light-danger fw-bolder">Disabled</div>
                )}
            </a>
        ),
    },
    {
        Header: (props) => (
            <UserCustomHeader tableProps={props} title='USER.DATE_LAST_LOGIN' className='min-w-125px'/>
        ),
        id: 'dateLastLogin',
        Cell: ({...props}) => (
            <div className='fw-bolder'>
                {props.data[props.row.index].dateLastLogin
                    ? moment(props.data[props.row.index].dateLastLogin).format('YYYY-MM-DD')
                    : ''
                }
            </div>
        ),
    },
    {
        Header: (props) => (
            <UserCustomHeader tableProps={props} title='USER.DATE_ADD' className='min-w-125px'/>
        ),
        id: 'dateAdd',
        Cell: ({...props}) => (
            <div className='fw-bolder'>
                {moment(props.data[props.row.index].dateAdd).format('YYYY-MM-DD')}
            </div>
        ),
    },
    {
        Header: (props) => (
            <UserCustomHeader tableProps={props} title='USER.ACTIONS' className='text-end min-w-100px'/>
        ),
        id: 'actions',
        Cell: ({...props}) => <UserActionsCell user={props.data[props.row.index]}/>,
    },
]

export {usersColumns}
