/* eslint-disable jsx-a11y/anchor-is-valid */
import {Dispatch, FC, SetStateAction, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {TradeModel} from "../core/_models";
import {MenuComponent} from "../../../../../_metronic/assets/ts/components";
import {ID, KTSVG} from "../../../../../_metronic/helpers";
import {useTradeModelListViewContext} from "../../../../common/provider/trade/TradeListViewProvider";
import {useTradeQueryResponse} from "../../../../common/provider/trade/TradeDataProvider";
import {deleteTradeById} from "../core/_requests";
import Swal from "sweetalert2";
import {AccountDto} from "../../../../common/dto/account/AccountDto";
import {getAccountById} from "../../../../common/request/account/AccountRequest";
import {useIntl} from "react-intl";
import {useThemeMode} from "../../../../../_metronic/partials";

type Props = {
    trade: TradeModel,
    account?: AccountDto,
    setAccount: Dispatch<SetStateAction<AccountDto>>
    setIsUpdateDeposit: Dispatch<SetStateAction<boolean>>
}

const TradeActionsCell: FC<Props> = ({trade, account, setAccount, setIsUpdateDeposit}) => {
    const intl = useIntl()
    const {isDark} = useThemeMode()
    const {setItemIdForUpdate, setItemForCreate} = useTradeModelListViewContext()
    const {query, refetch: refetchAllTrades} = useTradeQueryResponse()
    const queryClient = useQueryClient()
    let tradeId: string = ''

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const showDeleteTradeModal = (accountId: ID, tradeId: ID, ticker?: string) => {
        return Swal.fire({
            html: intl.formatMessage({id: 'TRADES.DELETE.MODAL.QUESTION.BODY'}) + ' <span class="text-dark">' + ticker + '</span>?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: intl.formatMessage({id: 'TRADES.DELETE.MODAL.QUESTION.BUTTON.YES'}),
            cancelButtonText: intl.formatMessage({id: 'TRADES.DELETE.MODAL.QUESTION.BUTTON.NO'}),
        }).then((result) => {
            if (result.isConfirmed) {
                deleteTradeById(trade?.account?.id, trade.id)
                    .then(() => {
                        Swal.fire(
                            intl.formatMessage({id: 'TRADES.DELETE.MODAL.SUCCESS.TITLE'}),
                            intl.formatMessage({id: 'TRADES.DELETE.MODAL.SUCCESS.BODY'}).replace('%VALUE%', ticker || ''),
                            'success'
                        )
                        refetchAllTrades()

                        getAccountById(accountId).then(response => response.data?.attributes)
                            .then(account => {
                                if (account) {
                                    setAccount(account)
                                }
                            })

                        setIsUpdateDeposit(true)
                    }).catch(error => {
                        Swal.fire(
                            intl.formatMessage({id: 'TRADES.DELETE.MODAL.ERROR.TITLE'}),
                            intl.formatMessage({id: 'TRADES.DELETE.MODAL.ERROR.BODY'}) + ": " + trade.ticker + "!",
                            'error'
                        )
                        console.error("Could not delete account: " + ticker + "! Reason: " + JSON.stringify(error?.response?.data || '{}'))
                    })
            }
        })
    }

    const openEditModal = () => {
        setItemIdForUpdate(trade)
    }

    const deleteItemMutation = useMutation(() => showDeleteTradeModal(trade?.account?.id, trade.id, trade.ticker))

    return (
        <>
            <a
                href='#'
                className={'btn btn-light btn-active-light-primary btn-sm ' + (isDark ? 'text-gray-800' : 'text-gray-700')}
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
            >
                {intl.formatMessage({id: 'TRADES.ACTIONS'})}
                <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0'/>
            </a>
            {/* begin::Menu */}
            <div
                className={'menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-state-bg-light-primary fs-7 w-150px py-4 ' + (isDark ? 'menu-gray-800' : 'menu-gray-700')}
                data-kt-menu='true'
            >
                {/* begin::Menu item */}
                <div className='menu-item px-3'>
                    <a className='menu-link px-3' onClick={openEditModal}>
                        {intl.formatMessage({id: 'TRADES.EDIT'})}
                    </a>
                </div>
                {/* end::Menu item */}

                {/* begin::Menu item */}
                <div className='menu-item px-3'>
                    <a
                        className='menu-link px-3'
                        onClick={async () => await deleteItemMutation.mutateAsync()}
                    >
                        {intl.formatMessage({id: 'TRADES.DELETE'})}
                    </a>
                </div>
                {/* end::Menu item */}
            </div>
        </>
    )
}

export {TradeActionsCell}
