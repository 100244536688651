import {useIntl} from "react-intl";
import {Helmet} from "react-helmet";
import React, {FC} from "react";

type Prop = {
    pageName: string
}

const MetaHeader: FC<Prop> = ({pageName}) => {
    const intl = useIntl()

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>Trades Journal - {intl.formatMessage({id: pageName})}</title>
            <meta name="theme-color" content="#000000" />
            <meta name="description" content={intl.formatMessage({id: "HTML.HEAD.DESCRIPTION"})} />
            <meta name="keywords" content={intl.formatMessage({id: "HTML.HEAD.META.KEYWORDS"})} />
            <meta property="og:description" content={intl.formatMessage({id: "HTML.HEAD.META.DESCRIPTION"})} />
            <meta property="og:locale" content="en_US" />
            <meta property="og:url" content="https://trades-journal.com/" />
            <meta property="og:site_name" content="Trades Journal" />
            <meta property="og:image" content="https://trades-journal.com/media/product-demos/dashboard.png" />
            <meta property="og:image:width" content="600" />
            <meta property="og:image:height" content="303" />
            <meta property="og:image:type" content="image/png" />
        </Helmet>
    )
}

export {MetaHeader}