import React, {useEffect} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {AccountQueryRequestProvider} from "./accounts-list/core/QueryRequestProvider";
import {AccountQueryResponseProvider} from "./accounts-list/core/QueryResponseProvider";
import {KTCard} from "../../../_metronic/helpers";
import {AccountsListHeader} from "./accounts-list/components/header/AccountsListHeader";
import {AccountsTable} from "./accounts-list/table/AccountsTable";
import {AccountDepositHistoryModal} from "./accounts-list/modal/AccountDepositHistoryModal";
import {NewAccountModal} from "./accounts-list/modal/NewAccountModal";
import {MetaHeader} from "../../common/component/meta/MetaHeader";
import {useIntl} from "react-intl";
import {AccountListViewProvider, useAccountListView} from "../../common/provider/account/AccountListViewProvider";
import {UpdateAccountDepositModal} from "./accounts-list/modal/UpdateAccountDepositModal";

const AccountsList = () => {
    const {itemForCreate, itemIdForUpdate, itemIdForDepositHistory, itemIdForUpdateDepositHistory} = useAccountListView()

    return (
        <>
            <KTCard>
                <AccountsListHeader/>
                <AccountsTable/>
            </KTCard>
            {(itemForCreate !== undefined || itemIdForUpdate !== undefined) && <NewAccountModal/>}
            {itemIdForUpdateDepositHistory !== undefined && <UpdateAccountDepositModal/>}
            {itemIdForDepositHistory !== undefined && <AccountDepositHistoryModal/>}
        </>
    )
}

const AccountsListWrapper = () => (
    <AccountQueryRequestProvider>
        <AccountQueryResponseProvider>
            <AccountListViewProvider>
                <AccountsList/>
            </AccountListViewProvider>
        </AccountQueryResponseProvider>
    </AccountQueryRequestProvider>
)

const AccountPage: React.FC = () => {
    const intl = useIntl()
    const accountBreadCrumbs: Array<PageLink> = [
        {
            title: intl.formatMessage({id: 'BREADCRUMB.ACCOUNTS.MANAGEMENT'}),
            path: '/accounts',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]

    useEffect(() => {
        document.getElementById('kt_content_container')?.classList.remove('container')
        document.getElementById('kt_content_container')?.classList.add('container-fluid')

        return () => {
            document.getElementById('kt_content_container')?.classList.remove('container-fluid')
            document.getElementById('kt_content_container')?.classList.add('container')
        }
    }, [])

    return (
        <>
            <MetaHeader pageName='HTML.HEAD.TITLE.ACCOUNTS'/>
            <PageTitle breadcrumbs={accountBreadCrumbs}>
                {intl.formatMessage({id: 'BREADCRUMB.ACCOUNTS.LIST'})}
            </PageTitle>
            <AccountsListWrapper/>
        </>
    )
}

export default AccountPage
