/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useEffect, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import {getUsers} from './_requests'
import {UserModel} from './_models'
import {useQueryRequest} from './QueryRequestProvider'
import {
    createJsonApiResponseContext,
    initialQueryResponse,
    initialQueryState,
    PaginationState,
    QUERIES,
    stringifyRequestQuery,
    WithChildren
} from "../../../../../_metronic/helpers";

const QueryResponseContext = createJsonApiResponseContext<UserModel>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
    const {state} = useQueryRequest()
    const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
    const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

    useEffect(() => {
        if (query !== updatedQuery) {
            setQuery(updatedQuery)
        }
    }, [updatedQuery])

    const {
        isFetching,
        refetch,
        data: response,
    } = useQuery(
        `${QUERIES.USERS_LIST}-${query}`,
        () => {
            return getUsers(query)
        },
        {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
    )
    return (
        <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
            {children}
        </QueryResponseContext.Provider>
    )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
    const {response} = useQueryResponse()
    if (!response) {
        return []
    }
    return response?.data?.map(function (value, index) {
        const user = value.attributes
        user.id = value.id as any

        return user
    }) || []
}

const useQueryResponsePagination = () => {
    const defaultPaginationState: PaginationState = {
        links: [],
        ...initialQueryState,
    }
    // TODO fix
    /*const {response} = useQueryResponse()
    if (!response || !response.payload || !response.payload.pagination) {
      return defaultPaginationState
    }*/

    return defaultPaginationState //response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
    const {isLoading} = useQueryResponse()
    return isLoading
}

export {
    QueryResponseProvider,
    useQueryResponse,
    useQueryResponseData,
    useQueryResponsePagination,
    useQueryResponseLoading,
}
