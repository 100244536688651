import {FC} from 'react'
import {ColumnInstance} from 'react-table'
import {TradeModel} from "../core/_models";

type Props = {
    column: ColumnInstance<TradeModel>,
    hiddenColumns: Array<string>,
}

const CustomHeaderColumn: FC<Props> = ({column, hiddenColumns}) => {
    if (hiddenColumns.indexOf(column.id) > -1) {
        return null
    }
    return (
        <>
            {column.Header && typeof column.Header === 'string' ? (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ) : (
                column.render('Header')
            )}
        </>
    )
}

export {CustomHeaderColumn}
