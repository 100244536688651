/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from "../../../../_metronic/assets/ts/_utils";
import {AccountResultTimelineDto} from "../../../common/dto/account/AccountDashboardDto";
import {IntlShape, useIntl} from "react-intl";
import {useThemeMode} from "../../../../_metronic/partials";

type Props = {
    className: string,
    resultTimeline?: Array<AccountResultTimelineDto>
}

const TradesChartWidget: React.FC<Props> = ({className, resultTimeline}) => {
    const intl = useIntl()
    const {isDark} = useThemeMode()
    const chartRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (!chartRef.current) {
            return
        }
        const height = parseInt(getCSS(chartRef.current, 'height'))
        const chart = new ApexCharts(chartRef.current, getChartOptions(height, intl, isDark, resultTimeline))
        
        if (chart) {
            chart.render()
        }

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef, resultTimeline])

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                {/* begin::Title */}
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fs-3 mb-1'>
                        {intl.formatMessage({id: "DASHBOARD.CHART.TRADES_STATISTICS.TITLE"})}
                    </span>

                    <span className='text-muted fs-7'>
                        {resultTimeline?.length || 0}&nbsp;
                        {intl.formatMessage({id: "DASHBOARD.CHART.RECORDS"})}
                    </span>
                </h3>
                {/* end::Title */}
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className='card-body'>
                {/* begin::Chart */}
                <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '350px'}}/>
                {/* end::Chart */}
            </div>
            {/* end::Body */}
        </div>
    )
}

export {TradesChartWidget}

function getChartOptions(height: number, intl: IntlShape, isDark: boolean, resultTimeline?: Array<AccountResultTimelineDto>): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')
    const longColor = getCSSVariableValue('--bs-success')
    const shortColor = getCSSVariableValue('--bs-danger')
    const breakevenColor = getCSSVariableValue('--bs-gray-300')
    const longDataList = resultTimeline?.map(value => value.longTrades || 0) || []
    const shortDataList = resultTimeline?.map(value => value.shortTrades || 0) || []
    const datesList = resultTimeline?.map(value => value.date || 0) || []
    const opacity = (isDark ? 0.7 : 1.0)

    return {
        series: [
            {
                name: intl.formatMessage({id: "DASHBOARD.CHART.TRADES_STATISTICS.LONG"}),
                data: longDataList,
            },
            {
                name: intl.formatMessage({id: "DASHBOARD.CHART.TRADES_STATISTICS.SHORT"}),
                data: shortDataList,
            },
        ],
        chart: {
            fontFamily: 'inherit',
            type: 'bar',
            height: height,
            stacked: true,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '30%',
                borderRadius: 5,
            },
        },
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: datesList,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        fill: {
            opacity: opacity,
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val) {
                    return val + ''
                },
            },
        },
        colors: [longColor, shortColor, breakevenColor],
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
    }
}
