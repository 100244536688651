import {FC} from "react";
import {useIntl} from "react-intl";
import {PercentChangeWidget} from "./PercentChangeWidget";
import CountUp from "react-countup";
import {WidgetKey} from "../../../common/dto/dashboard/WidgetDto";
import {useThemeMode} from "../../../../_metronic/partials";

type Props = {
    selectedWidgets: {[key: string]: boolean},
    value: number
    previousValue: number
}

const WidgetShortTrades: FC<Props> = ({selectedWidgets, value, previousValue}) => {
    const intl = useIntl()
    const {isDark} = useThemeMode()
    const darkStyle = (isDark ? "widget-dark" : "")

    if (!selectedWidgets[WidgetKey.SHORT_TRADES]) {
        return null
    }
    return (
        <div className="col-sm-4 col-xl-2 mb-xl-10">
            <div className="card h-lg-100">
                <div className="card-body d-flex justify-content-between align-items-start flex-column">
                    {/*begin::Icon*/}
                    <div className="m-0">
                        {/*begin::Svg Icon | path: icons/50-finance-icons-red/short-trade.svg*/}
                        <span className="svg-icon svg-icon-2hx svg-icon-gray-600">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 269.24 201.59"><defs><style>{`.short-trade-1{fill:none;stroke:#344154;stroke-linecap:round;stroke-miterlimit:10;stroke-width:5.5px;}.short-trade-2{fill:#ff6d3b;}`}</style></defs><g><g id="Icon_Set" data-name="Icon Set">
                                <rect className={"short-trade-1 " + darkStyle} x="2.75" y="2.75" width="144.59" height="82.84"/><path
                                className={"short-trade-1 " + darkStyle} d="M53.48,65.74a30.5,30.5,0,0,1,0-43.13"/><path className={"short-trade-1 " + darkStyle}
                                                                                                  d="M96.61,22.61a30.5,30.5,0,0,1,0,43.13"/><circle
                                className={"short-trade-1 " + darkStyle} cx="124.24" cy="46.23" r="1.73"/><path className={"short-trade-1 " + darkStyle}
                                                                                         d="M24.11,46.23A1.73,1.73,0,1,1,25.84,48,1.73,1.73,0,0,1,24.11,46.23Z"/><path
                                className={"short-trade-1 " + darkStyle}
                                d="M66.62,52.57a8.42,8.42,0,1,0,16.84,0c0-4.65-3.45-6.8-8.42-8.42-4.42-1.44-8.42-3.77-8.42-8.42a8.42,8.42,0,1,1,16.84,0"/><line
                                className={"short-trade-1 " + darkStyle} x1="75.04" y1="22.61" x2="75.04" y2="27.36"/><line className={"short-trade-1 " + darkStyle} x1="75.04" y1="60.99"
                                                                                                     x2="75.04" y2="65.74"/><polyline
                                className={"short-trade-1 " + darkStyle} points="166.27 104.57 166.27 21.72 147.34 21.72"/><polyline className={"short-trade-1 " + darkStyle}
                                                                                                              points="21.69 85.59 21.69 104.57 128.91 104.57"/><polyline
                                className={"short-trade-1 " + darkStyle} points="185.21 104.57 185.21 40.91 166.27 40.91"/><polyline className={"short-trade-1 " + darkStyle}
                                                                                                              points="40.63 104.57 40.63 123.75 109.89 123.75"/><polyline
                                className={"short-trade-1 " + darkStyle}
                                points="205.41 164.06 265.35 104.13 244.83 83.61 187.08 141.36 147.9 102.18 114.67 135.41 86.1 163.99 72.81 150.82 72.81 198.84 120.94 198.84 106.62 184.51 147.9 143.22 187.08 182.4 205.41 164.06"/><path
                                className="short-trade-2"
                                d="M83.81,187.84H94.39l-3.33-3.33,7.78-7.78,41.29-41.29,7.78-7.78,7.78,7.78,31.4,31.4,10.56-10.56,52.16-52.16-5-5-50,50-7.78,7.78-7.78-7.78-31.4-31.4-25.45,25.45L93.89,171.76l-7.75,7.75-2.32-2.3v10.63Z"/></g></g>
                            </svg>
                        </span>
                        {/*end::Svg Icon*/}
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Section*/}
                    <div className="d-flex flex-column my-7">
                        {/*begin::Number*/}
                        <span className="fs-2x text-gray-800 lh-1 ls-n2">
                            <CountUp end={value} duration={1.0} />
                        </span>
                        {/*end::Number*/}
                        {/*begin::Follower*/}
                        <div className="m-0">
                            <span className="fs-6 text-gray-400">
                                {intl.formatMessage({id: "DASHBOARD.SHORT_TRADES"})}
                            </span>
                        </div>
                        {/*end::Follower*/}
                    </div>
                    {/*end::Section*/}
                    <PercentChangeWidget currentValue={value} previousValue={previousValue} />
                </div>
            </div>
        </div>
    )
}

export {WidgetShortTrades}
