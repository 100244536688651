import {FC} from "react";
import {useIntl} from "react-intl";
import {PercentChangeWidget} from "./PercentChangeWidget";
import CountUp from "react-countup";
import {WidgetKey} from "../../../common/dto/dashboard/WidgetDto";
import {useThemeMode} from "../../../../_metronic/partials";

type Props = {
    selectedWidgets: {[key: string]: boolean},
    value: number
    previousValue: number
}

const WidgetBreakevenTrades: FC<Props> = ({selectedWidgets, value, previousValue}) => {
    const intl = useIntl()
    const {isDark} = useThemeMode()
    const darkStyle = (isDark ? "widget-dark" : "")

    if (!selectedWidgets[WidgetKey.BREAKEVEN_TRADES]) {
        return null
    }
    return (
        <div className="col-sm-4 col-xl-2 mb-xl-10">
            <div className="card h-lg-100">
                <div className="card-body d-flex justify-content-between align-items-start flex-column">
                    {/*begin::Icon*/}
                    <div className="m-0">
                        {/*begin::Svg Icon | path: icons/50-finance-icons-red/breakeven-trades.svg*/}
                        <span className="svg-icon svg-icon-2hx svg-icon-gray-600">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 167.17 263.05"><defs><style>{`.breakeven-trades-1,.breakeven-trades-2{fill:none;stroke:#344154;stroke-linecap:round;stroke-width:5.5px;}.breakeven-trades-1{stroke - linejoin:round;}.breakeven-trades-2{stroke - miterlimit:10;}.breakeven-trades-3{fill:#009ef7;}`}</style></defs><g><g id="Icon_Set" data-name="Icon Set">
                                <path className={"breakeven-trades-1 " + darkStyle} d="M122.77,147.05c0,20.8-17.55,37.67-39.19,37.67s-39.19-16.87-39.19-37.67"/><line
                                className={"breakeven-trades-1 " + darkStyle} x1="83.58" y1="207.53" x2="83.58" y2="239.42"/><line className={"breakeven-trades-1 " + darkStyle} x1="57.26" y1="201.98"
                                                                                                       x2="57.26" y2="223.48"/><line
                                className={"breakeven-trades-1 " + darkStyle} x1="109.68" y1="202.06" x2="109.68" y2="223.03"/><path className={"breakeven-trades-1 " + darkStyle}
                                                                                                         d="M83.58,260.3a10.44,10.44,0,1,0-10.44-10.44A10.43,10.43,0,0,0,83.58,260.3Z"/><path
                                className={"breakeven-trades-1 " + darkStyle} d="M57.26,244.36a10.44,10.44,0,1,0-10.44-10.44A10.43,10.43,0,0,0,57.26,244.36Z"/><path
                                className={"breakeven-trades-1 " + darkStyle} d="M109.68,244.36a10.44,10.44,0,1,0-10.44-10.44A10.43,10.43,0,0,0,109.68,244.36Z"/><path
                                className={"breakeven-trades-1 " + darkStyle} d="M109.72,202.09c21.7-9.54,36.78-30.59,36.78-55h0"/><path className={"breakeven-trades-1 " + darkStyle}
                                                                                                             d="M57.25,202a64.86,64.86,0,0,0,26.33,5.53"/><path
                                className={"breakeven-trades-1 " + darkStyle} d="M20.66,147.05h0a59.55,59.55,0,0,0,23.73,47.32"/><polyline className={"breakeven-trades-2 " + darkStyle}
                                                                                                               points="164.42 2.75 164.42 134.61 2.75 134.61 2.75 41.98 114.19 41.98"/><path
                                className={"breakeven-trades-2 " + darkStyle} d="M59.47,112.41a34.09,34.09,0,0,1,0-48.22"/><path className={"breakeven-trades-2 " + darkStyle}
                                                                                                     d="M107.69,64.19a34.09,34.09,0,0,1,0,48.22"/><path
                                className={"breakeven-trades-2 " + darkStyle} d="M136.65,90.6a1.94,1.94,0,1,1,1.94,1.94A1.94,1.94,0,0,1,136.65,90.6Z"/><path className={"breakeven-trades-2 " + darkStyle}
                                                                                                                                 d="M26.64,90.6a1.94,1.94,0,1,1,1.94,1.94A1.94,1.94,0,0,1,26.64,90.6Z"/><path
                                className={"breakeven-trades-2 " + darkStyle}
                                d="M74.17,97.68a9.42,9.42,0,1,0,18.84,0c0-5.2-3.86-7.6-9.42-9.42-4.94-1.61-9.42-4.22-9.42-9.42a9.42,9.42,0,0,1,18.84,0"/><line
                                className={"breakeven-trades-2 " + darkStyle} x1="83.58" y1="64.19" x2="83.58" y2="69.5"/><line className={"breakeven-trades-2 " + darkStyle} x1="83.58" y1="107.1"
                                                                                                    x2="83.58" y2="112.41"/><line className={"breakeven-trades-2 " + darkStyle}
                                                                                                                                  x1="146.47"
                                                                                                                                  y1="14.54"
                                                                                                                                  x2="146.47"
                                                                                                                                  y2="41.61"/><line
                                className={"breakeven-trades-2 " + darkStyle} x1="128.54" y1="41.61" x2="128.54" y2="26.49"/><path className="breakeven-trades-3"
                                                                                                       d="M83.58,173.72c12.93,0,23.83-8.29,27.14-19.55H56.45C59.75,165.43,70.65,173.72,83.58,173.72Z"/></g></g>
                            </svg>
                        </span>
                        {/*end::Svg Icon*/}
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Section*/}
                    <div className="d-flex flex-column my-7">
                        {/*begin::Number*/}
                        <span className="fs-2x text-gray-800 lh-1 ls-n2">
                            <CountUp end={value} duration={1.0} />
                        </span>
                        {/*end::Number*/}
                        {/*begin::Follower*/}
                        <div className="m-0">
                            <span className="fs-7 text-gray-400">
                                {intl.formatMessage({id: "DASHBOARD.BREAKEVEN_TRADES"})}
                            </span>
                        </div>
                        {/*end::Follower*/}
                    </div>
                    {/*end::Section*/}
                    <PercentChangeWidget currentValue={value} previousValue={previousValue} />
                </div>
            </div>
        </div>
    )
}

export {WidgetBreakevenTrades}
