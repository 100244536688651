import React, {FC, useEffect, useState} from 'react'
import {ID, KTSVG, toAbsoluteStaticUrl, toAbsoluteUrl} from "../../../../_metronic/helpers";
import {useTradeModelListViewContext} from "../../../common/provider/trade/TradeListViewProvider";
import 'bootstrap-daterangepicker/daterangepicker.css';
import {TradeMediaModel, TradeModel} from "../table/core/_models";
import prettyBytes from "pretty-bytes";
import {Document, Page, pdfjs} from "react-pdf";
import 'tui-image-editor/dist/tui-image-editor.css';
import FilerobotImageEditor, {TABS, TOOLS} from "react-filerobot-image-editor";
import axios, {AxiosResponse} from "axios";
import moment from "moment";

const DEFAULT_IMAGE_WIDTH = 1400
const DEFAULT_IMAGE_HEIGHT = 600
const API_URL = process.env.REACT_APP_API_URL

type Prop = {
    trade: TradeModel
}

type ImageData = {
    id: ID
    name: string
    url: string
    fileFormat: string
    width: number
    height: number
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const TradeMediaModal: FC<Prop> = (props) => {
    const API_URL = process.env.REACT_APP_API_URL
    const {setImagesView} = useTradeModelListViewContext()
    const [editImage, setEditImage] = useState<string | undefined>()
    const {trade} = props
    const medias = trade?.medias || []
    const [imageData, setImageData] = useState<ImageData>({
        id: medias[0].id,
        name: medias[0].name || '',
        url: toAbsoluteStaticUrl('/static/' + medias[0].id),
        fileFormat: (medias[0].fileFormat || ''),
        width: (medias[0].width || DEFAULT_IMAGE_WIDTH),
        height: (medias[0].height || DEFAULT_IMAGE_HEIGHT)
    })
    let {innerWidth: screenWidth, innerHeight: screenHeight} = window;
    screenWidth -= 300
    screenHeight -= 350

    let maxWidth = Math.max.apply(Math, medias.map((media) => media.width || 0))
    let maxHeight = Math.max.apply(Math, medias.map((media) => media.height || 0))

    if (maxWidth > screenWidth) {
        maxWidth = screenWidth
    }

    if (maxHeight > screenHeight) {
        maxHeight = screenHeight
    }
    useEffect(() => {
        updateImageUrl(medias[0])
    }, [])

    const updateImageUrl = (media: TradeMediaModel) => {
        setImageData({
            id: media.id,
            name: media.name || '',
            url: toAbsoluteStaticUrl('/static/' + media.id),
            fileFormat: media.fileFormat || '',
            width: maxWidth,
            height: maxHeight
        })
    }
    const restoreImage = (image: ImageData) => {
        axios.put(`${API_URL}/medias/${image.id}/restore`, {})
            .then((response: AxiosResponse) => {
                setImageData(
                    cloneImageData(image)
                )
            }).catch(error => {})
    }

    const cloneImageData = (image: ImageData) => {
        return {
            id: medias[0].id,
            name: medias[0].name || '',
            url: API_URL + '/static/' + medias[0].id + '?time=' + Date.now(),
            fileFormat: (medias[0].fileFormat || ''),
            width: (medias[0].width || DEFAULT_IMAGE_WIDTH),
            height: (medias[0].height || DEFAULT_IMAGE_HEIGHT)
        }
    }

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_trade'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered'
                     style={{minWidth: imageData.width + 'px', minHeight: imageData.height + 'px'}}>
                    {/* begin::Modal content */}
                    <div className='modal-content'>
                        <div className='modal-header'>
                            {/* begin::Modal title */}
                            <h2 className='fw-bolder'>Trade Files</h2>
                            {/* end::Modal title */}

                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary cursor-pointer'
                                data-kt-trade-modal-action='close'
                                onClick={() => setImagesView(undefined)}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                            </div>
                            {/* end::Close */}
                        </div>
                        {/* begin::Modal body */}
                        <div className='modal-body scroll-y mx-1 mx-xl-1 my-1'>
                            <div className='d-flex flex-stack'>
                                <div className="badge badge-lg badge-light-primary mb-10">
                                    <div className="d-flex align-items-center flex-wrap">
                                       <span className="svg-icon svg-icon-2x svg-icon-primary me-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.3"
                                                      d="M14.1 15.013C14.6 16.313 14.5 17.813 13.7 19.113C12.3 21.513 9.29999 22.313 6.89999 20.913C5.29999 20.013 4.39999 18.313 4.39999 16.613C5.09999 17.013 5.99999 17.313 6.89999 17.313C8.39999 17.313 9.69998 16.613 10.7 15.613C11.1 15.713 11.5 15.813 11.9 15.813C12.7 15.813 13.5 15.513 14.1 15.013ZM8.5 12.913C8.5 12.713 8.39999 12.513 8.39999 12.313C8.39999 11.213 8.89998 10.213 9.69998 9.613C9.19998 8.313 9.30001 6.813 10.1 5.513C10.6 4.713 11.2 4.11299 11.9 3.71299C10.4 2.81299 8.49999 2.71299 6.89999 3.71299C4.49999 5.11299 3.70001 8.113 5.10001 10.513C5.80001 11.813 7.1 12.613 8.5 12.913ZM16.9 7.313C15.4 7.313 14.1 8.013 13.1 9.013C14.3 9.413 15.1 10.513 15.3 11.713C16.7 12.013 17.9 12.813 18.7 14.113C19.2 14.913 19.3 15.713 19.3 16.613C20.8 15.713 21.8 14.113 21.8 12.313C21.9 9.513 19.7 7.313 16.9 7.313Z"
                                                      fill="currentColor"></path>
                                                <path
                                                    d="M9.69998 9.61307C9.19998 8.31307 9.30001 6.81306 10.1 5.51306C11.5 3.11306 14.5 2.31306 16.9 3.71306C18.5 4.61306 19.4 6.31306 19.4 8.01306C18.7 7.61306 17.8 7.31306 16.9 7.31306C15.4 7.31306 14.1 8.01306 13.1 9.01306C12.7 8.91306 12.3 8.81306 11.9 8.81306C11.1 8.81306 10.3 9.11307 9.69998 9.61307ZM8.5 12.9131C7.1 12.6131 5.90001 11.8131 5.10001 10.5131C4.60001 9.71306 4.5 8.91306 4.5 8.01306C3 8.91306 2 10.5131 2 12.3131C2 15.1131 4.2 17.3131 7 17.3131C8.5 17.3131 9.79999 16.6131 10.8 15.6131C9.49999 15.1131 8.7 14.1131 8.5 12.9131ZM18.7 14.1131C17.9 12.8131 16.7 12.0131 15.3 11.7131C15.3 11.9131 15.4 12.1131 15.4 12.3131C15.4 13.4131 14.9 14.4131 14.1 15.0131C14.6 16.3131 14.5 17.8131 13.7 19.1131C13.2 19.9131 12.6 20.5131 11.9 20.9131C13.4 21.8131 15.3 21.9131 16.9 20.9131C19.3 19.6131 20.1 16.5131 18.7 14.1131Z"
                                                    fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        <a>Trade</a>
                                        <span className="svg-icon svg-icon-2x svg-icon-primary mx-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none">
                                                <path
                                                    d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
                                                    fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        <a>{trade.ticker}</a>
                                        <span className="svg-icon svg-icon-2x svg-icon-primary mx-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none">
                                                <path
                                                    d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
                                                    fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        <a>{moment(trade.closeDate).format('DD-MM-YYYY HH:mm')}</a>
                                        <span className="svg-icon svg-icon-2x svg-icon-primary mx-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none">
                                                <path
                                                    d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
                                                    fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        <a>Files</a>
                                    </div>
                                </div>
                                <div className="badge badge-lg badge-primary">
                                    <span
                                        id="kt_file_manager_items_counter">{medias.length} {medias.length === 1 ? 'file' : 'files'}</span>
                                </div>
                            </div>
                            {(imageData.fileFormat || '') !== 'PDF' && (
                                <>
                                    <button type="button" className="btn btn-light-success btn-sm me-lg-10"
                                        onClick={() => restoreImage(imageData)}>
                                        <span className="svg-icon svg-icon-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                             height="24" viewBox="0 0 24 24" fill="none"><rect
                                        opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)"
                                        fill="currentColor"></rect><rect x="6" y="11" width="12" height="2" rx="1"
                                                                         fill="currentColor"></rect></svg></span>
                                        Restore Original Image
                                    </button>

                                    <button type="button" className="btn btn-light-primary btn-sm"
                                        onClick={() => setEditImage(imageData.url)}>
                                        <span className="svg-icon svg-icon-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                             height="24" viewBox="0 0 24 24" fill="none"><rect
                                        opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)"
                                        fill="currentColor"></rect><rect x="6" y="11" width="12" height="2" rx="1"
                                                                         fill="currentColor"></rect></svg></span>
                                        Edit Image
                                    </button>
                                </>
                            )}
                            <div className="d-flex flex-row">
                                <div className="d-flex bg-white flex-center scroll-y scroll-x p-6" style={{
                                    width: screenWidth + 'px',
                                    height: screenHeight + 'px'
                                }}>
                                    {(imageData.fileFormat || '') !== 'PDF' ? (
                                        <>
                                            {editImage &&
                                                <FilerobotImageEditor
                                                    source={imageData.url}
                                                    previewPixelRatio={window.devicePixelRatio}
                                                    savingPixelRatio={window.devicePixelRatio}
                                                    onSave={(editedImageObject, designState) => {
                                                        const API_URL = process.env.REACT_APP_API_URL

                                                        axios.put(`${API_URL}/medias/${imageData.id}`, {base64String: editedImageObject.imageBase64})
                                                            .then((response: AxiosResponse) => {
                                                                setImageData(
                                                                    cloneImageData(imageData)
                                                                )
                                                            })
                                                            .catch(error => {})
                                                    }}
                                                    onClose={() => setEditImage(undefined)}
                                                    closeAfterSave={true}
                                                    annotationsCommon={{
                                                        fill: 'transparent',
                                                        stroke: '#2962ff',
                                                        strokeWidth: 1
                                                    }}
                                                    Arrow={{strokeWidth: 1}}
                                                    Text={{
                                                        fill: '#2962ff',
                                                        lineHeight: 1,
                                                        fonts: [
                                                            'Roboto',
                                                            { label: 'Arial', value: 'Arial' },
                                                            'Tahoma',
                                                            'Sans-serif',
                                                            { label: 'Comic Sans', value: 'Comic-sans' },
                                                        ],
                                                        fontFamily: 'Roboto',
                                                        fontStyle: 'normal',
                                                        text: 'Text',
                                                    }}
                                                    tabsIds={[TABS.ANNOTATE, TABS.ADJUST]}
                                                    defaultTabId={TABS.ANNOTATE}
                                                    defaultToolId={TOOLS.TEXT}/>
                                            }
                                            {!editImage && (
                                                <img style={{minWidth: '600px', minHeight: '1024px', maxWidth: '100%', maxHeight: '100%'}} className="cursor-pointer" title="Click to open in new window" src={imageData.url} onClick={() => window.open(imageData.url)} />
                                            )}
                                        </>
                                    ) : (
                                        <Document file={imageData.url} onLoadSuccess={() => {}}>
                                            <Page pageNumber={1}/>
                                        </Document>
                                    )}
                                </div>
                                <div className=" w-450px m-lg-10">
                                    {medias.map(media => (
                                        <div key={media.id} className="d-flex align-items-center mb-6">
                                            <div className="symbol symbol-45px w-40px me-5">
                                                <span className="symbol-label bg-lighten">
                                                    <span>
                                                        <img style={{width: '32px', height: '32px'}}
                                                             src={getExtensionIconUrl(media.fileFormat || '')}/>
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center flex-wrap">
                                                <div className="mb-1 flex-grow-1" style={{width: "260px"}}>
                                              <span
                                                  className={"fs-6 text-gray-800 text-hover-primary fw-bolder cursor-pointer " + (media.name === imageData.name ? 'text-active' : '')}
                                                  onClick={() => updateImageUrl(media)}>
                                                  {media.name}
                                              </span>
                                                    <div
                                                        className="text-gray-400 fw-bold fs-7">{prettyBytes(media.size || 0)}</div>
                                                </div>
                                                <a href={API_URL + '/static/' + media.id + '/download'} target="_blank">
                                                    <svg width="26" height="26" viewBox="0 0 24 24" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.3"
                                                              d="M19 15C20.7 15 22 13.7 22 12C22 10.3 20.7 9 19 9C18.9 9 18.9 9 18.8 9C18.9 8.7 19 8.3 19 8C19 6.3 17.7 5 16 5C15.4 5 14.8 5.2 14.3 5.5C13.4 4 11.8 3 10 3C7.2 3 5 5.2 5 8C5 8.3 5 8.7 5.1 9H5C3.3 9 2 10.3 2 12C2 13.7 3.3 15 5 15H19Z"
                                                              fill="#666"/>
                                                        <path
                                                            d="M13 17.4V12C13 11.4 12.6 11 12 11C11.4 11 11 11.4 11 12V17.4H13Z"
                                                            fill="#0095e8"/>
                                                        <path opacity="0.3"
                                                              d="M8 17.4H16L12.7 20.7C12.3 21.1 11.7 21.1 11.3 20.7L8 17.4Z"
                                                              fill="#0095e8"/>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-backdrop fade show'></div>
        </>
    )
}

const getExtensionIconUrl = (extension: string) => {
    const baseUri = toAbsoluteUrl('/media/icons/flaticon/extensions/')

    switch (extension) {
        case 'PNG':
            return baseUri + "png.svg"
        case 'JPG':
        case 'JPEG':
            return baseUri + "jpg.svg"
        case 'PDF':
            return baseUri + "pdf.svg"
        case 'GIF':
            return baseUri + "gif.svg"
        default:
            return baseUri + "unknown.svg"
    }
}

export {TradeMediaModal}
