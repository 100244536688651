import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CookieConsent from "react-cookie-consent";
import {useIntl} from "react-intl";
import {MetaHeader} from "../../common/component/meta/MetaHeader";
import React from "react";
import {PricingFree} from "./components/PricingFree";
import {Link} from "react-router-dom";

function PreviousArrow(props: any) {
    const { className, style, onClick } = props;

    return (
        <button className={"btn btn-icon btn-active-color-primary slick-prev"} onClick={onClick} style={{ ...style, display: "block" }}>
            { /*begin::Svg Icon | path: icons/duotune/arrows/arr074.svg*/ }
            <span className="svg-icon svg-icon-3x">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                     fill="none">
                    <path
                        d="M11.2657 11.4343L15.45 7.25C15.8642 6.83579 15.8642 6.16421 15.45 5.75C15.0358 5.33579 14.3642 5.33579 13.95 5.75L8.40712 11.2929C8.01659 11.6834 8.01659 12.3166 8.40712 12.7071L13.95 18.25C14.3642 18.6642 15.0358 18.6642 15.45 18.25C15.8642 17.8358 15.8642 17.1642 15.45 16.75L11.2657 12.5657C10.9533 12.2533 10.9533 11.7467 11.2657 11.4343Z"
                        fill="currentColor"></path>
                </svg>
            </span>
            { /*end::Svg Icon*/ }
        </button>
    );
}

function NextArrow(props: any) {
    const { className, style, onClick } = props;

    return (
        <button className={"btn btn-icon btn-active-color-primary slick-next"} onClick={onClick} style={{ ...style, display: "block" }}>
            { /*begin::Svg Icon | path: icons/duotune/arrows/arr071.svg*/ }
            <span className="svg-icon svg-icon-3x">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                     fill="none">
                    <path
                        d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
                        fill="currentColor"></path>
                </svg>
            </span>
            { /*end::Svg Icon*/ }
        </button>
    );
}

const LandingPageContent = () => {
    const intl = useIntl()
    const startYear = 2022
    const currentYear = new Date().getFullYear()
    const settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: "center",
        prevArrow: <PreviousArrow />,
        nextArrow: <NextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const demoImages = [
        'media/product-demos/dashboard.png',
    ]

    return (
        <div className="d-flex flex-column flex-root">
            <CookieConsent
                buttonText="Accept All Cookies"
                cookieName="tradesJournalCookiesAccept"
                buttonStyle={{background: '#50cd89', color: '#ffffff'}}
                overlay
            >
                By clicking "Accept All Cookies", you agree to the storing of cookies on your device to enhance
                site navigation and analyze site usage.
            </CookieConsent>
            { /*begin::Header Section*/ }
            <div className="mb-0" id="home">
                { /*begin::Wrapper*/ }
                <div
                    className="bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg"
                    style={{backgroundImage: "url(media/svg/illustrations/landing.svg)"}}>
                    { /*begin::Header*/ }
                    <div className="landing-header" data-kt-sticky="true" data-kt-sticky-name="landing-header"
                         data-kt-sticky-offset="{default: '200px', lg: '300px'}" style={{animationDuration: "0.3s"}}>
                        { /*begin::Container*/ }
                        <div className="container">
                            { /*begin::Wrapper*/ }
                            <div className="d-flex align-items-center justify-content-between">
                                { /*begin::Logo*/ }
                                <div className="d-flex align-items-center flex-equal">
                                    { /*begin::Mobile menu toggle*/ }
                                    <button className="btn btn-icon btn-active-color-primary me-3 d-flex d-lg-none"
                                            id="kt_landing_menu_toggle">
                                        { /*begin::Svg Icon | path: icons/duotune/abstract/abs015.svg*/ }
                                        <span className="svg-icon svg-icon-2hx">
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none">
												<path
                                                    d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                                    fill="currentColor"></path>
												<path opacity="0.3"
                                                      d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                                      fill="currentColor"></path>
											</svg>
										</span>
                                        { /*end::Svg Icon*/ }
                                    </button>
                                    { /*end::Mobile menu toggle*/ }
                                    { /*begin::Logo image*/ }
                                    <a href="/">
                                        <img alt="Logo" src="media/logos/logo-dark.svg"
                                             className="logo-default h-25px h-lg-30px"/>
                                            <img alt="Logo" src="media/logos/logo.svg"
                                                 className="logo-sticky h-20px h-lg-25px"/>
                                    </a>
                                    { /*end::Logo image*/ }
                                </div>
                                { /*end::Logo*/ }
                                { /*begin::Menu wrapper*/ }
                                <div className="d-lg-block" id="kt_header_nav_wrapper">
                                    <div className="d-lg-block p-5 p-lg-0" data-kt-drawer="true"
                                         data-kt-drawer-name="landing-menu"
                                         data-kt-drawer-activate="{default: true, lg: false}"
                                         data-kt-drawer-overlay="true" data-kt-drawer-width="200px"
                                         data-kt-drawer-direction="start"
                                         data-kt-drawer-toggle="#kt_landing_menu_toggle" data-kt-swapper="true"
                                         data-kt-swapper-mode="prepend"
                                         data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav_wrapper'}">
                                        { /*begin::Menu*/ }
                                        <div
                                            className="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-500 menu-state-title-primary nav nav-flush fs-5 fw-bold"
                                            id="kt_landing_menu">
                                            { /*begin::Menu item*/ }
                                            <div className="menu-item">
                                                { /*begin::Menu link*/ }
                                                <a className="menu-link nav-link py-3 px-4 px-xxl-6 active"
                                                   href="/" data-kt-scroll-toggle="true"
                                                   data-kt-drawer-dismiss="true">Home</a>
                                                { /*end::Menu link*/ }
                                            </div>
                                            { /*end::Menu item*/ }
                                            { /*begin::Menu item*/ }
                                            <div className="menu-item">
                                                { /*begin::Menu link*/ }
                                                <a className="menu-link nav-link py-3 px-4 px-xxl-6" href="/about"
                                                   data-kt-scroll-toggle="true"
                                                   data-kt-drawer-dismiss="true">About</a>
                                                { /*end::Menu link*/ }
                                            </div>
                                            { /*end::Menu item*/ }
                                            { /*begin::Menu item*/ }
                                            <div className="menu-item">
                                                { /*begin::Menu link*/ }
                                                <a className="menu-link nav-link py-3 px-4 px-xxl-6" href="/contacts"
                                                   data-kt-scroll-toggle="true"
                                                   data-kt-drawer-dismiss="true">Contact Us</a>
                                                { /*end::Menu link*/ }
                                            </div>
                                            { /*end::Menu item*/ }
                                        </div>
                                        { /*end::Menu*/ }
                                    </div>
                                </div>
                                { /*end::Menu wrapper*/ }
                                { /*begin::Toolbar*/ }
                                <div className="flex-equal text-end ms-1">
                                    <a href="/auth"
                                       className="btn btn-success">Sign In</a>
                                </div>
                                { /*end::Toolbar*/ }
                            </div>
                            { /*end::Wrapper*/ }
                        </div>
                        { /*end::Container*/ }
                    </div>
                    { /*end::Header*/ }
                    { /*begin::Landing hero*/ }
                    <div className="d-flex flex-column flex-center w-100 min-h-350px min-h-lg-500px px-9">
                        { /*begin::Heading*/ }
                        <div className="text-center mb-5 mb-lg-10 py-10 py-lg-20">
                            { /*begin::Title*/ }
                            <h1 className="text-white lh-base fw-bolder fs-2x fs-lg-3x mb-15">
                                {intl.formatMessage({id: 'PAGE.MAIN.TITLE.LINE_1'})}
                                <br/>
                                {intl.formatMessage({id: 'PAGE.MAIN.TITLE.LINE_2'})}
                            </h1>
                            { /*end::Title*/ }
                            { /*begin::Action*/ }
                            <a href="/auth/registration" className="btn btn-primary">
                                {intl.formatMessage({id: 'PAGE.MAIN.BUTTON.REGISTER'})}
                            </a>
                            { /*end::Action*/ }
                        </div>
                        { /*end::Heading*/ }
                        { /*begin::Clients*/ }

                        { /*end::Clients*/ }
                    </div>
                    { /*end::Landing hero*/ }
                </div>
                { /*end::Wrapper*/ }
                { /*begin::Curve bottom*/ }
                <div className="landing-curve landing-dark-color mb-10 mb-lg-20">
                    <svg viewBox="15 12 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                            fill="currentColor"></path>
                    </svg>
                </div>
                { /*end::Curve bottom*/ }
            </div>
            { /*end::Header Section*/ }
            { /*begin::How It Works Section*/ }
            <div className="mb-n10 mb-lg-n20 z-index-2">
                { /*begin::Container*/ }
                <div className="container">
                    { /*begin::Heading*/ }
                    <div className="text-center mb-17">
                        { /*begin::Title*/ }
                        <h3 className="fs-2hx text-dark mb-5" id="how-it-works"
                            data-kt-scroll-offset="{default: 100, lg: 150}">
                            {intl.formatMessage({id: 'PAGE.MAIN.HOW_IT_WORKS'})}
                        </h3>
                        { /*end::Title*/ }
                    </div>
                    { /*end::Heading*/ }
                    { /*begin::Row*/ }
                    <div className="row w-100 gy-10 mb-md-20">
                        { /*begin::Col*/ }
                        <div className="col-md-4 px-5">
                            { /*begin::Story*/ }
                            <div className="text-center mb-10 mb-md-0">
                                { /*begin::Illustration*/ }
                                <img src="media/illustrations/sketchy-1/2.png" className="mh-125px mb-9" alt=""/>
                                    { /*end::Illustration*/ }
                                    { /*begin::Heading*/ }
                                    <div className="d-flex flex-center mb-5">
                                        { /*begin::Badge*/ }
                                        <span
                                            className="badge badge-circle badge-light-success fw-bolder p-5 me-3 fs-3">1</span>
                                        { /*end::Badge*/ }
                                        { /*begin::Title*/ }
                                        <div className="fs-5 fs-lg-3 fw-bolder text-dark">
                                            {intl.formatMessage({id: 'PAGE.MAIN.HOW_IT_WORKS.STEP_1.TITLE'})}
                                        </div>
                                        { /*end::Title*/ }
                                    </div>
                                    { /*end::Heading*/ }
                                    { /*begin::Description*/ }
                                    <div className="fw-bold fs-6 fs-lg-5 text-muted">
                                        {intl.formatMessage({id: 'PAGE.MAIN.HOW_IT_WORKS.STEP_1.BODY'})}
                                    </div>
                                { /*end::Description*/ }
                            </div>
                            { /*end::Story*/ }
                        </div>
                        { /*end::Col*/ }
                        { /*begin::Col*/ }
                        <div className="col-md-4 px-5">
                            { /*begin::Story*/ }
                            <div className="text-center mb-10 mb-md-0">
                                { /*begin::Illustration*/ }
                                <img src="media/illustrations/sketchy-1/8.png" className="mh-125px mb-9" alt=""/>
                                    { /*end::Illustration*/ }
                                    { /*begin::Heading*/ }
                                    <div className="d-flex flex-center mb-5">
                                        { /*begin::Badge*/ }
                                        <span
                                            className="badge badge-circle badge-light-success fw-bolder p-5 me-3 fs-3">2</span>
                                        { /*end::Badge*/ }
                                        { /*begin::Title*/ }
                                        <div className="fs-5 fs-lg-3 fw-bolder text-dark">
                                            {intl.formatMessage({id: 'PAGE.MAIN.HOW_IT_WORKS.STEP_2.TITLE'})}
                                        </div>
                                        { /*end::Title*/ }
                                    </div>
                                    { /*end::Heading*/ }
                                    { /*begin::Description*/ }
                                    <div className="fw-bold fs-6 fs-lg-5 text-muted">
                                        {intl.formatMessage({id: 'PAGE.MAIN.HOW_IT_WORKS.STEP_2.BODY'})}
                                    </div>
                                { /*end::Description*/ }
                            </div>
                            { /*end::Story*/ }
                        </div>
                        { /*end::Col*/ }
                        { /*begin::Col*/ }
                        <div className="col-md-4 px-5">
                            { /*begin::Story*/ }
                            <div className="text-center mb-10 mb-md-0">
                                { /*begin::Illustration*/ }
                                <img src="media/illustrations/sketchy-1/12.png" className="mh-125px mb-9" alt=""/>
                                    { /*end::Illustration*/ }
                                    { /*begin::Heading*/ }
                                    <div className="d-flex flex-center mb-5">
                                        { /*begin::Badge*/ }
                                        <span
                                            className="badge badge-circle badge-light-success fw-bolder p-5 me-3 fs-3">3</span>
                                        { /*end::Badge*/ }
                                        { /*begin::Title*/ }
                                        <div className="fs-5 fs-lg-3 fw-bolder text-dark">
                                            {intl.formatMessage({id: 'PAGE.MAIN.HOW_IT_WORKS.STEP_3.TITLE'})}
                                        </div>
                                        { /*end::Title*/ }
                                    </div>
                                    { /*end::Heading*/ }
                                    { /*begin::Description*/ }
                                    <div className="fw-bold fs-6 fs-lg-5 text-muted">
                                        {intl.formatMessage({id: 'PAGE.MAIN.HOW_IT_WORKS.STEP_3.BODY'})}
                                    </div>
                                { /*end::Description*/ }
                            </div>
                            { /*end::Story*/ }
                        </div>
                        { /*end::Col*/ }
                    </div>
                    { /*end::Row*/ }
                    { /*begin::Product slider*/ }
                    { /*begin::Slider*/ }
                    <Slider {...settings}>
                        {demoImages.map((el, index) => (
                            <img key={index} src={el} className='px-5 pt-5 pt-lg-10 px-lg-10 landing-slider-img'/>
                        ))}
                    </Slider>
                    { /*end::Slider*/ }
                </div>
                { /*end::Product slider*/ }
                { /*end::Container*/ }
            </div>
            { /*end::How It Works Section*/ }
            { /*begin::Statistics Section*/ }
            <div className="mt-sm-n10">
                { /*begin::Curve top*/ }
                <div className="landing-curve landing-dark-color">
                    <svg viewBox="15 -1 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                            fill="currentColor"></path>
                    </svg>
                </div>
                { /*end::Curve top*/ }
                { /*begin::Wrapper*/ }
                <div className="pb-15 pt-18 landing-dark-bg">
                    { /*begin::Container*/ }
                    <div className="container">
                        { /*begin::Heading*/ }
                        <div className="text-center mt-15 mb-18" id="achievements"
                             data-kt-scroll-offset="{default: 100, lg: 150}">
                            { /*begin::Title*/ }
                            <h3 className="fs-2hx text-white fw-bolder mb-5">Improve your performance</h3>
                            { /*end::Title*/ }
                            { /*begin::Description*/ }
                            <div className="fs-5 text-gray-700 fw-bold">View your performance anytime for different
                                <br/> accounts using different instruments</div>
                            { /*end::Description*/ }
                        </div>
                        { /*end::Heading*/ }
                        { /*begin::Statistics*/ }
                        <div className="d-flex flex-center">
                            { /*begin::Items*/ }
                            <div
                                className="d-flex flex-wrap flex-center justify-content-lg-between mb-15 mx-auto w-xl-900px">
                                { /*begin::Item*/ }
                                <div
                                    className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain"
                                    style={{backgroundImage: "url('media/svg/misc/octagon.svg')"}}>
                                    { /*begin::Symbol*/ }
                                    { /*begin::Svg Icon | path: icons/duotune/general/gen025.svg*/ }
                                    <span className="svg-icon svg-icon-2tx svg-icon-white mb-3">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none">
											<rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
											<rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2"
                                                  fill="currentColor"></rect>
											<rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2"
                                                  fill="currentColor"></rect>
											<rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2"
                                                  fill="currentColor"></rect>
										</svg>
									</span>
                                    { /*end::Svg Icon*/ }
                                    { /*end::Symbol*/ }
                                    { /*begin::Info*/ }
                                    <div className="mb-0">
                                        { /*begin::Label*/ }
                                        <span className="text-gray-600 fw-bold fs-5 lh-0">Widgets</span>
                                        { /*end::Label*/ }
                                    </div>
                                    { /*end::Info*/ }
                                </div>
                                { /*end::Item*/ }
                                { /*begin::Item*/ }
                                <div
                                    className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain"
                                    style={{backgroundImage: "url('media/svg/misc/octagon.svg')"}}>
                                    { /*begin::Symbol*/ }
                                    { /*begin::Svg Icon | path: icons/duotune/graphs/gra008.svg*/ }
                                    <span className="svg-icon svg-icon-2tx svg-icon-white mb-3">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none">
											<path
                                                d="M13 10.9128V3.01281C13 2.41281 13.5 1.91281 14.1 2.01281C16.1 2.21281 17.9 3.11284 19.3 4.61284C20.7 6.01284 21.6 7.91285 21.9 9.81285C22 10.4129 21.5 10.9128 20.9 10.9128H13Z"
                                                fill="currentColor"></path>
											<path opacity="0.3"
                                                  d="M13 12.9128V20.8129C13 21.4129 13.5 21.9129 14.1 21.8129C16.1 21.6129 17.9 20.7128 19.3 19.2128C20.7 17.8128 21.6 15.9128 21.9 14.0128C22 13.4128 21.5 12.9128 20.9 12.9128H13Z"
                                                  fill="currentColor"></path>
											<path opacity="0.3"
                                                  d="M11 19.8129C11 20.4129 10.5 20.9129 9.89999 20.8129C5.49999 20.2129 2 16.5128 2 11.9128C2 7.31283 5.39999 3.51281 9.89999 3.01281C10.5 2.91281 11 3.41281 11 4.01281V19.8129Z"
                                                  fill="currentColor"></path>
										</svg>
									</span>
                                    { /*end::Svg Icon*/ }
                                    { /*end::Symbol*/ }
                                    { /*begin::Info*/ }
                                    <div className="mb-0">
                                        { /*begin::Label*/ }
                                        <span className="text-gray-600 fw-bold fs-5 lh-0">Statistic Reports</span>
                                        { /*end::Label*/ }
                                    </div>
                                    { /*end::Info*/ }
                                </div>
                                { /*end::Item*/ }
                                { /*begin::Item*/ }
                                <div
                                    className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain"
                                    style={{backgroundImage: "url('media/svg/misc/octagon.svg')"}}>
                                    { /*begin::Symbol*/ }
                                    { /*begin::Svg Icon | path: icons/duotune/ecommerce/ecm002.svg*/ }
                                    <span className="svg-icon svg-icon-2tx svg-icon-white mb-3">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M17.7 5.59999C16.7 5.19999 15.5 5.50003 14.8 6.20003L10.2 10.8C9.5 11.5 8.4 11.8 7.5 11.5L5.10001 10.8V18.9H20.1V6.40004L17.7 5.59999Z" fill="currentColor"/>
                                            <path d="M21 18H6V3C6 2.4 5.6 2 5 2C4.4 2 4 2.4 4 3V18H3C2.4 18 2 18.4 2 19C2 19.6 2.4 20 3 20H4V21C4 21.6 4.4 22 5 22C5.6 22 6 21.6 6 21V20H21C21.6 20 22 19.6 22 19C22 18.4 21.6 18 21 18Z" fill="currentColor"/>
                                        </svg>
									</span>
                                    { /*end::Svg Icon*/ }
                                    { /*end::Symbol*/ }
                                    { /*begin::Info*/ }
                                    <div className="mb-0">
                                        { /*begin::Label*/ }
                                        <span className="text-gray-600 fw-bold fs-5 lh-0">Charts</span>
                                        { /*end::Label*/ }
                                    </div>
                                    { /*end::Info*/ }
                                </div>
                                { /*end::Item*/ }
                            </div>
                            { /*end::Items*/ }
                        </div>
                        { /*end::Statistics*/ }
                    </div>
                    { /*end::Container*/ }
                </div>
                { /*end::Wrapper*/ }
                { /*begin::Curve bottom*/ }
                <div className="landing-curve landing-dark-color">
                    <svg viewBox="15 12 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                            fill="currentColor"></path>
                    </svg>
                </div>
                { /*end::Curve bottom*/ }
            </div>
            { /*end::Statistics Section*/ }
            { /*begin::Projects Section*/ }
            <div className="mb-lg-n15 position-relative z-index-2">
                { /*begin::Container*/ }
                <div className="container">
                    { /*begin::Card*/ }
                    <div className="card" style={{filter: "drop-shadow(0px 0px 40px rgba(68, 81, 96, 0.08))"}}>
                        { /*begin::Card body*/ }
                        <div className="card-body p-lg-20">
                            { /*begin::Tabs content*/ }
                            <div className="tab-content">
                                { /*begin::Tab pane*/ }
                                <div className="tab-pane fade show active" id="kt_landing_projects_latest">
                                    { /*begin::Row*/ }
                                    <div className="row g-10">
                                        { /*begin::Col*/ }
                                        <div className="col-lg-6">
                                            { /*begin::Item*/ }
                                            <div
                                                className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-700px"
                                                style={{backgroundImage: "url('media/product-demos/demo-6.png')", backgroundPosition: 'left'}}></div>
                                            { /*end::Item*/ }
                                        </div>
                                        { /*end::Col*/ }
                                        { /*begin::Col*/ }
                                        <div className="col-lg-6">
                                            { /*begin::Row*/ }
                                            <div className="row g-10 mb-10">
                                                { /*begin::Col*/ }
                                                <div className="col-lg-12">
                                                    { /*begin::Item*/ }
                                                    <div
                                                        className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-300px"
                                                        style={{backgroundImage: "url('media/product-demos/demo-7.png')"}}></div>
                                                    { /*end::Item*/ }
                                                </div>
                                                { /*end::Col*/ }
                                            </div>
                                            { /*end::Row*/ }
                                            { /*begin::Item*/ }
                                            <div
                                                className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-300px"
                                                style={{backgroundImage: "url('media/product-demos/demo-8.png')"}}></div>
                                            { /*end::Item*/ }
                                        </div>
                                        { /*end::Col*/ }
                                    </div>
                                    { /*end::Row*/ }
                                </div>
                                { /*end::Tab pane*/ }
                            </div>
                            { /*end::Tabs content*/ }
                        </div>
                        { /*end::Card body*/ }
                    </div>
                    { /*end::Card*/ }
                </div>
                { /*end::Container*/ }
            </div>
            { /*end::Projects Section*/ }
            { /*begin::Pricing Section*/ }
            <PricingFree/>
            { /*end::Pricing Section*/ }
            { /*begin::Testimonials Section*/ }
            <div className="mb-n20 position-relative z-index-0">
                { /*begin::Container*/ }
                <div className='mt-lg-n20 position-relative z-index-0'>
                    <div className="container" style={{height: "300px"}}></div>
                </div>
                { /*end::Container*/ }
            </div>
            { /*end::Testimonials Section*/ }
            { /*begin::Footer Section*/ }
            <div className="mb-0">
                { /*begin::Curve top*/ }
                <div className="landing-curve landing-dark-color">
                    <svg viewBox="15 -1 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                            fill="currentColor"></path>
                    </svg>
                </div>
                { /*end::Curve top*/ }
                { /*begin::Wrapper*/ }
                <div className="landing-dark-bg pt-20">
                    { /*begin::Container*/ }
                    <div className="container">
                        { /*begin::Row*/ }
                        <div className="row py-10 py-lg-20">
                            { /*begin::Col*/ }
                            <div className="col-lg-6 pe-lg-16 mb-10 mb-lg-0">
                                { /*begin::Block*/ }
                                <div className="rounded landing-dark-border p-9 mb-10">
                                    { /*begin::Title*/ }
                                    <h2 className="text-white">Risk Disclosure</h2>
                                    { /*end::Title*/ }
                                    { /*begin::Text*/ }
                                    <span className="fw-normal fs-4 text-gray-700">
                                        We do not sell or advertise investments of any kind.
                                        We are not an investment or trading company.
                                    </span>
                                    { /*end::Text*/ }
                                </div>
                                { /*end::Block*/ }
                            </div>
                            { /*end::Col*/ }
                            { /*begin::Col*/ }
                            <div className="col-lg-6 ps-lg-16">
                                { /*begin::Navs*/ }
                                <div className="d-flex justify-content-center">
                                    { /*begin::Links*/ }
                                    <div className="d-flex fw-bold flex-column me-20">
                                        { /*begin::Subtitle*/ }
                                        <h4 className="fw-bolder text-gray-400 mb-6">More for Trades Journal</h4>
                                        { /*end::Subtitle*/ }
                                        { /*begin::Link*/ }
                                        <a href="/"
                                           className="text-white opacity-50 text-hover-primary fs-5 mb-6">Home</a>
                                        { /*end::Link*/ }
                                        { /*begin::Link*/ }
                                        <a href="/contacts"
                                           className="text-white opacity-50 text-hover-primary fs-5 mb-6">Contact Us</a>
                                        { /*end::Link*/ }
                                        { /*begin::Link*/ }
                                        <a href="/about"
                                           className="text-white opacity-50 text-hover-primary fs-5 mb-6">About</a>
                                        { /*end::Link*/ }
                                        { /*begin::Link*/ }
                                        <a href="auth/registration"
                                           className="text-white opacity-50 text-hover-primary fs-5 mb-6">Sign Up</a>
                                        { /*end::Link*/ }
                                    </div>
                                    { /*end::Links*/ }

                                    { /*begin::Links*/ }
                                    <div className="d-flex fw-bold flex-column ms-lg-5">
                                        { /*begin::Subtitle*/ }
                                        <h4 className="fw-bolder text-gray-400 mb-6">Stay Connected</h4>
                                        { /*end::Subtitle*/ }
                                        { /*begin::Link*/ }
                                        <a href="https://www.facebook.com/trades_journal" className="mb-6">
                                            <img src="media/svg/brand-logos/facebook-4.svg"
                                                 className="h-20px me-2" alt=""/>
                                                <span
                                                    className="text-white opacity-50 text-hover-primary fs-5 mb-6">Facebook</span>
                                        </a>
                                        { /*end::Link*/ }
                                        { /*begin::Link*/ }
                                        <a href="https://twitter.com/mtrades_journal" className="mb-6">
                                            <img src="media/svg/brand-logos/twitter.svg" className="h-20px me-2"
                                                 alt=""/>
                                                <span
                                                    className="text-white opacity-50 text-hover-primary fs-5 mb-6">Twitter</span>
                                        </a>
                                        { /*end::Link*/ }
                                        { /*begin::Link*/ }
                                        <a href="https://www.instagram.com/trades.journal" className="mb-6">
                                            <img src="media/svg/brand-logos/instagram-2-1.svg"
                                                 className="h-20px me-2" alt=""/>
                                                <span
                                                    className="text-white opacity-50 text-hover-primary fs-5 mb-6">Instagram</span>
                                        </a>
                                        { /*end::Link*/ }
                                    </div>
                                    { /*end::Links*/ }

                                    { /*begin::Links*/ }
                                    <div className="d-flex fw-bold flex-column ms-lg-15">
                                        { /*begin::Subtitle*/ }
                                        <h4 className="fw-bolder text-gray-400 mb-6">Languages</h4>
                                        { /*end::Subtitle*/ }
                                        { /*begin::Link*/ }
                                        <a className="mb-6" href='#'>
                                            <img className="w-15px h-15px rounded-1 ms-2" src="/media/flags/united-states.svg" alt=""/> English
                                        </a>
                                        <a className="mb-6" href='#'>
                                            <img className="w-15px h-15px rounded-1 ms-2" src="/media/flags/russia.svg" alt=""/> Russian
                                        </a>
                                        { /*end::Link*/ }
                                    </div>
                                    { /*end::Links*/ }
                                </div>
                                { /*end::Navs*/ }
                            </div>
                            { /*end::Col*/ }
                        </div>
                        { /*end::Row*/ }
                    </div>
                    { /*end::Container*/ }
                    { /*begin::Separator*/ }
                    <div className="landing-dark-separator"></div>
                    { /*end::Separator*/ }
                    { /*begin::Container*/ }
                    <div className="container">
                        { /*begin::Wrapper*/ }
                        <div className="d-flex flex-column flex-md-row flex-stack py-7 py-lg-10">
                            { /*begin::Copyright*/ }
                            <div className="d-flex align-items-center order-2 order-md-1">
                                { /*begin::Logo*/ }
                                <Link className='text-muted text-hover-primary px-2' to="/">
                                    <img alt="Logo" src="media/logos/logo-dark.svg"
                                         className="h-15px h-md-20px"/>
                                </Link>
                                { /*end::Logo image*/ }
                                { /*begin::Logo image*/ }
                                <span className="mx-5 fs-6 fw-bold text-gray-600 pt-1">
                                    &copy;&nbsp;
                                    {currentYear > startYear ? startYear + ' - ' + currentYear : currentYear}
                                    &nbsp;Trades Journal
                                </span>
                                { /*end::Logo image*/ }
                            </div>
                            { /*end::Copyright*/ }
                            { /*begin::Menu*/ }
                            <ul className="menu menu-gray-600 menu-hover-primary fw-bold fs-6 fs-md-5 order-1 mb-5 mb-md-0">
                                <li className="menu-item">
                                    <a href="/about" className="menu-link px-2">About</a>
                                </li>
                            </ul>
                            { /*end::Menu*/ }
                        </div>
                        { /*end::Wrapper*/ }
                    </div>
                    { /*end::Container*/ }
                </div>
                { /*end::Wrapper*/ }
            </div>
            { /*end::Footer Section*/ }
            { /*begin::Scrolltop*/ }
            <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                { /*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/ }
                <span className="svg-icon">
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
						<rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)"
                              fill="currentColor"></rect>
						<path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="currentColor"></path>
					</svg>
				</span>
                { /*end::Svg Icon*/ }
            </div>
            { /*end::Scrolltop*/ }
        </div>
    )
}

const LandingPage = () => {
    return (
        <>
            <MetaHeader pageName='HTML.HEAD.TITLE.LANDING'/>
            <LandingPageContent />
        </>
    )
}

export {LandingPage}
