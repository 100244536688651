import React, {createContext, FC} from "react";
import {AccountModel} from "../../../modules/accounts/accounts-list/core/_models";
import {AccountDto} from "../../dto/account/AccountDto";
import {QUERIES, WithChildren} from "../../../../_metronic/helpers";
import {useQuery} from "react-query";
import {JsonApiArrayDto} from "../../dto/json-api/JsonApiDto";
import axios, {AxiosResponse} from "axios";

export type AccountContext = {
    accounts: AccountDto[];
};

const AccountQueryContext = createContext<AccountContext>({accounts: []})

const AccountDataProvider: FC<WithChildren> = ({children}) => {
    const accounts: AccountModel[] = []

    return (
        <AccountQueryContext.Provider value={{accounts}}>
            {children}
        </AccountQueryContext.Provider>
    )
}

type JsonApiResponseContextProps<T> = {
    response?: T | undefined
    //refetch: () => void
    //isLoading: boolean
    query: string
}

const AccountQueryResponseContext = createContext<JsonApiResponseContextProps<AccountDto[]>>({query: ''})

const AccountDataResponseProvider: FC<WithChildren> = ({children}) => {
    const query = ''
    const {
        //isFetching,
        //refetch,
        data: response,
    } = useQuery(
        `${QUERIES.ACCOUNT_LIST}`,
        () => {
            return getAccounts(query)
        },
        {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
    )
    return (
        <AccountQueryResponseContext.Provider value={{response, query}}>
            {children}
        </AccountQueryResponseContext.Provider>
    )
}

const API_URL = process.env.REACT_APP_API_URL
const ACCOUNT_URL = `${API_URL}/accounts`

const getAccounts = (query: string): Promise<AccountDto[]> => {
    return axios.get(`${ACCOUNT_URL}?${query}`)
        .then((response: AxiosResponse<JsonApiArrayDto<AccountDto>>) => {
            if (!response) {
                return []
            }
            return response.data?.data?.map(function (value) {
                const item = value.attributes
                item.id = value.id as any

                return item
            }) || []
        })
}

export {
    AccountDataProvider,
    AccountDataResponseProvider,
    AccountQueryResponseContext
}