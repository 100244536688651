import {getCurrencySymbol} from "../../../common/utils/currency/CurrencyUtils";
import {FC} from "react";
import {PercentChangeWidget} from "./PercentChangeWidget";
import {useIntl} from "react-intl";
import CountUp from "react-countup";
import {WidgetKey} from "../../../common/dto/dashboard/WidgetDto";
import {useThemeMode} from "../../../../_metronic/partials";

type Props = {
    selectedWidgets: {[key: string]: boolean},
    value: number
    previousValue: number
    currency: string
}

const WidgetAvgTradeLoss: FC<Props> = ({selectedWidgets, value, previousValue, currency}) => {
    const intl = useIntl()
    const {isDark} = useThemeMode()
    const darkStyle = (isDark ? "widget-dark" : "")

    if (!selectedWidgets[WidgetKey.AVG_TRADE_LOSS]) {
        return null
    }
    return (
        <div className="col-sm-4 col-xl-2 mb-xl-10">
            <div className="card h-lg-100">
                <div className="card-body d-flex justify-content-between align-items-start flex-column">
                    {/*begin::Icon*/}
                    <div className="m-0">
                        {/*begin::Svg Icon | path: icons/50-finance-icons-red/avg-trade-loss.svg*/}
                        <span className="svg-icon svg-icon-2hx svg-icon-gray-600">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 261.86 220.72"><defs><style>{`.avg-trade-loss-1,.avg-trade-loss-2{fill:none;stroke:#344154;stroke-linecap:round;}.avg-trade-loss-1{stroke - miterlimit:10;stroke-width:5.5px;}.avg-trade-loss-2{stroke - miterlimit:10;stroke-width:5.5px;}.avg-trade-loss-3{fill:#009ef7;}`}</style></defs><g><g id="Icon_Set" data-name="Icon Set">
                                <line className={"avg-trade-loss-1 " + darkStyle} x1="185.06" y1="143.76" x2="229.35" y2="188.05"/><line
                                className={"avg-trade-loss-1 " + darkStyle} x1="229.35" y1="143.76" x2="185.06" y2="188.05"/><circle className={"avg-trade-loss-2 " + darkStyle} cx="207.18" cy="166.04"
                                                                                                           r="51.92"/><polyline className={"avg-trade-loss-1 " + darkStyle}
                                                                                                                                points="26.43 173.92 26.43 26.47 207.2 26.47 207.2 113.79"/><path
                                className={"avg-trade-loss-1 " + darkStyle} d="M143.78,51.29a38.13,38.13,0,0,1,0,53.92"/><path className={"avg-trade-loss-1 " + darkStyle}
                                                                                                     d="M89.85,105.22a38.13,38.13,0,0,1,0-53.92"/><path
                                className={"avg-trade-loss-1 " + darkStyle} d="M57.47,75.68a2.17,2.17,0,1,1-2.17-2.17A2.17,2.17,0,0,1,57.47,75.68Z"/><path className={"avg-trade-loss-1 " + darkStyle}
                                                                                                                                 d="M180.49,75.68a2.17,2.17,0,1,1-2.17-2.17A2.17,2.17,0,0,1,180.49,75.68Z"/><path
                                className={"avg-trade-loss-1 " + darkStyle}
                                d="M127.34,67.76a10.53,10.53,0,0,0-21.06,0c0,5.82,4.32,8.5,10.53,10.53,5.53,1.8,10.53,4.71,10.53,10.53a10.53,10.53,0,0,1-21.06,0"/><line
                                className={"avg-trade-loss-1 " + darkStyle} x1="116.82" y1="105.22" x2="116.82" y2="99.28"/><line className={"avg-trade-loss-1 " + darkStyle} x1="116.82" y1="57.23"
                                                                                                        x2="116.82" y2="51.29"/><polyline
                                className={"avg-trade-loss-1 " + darkStyle} points="26.43 106.32 2.75 106.32 2.75 2.75 183.53 2.75 183.53 26.47"/><line className={"avg-trade-loss-1 " + darkStyle}
                                                                                                                              x1="46.49" y1="160.74"
                                                                                                                              x2="46.49"
                                                                                                                              y2="130.46"/><polyline
                                className={"avg-trade-loss-1 " + darkStyle} points="66.55 147.37 66.55 130.46 152.2 130.46"/><polygon className="avg-trade-loss-3"
                                                                                                            points="36.09 36.73 36.09 61.52 60.88 36.73 36.09 36.73"/></g></g>
                            </svg>
                        </span>
                        {/*end::Svg Icon*/}
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Section*/}
                    <div className="d-flex flex-column my-7">
                        {/*begin::Number*/}
                        <span className="fs-2x text-gray-800 lh-1 ls-n2">
                            {getCurrencySymbol(currency)}
                            <CountUp end={value} decimals={2} duration={1.0} />
                        </span>
                        {/*end::Number*/}
                        {/*begin::Follower*/}
                        <div className="m-0">
                            <span className="fs-6 text-gray-400">
                                {intl.formatMessage({id: "DASHBOARD.AVG_TRADE_LOSS"})}
                            </span>
                        </div>
                        {/*end::Follower*/}
                    </div>
                    {/*end::Section*/}
                    <PercentChangeWidget currentValue={value} previousValue={previousValue} />
                </div>
            </div>
        </div>
    )
}

export {WidgetAvgTradeLoss}
