/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from "../../../../app/modules/auth";

export function AsideMenuMain() {
    const intl = useIntl()
    const {currentUser} = useAuth()

    return (
        <>
            <AsideMenuItem
                to='/dashboard'
                icon='/media/icons/duotune/art/art002.svg'
                title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
                fontIcon='bi-app-indicator'
            />
            {currentUser?.role === 'ADMIN' &&
                <AsideMenuItem
                    to='/users'
                    icon='/media/icons/duotune/communication/com006.svg'
                    title={intl.formatMessage({id: 'MENU.USERS'})}
                    fontIcon='bi-layers'
                />
            }
            <AsideMenuItem
                to='/accounts'
                icon='/media/icons/duotune/finance/fin006.svg'
                fontIcon='bi-person'
                title={intl.formatMessage({id: 'MENU.ACCOUNTS'})}
            />
            <AsideMenuItem
                to='/trades'
                icon='/media/icons/duotune/finance/fin008.svg'
                fontIcon='bi-person'
                title={intl.formatMessage({id: 'MENU.TRADES'})}
            />
            <AsideMenuItem
                to='/journals'
                icon='/media/icons/duotune/coding/cod002.svg'
                fontIcon='bi-person'
                title={intl.formatMessage({id: 'MENU.JOURNAL'})}
            />
        </>
    )
}
