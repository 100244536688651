import clsx from 'clsx'
import {Dispatch, FC, SetStateAction} from 'react'
import {Row} from 'react-table'
import {TradeModel} from "../core/_models";
import {AccountDto} from "../../../../common/dto/account/AccountDto";

type Props = {
    row: Row<TradeModel>,
    hiddenColumns: Array<string>,
    account?: AccountDto,
    setAccount: Dispatch<SetStateAction<AccountDto>>
    setIsUpdateDeposit: Dispatch<SetStateAction<boolean>>
}

const CustomRow: FC<Props> = ({row, hiddenColumns, account, setAccount, setIsUpdateDeposit}) => (
    <tr {...row.getRowProps()}>
        {row.cells.map((cell) => {
            return (
                <td
                    {...cell.getCellProps()}
                    className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
                >
                    {cell.render('Cell', {account: account, setAccount: setAccount, hiddenColumns: hiddenColumns, setIsUpdateDeposit: setIsUpdateDeposit})}
                </td>
            )
        })}
    </tr>
)

export {CustomRow}
