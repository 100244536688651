import axios, {AxiosResponse} from 'axios'
import {JsonApiObjectUserModel, JsonApiObjectUserStatisticsModel, JsonApiUserModel, User} from './_models'
import {ID} from "../../../../../_metronic/helpers";

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/users`

const getUsers = (query: string): Promise<JsonApiUserModel> => {
    return axios.get(`${USER_URL}?${query}`)
        .then((response: AxiosResponse<JsonApiUserModel>) => response.data)
}

const getUserById = (id: ID): Promise<JsonApiUserModel | undefined> => {
    return axios.get(`${USER_URL}/${id}`)
        .then((response: AxiosResponse<JsonApiUserModel>) => response.data)
}

const getUserStatisticsById = (id: ID): Promise<JsonApiObjectUserStatisticsModel | undefined> => {
    return axios.get(`${USER_URL}/${id}/profile/statistics`)
        .then((response: AxiosResponse<JsonApiObjectUserStatisticsModel>) => response.data)
}

const createUser = (email: string | undefined, username: string | undefined, password: string | undefined): Promise<JsonApiObjectUserModel> => {
    return axios.post(USER_URL, {email: email, username: username, password: password})
        .then((response: AxiosResponse<JsonApiObjectUserModel>) => response.data)
}

const updateUser = (user: User): Promise<User | undefined> => {
    return axios
        .post(`${USER_URL}/${user.id}`, user)
        //.then((response: AxiosResponse<JsonApiObjectUserModel>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
    return axios.delete(`${USER_URL}/${userId}`)
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
    const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))

    return axios.all(requests).then(() => {

    })
}

export {getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser, getUserStatisticsById}
