import {Dispatch, FC, SetStateAction, useEffect, useMemo} from 'react'
import {ColumnInstance, Row, useTable} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {tradeColumns} from './columns/_columns'
import {KTCardBody} from "../../../../_metronic/helpers";
import {TradeModel} from "./core/_models";
import {
    useTradeQueryResponseData,
    useTradeQueryResponseLoading
} from "../../../common/provider/trade/TradeDataProvider";
import {AccountDto} from "../../../common/dto/account/AccountDto";
import {useIntl} from "react-intl";
import {Loader} from "../../../common/loader/Loader";
import {toJson} from "../../../../index";
import {useThemeMode} from "../../../../_metronic/partials";

type Prop = {
    account?: AccountDto
    hiddenColumns: Array<string>
    setAccount: Dispatch<SetStateAction<AccountDto>>
    setIsLoading: Dispatch<SetStateAction<boolean>>
    setAllColumns: Dispatch<SetStateAction<Array<string>>>
    setIsUpdateDeposit: Dispatch<SetStateAction<boolean>>
}

const TradeTable: FC<Prop> = (props) => {
    const intl = useIntl()
    const {isDark} = useThemeMode()
    const {account, setAccount, setIsLoading} = props
    const {setAllColumns, hiddenColumns, setIsUpdateDeposit} = props
    const trades = useTradeQueryResponseData()
    const isLoading = useTradeQueryResponseLoading()
    const data = useMemo(() => trades, [trades])
    const columns = useMemo(() => tradeColumns, [])
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow, allColumns, setHiddenColumns} = useTable({
        columns,
        data,
        initialState: {
            hiddenColumns: hiddenColumns,
        },
    })

    useEffect(() => {
        const columns = allColumns.filter(column => column.id !== 'actions' && column.id !== 'selection')
            .map(column => column.id)
            .sort()

        setAllColumns(columns)
    }, [toJson(allColumns)])

    useEffect(() => setHiddenColumns(hiddenColumns), [hiddenColumns])

    useEffect(() => setIsLoading(isLoading), [isLoading])

    return (
        <KTCardBody className='py-4'>
            <div className='table-responsive'>
                <table
                    id='kt_table_users'
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    {...getTableProps()}
                >
                    <thead>
                    <tr className='text-start text-muted fs-7 text-uppercase gs-0'>
                        {headers.map((column: ColumnInstance<TradeModel>) => (
                            <CustomHeaderColumn key={column.id}
                                                hiddenColumns={hiddenColumns}
                                                column={column}/>
                        ))}
                    </tr>
                    </thead>
                    <tbody className={isDark ? '' : 'text-gray-700'} {...getTableBodyProps()}>
                    {rows.length > 0 ? (
                        rows.map((row: Row<TradeModel>, i) => {
                            prepareRow(row)
                            return <CustomRow row={row}
                                              key={`row-${i}-${row.id}`}
                                              hiddenColumns={hiddenColumns}
                                              account={account}
                                              setAccount={setAccount}
                                              setIsUpdateDeposit={setIsUpdateDeposit}/>
                        })
                    ) : (
                        <tr>
                            <td colSpan={columns.length}>
                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                    {intl.formatMessage({id: "TABLE.NO_RECORDS"})}
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            {isLoading && <Loader/>}
        </KTCardBody>
    )
}

export {TradeTable}
