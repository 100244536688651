import React, {FC} from 'react'
import {TradeModel} from "../core/_models";
import {useTradeModelListViewContext} from "../../../../common/provider/trade/TradeListViewProvider";

type Props = {
    trade: TradeModel
}

const TradeMediaCell: FC<Props> = (props) => {
    const {trade} = props
    const {setImagesView} = useTradeModelListViewContext()

    if (!trade.medias) {
        return null
    }
    return (
        <>
            {trade.medias.length > 0 && (
                <div className="btn position-relative me-5 cursor-pointer" onClick={() => setImagesView(trade)}>
                    <svg id="Layer_1" height="22" viewBox="0 0 512 512" width="22" xmlns="http://www.w3.org/2000/svg"
                         data-name="Layer 1">
                        <linearGradient id="GradientFill_1" gradientUnits="userSpaceOnUse" x1="255.999" x2="256"
                                        y1="471.697" y2="40.303">
                            <stop offset="0" stopColor="#6c54a3"/>
                            <stop offset="1" stopColor="#00b1d2"/>
                        </linearGradient>
                        <path
                            d="m207.26 308.953a43.11 43.11 0 1 0 -43.112-43.107 43.161 43.161 0 0 0 43.112 43.107zm288.508-152.132-71.712 267.6a7.009 7.009 0 0 1 -8.536 4.93l-15.151-4.062v-253.617a23 23 0 0 0 -22.97-22.979h-260.824l23.373-87.215a6.978 6.978 0 0 1 8.541-4.935l342.349 91.732a6.993 6.993 0 0 1 4.93 8.546zm-472.789 7.879h354.421a6.974 6.974 0 0 1 6.968 6.977v172.4l-69.72-49.728a8 8 0 0 0 -9.288 0l-110.942 79.118-79.439-56.654a8.02 8.02 0 0 0 -9.292 0l-89.687 63.964v-209.1a6.985 6.985 0 0 1 6.982-6.977zm472-31.872a23.006 23.006 0 0 1 16.241 28.139l-71.7 267.6a22.947 22.947 0 0 1 -28.139 16.25l-11.01-2.948v6.845a23 23 0 0 1 -22.971 22.986h-354.421a23.01 23.01 0 0 1 -22.979-22.987v-277.041a23.006 23.006 0 0 1 22.983-22.979h77.026l24.482-91.356a23.025 23.025 0 0 1 28.139-16.246z"
                            fill="url(#GradientFill_1)" fillRule="evenodd"/>
                    </svg>
                    <span className="position-absolute top-0 start-80 badge badge-circle badge-light-primary"
                          style={{marginBottom: '-10px', marginLeft: '-10px'}}>
                        {trade.medias.length}
                    </span>
                </div>
            )}
        </>
    )
}

export {TradeMediaCell}
