import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useIntl} from "react-intl";
import {MetaHeader} from "../../common/component/meta/MetaHeader";
import React from "react";

const AboutPageContent = () => {
    const intl = useIntl()
    const startYear = 2022
    const currentYear = new Date().getFullYear()

    return (
        <div className="d-flex flex-column flex-root">
            { /*begin::Header Section*/ }
            <div className="mb-0" id="home">
                { /*begin::Wrapper*/ }
                <div
                    className="bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg"
                    style={{backgroundImage: "url(media/svg/illustrations/landing.svg)"}}>
                    { /*begin::Header*/ }
                    <div className="landing-header" data-kt-sticky="true" data-kt-sticky-name="landing-header"
                         data-kt-sticky-offset="{default: '200px', lg: '300px'}" style={{animationDuration: "0.3s"}}>
                        { /*begin::Container*/ }
                        <div className="container">
                            { /*begin::Wrapper*/ }
                            <div className="d-flex align-items-center justify-content-between">
                                { /*begin::Logo*/ }
                                <div className="d-flex align-items-center flex-equal">
                                    { /*begin::Mobile menu toggle*/ }
                                    <button className="btn btn-icon btn-active-color-primary me-3 d-flex d-lg-none"
                                            id="kt_landing_menu_toggle">
                                        { /*begin::Svg Icon | path: icons/duotune/abstract/abs015.svg*/ }
                                        <span className="svg-icon svg-icon-2hx">
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none">
												<path
                                                    d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                                    fill="currentColor"></path>
												<path opacity="0.3"
                                                      d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                                      fill="currentColor"></path>
											</svg>
										</span>
                                        { /*end::Svg Icon*/ }
                                    </button>
                                    { /*end::Mobile menu toggle*/ }
                                    { /*begin::Logo image*/ }
                                    <a href="/">
                                        <img alt="Logo" src="media/logos/logo-dark.svg"
                                             className="logo-default h-25px h-lg-30px"/>
                                            <img alt="Logo" src="media/logos/logo.svg"
                                                 className="logo-sticky h-20px h-lg-25px"/>
                                    </a>
                                    { /*end::Logo image*/ }
                                </div>
                                { /*end::Logo*/ }
                                { /*begin::Menu wrapper*/ }
                                <div className="d-lg-block" id="kt_header_nav_wrapper">
                                    <div className="d-lg-block p-5 p-lg-0" data-kt-drawer="true"
                                         data-kt-drawer-name="landing-menu"
                                         data-kt-drawer-activate="{default: true, lg: false}"
                                         data-kt-drawer-overlay="true" data-kt-drawer-width="200px"
                                         data-kt-drawer-direction="start"
                                         data-kt-drawer-toggle="#kt_landing_menu_toggle" data-kt-swapper="true"
                                         data-kt-swapper-mode="prepend"
                                         data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav_wrapper'}">
                                        { /*begin::Menu*/ }
                                        <div
                                            className="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-500 menu-state-title-primary nav nav-flush fs-5 fw-bold"
                                            id="kt_landing_menu">
                                            { /*begin::Menu item*/ }
                                            <div className="menu-item">
                                                { /*begin::Menu link*/ }
                                                <a className="menu-link nav-link py-3 px-4 px-xxl-6"
                                                   href="/" data-kt-scroll-toggle="true"
                                                   data-kt-drawer-dismiss="true">Home</a>
                                                { /*end::Menu link*/ }
                                            </div>
                                            { /*end::Menu item*/ }
                                            { /*begin::Menu item*/ }
                                            <div className="menu-item">
                                                { /*begin::Menu link*/ }
                                                <a className="menu-link nav-link py-3 px-4 px-xxl-6 active" href="/about"
                                                   data-kt-scroll-toggle="true"
                                                   data-kt-drawer-dismiss="true">About</a>
                                                { /*end::Menu link*/ }
                                            </div>
                                            { /*end::Menu item*/ }
                                            { /*begin::Menu item*/ }
                                            <div className="menu-item">
                                                { /*begin::Menu link*/ }
                                                <a className="menu-link nav-link py-3 px-4 px-xxl-6" href="/contacts"
                                                   data-kt-scroll-toggle="true"
                                                   data-kt-drawer-dismiss="true">Contact Us</a>
                                                { /*end::Menu link*/ }
                                            </div>
                                            { /*end::Menu item*/ }
                                        </div>
                                        { /*end::Menu*/ }
                                    </div>
                                </div>
                                { /*end::Menu wrapper*/ }
                                { /*begin::Toolbar*/ }
                                <div className="flex-equal text-end ms-1">
                                    <a href="/auth"
                                       className="btn btn-success">Sign In</a>
                                </div>
                                { /*end::Toolbar*/ }
                            </div>
                            { /*end::Wrapper*/ }
                        </div>
                        { /*end::Container*/ }
                    </div>
                    { /*end::Header*/ }
                    { /*begin::Landing hero*/ }
                    <div className="d-flex flex-column flex-center w-100 min-h-350px min-h-lg-500px px-9">
                        { /*begin::Heading*/ }
                        <div className="text-center mb-5 mb-lg-10 py-10 py-lg-20">
                            { /*begin::Title*/ }
                            <h1 className="text-white lh-base fw-bolder fs-2x fs-lg-3x mb-15">
                                About Us
                            </h1>
                            { /*end::Title*/ }
                        </div>
                        { /*end::Heading*/ }
                        { /*begin::Clients*/ }

                        { /*end::Clients*/ }
                    </div>
                    { /*end::Landing hero*/ }
                </div>
                { /*end::Wrapper*/ }
                { /*begin::Curve bottom*/ }
                <div className="landing-curve landing-dark-color mb-10 mb-lg-20">
                    <svg viewBox="15 12 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                            fill="currentColor"></path>
                    </svg>
                </div>
                { /*end::Curve bottom*/ }
            </div>
            { /*end::Header Section*/ }
            { /*begin::Projects Section*/ }
            <div className="mb-lg-n15 position-relative z-index-2">
                { /*begin::Container*/ }
                <div className="container">
                    { /*begin::Card*/ }
                    <div className="card" style={{filter: "drop-shadow(0px 0px 40px rgba(68, 81, 96, 0.08))"}}>
                        { /*begin::Card body*/ }
                        <div className="card-body p-lg-20">
                            { /*begin::Tabs content*/ }
                            <div className="tab-content fw-bold fs-5">
                                {intl.formatMessage({id: 'PAGE.ABOUT.BODY.LINE_1'})}&nbsp;
                                {intl.formatMessage({id: 'PAGE.ABOUT.BODY.LINE_2'})}&nbsp;
                                {intl.formatMessage({id: 'PAGE.ABOUT.BODY.LINE_3'})}&nbsp;
                                {intl.formatMessage({id: 'PAGE.ABOUT.BODY.LINE_4'})}&nbsp;
                                <br/><br/>
                                {intl.formatMessage({id: 'PAGE.ABOUT.BODY.LINE_6'})}&nbsp;
                                {intl.formatMessage({id: 'PAGE.ABOUT.BODY.LINE_7'})}&nbsp;
                                {intl.formatMessage({id: 'PAGE.ABOUT.BODY.LINE_8'})}&nbsp;
                                {intl.formatMessage({id: 'PAGE.ABOUT.BODY.LINE_9'})}&nbsp;
                                {intl.formatMessage({id: 'PAGE.ABOUT.BODY.LINE_10'})}&nbsp;
                                <br/><br/>
                                {intl.formatMessage({id: 'PAGE.ABOUT.BODY.LINE_12'})}&nbsp;
                                {intl.formatMessage({id: 'PAGE.ABOUT.BODY.LINE_13'})}
                            </div>
                        </div>
                        { /*end::Card body*/ }
                    </div>
                    { /*end::Card*/ }
                </div>
                { /*end::Container*/ }
            </div>
            { /*end::Projects Section*/ }
            { /*begin::Testimonials Section*/ }
            <div className="mb-n20 position-relative z-index-0">
                { /*begin::Container*/ }
                <div className='mt-lg-n20 position-relative z-index-0'>
                    <div className="container" style={{height: "300px"}}></div>
                </div>
                { /*end::Container*/ }
            </div>
            { /*end::Testimonials Section*/ }
            { /*begin::Footer Section*/ }
            <div className="mb-0">
                { /*begin::Curve top*/ }
                <div className="landing-curve landing-dark-color">
                    <svg viewBox="15 -1 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                            fill="currentColor"></path>
                    </svg>
                </div>
                { /*end::Curve top*/ }
                { /*begin::Wrapper*/ }
                <div className="landing-dark-bg pt-20">
                    { /*begin::Container*/ }
                    <div className="container">
                        { /*begin::Row*/ }
                        <div className="row py-10 py-lg-20">
                            { /*begin::Col*/ }
                            <div className="col-lg-6 pe-lg-16 mb-10 mb-lg-0">
                                { /*begin::Block*/ }
                                <div className="rounded landing-dark-border p-9 mb-10">
                                    { /*begin::Title*/ }
                                    <h2 className="text-white">Risk Disclosure</h2>
                                    { /*end::Title*/ }
                                    { /*begin::Text*/ }
                                    <span className="fw-normal fs-4 text-gray-700">
                                        We do not sell or advertise investments of any kind.
                                        We are not an investment or trading company.
                                    </span>
                                    { /*end::Text*/ }
                                </div>
                                { /*end::Block*/ }
                            </div>
                            { /*end::Col*/ }
                            { /*begin::Col*/ }
                            <div className="col-lg-6 ps-lg-16">
                                { /*begin::Navs*/ }
                                <div className="d-flex justify-content-center">
                                    { /*begin::Links*/ }
                                    <div className="d-flex fw-bold flex-column me-20">
                                        { /*begin::Subtitle*/ }
                                        <h4 className="fw-bolder text-gray-400 mb-6">More for Trades Journal</h4>
                                        { /*end::Subtitle*/ }
                                        { /*begin::Link*/ }
                                        <a href="/"
                                           className="text-white opacity-50 text-hover-primary fs-5 mb-6">Home</a>
                                        { /*end::Link*/ }
                                        { /*begin::Link*/ }
                                        <a href="/contacts"
                                           className="text-white opacity-50 text-hover-primary fs-5 mb-6">Contact Us</a>
                                        { /*end::Link*/ }
                                        { /*begin::Link*/ }
                                        <a href="/about"
                                           className="text-white opacity-50 text-hover-primary fs-5 mb-6">About</a>
                                        { /*end::Link*/ }
                                        { /*begin::Link*/ }
                                        <a href="auth/registration"
                                           className="text-white opacity-50 text-hover-primary fs-5 mb-6">Sign Up</a>
                                        { /*end::Link*/ }
                                    </div>
                                    { /*end::Links*/ }
                                    { /*begin::Links*/ }
                                    <div className="d-flex fw-bold flex-column ms-lg-20">
                                        { /*begin::Subtitle*/ }
                                        <h4 className="fw-bolder text-gray-400 mb-6">Stay Connected</h4>
                                        { /*end::Subtitle*/ }
                                        { /*begin::Link*/ }
                                        <a href="https://www.facebook.com/keenthemes" className="mb-6">
                                            <img src="media/svg/brand-logos/facebook-4.svg"
                                                 className="h-20px me-2" alt=""/>
                                                <span
                                                    className="text-white opacity-50 text-hover-primary fs-5 mb-6">Facebook</span>
                                        </a>
                                        { /*end::Link*/ }
                                        { /*begin::Link*/ }
                                        <a href="https://twitter.com/mtrades_journal" className="mb-6">
                                            <img src="media/svg/brand-logos/twitter.svg" className="h-20px me-2"
                                                 alt=""/>
                                                <span
                                                    className="text-white opacity-50 text-hover-primary fs-5 mb-6">Twitter</span>
                                        </a>
                                        { /*end::Link*/ }
                                        { /*begin::Link*/ }
                                        <a href="https://www.instagram.com/keenthemes" className="mb-6">
                                            <img src="media/svg/brand-logos/instagram-2-1.svg"
                                                 className="h-20px me-2" alt=""/>
                                                <span
                                                    className="text-white opacity-50 text-hover-primary fs-5 mb-6">Instagram</span>
                                        </a>
                                        { /*end::Link*/ }
                                    </div>
                                    { /*end::Links*/ }
                                </div>
                                { /*end::Navs*/ }
                            </div>
                            { /*end::Col*/ }
                        </div>
                        { /*end::Row*/ }
                    </div>
                    { /*end::Container*/ }
                    { /*begin::Separator*/ }
                    <div className="landing-dark-separator"></div>
                    { /*end::Separator*/ }
                    { /*begin::Container*/ }
                    <div className="container">
                        { /*begin::Wrapper*/ }
                        <div className="d-flex flex-column flex-md-row flex-stack py-7 py-lg-10">
                            { /*begin::Copyright*/ }
                            <div className="d-flex align-items-center order-2 order-md-1">
                                { /*begin::Logo*/ }
                                <a href="/">
                                    <img alt="Logo" src="media/logos/logo-dark.svg"
                                         className="h-15px h-md-20px"/>
                                </a>
                                { /*end::Logo image*/ }
                                { /*begin::Logo image*/ }
                                <span className="mx-5 fs-6 fw-bold text-gray-600 pt-1">
                                    &copy;&nbsp;
                                    {currentYear > startYear ? startYear + ' - ' + currentYear : currentYear}
                                    &nbsp;Trades Journal
                                </span>
                                { /*end::Logo image*/ }
                            </div>
                            { /*end::Copyright*/ }
                            { /*begin::Menu*/ }
                            <ul className="menu menu-gray-600 menu-hover-primary fw-bold fs-6 fs-md-5 order-1 mb-5 mb-md-0">
                                <li className="menu-item">
                                    <a href="/about" className="menu-link px-2">About</a>
                                </li>
                            </ul>
                            { /*end::Menu*/ }
                        </div>
                        { /*end::Wrapper*/ }
                    </div>
                    { /*end::Container*/ }
                </div>
                { /*end::Wrapper*/ }
            </div>
            { /*end::Footer Section*/ }
            { /*begin::Scrolltop*/ }
            <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                { /*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/ }
                <span className="svg-icon">
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
						<rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)"
                              fill="currentColor"></rect>
						<path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="currentColor"></path>
					</svg>
				</span>
                { /*end::Svg Icon*/ }
            </div>
            { /*end::Scrolltop*/ }
        </div>
    )
}

const AboutPage = () => {
    return (
        <>
            <MetaHeader pageName='HTML.HEAD.TITLE.ABOUT'/>
            <AboutPageContent />
        </>
    )
}

export {AboutPage}
