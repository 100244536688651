import {ID, Response, toAbsoluteStaticUrl} from "../../../../_metronic/helpers";
import ReactQuill, {Quill} from "react-quill";
import {useIntl} from "react-intl";
import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {JournalCategoryDto, JournalPostDto} from "../JournalPage";
import {useUserSettingsContext} from "../../../common/provider/settings/UserSettingsDataProvider";
import {UserSettingsMapper} from "../../../common/mapper/settings/UserSettingsMapper";
import axios, {AxiosResponse} from "axios";
import {JsonApiArrayDto, JsonApiObjectDto} from "../../../common/dto/json-api/JsonApiDto";
import {logger} from "../../../../index";
import {TradeMediaModel} from "../../trades/table/core/_models";
import * as Yup from "yup";
import {useFormik} from "formik";
import {updateUserSettings} from "../../../common/request/settings/UserSettingsRequest";
import clsx from "clsx";
import EditorToolbar from "./EditorToolbar";
// @ts-ignore
import ImageResize from 'quill-image-resize-module-react';
import './quill.css';
import {Loader} from "../../../common/loader/Loader";
import {useThemeMode} from "../../../../_metronic/partials";

type NewJournalPostDto = {
    categoryId: ID,
    title: string,
    data: string,
}

const initQuill = () => {
    Quill.register('modules/imageResize', ImageResize)

    const Block = Quill.import('blots/block')
    Block.tagName = 'DIV'

    // Avoid large spaces between rows when use default <p> tags as wrapper
    Quill.register(Block, true)

    const Size = Quill.import('attributors/style/size')
    Size.whitelist = ['14px', '16px', '18px', '20px', '22px', '24px', '32px']

    Quill.register(Size, true)
}

initQuill()

const JournalEditPostPageContent = () => {
    const intl = useIntl()
    const {isDark} = useThemeMode()
    const {id: postId} = useParams()
    const navigate = useNavigate();
    const [value, setValue] = useState('')
    const [editPost, setEditPost] = useState<JournalPostDto>()
    const [categories, setCategories] = useState<Array<JournalCategoryDto>>([])
    const quillRef = useRef<ReactQuill | null>(null)
    const {response: userSettings} = useUserSettingsContext()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const selectedCategoryId = userSettings?.settings?.journal?.selectedCategoryId
    const userSettingsMapper = new UserSettingsMapper()

    useEffect(() => {
        const API_URL = process.env.REACT_APP_API_URL
        const JOURNAL_CATEGORY_URL = `${API_URL}/journals/categories`

        axios.get(JOURNAL_CATEGORY_URL)
            .then((response: AxiosResponse<JsonApiArrayDto<JournalCategoryDto>>) => {
                const data = response.data?.data?.map(function (value) {
                    const item = value.attributes
                    item.id = value.id as any

                    return item
                }) || []

                setCategories(data)
            }).catch(error => {
                logger.error("Could not get journals categories! Reason: " + error.message)
            })
    }, [])

    const imageHandler = () => {
        const input = document.createElement('input');

        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            if (!input.files) {
                return
            }
            const file: any = input.files[0];
            const formData = new FormData();

            formData.append('image', file);

            return uploadFiles(file, file.name, quillRef)
        }
    }

    const modules = useMemo(() => {
        const fontSize = Quill.import('attributors/style/size')

        return {
            toolbar: {
                container: [
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                    //[{ 'font': ['Roboto'] }],
                    [{ 'size': fontSize.whitelist }],
                    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                    ['bold', 'italic', 'underline', 'strike'],

                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    [{ 'indent': '-1'}, { 'indent': '+1' }],

                    ['blockquote', 'code-block'],

                    [{ 'color': [] }, { 'background': [] }],

                    ['link', 'image']
                ],
                handlers: {
                    image: imageHandler
                }
            },
            imageResize: {
                parchment: Quill.import('parchment'),
                modules: ['Resize', 'DisplaySize', 'Toolbar']
            }
        }
    }, [])

    const uploadFiles = (uploadFile: File, fileName: string, quill: any) => {
        const formData = new FormData();
        formData.append('file', uploadFile);

        const API_URL = process.env.REACT_APP_API_URL
        const MEDIA_URL = `${API_URL}/medias`

        return axios.post(MEDIA_URL, formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then((response: AxiosResponse<Response<TradeMediaModel>>) => {
                const range = quill.current.getEditorSelection()
                quill.current
                    .getEditor()
                    .insertEmbed(range.index, 'image', toAbsoluteStaticUrl('/static/' + response?.data?.data?.id), 'user')
            }).catch(error => {
                logger.error("Upload media error: " + error.message)
            })
    }
    const [initialPostValues] = useState<NewJournalPostDto>({
        categoryId: undefined,
        title: '',
        data: '',
    })
    const newJournalPostSchema = Yup.object().shape({
        categoryId: Yup.string()
            .min(1, intl.formatMessage({id: 'JOURNAL.POST.CREATE.VALIDATION.MINIMUM.SYMBOLS'}).replace('%VALUE%', '1'))
            .max(256, intl.formatMessage({id: 'JOURNAL.POST.CREATE.VALIDATION.MAXIMUM.SYMBOLS'}).replace('%VALUE%', '256'))
            .required(intl.formatMessage({id: 'JOURNAL.POST.CREATE.VALIDATION.REQUIRED'})),
        title: Yup.string()
            .min(5, intl.formatMessage({id: 'JOURNAL.POST.CREATE.VALIDATION.MINIMUM.SYMBOLS'}).replace('%VALUE%', '1'))
            .max(256, intl.formatMessage({id: 'JOURNAL.POST.CREATE.VALIDATION.MAXIMUM.SYMBOLS'}).replace('%VALUE%', '256'))
            .required(intl.formatMessage({id: 'JOURNAL.POST.CREATE.VALIDATION.REQUIRED'})),
    })

    const formik = useFormik({
        initialValues: initialPostValues,
        validationSchema: newJournalPostSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            if (!value) {
                return
            }
            const API_URL = process.env.REACT_APP_API_URL
            const ACCOUNT_URL = `${API_URL}/journals`
            let response: Promise<AxiosResponse<any>>

            if (!editPost) {
                response = axios.post(ACCOUNT_URL, {categoryId: values.categoryId, title: values.title, data: value})
            } else {
                response = axios.put(`${ACCOUNT_URL}/posts/${editPost.id}`, {categoryId: values.categoryId, title: values.title, data: value})
            }
            response.then((response: AxiosResponse<Response<JournalPostDto>>) => {
                navigateToJournalPage()
            }).catch(error => {
                logger.error(error.message)
            })
        }
    })

    useEffect(() => {
        const API_URL = process.env.REACT_APP_API_URL
        const JOURNAL_POST_URL = `${API_URL}/journals/posts/${postId}`

        setIsLoading(true)

        axios.get(JOURNAL_POST_URL)
            .then((response: AxiosResponse<JsonApiObjectDto<JournalPostDto>>) => {
                if (!response.data?.data?.attributes) {
                    return
                }
                const data = response.data?.data?.attributes
                data.id = response.data?.data?.id as any

                setEditPost(data)

                formik.values.categoryId = data.journalCategory.id
                formik.values.title = data.title

                setValue(data.post)
            }).catch(error => {
                logger.error("Could not get journal post: " + postId + "! Reason: " + error.message)
            }).finally(() => setIsLoading(false))
    }, [postId !== null])

    const navigateToJournalPage = () => {
        const updatedUserSettings = userSettingsMapper.updateJournalCategoryId(userSettings, formik.values.categoryId)

        updateUserSettings(updatedUserSettings)

        navigate("/journals")
    }

    return (
        <div className="content d-flex flex-column flex-column-fluid container-xxl" id="kt_content">
            {/*begin::Post*/}
            <div className="post d-flex flex-column-fluid" id="kt_post">
                {/*begin::Container*/}
                <div id="kt_content_container" className="container-fluid ">
                    <form className="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework"
                          onSubmit={formik.handleSubmit}>
                        {/*begin::Main column*/}
                        <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                            {/*begin::General options*/}
                            <div className="card card-flush py-4">
                                {/*begin::Card body*/}
                                <div className="card-body pt-0">
                                    {/*begin::Input group*/}
                                    <div className="mb-10 fv-row fv-plugins-icon-container">
                                        {/*begin::Label*/}
                                        <label className="required form-label text-muted">Category</label>
                                        {/*end::Label*/}
                                        {/*begin::Input*/}
                                        <select
                                            {...formik.getFieldProps('categoryId')}
                                            data-placeholder="Select a category..."
                                            className="form-select form-select-solid form-select-lg select2-hidden-accessible"
                                            aria-hidden="true" defaultValue={selectedCategoryId as any}>
                                            <option>Select a Category...</option>
                                            {categories.map(category => (
                                                <option value={category.id as any}>{category.name}</option>
                                            ))}
                                        </select>
                                        {/*end::Input*/}
                                        {/*begin::Description*/}
                                        {formik.touched.categoryId && formik.errors.categoryId && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>Field is required.</span>
                                                </div>
                                            </div>
                                        )}
                                        {/*end::Description*/}
                                        <div className="fv-plugins-message-container invalid-feedback"></div></div>
                                    {/*end::Input group*/}
                                    {/*begin::Input group*/}
                                    <div className="mb-10 fv-row fv-plugins-icon-container">
                                        {/*begin::Label*/}
                                        <label className="required text-muted form-label">Post Title</label>
                                        {/*end::Label*/}
                                        {/*begin::Input*/}
                                        <input type="text"
                                               {...formik.getFieldProps('title')}
                                               className={clsx(
                                                   'form-control form-control-solid mb-3 mb-lg-0',
                                                   {'is-invalid': formik.touched.title && formik.errors.title},
                                                   {'is-valid': formik.touched.title && !formik.errors.title}
                                               )}
                                               placeholder="Title"/>
                                        {formik.touched.title && formik.errors.title && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.title}</span>
                                                </div>
                                            </div>
                                        )}
                                        {/*end::Input*/}
                                        {/*begin::Description*/}
                                        <div className="text-muted fs-7">A post title is required and recommended
                                            to be unique, ie APPL bad news (-9%) - low revenue in 3-d quarter
                                            (the date will be added automatically on save).
                                        </div>
                                        {/*end::Description*/}
                                        <div className="fv-plugins-message-container invalid-feedback"></div></div>
                                    {/*end::Input group*/}
                                    {/*begin::Input group*/}
                                    <div>
                                        {/*begin::Label*/}
                                        <label className="form-label text-muted">Description</label>
                                        {/*end::Label*/}
                                        <div>
                                            <EditorToolbar />
                                            <ReactQuill
                                                theme={isDark ? "snow" : "snow"}
                                                value={value}
                                                onChange={setValue}
                                                placeholder={"Type your note..."}
                                                modules={modules}
                                                ref={quillRef}
                                            />
                                        </div>
                                    </div>
                                    {/*end::Input group*/}
                                </div>
                                {/*end::Card header*/}
                                {isLoading && <Loader/>}
                            </div>
                            {/*end::General options*/}
                            <div className="d-flex justify-content-end">
                                {/*begin::Button*/}
                                <Link to='/journals'>
                                    <button type="reset" className="btn btn-light me-5"
                                            onClick={navigateToJournalPage}>
                                        {intl.formatMessage({id: 'BUTTON.CANCEL'})}
                                    </button>
                                </Link>
                                {/*end::Button*/}
                                {/*begin::Button*/}
                                <button type="submit"
                                        className="btn btn-primary"
                                        disabled={formik.isSubmitting || !formik.isValid || !formik.touched || isLoading}>
                                    <span className="indicator-label">
                                        {intl.formatMessage({id: 'BUTTON.SAVE'})}
                                    </span>
                                    <span className="indicator-progress" >Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                                {/*end::Button*/}
                            </div>
                        </div>
                        {/*end::Main column*/}
                    </form>
                </div>
                {/*end::Container*/}
            </div>
            {/*end::Post*/}
        </div>
    )
}

export {
    JournalEditPostPageContent
}
